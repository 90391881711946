import React from 'react'
import Frame from './frame'
import focus from '../icons/focus.svg'
import crop from '../icons/crop.svg'
import glassesIcon from '../images/glassesFace.svg'

import Glasses from '../functions/fabricFunctions/fabricGlasses.js'




class NavigationViewer extends React.Component {

  static defaultProps = {
    pictureType : 'facePicture'
  }

  constructor(props) {

    super(props)

    this.state = {
      crop : false,
      glassesVisibility : false,
      glasses : false
    }

  }

  componentDidMount = () => {
   // this.drawGlasses()
    ['facePicture', 'spreaderFacePicture', 'facePictureWithSmile','leftSidePicture', 'rightSidePicture'].includes(this.props.pictureType)? this.setState({glasses : true}) : this.setState({glasses : false})
   
    if(this.props.canvas) this.drawGlasses()
    if(this.props.loadingData) this.setCrop(this.props.loadingData.data.crop)


      

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  if (this.props.canvas !== prevProps.canvas) {
    if(this.props.canvas) this.drawGlasses()
  }

if (this.props.loadingData !== prevProps.loadingData) {
    this.setCrop(this.props.loadingData.data.crop)
     
  }

    
  }

  drawGlasses= async ()=>{



    this.glasses=new Glasses(this.props.canvas)
    await this.glasses.create(this.props.pictureType==='leftSidePicture'? 'leftSide' : this.props.pictureType==='rightSidePicture'? 'rightSide' : 'face')
    //console.log('load', this.props.loadingData.data.anonym)
    if (this.props.loadingData.success && this.props.loadingData.data.anonym) {
      this.glasses.loadData(this.props.loadingData.data.anonym)
      //this.setState({glassesVisibility : this.props.loadingData.data.anonym.visibility})
      this.setVisibilityGlasses(this.props.loadingData.data.anonym.visibility)
    }


    // this.glasses.setVisibility(true)
  }



  setVisibilityGlasses = value=>{

   // console.log(e.target.checked)
    this.glasses.setVisibility(value)
    this.setState({glassesVisibility : value})
    
  }

  resetPositionGlasses=e=>this.glasses.resetPosition()


  setCrop = value=>{   
    this.setState({crop : value}, ()=>this.props.setCrop(value))
  }

  getGlassesOption = ()=> this.state.glasses? this.glasses.getValues() : false




  render = () => {

     const frameStyle = {
      position : 'absolute',
      zIndex : 6,
      bottom : 40, left : 40, ...this.props.style
    }

    const divStyle = {
      position : 'relative',
      display : 'flex', justifyContent : 'space-between', alignItems : 'center',
      //flexDirection : 'column',
      padding : 10,
    }

  
    return (
       <Frame style={frameStyle} label="Options">

        <div style = {divStyle}>
          
         
        {this.state.glasses &&  <div>
          <input type='checkbox' onChange={e=>this.setVisibilityGlasses(e.target.checked)}  checked={this.state.glassesVisibility} style={{cursor : 'pointer'}}/>
          <img title='Anonymiser la photographie' src={glassesIcon} height ={15} onClick={this.resetPositionGlasses} style={{cursor : 'pointer'}} alt='glass'/>
        </div>}

        <div>
          <img title = "Centrer l'image" alt='pan' src={focus} width ={40} onClick={this.props.focus} style={{cursor : 'pointer'}}/> 
        </div>

        <div>
        <input type='checkbox' onChange={e=>this.setCrop(e.target.checked)} checked={this.state.crop} style={{position : 'relative', top : -10, cursor : 'pointer'}}/>
        <img title="Découper les bords de l'image" src={crop} width ={40} alt='crop'/>
        </div>

        

      </div>

    </Frame>

    )

  }

}







export default NavigationViewer