const LandMarksFaceObject = (landmarks)=> [

  {
    name : 'eyeLineLandmark',
    frenchName : 'Ligne pupillaire',
    lineCoords : [0, 900, 1800, 900],
    dash : false,
    prolongation : false
  },
  {
    name : 'centerLine',
    frenchName : 'Ligne de symétrie',
    lineCoords : [900, 0, 900, 1800],
    dash : false,
    prolongation : false
  },
   {
    name : 'noseLandmark',
    frenchName : 'Pointe nasale',
    lineCoords : [landmarks.nose.x, 900, landmarks.nose.x, 1800],
    dash : true,
    prolongation : false
  }

]


const LandMarksSpreaderFaceObject = (landmarks)=> [

  {
    name : 'eyeLineLandmark',
    frenchName : 'Ligne pupillaire',
    lineCoords : [0, 900, 1800, 900],
    dash : false,
    prolongation : false
  },
  {
    name : 'centerLine',
    frenchName : 'Ligne de symétrie',
    lineCoords : [900, 0, 900, 1800],
    dash : false,
    prolongation : false
  },
   {
    name : 'noseLandmark',
    frenchName : 'Pointe nasale',
    lineCoords : [landmarks.nose.x, 900, landmarks.nose.x, 1800],
    dash : true,
    prolongation : false
  },
  {
    name : 'occlusalPlane',
    frenchName : "Plan d'occlusion",
    lineCoords : [landmarks.rightTooth.x, landmarks.rightTooth.y, landmarks.leftTooth.x, landmarks.leftTooth.y],
    dash : false,
    prolongation : true
  }


]



const LandMarksSideObject = (landmarks)=>[
  {
    name : 'francfort',
    frenchName : "Plan de Francfort",
    lineCoords : [0, landmarks.ear.y, 1800, landmarks.ear.y],
    dash : false,
    prolongation : false
  },
  {
    name : 'eyeLineLandmark',
    frenchName : "Ligne pupillaire",
    lineCoords : [0, landmarks.eye.y, 1800, landmarks.eye.y],
    dash : false,
    prolongation : false
  },
   {
    name : 'noseLandmark',
    frenchName : "Pointe nasale",
    lineCoords : [landmarks.nose.x, 900, landmarks.nose.x, 1800],
    dash : true,
    prolongation : false
  }

]


const LandMarksTeethFaceObject = (landmarks)=>[
  {
    name : 'middleLandmark',
    frenchName : "Plan de symétrie",
    lineCoords : [900, 0, 900, 1800],
    dash : false,
    prolongation : false
  },
  {
    name : 'occlusalPlane',
    frenchName : "plan d'occlusion",
    lineCoords : [landmarks.rightCanin.x, landmarks.rightCanin.y, landmarks.leftCanin.x, landmarks.leftCanin.y],
    dash : false,
    prolongation : true
  }
]


const LandMarksTeethOpenFaceObject = (landmarks)=>[
  {
    name : 'middleLandmark',
    frenchName : "Plan de symétrie",
    lineCoords : [900, 0, 900, 1800],
    dash : false,
    prolongation : false
  },
  {
    name : 'occlusalPlane',
    frenchName : "plan d'occlusion",
    lineCoords : [landmarks.rightCanin.x, landmarks.rightCanin.y, landmarks.leftCanin.x, landmarks.leftCanin.y],
    dash : false,
    prolongation : true
  },
  {
    name : 'middleMandibular',
    frenchName : "Point interinsicif mandibulaire",
    lineCoords : [landmarks.middleMandibular.x, 0, landmarks.middleMandibular.x, 1800],
    dash : true,
    prolongation : false
  },
  {
    name : 'mandibularPlane',
    frenchName : "Plan mandibulaire",
    lineCoords : [landmarks.leftCaninMandibular.x, landmarks.leftCaninMandibular.y, landmarks.rightCaninMandibular.x, landmarks.rightCaninMandibular.y],
    dash : true,
    prolongation : true
  }

]


const LandMarksTeethOcclObject = landmarks=>[
  {
    name : 'interSissor',
    frenchName : 'Ligne inter-incisif',
    lineCoords : [900,0,900,1800],
    dash : false,
    prolongation : false
  },
  {
    name : 'molarLine',
    frenchName : 'Ligne 1er molaires',
    lineCoords : [0,landmarks.rightMolar.y,1800,landmarks.rightMolar.y],
    dash : false,
    prolongation : false
  },
  {
    name : 'middleLandmark',
    frenchName : 'Ligne de symétrie',
    lineCoords : [(landmarks.rightMolar.x+landmarks.leftMolar.x)/2,0,(landmarks.rightMolar.x+landmarks.leftMolar.x)/2,1800],
    dash : true,
    prolongation : false
  }
]



const LandMarksTeethSideObject = (landmarks)=>[
  {
    name : 'occlusalPlane',
    frenchName : "Plan d'occlusion'",
    lineCoords : [landmarks.canin.x, landmarks.canin.y, landmarks.molar.x, landmarks.molar.y],
    dash : false,
    prolongation : true
  }
]


const LandMarksOrthopantoObject = (landmarks)=>[
  {
    name : 'horizontally',
    frenchName : "Ligne horizontale",
    lineCoords : [landmarks.rightMolar.x, landmarks.rightMolar.y, landmarks.leftMolar.x, landmarks.leftMolar.y],
    dash : false,
    prolongation : true
  },
   {
    name : 'middleLandmark',
    frenchName : 'Ligne de symétrie',
    lineCoords : [landmarks.middle.x,0,landmarks.middle.x,1800],
    dash : false,
    prolongation : false
  },
  {
    name : 'middleMandibular',
    frenchName : "Point interinsicif mandibulaire",
    lineCoords : [landmarks.middleMandibular.x, 0, landmarks.middleMandibular.x, 1800],
    dash : true,
    prolongation : false
  }

]


const LandMarksCephaloObject = (landmarks)=>[
  
   {
    name : 'francfort',
    frenchName : "Plan de Francfort",
    lineCoords : [landmarks.ear.x, landmarks.ear.y, landmarks.orbit.x, landmarks.orbit.y],
    dash : false,
    prolongation : true
  },
  
   
]







export {
  LandMarksFaceObject, 
  LandMarksSpreaderFaceObject, 
  LandMarksSideObject, 
  LandMarksTeethFaceObject, 
  LandMarksTeethOpenFaceObject, 
  LandMarksTeethSideObject,
  LandMarksTeethOcclObject,
  LandMarksOrthopantoObject,
  LandMarksCephaloObject
}