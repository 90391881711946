import React from 'react'
import Frame from './frame'
import hashParse from '../functions/hashParse'
import download from 'downloadjs'
import camera from '../icons/photograph.svg'
import WrapComponent from '../contexts/wrap'


class Export extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
     // date : new Date()
    }

  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

  backToPrepare = e=>{
    this.props.save()
    window.location.hash  = window.location.hash.split('?')[0]+'?page='+hashParse()[1].hashVar.page+'&view=preparePicture&type='+this.props.type
  }

  exitWithoutSave =e=>{
    window.location.hash  = window.location.hash.split('?')[0]+'?page='+hashParse()[1].hashVar.page
  }

  exitWithSave =e=>{
    this.props.save()
    window.location.hash  = window.location.hash.split('?')[0]+'?page='+hashParse()[1].hashVar.page
  }

  drawPicture = async e=>{
    this.fabricCanvas=this.props.presentationCanvas.canvas
    
    let twoNumbers = number=> Number(number)>9 ? number : '0'+number 

   

    let dataURLBigSize = this.fabricCanvas.toDataURL()
    //let dataURLMinisize = await resizeImage(dataURLBigSize, 300)

   
    let url = dataURLBigSize
   

   
    let creationDate = new Date(this.props.context.fileData.creationDate)

    let date = `${twoNumbers(creationDate.getDate())}${twoNumbers(creationDate.getMonth()+1)}${creationDate.getFullYear()}`
    let fileTitle = (
      this.props.context.patientData.name.lastname.slice(0,3)+
      this.props.context.patientData.name.firstname.slice(0,3)+'_'+
      date+'_encombrement.jpg'
    )


     download(url, fileTitle)

 
     
  }

 

  render = () => {

     const frameStyle = {
      position : 'absolute',
      top : 5, right : 40,
      width : 230, 
      display : 'flex', justifyContent : 'center', flexDirection : 'column',
      ...this.props.style
    }

    const divStyle = {
     // border : 'solid',
      position : 'relative', left : -10, height : 40,
      display : 'flex', justifyContent : 'space-around', alignItems : 'center',
      zIndex : this.state.select? 3 : 1,
      padding : 5
      //backgroundColor : 'white'

    }

    const iconStyle = {
      width : 40,
      cursor : 'pointer'
    }

     const checkStyle = {
    color : 'green',
    // opacity : props.validate? 1 : 0,
    // pointerEvents : props.validate? 'auto' : 'none',
    transition : 'all 0.3s',
    cursor : 'pointer',
    fontSize : '2.5em', fontWeight : 1000
  }

  const uncheckStyle = {
    color : 'red',
    cursor :'pointer',
    fontSize : '2.5em', fontWeight : 1000
  }

    return (

      <Frame style={frameStyle} label="Sortie">

        <div style ={divStyle}>

          {this.props.context.creator && <div title='Enregistrer les modifications' style={checkStyle} onClick={this.exitWithSave}>✓</div>}

          <div title='Annuler les modifications' style={uncheckStyle} onClick={this.exitWithoutSave}>✗</div>

          <img title='Exporter la photographie' src={camera} style={iconStyle} onClick={this.drawPicture} alt='camera'/>

        </div>

      </Frame>
      


    )

  }

}

export default WrapComponent(Export)