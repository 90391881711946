import React from 'react'
import {LastNameInput, FirstNameInput, MailInput} from './formatedInput'
import {CreatePassword} from './passInput'
import Frame from './frame'
import CustomButton from './customButton'
import Loader from './loader'
import cryptoJS from 'crypto-js'
import socket from '../modules/socket'
import Popup from './popup'



class Subscribe extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      loader : false,
      popup : false,

      firstname : {value : '',validation : false},
      lastname : {value : '',validation : false},
      mail : {value : '',validation : false},
      password : {value : '',validation : false}

    }

  }

  

  returnValue = (stateObject)=>this.setState(stateObject)
 

  pushSubscribe = e=>{
    
    let objectSubscribe = {
      name : {firstname : this.state.firstname.value, lastname : this.state.lastname.value},
      mail : this.state.mail.value,
      password : cryptoJS.SHA512(this.state.password.value).toString(cryptoJS.enc.Base64),
    }

   // console.log(objectSubscribe)
    socket.emit('fetchSubscribe',objectSubscribe,this.callbackSubscribe)
    this.setState({loader : true})
  }



  callbackSubscribe = answer=>{
    let state = Object.assign({}, {loader : false, popup : answer})
    this.setState(state)
  }



  return = ()=>window.location.hash = 'connexion'

  closePopup = exit=> { 
    exit? window.location.hash = 'connexion' : this.setState({popup : false})
  }

  render = () => {
    
   

    const frameStyle = {
      marginTop: 'auto', 
      marginBottom: 'auto',
      maxWidth : 400, width : '80vw',
      paddingLeft : 20,
    }

    const formStyle = {
      position : 'relative',
      //border : 'solid',
       display : 'flex', flexWrap : 'wrap',
       flexDirection : 'row', justifyContent : 'space-evenly',
       width : '100%',
    }

    const divButtonStyle = {
      position : 'relative',
     // border : 'solid',
       display : 'flex',
       justifyContent : 'center',
       width : '100%'
    }
   

    const buttonValidation = (
      this.state.firstname.validation &&
      this.state.lastname.validation  &&
      this.state.mail.validation &&
      this.state.password.validation 
    )

    return (

      <>

      {this.state.loader && <Loader/>}

      {this.state.popup && <Popup success = {this.state.popup.success} error={this.state.popup.error} msg={this.state.popup.msg} handleClick={()=>this.closePopup(this.state.popup.success)}/>}


      <Frame label='Inscription' style = {frameStyle} close={true} onClick={this.return}>

       <form>

        <div style={formStyle}>

          <MailInput
            value={value=>this.returnValue({mail : {value : value.unmaskedValue, validation : value.validation}})}
            localStorage = 'userMail'
          />

          <LastNameInput 
            value={value=>this.returnValue({lastname : {value : value.unmaskedValue, validation : value.validation}})}
          />

          <FirstNameInput 
            value={value=>this.returnValue({firstname : {value : value.unmaskedValue, validation : value.validation}})}
          />

  

          <CreatePassword 
            value={value=>this.returnValue({password : {value : value.pw, validation : value.validation}})}
          />


        </div>



        <div style = {divButtonStyle}>

          <CustomButton 
            label="S'inscrire"
            active={buttonValidation}
            handleClick = {this.pushSubscribe}
            activeEnter = {!this.state.popup}

          />  

        </div>

         
      </form>


      </Frame>

      </>
    )

  }

}



export default Subscribe