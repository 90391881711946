import React from 'react'
import Connexion from '../components/connexion'
import Main from '../components/main'
import hashParse from '../functions/hashParse'
import StoreProvider from '../contexts/storeProvider'


class Application extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
     page : '',
     loader : false
    }

  }

  componentDidMount =  () => {

    window.location.hash = ''
    window.addEventListener('hashchange', this.hashPage)

    // navigator.clipboard.readText().then(text=>console.log(text))
// 
//     window.addEventListener("paste", function(thePasteEvent){
//     console.log(thePasteEvent)
// }, false);
  }

  componentWillUnmount = () => {

    window.removeEventListener('hashchange', this.hashPage)

  }

   hashPage = ()=>{
    let hashList = hashParse()
    this.setState({page : hashList[0].hashPage})
  }

  
 

  render = () => {



    return (

   
      <StoreProvider>

        {this.state.page!=='connexion' && this.state.page!=='subscribe'  && <Main/>}

        {/* {mobile() && this.state.page!=='connexion' && this.state.page!=='subscribe'  && <PWA/>} */}

        <Connexion/>

      </StoreProvider>


    )

  }

}




export default Application