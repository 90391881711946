import React from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

class CustomButton extends React.Component {

  static defaultProps = {
    handleClick : click=>click,
    label : 'Button',
    active : true,
    loading : false
  }

  constructor(props) {

    super(props)

    this.state = {
      //loading : false,
      //active : true
    }

  }

  componentDidMount = () => {

    window.addEventListener('keydown', this.pressEnter)

  }

  componentWillUnmount = () => {

    window.removeEventListener('keydown', this.pressEnter)

  }

  pressEnter = event=>{
    //event.preventDefault()
    if (event.key==='Enter' && this.props.activeEnter) {
      this.handleClick()
      event.preventDefault()
    }
  }

  handleClick = e=> this.props.active? this.props.handleClick() : false

  render = () => {

    const buttonStyle = Object.assign({},{
      fontSize : '1.1em',
      padding : 10, margin : 20,
      border : 'solid 2px',
      borderLeft : 'solid 1px', borderTop : 'solid 1px',
      borderColor : this.props.active? 'purple' : 'grey',
      width : 130, height : 40,
      outline : 'none',
      borderRadius : 5,
      cursor : this.props.loading ? 'wait' : this.props.active ? 'pointer' : 'not-allowed', 
      opacity : this.props.active ? 1 : 0.5,
      transition : 'opacity 0.2s'
    },this.props.style)

    const loaderStyle = {
      position : 'absolute',
      top : 25, left : 70, 
      pointerEvents: 'none'
    }

    return (

      <div style={{position : 'relative'}}>
      
        <input style={buttonStyle} type='button' value={this.props.loading? ' ' : this.props.label} onClick={this.handleClick}/>

        {this.props.loading && 
          // <img

          <div style={loaderStyle} >
          <Loader
         type="Oval"
         color="purple"
         height={30}
         width={30}
         radius = {15}
         />

         </div>
        }

      </div>

    )

  }

}



export default CustomButton