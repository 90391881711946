import cryptoJS from 'crypto-js'
import JSEncrypt from 'jsencrypt'




const cryptHybride =  {

	encrypt : (message, publicKey)=>{


	
		try{



			let WEPKey = generateHexString(26)   //generation de la clé de chiffrement aléatoire

			let messageChiffre = cryptoJS.AES.encrypt(message, WEPKey).toString() //Chiffrement symetrique du message

			let crypt = new JSEncrypt()
			crypt.setPublicKey(publicKey)       //attribution de la clé asymetrique

			let WEPKeyChiffre = crypt.encrypt(WEPKey)      //chiffrement de la clé de chiffrement

			var messageCode = Buffer.from(WEPKeyChiffre).toString('base64')+'.'+Buffer.from(messageChiffre).toString('base64')   //concatenation de la clé chiffré et du message chiffré en base64

			return messageCode 

		}catch(e){}


	},


	decrypt :  (chiffre,privateKey)=>{

		try{

			let splitBase64 = chiffre.split('.')     //Separation clé et message chiffré

			let split = splitBase64.map(base64=>Buffer.from(base64, 'base64').toString('utf-8'))  //conversition base64 => utf8
			
			let crypt = new JSEncrypt()
			crypt.setPrivateKey(privateKey)				//Attribution de la clé privée

			let WEPKey = crypt.decrypt(split[0])		// dechiffrement de la clé symetrique

			let messageDechiffre = cryptoJS.AES.decrypt(split[1],WEPKey).toString(cryptoJS.enc.Utf8) //dechiffremnt du message

			return messageDechiffre

		}catch(e){}

	}


}





	const generateHexString = (length) => {

  		var ret = ""

  		while (ret.length < length) {
    		ret += Math.random().toString(16).substring(2)
  		}

  		return ret.substring(0,length)
	}



	



export default cryptHybride
