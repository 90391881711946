const luhn = number=>{
  
  let sumNumber = 0

  for (var i = 0; i < number.length; i++) {
    
    let currentNumber = Number(number[i])

    if (i%2===0) sumNumber = sumNumber+currentNumber

    else {
      let double = currentNumber*2
      if(double>9) sumNumber = sumNumber+1+(double-10)
      else sumNumber = sumNumber+double 
    }

  }

  return sumNumber%10 === 0 ? true : false

}
 

export default luhn