import React from 'react'
import socket from '../modules/socket'
import add from '../icons/plus.svg'
import WrapComponent from '../contexts/wrap'
import {LastNameInput, FirstNameInput, SexInput} from './formatedInput'
import BirthInput from './datesInput'
import CustomButton from './customButton'
import Frame from './frame'
import trash from '../icons/trash.svg'
//import folder from '../icons/folder.svg'
import pdf from '../icons/pdf.svg'
import yearsOld from '../functions/yearsOld'
import Popup from './popup'
import Loader from './loader'

import generatePictures from '../functions/zipPictures'

import cancel from '../icons/cancel.svg'
import PictureId from './pictureId'

import download from 'downloadjs'

import ShareFile from './shareFile'







const months= ['janvier','février','mars', 'avril', 'mai','juin','juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']





class CheckUpList extends React.Component {

  constructor(props) {
    super(props)
   
    this.state = {
      filesList : [],
      popup : false,
      validPopup : ()=>true
    
     
    }
  }

  componentDidMount = () => {
    this.fetchCheckUpData()

   
   

  }

  fetchCheckUpData = ()=> socket.emit('fetchCheckUpData',this.props.patientId, data=>{
    this.setState({filesList : data.data})
  })
  
 


  createFile = e=> socket.emit('createFile',this.props.patientId, data=>{
    window.location.hash += `#file${data.id}?page=1`
    this.props.liftUpFileData({_id : data.id, creationDate : new Date()})
  })

  goToFile = data=>{
      window.location.hash += `#file${data._id}?page=1`
      this.props.liftUpFileData(data)
      // this.props.context.updateFileId(data._id)
      // this.props.context.updateFileData(data)
  }

  deleteCheckUp = id=>this.setState({popup : {msg : "Voulez-vous vraiment effacer ce bilan ?"}, validPopup : ()=>this.deleteConfirmation(id)})

  cancelPopup = ()=>this.setState({popup : false, validPopup : ()=>true})

  deleteConfirmation = (id)=>{
    socket.emit('deleteFile',id,response=>{
      this.fetchCheckUpData()
      this.cancelPopup()
    })

  }


  render = () => {

    const divStyle = {
      //position : 'relative',
    //  textAlign : 'center',
     // border : 'solid',
      height : '39%',
      width : '40%',
      overflowY: 'auto',
      paddingLeft : 40, 
      //paddingTop : 20
    }

    const styleId = {
     // border : 'solid',
     // position : 'absolute',
      width : '100%', height : '60%',
      display : 'flex', justifyContent : 'space-around', alignItems : 'center'
    }

    const addStyle = {
      position : 'absolute',
      top : '61%', left : 10,
      width : 30,
      cursor : 'pointer'
    }


//console.log('ddrrreator', this.props.context)

   this.state.filesList.sort((a,b)=>new Date(b.checkUpDate).getTime()-new Date(a.checkUpDate).getTime())

    return (

      <>

    
      <div style={styleId}>

       <PictureId patientId={this.props.patientId}/>

      {this.props.context.creator ?  <Comments patientId={this.props.patientId}/> : <ShareId/> }
        
       <PatientIdentity patientId={this.props.patientId}/>

       <ClearPatient patientId={this.props.patientId}/>
      

    </div>





      <div style={divStyle} >

        {this.props.context.creator && <img style={addStyle} src={add} height = {40} onClick={this.createFile} alt='add' title='Créer un nouveau dossier'/>}

        {this.state.filesList.map((element, index)=>
            <CheckUpElement element={element} key = {index}  onClick = {e=>this.goToFile(element)} delete={this.deleteCheckUp}/>
        )}

      </div>


      {this.props.context.creator && <ShareFile/>}


     

      {this.state.popup && <Popup choice msg={this.state.popup.msg}  handleClickYes={this.state.validPopup} handleClickNo ={this.cancelPopup}/>}


      </>
     
    )

  }

}


class PatientIdentity extends React.Component {


  constructor(props) {

    super(props)

    this.state = {
      popup : false
    }

  }

  componentDidMount = () => {
     this.fetchPatientData(this.props.patientId)
     socket.on('updateListPatient',()=>this.fetchPatientData(this.props.patientId))

  }

  cancelPopup = ()=>this.setState({popup : false})

  updatePopup = ()=>this.setState({popup : {msg : 'Voulez-vous vraiment mettre à jour les informations du patient?'}})

 

   fetchPatientData = id=>{
   
    socket.emit('fetchPatientData', id, data=>{

      if (data.success) {

        this.props.context.updatePatientData(data.data)
        
        this.setState({
          patientLastNameUpdate : data.data.name.lastname,
          patientFirstNameUpdate : data.data.name.firstname,
          patientSexUpdate : data.data.sex,
          patientBirthdayUpdate : formatBirthday(data.data.birthday)

        })
      }
    })

  }

  updateId = ()=>{

    let parseBirthday = this.state.birthday.maskedValue.split('/')

    let newData = {
        name : {
        firstname : this.state.firstName.maskedValue,
        lastname : this.state.lastName.maskedValue
      },
      sex : this.state.sex.value,
      birthday : {day : parseBirthday[0], month : parseBirthday[1], year : parseBirthday[2]}
    }

    socket.emit('updatePatient',newData, this.props.context.patientData._id,()=>{
      this.setState({popup : false})
    })
  }

  render = () => {

     const idStyle = {
      //border : 'solid',
      transform : 'scale(0.8)',
     // pointerEvents : 'none'
    }

    
    let validation = this.state.lastName && this.state.firstName && this.state.birthday && this.state.sex ?
    this.state.lastName.validation && this.state.firstName.validation && this.state.birthday.validation && this.state.sex.validation : false

    return (

      <>

          {this.state.popup && <Popup choice msg={this.state.popup.msg}  handleClickYes={this.updateId} handleClickNo ={this.cancelPopup}/>}
     
      <div style={idStyle}>

          <LastNameInput updateValue={this.state.patientLastNameUpdate} value={value=>this.setState({lastName : value})}/>
          <FirstNameInput updateValue={this.state.patientFirstNameUpdate} value={value=>this.setState({firstName : value})}/>
          <BirthInput updateValue={this.state.patientBirthdayUpdate} value={value=>this.setState({birthday : value})}/>
          <SexInput updateValue={this.state.patientSexUpdate} value={value=>this.setState({sex : value})}/>
          
         {this.props.context.creator &&  <CustomButton 
                     label="Mettre à jour"
                     active={validation}
                     handleClick = {this.updatePopup}
                   /> 
         }
         

       </div>

       </>

    )

  }

}






class CheckUpElement extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
     popup : false, 
     validPopup : ()=>true,
     loader: false,
     over : false
    }

  }

  componentDidMount = () => {
  

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

  deleteCheckUp = ()=>{
   let id = this.props.element._id
    this.props.delete(id)
  }

  cancelPopup = ()=>this.setState({popup : false, validPopup : ()=>true})

  // createPopup = ()=>this.setState({popup : {msg : "Voulez-vous vraiment effacer ce bilan ?"}, validPopup : this.deleteCheckUp})

  zipPopup = ()=>this.setState({popup : {msg : "La génération du dossier peut prendre plusieurs minutes. Voulez-vous vraiment générer le dossier rassemblant les photographie en haute définition ?"}, validPopup : this.zipPictures})

  pdfPopup = ()=>this.setState({popup : {msg : "La génération du rapport peut prendre plusieurs minutes. Voulez-vous vraiment générer le rapport PDF ?"}, validPopup : this.pdfReport})

  zipPictures = async ()=>{


    this.setState({loader : true, popup : false, validPopup : ()=>true})
  
    let zip = new generatePictures(this.props.element._id, this.props.context, this.props.element.checkUpDate)
    //zip.onProgress(()=>console.log('progress'))
    zip.onFinish(()=>this.setState({loader : false}))
    zip.zipGenerate()

  }

  pdfReport = ()=>{
    this.setState({loader : true, popup : false, validPopup : ()=>true})
    let zip = new generatePictures(this.props.element._id, this.props.context, this.props.element.checkUpDate)
    
    zip.pdfReport((pdfURL)=>{

      this.setState({loader : false})

     
      let twoNumbers = number=> Number(number)>9 ? number : '0'+number 
      let creationDate = new Date(this.props.element.checkUpDate)
      let date = `${twoNumbers(creationDate.getDate())}${twoNumbers(creationDate.getMonth()+1)}${creationDate.getFullYear()}`
      
      let fileTitle = (
        this.props.context.patientData.name.lastname.slice(0,3)+
        this.props.context.patientData.name.firstname.slice(0,3)+'_'+
        date+'_rapportBilan.pdf'
      )


     download(pdfURL, fileTitle)



    })

  }

  over = ()=>this.setState({over : true})

  leave = ()=>this.setState({over : false})


  testZip=()=>{
    socket.emit('testfabric')
  }



  render = () => {

    let date = new Date(this.props.element.checkUpDate)
    let birthday = this.props.context.patientData.birthday
    let birthDate
   if (birthday) birthDate = new Date(birthday.year, birthday.month-1, birthday.day)
    let checkUpAge=yearsOld(birthDate, date)

    const styleDiv = {
      //border : 'solid',
      margin : 10,
      padding : 10,
      border : 'solid 2px',
      borderRight : 'solid 1px', borderBottom : 'solid 1px',
      borderColor : 'purple', borderRadius : 10,
      color : this.state.over ? 'white' : 'purple',
      width : '70%',
      cursor : 'pointer',
      backgroundColor : this.state.over ? 'purple' : 'white'
    }

    const styleTrash = {
      position : 'absolute',
      top : 15, left : '80%',
      cursor : 'pointer'
    }

    //  const styleFolder = {
    //   position : 'absolute',
    //   top : 15, left : 400,
    //   cursor : 'pointer'
    // }

    const stylePdf = {
      position : 'absolute',
      top : 15, left : '90%',
      cursor : 'pointer'
    }



    return (

      <div style={{position : 'relative'}}>

        {this.state.loader && <Loader/>}

        {this.state.popup && <Popup choice msg={this.state.popup.msg}  handleClickYes={this.state.validPopup} handleClickNo ={this.cancelPopup}/>}

        <div style={styleDiv} onClick={this.props.onClick} onMouseEnter = {this.over} onMouseLeave = {this.leave}>

          Bilan du {date.getDate()} {months[date.getMonth()]} {date.getFullYear()} ({checkUpAge}) 

        </div>

        {this.props.context.creator && <img title='Effacer le bilan' src={trash} width={20} style={styleTrash} onClick={this.deleteCheckUp} alt='trash'/>}

        {/* <img title="Exporter l'ensemble des images" src={folder} width={20} style={styleFolder} onClick={this.testZip} alt='folder'/> */}

        {/* <img title="Exporter l'ensemble des images" src={folder} width={20} style={styleFolder} onClick={this.zipPopup} alt='folder'/> */}

        <img title="Créer un rapport au format pdf " src={pdf} width={20} style={stylePdf} onClick={this.pdfPopup} alt='pdf'/>

      </div>
    )

  }

}

class Comments extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      comment : ''
    //  date : new Date()
    }

  }

  componentDidMount = () => {
this.loadComment()
   

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  if (this.props.context.patientData._id !== prevProps.context.patientData._id) this.loadComment()
    
  }

  loadComment = ()=>{

     socket.emit('loadComment',this.props.context.patientData._id,data=>{
      if (data.success) this.setState({comment : data.data})
    })

  }

  handleChange = e=>{
    this.setState({comment : e.target.value})
    clearTimeout(this.timer)
    this.timer = setTimeout(()=>{
      socket.emit('saveComment',this.props.context.patientData._id, this.state.comment)
   }, 1000)


  }

  focus = ()=>this.setState({focus : true})

  blur = ()=>this.setState({focus : false})

  render = () => {

     const commentStyle = {
      height : '35vh', width : '30vw',
    //  maxWidth : 400, maxHeight : 400
      marginTop : 10, marginLeft : -15, marginRight : -35,
      resize: 'none', border : 'none', outline : 'none'
    }

  

    return (
     
       <Frame label='Commentaire' color={this.state.focus? 'purple' : 'grey'}>
         
        <textarea style={commentStyle} onChange={this.handleChange} onFocus={this.focus} onBlur={this.blur} value={this.state.comment}/>

       </Frame>


    )

  }

}


let ShareId = props=>{

  return (

    <div>

   { props.context.patientData.idUser && <>

    <div>
    
          
            Partagé par {props.context.patientData.idUser.name.lastname} {props.context.patientData.idUser.name.firstname}
          </div>
          <div>
            Mail : {props.context.patientData.idUser.mail}
          </div>

        </>}
          
        
    
        </div>
    )
}


class ClearPatient extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      popup : false
    }

  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

   exit = e=> window.location.hash  = ''

  clearPopup = ()=>{
    console.log('clear')
    this.setState({popup : {msg : "Etes-vous sur de vouloir effacer définitivement le dossier de ce patient ?"}})
  }

  cancelPopup = ()=>this.setState({popup : false})



  clearPatient = ()=>{
    this.exit()
    //console.log(this.props.patientId)
    socket.emit('deletePatient', this.props.patientId, data=>{
      console.log('done', data)

    })
  }


  render = () => {

     const styleTrash = {
      position : 'absolute',
      width : 40, height : 40,
      bottom : 20, right : 30,
      cursor : 'pointer',
     zIndex : 2
    }

     const styleClose = {
      position : 'absolute',
      width : 40,
      bottom : 20, right : 100,
      cursor : 'pointer',
       zIndex : 2
    }

    return (

      <>
     
      {this.state.popup && <Popup choice msg={this.state.popup.msg}  handleClickYes={this.clearPatient} handleClickNo ={this.cancelPopup}/>}

     {this.props.context.creator && <img src={trash} style={styleTrash} onClick={this.clearPopup} alt='trash'/>}

     <img src={cancel} style={styleClose} onClick={this.exit} alt='cancel'/>

     </>

    )

  }

}



const formatBirthday = birthday=>{
      let twoNumbers = number=> Number(number)>9 ? number : '0'+number 
      return `${twoNumbers(birthday.day)}/${twoNumbers(birthday.month)}/${birthday.year}`
    }



PatientIdentity = WrapComponent(PatientIdentity)
CheckUpElement = WrapComponent(CheckUpElement)
Comments = WrapComponent(Comments)
ClearPatient = WrapComponent(ClearPatient)
ShareId = WrapComponent(ShareId)

export default WrapComponent(CheckUpList)