import React from 'react'
import Frame from '../components/frame'
import {CreatePassword} from '../components/passInput'
import CustomButton from '../components/customButton'
import parseSearch from '../functions/parseSearch'
import Popup from '../components/popup'
import Loader from '../components/loader'
import socket from '../modules/socket'
import cryptoJS from 'crypto-js'



class ForgetPassword extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      password : '',
      validation : false,
      mail : '',
      token : '',
      loader : false,
      popup : false
    }

  }

  componentDidMount = () => {

    console.log(window.location.search)
    let search = parseSearch(window.location.search)
    this.setState({mail : search.mail, token : search.token})

  }

  componentWillUnmount = () => {

  }

  changePassword = input=>this.setState({password : input.pw, validation : input.validation})

  send = ()=>{

    this.setState({loader : true})

    let object = {
      mail : this.state.mail,
      token : this.state.token,
      newPassword : cryptoJS.SHA512(this.state.password).toString(cryptoJS.enc.Base64)
    }


    socket.emit('updateNewPassword',object,response=>{
      //console.log(response)
      this.setState({loader : false, popup : response})
    })

  }

  closePopup = exit=>exit? window.close() : this.setState({popup : false})
   

  render = () => {

    const frameStyle = {
      height : 250, maxWidth : 400, width : '80vw',  

    }

    const divStyle = {
      display : 'flex', justifyContent : 'center', 
      alignItems : 'center',
      width : '100vw', height : '100vh',
    }

    const divStyleFrame = {
      display : 'flex', justifyContent : 'space-around', 
      alignItems : 'center', flexDirection : 'column',
      width : '100%', height : '100%',
    }

    return (

      <div style={divStyle}>

        {this.state.loader && <Loader/>}

        {this.state.popup && 
          <Popup 
            error={this.state.popup.error}
            success={this.state.popup.success} 
            msg={this.state.popup.msg} 
            handleClick={()=>this.closePopup(this.state.popup.success)}/>
          }


        <Frame style = {frameStyle} label='Réinitialisation mot de passe'>

          <div style={divStyleFrame}>


     
        <CreatePassword value={this.changePassword}/>

        <CustomButton
          label = 'Renouvellez'
          active = {this.state.validation}
          handleClick = {this.send}
        />

        </div>



      </Frame>

       </div>
      


    )

  }

}


export default ForgetPassword