import React from 'react'
import {fabricViewer} from '../functions/fabricFunctions/viewerPictureFabric'
import Visor from './visor'
import {PointCephalo, LineCephalo, LinesCephalo, ToothCephalo} from '../functions/fabricFunctions/fabricPoint'
import Frame from './frame'
import eye from '../icons/eye.svg'
import toothSVG from '../icons/tooth.svg'
import ghostSVG from '../icons/ghost.svg'
import Calibration from './calibration'
import Navigation from './navigationViewer'
import Export from './export'
import resizeImage from '../functions/resizeImage'
import socket from '../modules/socket'
import ss from 'socket.io-stream'
import WrapComponent from '../contexts/wrap'
import Loader from './loader'
import placePicture from '../functions/placePicture'
import {pointsAngle} from '../functions/fabricFunctions/fabricFunctions'
import {Tools} from './viewerTools'
import exportCephaloPdf from '../functions/exportCephaloPdf'
import {cephaloMarks, cephaloLin} from '../objects/cephaloObject'
import download from 'downloadjs'




class CephaloViewer extends React.Component {


  constructor(props) {

    super(props)
    this.canvasRef = React.createRef()
    this.navigationRef = React.createRef()

    this.state = {
      tools : [],
      ratio : false,
      loadingData : false,
      loader : true,
      imagePlaced : false,
      crop : true,
    }

  }

  componentDidMount = () => {

    this.presentationCanvas = new fabricViewer(this.canvasRef.current, this.props.corrections)

    this.fabricCanvas = this.presentationCanvas.canvas
    this.setState({canvas : this.fabricCanvas})

    this.presentationCanvas.loadImage(this.props.image)
      .then(image=>{

        this.imageFabric=image
        this.imagePlacement = new placePicture(this.presentationCanvas,this.props.corrections.markers, this.props.placePictureData, this.props.context.fileData._id)
        
        this.imagePlacement.place(()=>{
          this.imagePlacement.placeLandmarks(this.updateLandmarks)
          this.setState({imagePlaced : true})
        })
        
      })

    socket.emit('fetchTools', this.props.context.fileData._id, this.props.pictureType, this.props.context.patientData._id, data=>{
      this.setState({loadingData : data})
    })

  }


  updateLandmarks = landmarks=>this.setState({LandMarks : landmarks})

  visorCanvas = canvas=>{

    if (canvas === '') this.timer = setTimeout(()=>this.setState({visor : canvas}),1000)
    
    else {
      clearTimeout(this.timer)
      this.setState({visor : canvas})
    }
    
  }


  liftUpTools = tools=>this.setState({tools : tools})

  calibrate = ratio=>{

    this.setState({ratio : ratio},()=>{
      this.fabricCanvas.renderAll()
    })
    
  }


  saveLength = ()=>{

    //console.log(this.fabricCanvas.getObjects().filter(elem=>elem.x1))

    let activeTools = this.state.tools.filter(tool=>tool.completed)

    this.toolsDef = activeTools.map(tool=>{

      let obj = {
        name : tool.name,
        coords : tool.markerArray.map(marker=>[marker.left, marker.top]),
        color : tool.color,
        markerVisibility : tool.markerVisibility,
        visibility : tool.visibility,
        id : tool.id
      }


      if (tool.hasOwnProperty('text')){

        let text = {
          text : tool.text.text,
          coords : [tool.text.left, tool.text.top],
          height : tool.text.height, width : tool.text.width,
          angle : tool.text.angle,
          scaleX : tool.text.scaleX, scaleY : tool.text.scaleY,
          visibility : tool.textVisibility
        }

        obj.text = text

      }

      if (tool.hasOwnProperty('prolongationLine')) obj.prolongation = tool.prolongationLine

      if (tool.hasOwnProperty('direction')) obj.direction = tool.direction
      
      return obj

    })


      
    let dataTools = {

      toolsDefinition : this.toolsDef,
      calibration : this.state.ratio,
      crop : this.state.crop,
      anonym : this.navigationRef.current.getGlassesOption(),
      cephalometry : {
        markers : this.state.cephalometryMarkers,
        lines : Array.from(this.state.cephalometryLines,line=>line.getDatas())
      }
    }


    socket.emit('savePictureTools', dataTools, this.props.context.fileData._id, this.props.pictureType, response=>{

    })

    
    this.savePreview()
  

  }

  setCrop = value=>{
    this.presentationCanvas.applyCrop(value)
    this.setState({crop : value})
  }


  savePreview = async ()=>{

    let vptcoords = this.fabricCanvas.vptCoords.tl
    let zoom = this.fabricCanvas.getZoom()

    this.presentationCanvas.navigateCanvas.resetCanvasPosition()

    let dataURLBigSize = this.fabricCanvas.toDataURL()
    let dataURLMinisize = await resizeImage(dataURLBigSize, 600)

    this.fabricCanvas.absolutePan(vptcoords)
    this.fabricCanvas.setZoom(zoom)

    fetch(dataURLMinisize).then(res => res.blob()).then(blob => {
      this.preview = blob
      this.pushViewerData()
    })
     
  }


  pushViewerData = ()=>{

    let  file = this.preview
    let stream = ss.createStream()
    ss(socket).emit('file', stream, {name : this.props.pictureType+'Preview', size: file.size, fileId : this.props.context.fileData._id, type : 'image'})
    let blobStream = ss.createBlobReadStream(file)
    blobStream.pipe(stream)

  }



  liftUpMarker = markers=>this.setState({cephalometryMarkers : markers})

  liftUpLines = lines=>{
    this.setState({cephalometryLines : lines, loader : lines.length===cephaloLin.length? false : true })
   // console.log(lines)
    //console.log(cephaloLin.length)
  //  if (lines.length===cephaloLin.length)
  }

  exportPdf = ()=> {

    let pdfURL = new exportCephaloPdf(Array.from(this.state.cephalometryLines,line=>line.getDatas()), this.state.ratio, this.props.context).createPdf()
    //console.log(pdfURL)

    let twoNumbers = number=> Number(number)>9 ? number : '0'+number 
   

   
    let creationDate = new Date(this.props.context.fileData.creationDate)

    let date = `${twoNumbers(creationDate.getDate())}${twoNumbers(creationDate.getMonth()+1)}${creationDate.getFullYear()}`
    let fileTitle = (
      this.props.context.patientData.name.lastname.slice(0,3)+
      this.props.context.patientData.name.firstname.slice(0,3)+'_'+
      date+'_rapportCeph.pdf'
    )


     download(pdfURL, fileTitle)


  
  }


  render = () => {

     const divStyle = {
      position : 'fixed',
      border : 'solid',
      width : '100vw', height : '100vh',
      top : 0, left : 0,
      display : 'flex', alignItems : 'center',
      backgroundColor : 'rgba(255,255,255,0.8)',
      zIndex : 2
    }

    const canvasStyle = {
      position : 'absolute',
      border : 'solid 2px',
      borderRight : 'solid 1px', borderBottom : 'solid 1px',
      borderColor : 'purple', borderRadius : 10,
      zIndex : 10,
      margin : 20
    }

    const toolsStyle = {
     // top : 90, right : 20,

    }

    const calibrationStyle = {
      top : 10, 
      //right : 20, left : ''
    }

    const navigationStyle = {
     // position : 'fixed',
      bottom : 40, right : 320,
      left : ''
    }

    const exportStyle = {

    }


    return (

      <div style={divStyle}>


        {this.state.loader && <Loader/>}
      
        <canvas style={canvasStyle} ref={this.canvasRef} height={1800} width={1800}/>

        {this.state.visor && <Visor canvas={this.state.visor}/>}

        {this.state.imagePlaced && <Tools 
                  style={toolsStyle}
                  canvas = {this.state.canvas} 
                  visorCanvas={this.visorCanvas} 
                  canvasRef={this.canvasRef} 
                  ratio={this.state.ratio} 
                  liftUpTools={this.liftUpTools}
                  loadingData = {this.state.loadingData}
                />}

       

        {this.state.imagePlaced && <Calibration style={calibrationStyle} canvas = {this.state.canvas} visorCanvas={this.visorCanvas} updateCalibration={this.calibrate} loadingData = {this.state.loadingData}/>}

        {this.state.imagePlaced && this.state.loadingData && <Navigation style={navigationStyle} ref={this.navigationRef} focus={()=>this.presentationCanvas.navigateCanvas.resetCanvasPosition()} canvas={this.state.canvas} setCrop={this.setCrop} setGlasses={this.setGlasses} loadingData = {this.state.loadingData} pictureType={this.props.pictureType}/>}

        {this.state.canvas && <Export style = {exportStyle} save={this.saveLength} exportPdf={this.exportPdf} type={this.props.pictureType} presentationCanvas={this.presentationCanvas} pdf={true}/>}


      {this.state.imagePlaced && <Cephalometry 
        canvas = {this.state.canvas} 
        visorCanvas={this.visorCanvas} 
        initialMarkers = {this.state.LandMarks} 
        liftUpMarker = {this.liftUpMarker}
        liftUpLines = {this.liftUpLines}
        loadingData = {this.state.loadingData}
        ratio={this.state.ratio} 
        context={this.props.context}
      />}



      </div>
    )

  }

}

class Cephalometry extends React.Component {


  constructor(props) {

    super(props)
     this.scrollRef = React.createRef()

    this.state = {
      canvas : this.props.canvas,
      markers : []
    }

  }

  componentDidMount = () => {

    if (this.props.loadingData.success) this.setState({loadingData : this.props.loadingData.data})

  }


  componentDidUpdate = (prevProps)=> {
  
    if (this.props.loadingData !== prevProps.loadingData) {
      if (this.props.loadingData.success) this.setState({loadingData : this.props.loadingData.data})
    }

  }


  liftUpMarker = (markers)=>{
    this.setState({markers : markers})
    this.props.liftUpMarker(markers.filter(marker=>marker))
  }

  liftUpLines = lines=>this.props.liftUpLines(lines)

  scroll = (position)=>{
    let el = this.scrollRef.current
    if (el) el.scrollTo({top: position-30 ,behavior: 'smooth'})
  }

  render = () => {

     const cephaloStyle = {
      //border : 'solid',
      position : 'absolute',
      right : 310, top : 20,
      width : 240, zIndex : 6,
      //position : 'relative'
      //maxHeight : 200
      //padding : 10
      //bottom : 30
    }

    const styleDiv={
      //border : 'solid',
      position : 'relative', 
      width : '100%', height : '100%', overflowY : 'auto',
      maxHeight : '70vh',
      paddingBottom : 40,
      padding : 10, left : -20,
    }

    return (
      
      <Frame label='Cephalometrie' style={cephaloStyle}>

        <div style={styleDiv} ref={this.scrollRef}>
  
          <CephaloMarkers  canvas = {this.state.canvas} visorCanvas={this.props.visorCanvas} liftUpMarker={this.liftUpMarker} initialMarkers = {this.props.initialMarkers} scroll={this.scroll} loadingData = {this.state.loadingData}/>

          <CephaloLines canvas = {this.state.canvas} markers={this.state.markers} liftUpLines={this.liftUpLines} ratio={this.props.ratio} context={this.props.context} loadingData = {this.state.loadingData}/>
        
        </div>

      </Frame>

    )

  }

}








class CephaloMarkers extends React.Component {

   static defaultProps = {
    markerCephaloList : cephaloMarks
  }

  constructor(props) {

    super(props)
   
    this.PointCephaloList = new Array(this.props.markerCephaloList.length)

    this.state = {
      selected : false,
      open : true,
      allVisibility : true
    }

  }

  componentDidMount = () => {
    this.setState({loadingData : this.props.loadingData})
  }

  componentDidUpdate = (prevProps)=> {
    
      if (this.props.loadingData !== prevProps.loadingData) {
        let visible = this.props.loadingData.cephalometry.markers.findIndex(marker=>marker.visibility===true)
        this.setState({loadingData : this.props.loadingData, allVisibility : visible===-1? false : true})
      }
  }



  selectPoint = (index, drawed)=>{
   
    (index===-1 && drawed) ? this.setState({selected : false, open : false}) : this.setState({selected : index})
    
  }
   
  nextMarker = (index, drawed)=>{

    let newIndex = this.PointCephaloList.findIndex((marker, indexMarker) => (drawed? !marker : marker) && indexMarker>index)
    if (newIndex===-1)  newIndex = this.PointCephaloList.findIndex((marker, indexMarker) => drawed? !marker : marker)
    this.selectPoint(newIndex, drawed)

  }


  liftUpMarkerObject = (marker, index)=>{
    this.PointCephaloList[index] = marker
    this.updateMarker()
  }


  updateMarker = (update)=>{

    let markerData = Array.from(this.PointCephaloList, marker=>{

      if (marker) {
        return (
          {
            name : marker.name, 
            position : {x : marker.marker.left, y : marker.marker.top},
            color : marker.color,
            visibility : marker.visibility
          }
        )
      }
      else return undefined
    })

    this.props.liftUpMarker(markerData)

  }

  openList = e=>this.setState({open : !this.state.open})

  changeAllVisibility = e=>{
    this.PointCephaloList.map(marker=>marker.setVisibility(e.target.checked))
    this.setState({allVisibility : e.target.checked}, this.updateMarker)
  }


  render = () => {

    const styleDiv = {
      // border : 'solid',
      position : 'fixed',
      top : 0, left : 0,
      width : '100vw', height : '100vh',
    // backgroundColor : 'yellow',
      zIndex : 1
      // right : 20, bottom : 30
    }

    const markerListStyle = {
     // border : 'solid',
      position : 'relative',
      height : this.state.open ? 'auto' : 0,
      overflowY : this.state.open? 'auto' : 'hidden',
      transition : 'all 0.5s',
      zIndex : 2
    }

    const headerStyle = {
       //border : 'solid',
       color : 'white',
       marginLeft : 5, marginRight : 5,
       cursor : 'pointer'
    }

    const arrowStyle = {
      color : 'white',
      transform : this.state.open? 'rotate(-180deg)' : 'rotate(0deg)',
      transition : 'all 0.4s',
      cursor : 'default'
    }

    const header = {
      position : 'relative',
      display : 'flex', backgroundColor : 'purple', borderRadius : 5, 
      border : 'solid white', justifyContent : 'space-between',
      padding : 5,
      zIndex : 2
    }

    return (

      <div>

        {this.state.selected !== false && <div style={styleDiv} onClick = {()=>this.selectPoint(false)}/>}

        <div style={header}> 
          <div style={headerStyle} onClick={this.openList}>Points céphalométriques</div> 
          <div><input type='checkbox' onChange={this.changeAllVisibility} checked={this.state.allVisibility}/></div>
          <div style={arrowStyle}>▼</div>
        </div>

         <div style={markerListStyle}>
          
          {this.props.markerCephaloList.map((elem, index)=><Marker 
            initialMarkers = {this.props.initialMarkers}
            key={index} 
            index={index}
            markers = {this.props.markerCephaloList}
            data={elem} 
            canvas={this.props.canvas} 
            visorCanvas={this.props.visorCanvas}
            updateSelected={this.selectPoint}
            selected={this.state.selected===index? true : false}
            liftUpMarkerObject = {this.liftUpMarkerObject}
            nextMarker = {this.nextMarker}
            updateMarker = {this.updateMarker}
            scroll = {this.props.scroll}
            loadingData = {this.state.loadingData}

          />)}

        </div>

      </div>

    )

  }

}



class Marker extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)
    this.markerRef = React.createRef()

    this.state = {
      drawed : false,
      color : false,
      visibility : true
    }

  }

  componentDidMount = ()=>{
    this.loadSavedMarker(this.props.data.initials)
  }


  componentDidUpdate = (prevProps)=> {
    if (this.props.selected !== prevProps.selected) this.props.selected? this.selection() : this.unselection()
    if (this.props.loadingData !== prevProps.loadingData){
      this.loadSavedMarker(this.props.data.initials)
    }
  }

  select = e=>{

      if (this.props.selected) {
        this.props.updateSelected(false)
        this.props.canvas.discardActiveObject()
        this.props.canvas.renderAll()
      }

      else this.props.updateSelected(this.props.index)

  }

  loadMarkerDefault = (coords)=>{
   
    this.fabricMarker = new PointCephalo(this.props.canvas, this.props.visorCanvas, this.updateMarkerRelease, this.updateMarker)
    this.fabricMarker.name = this.props.data.initials
    this.fabricMarker.color = '#800080'
    this.fabricMarker.load(coords,this.drawed)
  }

  loadSavedMarker = initials=>{

    if (this.props.loadingData) {

      let data=this.props.loadingData.cephalometry.markers.filter(data=>data.name===initials)[0]
    
      if(data) {
        this.fabricMarker = new PointCephalo(this.props.canvas, this.props.visorCanvas, this.updateMarkerRelease, this.updateMarker)
        this.fabricMarker.name = initials
        this.fabricMarker.color = data.color
        this.fabricMarker.load(data.position,this.drawed)
        this.fabricMarker.setVisibility(data.visibility)
      }

      else {
        let initialMarkers = this.props.initialMarkers
        if (initialMarkers && initials==='Or') this.loadMarkerDefault(initialMarkers.orbit)
        if (initialMarkers && initials==='Po') this.loadMarkerDefault(initialMarkers.ear)
      }

    }

  }



  createMarker = ()=>{
    this.props.canvas.discardActiveObject()
    this.props.canvas.renderAll()
    this.props.updateSelected(this.props.index)
    this.fabricMarker = new PointCephalo(this.props.canvas, this.props.visorCanvas, this.updateMarkerRelease, this.updateMarker)
    this.fabricMarker.name = this.props.data.initials
    this.fabricMarker.color = '#800080'
    this.fabricMarker.create(this.drawed)
  }


  selectFabric = ()=>{
    this.props.canvas.setActiveObject(this.state.marker)
    this.props.canvas.renderAll()  
  }

  selection = ()=>{  
    if (!this.state.drawed) this.createMarker()
    if (this.state.drawed) this.selectFabric()
  }

  unselection=()=>{

    if (this.fabricMarker && !this.fabricMarker.completed) {
      this.fabricMarker.cancel()
      this.setState({drawed : false, marker : false, color : false})
      delete this.fabricMarker
    }

  }

  drawed = (marker,type)=>{

    if (type==='delete') {
      this.fabricMarker.cancel()
      this.setState({drawed : false, marker : false, color : false},()=>this.props.updateSelected(false))
      this.props.liftUpMarkerObject(false, this.props.index)
      this.props.nextMarker(this.props.index, false) 
      this.props.scroll(this.markerRef.current.offsetTop)
    }

    if (type==='add' || type==='load') {
      this.setState({drawed : true, marker : marker, color : this.fabricMarker.color},()=>this.props.canvas.discardActiveObject())
    
      marker.on('selected',e=>this.props.updateSelected(this.props.index))
      marker.on('deselected',()=>this.props.updateSelected(false))

      this.props.liftUpMarkerObject(this.fabricMarker, this.props.index)
      this.props.nextMarker(this.props.index,true) 

      this.props.scroll(this.markerRef.current.offsetTop)

    }

  }

  updateMarker = ()=>this.props.updateMarker()

  updateMarkerRelease = ()=>this.props.updateMarker(true)

  changeColor = e=>{
    this.setState({color : e.target.value})
    this.fabricMarker.setColor(e.target.value)
    this.updateMarker()
  }

  changeVisibility = e=> {
    this.fabricMarker.setVisibility(e.target.checked)
    this.forceUpdate()
    this.updateMarker()
  }


  render = () => {

    const styleDiv = {
      position : 'relative',
      backgroundColor : this.props.selected? 'purple' : 'white',
      color : this.props.selected? 'white' : 'purple',
      border : 'solid', borderRadius : 5,
      height : 30, width : '90%',
      margin : 2
    }

    const titleStyle = {
     // border : 'solid',
      position : 'absolute', top : 5, left : 25,
      pointerEvents : 'none'
    }

    const styleCheck = {
      position : 'absolute',
      top : 5, left : 2,
      color : 'green',
      pointerEvents : 'none'
    }

    const colorStyle = {
      width : 25, position : 'absolute',
      right : 80
    }

    const visibilityStyle = {
      position : 'absolute',
      right : 30,
      //pointerEvents : 'none'

    }

    const backStyle = {
      position : 'absolute', top : 0, left : 0,
      width : '100%', height : '100%' ,
    }

    //eye.path.fill = 'red'

    return (
      
      <div  style={styleDiv} title={this.props.data.name} ref={this.markerRef}>

        <div onClick={this.select} style={backStyle}/>

        {this.state.drawed && <div style={styleCheck}>✔</div>}
        
        <div style={titleStyle}>{this.props.data.initials}</div>
        {this.state.color && <input type='color' style={colorStyle} value={this.state.color} onChange={this.changeColor}/>}

        {this.state.drawed && <div style={visibilityStyle}>
          <input type='checkbox' onChange={this.changeVisibility} checked={this.fabricMarker.visibility}/>
          <img onClick={this.select} src={eye} width={20} style={{position : 'relative', left: 2, top : 5}} alt='visibility'/>
        </div>}

      </div>
    )

  }

}






class CephaloLines extends React.Component {

  static defaultProps = {
    linesData : cephaloLin,
    lines : [],


  }

  constructor(props) {

    super(props)
    this.LineCephaloList = new Array(this.props.linesData)

    this.state = {
      dataLine : []
    }

  }



  updateLine = (lineData, index)=>{
    this.LineCephaloList[index]=lineData
    this.setState({lines : this.LineCephaloList})

    let dataLine = Array.from(this.LineCephaloList,line=>{
      let datas = line.getDatas()
      return datas
    })


    this.setState({dataLine : dataLine})

    this.props.liftUpLines(this.LineCephaloList)
    //console.log(dataLine)
  
  }


  render = () => {

     const headerStyle = {
       //border : 'solid',
       color : 'white',
       marginLeft : 5, marginRight : 5,
       cursor : 'default'
    }

    const arrowStyle = {
      color : 'white',
      transform : this.state.open? 'rotate(-180deg)' : 'rotate(0deg)',
      transition : 'all 0.4s',
      cursor : 'default'
    }

    const header = {
      display : 'flex', backgroundColor : 'purple', borderRadius : 5, 
      border : 'solid white', justifyContent : 'space-between',
      padding : 5
    }

    return (
     
     <div>

      <div style={header}> <div style={headerStyle} onClick={this.openList}>
        Mesures céphalo
      </div> <div style={arrowStyle}>▼</div></div>
       
       {this.props.linesData.map((line, index)=>
          <div key={index}>
          {line.type==='length' && <Line key={index} index={index} data={line} markers={this.props.markers} lines = {this.state.lines} canvas={this.props.canvas} update={this.updateLine} ratio={this.props.ratio} context={this.props.context} loadingData = {this.props.loadingData} dataLine ={this.state.dataLine}/>} 
          {line.type==='lines' && <Lines key={index} index={index} data={line} markers={this.props.markers} lines = {this.state.lines} canvas={this.props.canvas} update={this.updateLine} ratio={this.props.ratio} context={this.props.context} loadingData = {this.props.loadingData} dataLine ={this.state.dataLine}/>}
          {line.type==='tooth' && <Tooth key={index} index={index} data={line} markers={this.props.markers} lines = {this.state.lines} canvas={this.props.canvas} update={this.updateLine} ratio={this.props.ratio} context={this.props.context} loadingData = {this.props.loadingData} dataLine ={this.state.dataLine}/>} 
          </div>
        )} 

        

     </div>
    )

  }

}


class Line extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      drawed : false,
      ratio : false
    }

  }

  componentDidMount = () => {

    this.line = new LineCephalo(this.props.canvas, [this.props.data.origin.prolongation, this.props.data.end? this.props.data.end.prolongation : true], this.props.data.dash)
    this.line.name = this.props.data.name
    this.line.initials = this.props.data.initials
    this.line.create()
    this.line.ratio = this.props.ratio
    this.line.setVisibility(this.props.data.visibility)

    this.loadSavedLine(this.props.data.initials)

    this.updateLine()
  }

  

  componentDidUpdate = (prevProps)=> {

    if (this.props.markers !== prevProps.markers) this.updateLine()
    if (this.props.ratio !== prevProps.ratio) this.updateRatio()
    if (this.props.loadingData !== prevProps.loadingData) this.loadSavedLine(this.props.data.initials)
  
    
  }

loadSavedLine = initials=>{
 
  if (this.props.loadingData) {
   // console.log('saved', this.props.loadingData.cephalometry.lines.filter(line=>line.name===initials)[0])
    let datas = this.props.loadingData.cephalometry.lines.filter(line=>line.name===initials)[0]
    if (datas && datas.hasOwnProperty('color')) this.changeColor(datas.color)
    if (datas && datas.hasOwnProperty('visibility'))this.changeVisibility(datas.visibility)
    //  console.log('text', datas.text)
    if (datas && datas.hasOwnProperty('text'))this.loadTextData(datas.text)
   // this.forceUpdate()

    this.props.update(this.line, this.props.index)
  }
}

  updateRatio = ()=>{
    this.line.ratio = this.props.ratio
    this.updateLine()
    this.setState({ratio : this.props.ratio})
  }

  findCoords=marker=>{

    if (typeof marker==='string') {
      let coords = this.props.markers.filter(line=>line && line.name===marker)[0]
      return coords && coords.hasOwnProperty('position') ? coords.position : false
    }

    else if (marker.hasOwnProperty('cross') && this.props.lines) {

      let line1 = this.props.lines.filter(line=>line.initials===marker.cross[0])[0]
      let line2 = this.props.lines.filter(line=>line.initials===marker.cross[1])[0]
        
      let xCoords = (line2.formula.b-line1.formula.b)/(line1.formula.a-line2.formula.a)
      let yCoords = (line1.formula.a * xCoords) + line1.formula.b

      return {x : xCoords, y : yCoords}

    }

    else if (marker.length===2){
      let coords1 = this.props.markers.filter(line=>line && line.name===marker[0])[0]
      let coords2 = this.props.markers.filter(line=>line && line.name===marker[1])[0]

      return coords1 && coords1.hasOwnProperty('position') && coords2 && coords2.hasOwnProperty('position') ?
        {x : (coords1.position.x+coords2.position.x)/2, y : (coords1.position.y+coords2.position.y)/2} : false
    }

  }

  updateLine = ()=>{

    let point1 = this.findCoords(this.props.data.origin.marker)
    
   // let point2 
    if (this.props.data.end) {
      let point2 = this.findCoords(this.props.data.end.marker)
      this.line.update(point1,point2)
      this.setState({drawed : point1 && point2 ? true : false})
    }

    else if (typeof this.props.data.angle === 'number') {
      let angle = this.props.data.angle
      this.line.updateWithAngle(point1, angle)
      this.setState({drawed : point1 && angle ? true : false})
    }

    else if (typeof this.props.data.angle === 'object') {
    
      let angle = this.props.data.angle.origin && this.props.data.angle.end? (pointsAngle(this.findCoords(this.props.data.angle.end), this.findCoords(this.props.data.angle.origin)) * (180/Math.PI)) - this.props.data.angle.add : false
      
      this.line.updateWithAngle(point1, angle)
      this.setState({drawed : point1 && angle ? true : false})
    }

    let measure = (this.line.ratio.ratio*this.line.length).toFixed(1)
    this.line.calculatedMeasure = measure
    this.line.setText(measure+' mm')
    if (!this.line.ratio) this.changeTextVisibility(false)

    this.props.update(this.line, this.props.index)
   
  }


  changeColor = color=>{
    this.line.setColor(color)
    this.forceUpdate()
   // this.props.update(this.line, this.props.index)
  }

  changeVisibility = value=> {
    this.line.setVisibility(value)
    this.forceUpdate()
   // this.props.update(this.line, this.props.index)
  }

  changeTextVisibility = value => {
    this.line.setTextVisibility(value)
    this.forceUpdate()
  //  this.props.update(this.line, this.props.index)
  }

  loadTextData = data=>{
    this.line.setTextData(data)
  }





  render = () => {



    const styleDiv = {
      position : 'relative',
      //backgroundColor : this.props.selected? 'purple' : 'white',
      color :  'purple',
      border : 'solid', borderRadius : 5,
      height : this.props.data.measure && this.props.ratio.ratio && this.state.drawed? 60 : 30,
      width : '90%',
      margin : 2,
      display : this.props.data.display==='none'? 'none' : '',
      overflowY : 'hidden'
    }

    const titleStyle = {
     // border : 'solid',
      position : 'absolute', top : 5, left : 25,
      pointerEvents : 'none',
      fontSize : '0.8em'
    }

    const styleCheck = {
      position : 'absolute',
      top : 5, left : 2,
      color : 'green',
      pointerEvents : 'none'
    }

    const colorStyle = {
      width : 30, position : 'absolute',
      right : 80,
      cursor : 'pointer'
    }

    const visibilityStyle = {
      position : 'absolute',
      right : 30,
      cursor : 'pointer'
      //pointerEvents : 'none'

    }

    //  const backStyle = {
    //    position : 'absolute', top : 0, left : 0,
    //    width : '100%', height : '100%' ,
    //    backgroundColor : 'red'
    // }

    const measureStyle = {
      position : 'absolute',
      top : 30,
 //border : 'solid',
 width : '100%',fontSize : '0.9em',
 textAlign: 'center',
 cursor : 'default'

 //display : 'flex', justifyContent : 'center', alignItems : 'center',
    }

    //let length = (this.props.ratio.ratio*this.line.length).toFixed(1)

    let color = 'purple'
    let old = yearsOld(new Date(this.props.context.patientData.birthday.year,this.props.context.patientData.birthday.month, this.props.context.patientData.birthday.day),new Date(this.props.context.fileData.checkUpDate))

    if (this.state.drawed && this.props.data.measure && this.line.ratio.ratio && this.props.data.min && this.props.data.max){
     // let measure = (this.line.ratio.ratio*this.line.length).toFixed(1)
      let measure = this.line.calculatedMeasure
      if (measure<this.props.data.min(old) || measure>this.props.data.max(old)) color = 'red'
    }

    const styleLength = {
      color : color
    }


    return (
      
       <div  style={styleDiv} title={this.props.data.name} ref={this.markerRef}>

       
        <div>

        {this.state.drawed && <div style={styleCheck}>✔</div>}
        <div style={titleStyle}>{this.props.data.initials}</div>
        {this.state.drawed && <input type='color' style={colorStyle} value={this.line.color} onChange={e=>this.changeColor(e.target.value)}/>}

        {this.state.drawed && <div style={visibilityStyle}>
          <input type='checkbox' onChange={e=>this.changeVisibility(e.target.checked)} checked={this.line.visibility? this.line.visibility : false}/>
          <img onClick={this.select} src={eye} width={20} style={{position : 'relative', left: 2, top : 5}} alt='visibility'/>
        </div>}

      </div>


        {this.state.drawed && this.props.data.measure && this.line.ratio.ratio && <div style={measureStyle}>
           {this.props.data.min && <span>{this.props.data.min(old)} mm &lt; </span>}
           <span style={styleLength}>{this.line.calculatedMeasure} mm</span>
           {this.props.data.max && <span> &lt; {this.props.data.max(old)} mm</span>}
            {this.line.visibility && <input type='checkbox' onChange={e=>this.changeTextVisibility(e.target.checked)} checked={this.line.textVisibility}/>}
           
        </div>}
      


      </div>

    )

  }

}





class Lines extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      drawed : false,
      ratio : false
    }

  }

  componentDidMount = () => {
    this.lines = new LinesCephalo(this.props.canvas, this.props.data)
    this.lines.name = this.props.data.name
    this.lines.initials = this.props.data.initials
    this.lines.create()

    this.lines.ratio = this.props.ratio
    this.lines.setVisibility(this.props.data.visibility)

    this.loadSavedLine(this.props.data.initials)

    this.updateLines()


  }

  componentWillUnmount = () => {

  }

  componentDidUpdate = (prevProps)=> {

    if (this.props.markers !== prevProps.markers) this.updateLines()
    if (this.props.ratio !== prevProps.ratio) this.updateRatio()
    if (this.props.loadingData !== prevProps.loadingData) this.loadSavedLine(this.props.data.initials)
    
  }

  loadSavedLine = initials=>{
   
    if (this.props.loadingData) {
     // console.log('saved', this.props.loadingData.cephalometry.lines.filter(line=>line.name===initials)[0])
      let datas = this.props.loadingData.cephalometry.lines.filter(line=>line.name===initials)[0]
      if (datas && datas.hasOwnProperty('color')) this.changeColor(datas.color)
      if (datas && datas.hasOwnProperty('visibility'))this.changeVisibility(datas.visibility)
      if (datas && datas.hasOwnProperty('text'))this.loadTextData(datas.text)
     // this.forceUpdate()

      //this.props.update(this.line, this.props.index)
    }
  }

  updateRatio = ()=>{
    this.lines.ratio = this.props.ratio
    this.updateLines()
    this.setState({ratio : this.props.ratio})

  }

  findCoords=marker=>{



    if (typeof marker==='string') {
      let coords = this.props.markers.filter(line=>line && line.name===marker)[0]
      return coords && coords.hasOwnProperty('position') ? coords.position : false
    }

    else if (marker.hasOwnProperty('cross') && this.props.lines) {

      
      let line1 = this.props.lines.filter(line=>line.initials===marker.cross[0])[0]
      let line2 = this.props.lines.filter(line=>line.initials===marker.cross[1])[0]

//console.log(line1, line2, this.lines.name)


      let xCoords = (line2.formula.b-line1.formula.b)/(line1.formula.a-line2.formula.a)
      let yCoords = (line1.formula.a * xCoords) + line1.formula.b

      return (isNaN(xCoords) && isNaN(yCoords))? false : {x : xCoords, y : yCoords}

    }

    else if (marker.length===2){
      let coords1 = this.props.markers.filter(line=>line && line.name===marker[0])[0]
      let coords2 = this.props.markers.filter(line=>line && line.name===marker[1])[0]

      return coords1 && coords1.hasOwnProperty('position') && coords2 && coords2.hasOwnProperty('position') ?
        {x : (coords1.position.x+coords2.position.x)/2, y : (coords1.position.y+coords2.position.y)/2} : false
    }






  }


  updateLines = ()=>{

    let arrayCoords = this.props.data.lines
    arrayCoords.map((coords, index)=>this.updateLine(coords,index))
    this.setState({drawed : this.lines.completed})

    //let measure = (this.line.ratio.ratio*this.line.length).toFixed(1)
    this.lines.calculatedMeasure = this.lines.measure

    this.lines.setText(this.lines.measure+''+this.props.data.measure.unit)
    //console.log('measure',this.lines.measure)

    this.props.update(this.lines, this.props.index)
  }

  updateLine = (coords,index)=>{

    let point1 = this.findCoords(coords.origin.marker)
    
   // let point2 
    if (coords.end) {
      let point2 = this.findCoords(coords.end.marker)
      this.lines.update(point1,point2,index)
    }

    else if (typeof coords.angle === 'number') {
      let angle = coords.angle
      this.lines.updateWithAngle(point1, angle, index)
    }

    else if (typeof coords.angle === 'object') {
      let angleOrigin = this.props.markers.filter(line=>line && line.name===coords.angle.origin)[0]
      let angleEnd = this.props.markers.filter(line=>line && line.name===coords.angle.end)[0]
      let angle = angleEnd && angleOrigin? (pointsAngle(this.findCoords(angleOrigin), this.findCoords(angleEnd)) * (180/Math.PI)) - coords.angle.add : false
      this.lines.updateWithAngle(point1, angle, index)
    }
   
  }


  changeColor = color=>{
    this.lines.setColor(color)
    this.forceUpdate()
    this.props.update(this.lines, this.props.index)
  }

  changeVisibility = value=> {
    this.lines.setVisibility(value)
    this.forceUpdate()
    this.props.update(this.lines, this.props.index)
  }

  changeTextVisibility = value=>{
    this.lines.setTextVisibility(value)
    this.forceUpdate()
    this.props.update(this.lines, this.props.index)
  }

   loadTextData = data=>{
    this.lines.setTextData(data)
  }



  render = () => {

    const styleDiv = {
      position : 'relative',
      //backgroundColor : this.props.selected? 'purple' : 'white',
      color :  'purple',
      border : 'solid', borderRadius : 5,
      height : this.props.data.measure && this.state.drawed && this.lines.calculatedMeasure? 60 : 30,
      width : '90%',
      margin : 2,
      display : this.props.data.display==='none'? 'none' : '',
      overflowY : 'hidden'
    }

    const titleStyle = {
     // border : 'solid',
      position : 'absolute', top : 5, left : 25,
      pointerEvents : 'none',
      fontSize : '0.8em'
    }

    const styleCheck = {
      position : 'absolute',
      top : 5, left : 2,
      color : 'green',
      pointerEvents : 'none'
    }

    const colorStyle = {
      width : 30, position : 'absolute',
      right : 80,
      cursor : 'pointer'
    }

    const visibilityStyle = {
      position : 'absolute',
      right : 30,
      cursor : 'pointer'
      //pointerEvents : 'none'

    }

    //  const backStyle = {
    //    position : 'absolute', top : 0, left : 0,
    //    width : '100%', height : '100%' ,
    //    backgroundColor : 'red'
    // }

    const measureStyle = {
      position : 'absolute',
      top : 30,
 //border : 'solid',
 width : '100%',fontSize : '0.9em',
 textAlign: 'center',
 cursor : 'default'

 //display : 'flex', justifyContent : 'center', alignItems : 'center',
    }

    //let length = (this.props.ratio.ratio*this.line.length).toFixed(1)

    let color = 'purple'
    let old = yearsOld(new Date(this.props.context.patientData.birthday.year,this.props.context.patientData.birthday.month, this.props.context.patientData.birthday.day),new Date(this.props.context.fileData.checkUpDate))


    if (this.state.drawed && this.props.data.measure){
      let measure = this.lines.calculatedMeasure
      //this.lines.calculatedMeasure = measure
      if ((this.props.data.min && measure<this.props.data.min(old,this.props.ratio,this.props.dataLine)) || (this.props.data.max && measure>this.props.data.max(old,this.props.ratio,this.props.dataLine))) color = 'red'
    }

    const styleLength = {
      color : color
    }



    return (
      
       <div  style={styleDiv} title={this.props.data.name} ref={this.markerRef}>

       
        <div>

        {this.state.drawed && <div style={styleCheck}>✔</div>}
        <div style={titleStyle}>{this.props.data.initials}</div>
        {this.state.drawed && <input type='color' style={colorStyle} value={this.lines.color} onChange={e=>this.changeColor(e.target.value)}/>}

        {this.state.drawed && <div style={visibilityStyle}>
          <input type='checkbox' onChange={e=>this.changeVisibility(e.target.checked)} checked={this.lines.visibility}/>
          <img onClick={this.select} src={eye} width={20} style={{position : 'relative', left: 2, top : 5}} alt='visibility'/>
        </div>}

      </div>


        {this.state.drawed && this.props.data.measure && this.lines.calculatedMeasure && isNaN(this.lines.calculatedMeasure)===false && <div style={measureStyle}>
           {this.props.data.min && <span>{this.props.data.min(old,this.props.ratio,this.props.dataLine)} {this.props.data.measure.unit} &lt; </span>}
           <span style={styleLength}>{this.lines.calculatedMeasure} {this.props.data.measure.unit}</span>
           {this.props.data.max && <span> &lt; {this.props.data.max(old,this.props.ratio,this.props.dataLine)} {this.props.data.measure.unit}</span>}
           {this.lines.visibility && <input type='checkbox' onChange={e=>this.changeTextVisibility(e.target.checked)} checked={this.lines.textVisibility}/>}
           
        </div>}
      


      </div>


    )

  }

}






class Tooth extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      drawed : false,
      ratio : false,
      ghost : false
    }

  }

  componentDidMount = () => {

    this.tooth = new ToothCephalo(this.props.canvas, this.props.data)
    this.tooth.mandibule = this.props.data.mandibule
    this.tooth.name = this.props.data.name
    this.tooth.dataMarkers = this.props.markers
    this.tooth.initials = this.props.data.initials
    this.tooth.ratio = this.props.ratio

    this.tooth.create(()=>this.loadSavedLine(this.props.data.initials))
    
    this.updateLine()
    this.updateDataMarkers()
  }

  componentWillUnmount = () => {

  }

  componentDidUpdate = (prevProps, prevState)=> {

    if (this.props.markers !== prevProps.markers) {
      this.updateLine()
      this.updateDataMarkers()
    }
    
    if (this.props.ratio !== prevProps.ratio) this.updateRatio()
    if (this.props.loadingData !== prevProps.loadingData) this.loadSavedLine(this.props.data.initials)
    if (!this.state.drawed) this.tooth.cancelGhost()
  
  }

loadSavedLine = initials=>{
 
  if (this.props.loadingData) {
   // console.log('saved', this.props.loadingData.cephalometry.lines.filter(line=>line.name===initials)[0])
    let datas = this.props.loadingData.cephalometry.lines.filter(line=>line.name===initials)[0]
    if (datas && datas.hasOwnProperty('color')) this.changeColor(datas.color)
    if (datas && datas.hasOwnProperty('visibility'))this.changeVisibility(datas.visibility)
     if (datas && datas.hasOwnProperty('toothVisibility'))this.changeToothVisibility(datas.toothVisibility)
    
    if (datas && datas.hasOwnProperty('ghostData') && datas.ghostData!==false){

       setTimeout(()=>{
        this.toggleGhost()
        this.tooth.loadGhostData(datas.ghostData)

      }, 1)
     
       //console.log(this.tooth.ghost)

     // this.toggleGhost()
      
    }

     //

    //this.forceUpdate()

    //this.props.update(this.line, this.props.index)
  }
}

  updateRatio = ()=>{
    this.tooth.ratio = this.props.ratio
    this.updateLine()
    this.setState({ratio : this.props.ratio})
  }

  updateDataMarkers = ()=>{
    this.tooth.dataMarkers = this.props.markers
    // console.log('rrrr',this.props.markers)
  }


  findCoords=marker=>{

    if (typeof marker==='string') {
      let coords = this.props.markers.filter(line=>line && line.name===marker)[0]
    //  console.log(this.props.markers)
      return coords && coords.hasOwnProperty('position') ? coords.position : false
    }

    else if (marker.hasOwnProperty('cross') && this.props.lines) {

      let line1 = this.props.lines.filter(line=>line.initials===marker.cross[0])[0]
      let line2 = this.props.lines.filter(line=>line.initials===marker.cross[1])[0]

      let xCoords = (line2.formula.b-line1.formula.b)/(line1.formula.a-line2.formula.a)
      let yCoords = (line1.formula.a * xCoords) + line1.formula.b

      return {x : xCoords, y : yCoords}

    }

    else if (marker.length===2){
      let coords1 = this.props.markers.filter(line=>line && line.name===marker[0])[0]
      let coords2 = this.props.markers.filter(line=>line && line.name===marker[1])[0]

      return coords1 && coords1.hasOwnProperty('position') && coords2 && coords2.hasOwnProperty('position') ?
        {x : (coords1.position.x+coords2.position.x)/2, y : (coords1.position.y+coords2.position.y)/2} : false
    }

  }

  updateLine = ()=>{

    let point1 = this.findCoords(this.props.data.origin.marker)
   // console.log(point1)
    
   // let point2 
    if (this.props.data.end) {
      let point2 = this.findCoords(this.props.data.end.marker)
      this.tooth.update(point1,point2)
      this.setState({drawed : point1 && point2 ? true : false})
    }

    else if (typeof this.props.data.angle === 'number') {
      let angle = this.props.data.angle
      this.tooth.updateWithAngle(point1, angle)
      this.setState({drawed : point1 && angle ? true : false})
    }

    else if (typeof this.props.data.angle === 'object') {
    
      let angle = this.props.data.angle.origin && this.props.data.angle.end? (pointsAngle(this.findCoords(this.props.data.angle.end), this.findCoords(this.props.data.angle.origin)) * (180/Math.PI)) - this.props.data.angle.add : false
      
      this.tooth.updateWithAngle(point1, angle)
      this.setState({drawed : point1 && angle ? true : false})
    }


  //  this.updateDataMarkers()

//     let measure = (this.line.ratio.ratio*this.line.length).toFixed(1)
//     this.line.calculatedMeasure = measure
// 
     this.props.update(this.tooth, this.props.index)
   
  }

  toggleGhost = e=>{
    //console.log('create')
    //this.tooth.createGhost()
    this.setState({ghost : !this.state.ghost},()=>{
      this.state.ghost? this.tooth.createGhost(this.updateGhost) : this.tooth.cancelGhost()
      this.props.update(this.tooth, this.props.index)
    })
  }

  updateGhost = ()=>{
   this.forceUpdate()
   this.props.update(this.tooth, this.props.index)
  }


  changeColor = color=>{
    this.tooth.setColor(color)
    this.forceUpdate()
     this.props.update(this.tooth, this.props.index)
  }

  changeGhostColor = color=>{
    this.tooth.setGhostColor(color)
    this.forceUpdate()
     this.props.update(this.tooth, this.props.index)
  }

  changeVisibility = value=> {
    this.tooth.setVisibility(value)
    this.forceUpdate()
     this.props.update(this.tooth, this.props.index)
  }

  changeToothVisibility = value=>{
     this.tooth.setToothVisibility(value)
    this.forceUpdate()
    this.props.update(this.tooth, this.props.index)
  }

  changeGhostVisibility = value=>{
     this.tooth.setGhostVisibility(value)
    this.forceUpdate()
    this.props.update(this.tooth, this.props.index)
  }





  render = () => {



    const styleDiv = {
      position : 'relative',
      //backgroundColor : this.props.selected? 'purple' : 'white',
      color :  'purple',
      border : 'solid', borderRadius : 5,
      height : (this.state.drawed && this.state.ghost)? 110 : this.state.drawed ? 60 : 30,
      width : '90%',
      margin : 2,
      display : this.props.data.display==='none'? 'none' : '',
      overflowY : 'hidden',
      transition : 'all 0.3s'
    }

    const titleStyle = {
     // border : 'solid',
      position : 'absolute', top : 5, left : 25,
      pointerEvents : 'none',
      fontSize : '0.8em'
    }

    const styleCheck = {
      position : 'absolute',
      top : 5, left : 2,
      color : 'green',
      pointerEvents : 'none'
    }

    const colorStyle = {
      width : 30, position : 'absolute',
      right : 80, top : 5,
      cursor : 'pointer'
    }

    const visibilityStyle = {
      position : 'absolute',
      right : 30, top : 5,
      cursor : 'pointer'
      //pointerEvents : 'none'

    }

    const visibilityStyleGhost = {
      position : 'absolute',
      right : 30, top : 5,
     // cursor : 'pointer'
     // border : 'solid'
    //  top : 30
      //pointerEvents : 'none'

    }

    //  const backStyle = {
    //    position : 'absolute', top : 0, left : 0,
    //    width : '100%', height : '100%' ,
    //    backgroundColor : 'red'
    // }

    const measureStyle = {
      position : 'absolute',
      top : 30,
 //border : 'solid',
 width : '100%',fontSize : '0.9em',
 //textAlign: 'center'

 display : 'flex', justifyContent : 'center', alignItems : 'center',
 cursor : 'default'
    }

    //let length = (this.props.ratio.ratio*this.line.length).toFixed(1)

   
    const toothVisibility = {
      position : 'absolute',
     top : 5, left : 60,
     cursor : 'pointer'
 

    }

    const ghostStyle = {
      position : 'absolute',
      height : 25,
      opacity : this.state.ghost? 1 : 0.5,
      top : 5, left : 60,
      cursor : 'pointer'
    }

    const ghostMeasure = {
      position : 'absolute',
      top : 60,
      cursor : 'default'
     //  display : 'flex', justifyContent : 'space-between', alignItems : 'center',
    }


    return (
      
       <div   style={styleDiv} title={this.props.data.name}>

       
        <div>

        {this.state.drawed && <div style={styleCheck}>✔</div>}

        <div style={titleStyle}>{this.props.data.initials}</div>

        {this.state.drawed && <div style={toothVisibility}>
          <input type='checkbox' onChange={e=>this.changeToothVisibility(e.target.checked)} checked={this.tooth.toothVisibility}/>
          <img src={toothSVG} height={20} alt='tooth'/>
        </div>}

        {this.state.drawed && <input type='color' style={colorStyle} value={this.tooth.color} onChange={e=>this.changeColor(e.target.value)}/>}

        {this.state.drawed && <div style={visibilityStyle}>
          <input type='checkbox' onChange={e=>this.changeVisibility(e.target.checked)} checked={this.tooth.visibility}/>
          <img  src={eye} width={20} style={{position : 'relative', left: 2, top : 5}} alt='visibility'/>
        </div>}

      </div>


        {this.state.drawed && <div style={measureStyle} >

          <img src={ghostSVG} style={ghostStyle} onClick={this.toggleGhost} alt='ghost' />

          {this.state.ghost && <input type='color' style={colorStyle} value={this.tooth.ghostColor} onChange={e=>this.changeGhostColor(e.target.value)}/>}


          {this.state.ghost && <div style={visibilityStyleGhost}>
            <input type='checkbox' onChange={e=>this.changeGhostVisibility(e.target.checked)} checked={this.tooth.ghostVisibility}/>
            <img src={eye} width={20} style={{position : 'relative', left: 2, top : 5}} alt='visibility'/>
          </div>}

        </div>}

        {this.state.ghost && <div style={ghostMeasure}>

          <div>{this.tooth.ghostRotate<0? (this.props.data.mandibule? 'Linguoversion' : 'Palatoversion') : 'vestibuloversion'} : {Math.abs(this.tooth.ghostRotate)}°</div>
          {this.tooth.ratio && <div>{this.tooth.ghostBackward<0? 'recul' : 'avancé'} {Math.abs(this.tooth.ghostBackward)} mm</div>}

        </div>}

      


      </div>

    )

  }

}







const yearsOld = (date, today=new Date())=>{

    today.setHours(0,0,0)
    
    let diff = today-date
    let yearsOld = diff/(1000*3600*24*365.24)

    let age = Math.floor(yearsOld)
        
    return age

}


















export default WrapComponent(CephaloViewer)