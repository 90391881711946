import {fabric} from 'fabric'
//import generateHexa from './hexa'
//import * as faceapi from 'face-api.js'
//import {fabric} from 'fabric-with-gestures'

// import detectFace from './detectFace.js'


const sizeFunction = ()=>{
  let height = 0.8*window.innerHeight
  let width = 0.8*window.innerWidth
  //let maxWidth = window.innerWidth - 650

  return height>width? width : height
}


class navigateCanvasFabric {

  constructor(ref, width=sizeFunction, height =sizeFunction, scroll=true) {
    this.minZoom = 1
    this.canvas = new fabric.Canvas(ref, {preserveObjectStacking : true})
    this.height = height
    this.width = width
    this.canvas.setDimensions({width : width()+'px', height : height()+'px'},{cssOnly: true, backstoreOnly : false})
    this.canvas.set({selection : false, stopContextMenu: true,  fireRightClick: true, backgroundColor : 'white'})
    this.canvas.skipOffscreen = false
    this.scroll = scroll
    this.on()

  }

  on = canvas => {

    if (this.scroll) {

      this.canvas
      .on('mouse:wheel', this.zoom)
      .on('mouse:down', this.scrollStart)

    }

    window.addEventListener('resize',this.resize)
     // .on('selection:created',this.selection)
     // .on('touch:gesture', this.scrollTouch)
  }


  off = canvas => {

    this.canvas
      .off('mouse:wheel', this.zoom)
      .off('mouse:down', this.scrollStart) 

    window.removeEventListener('resize',this.resize)
     // .off('touch:gesture', this.scrollTouch)
  }
  
//   selection = opt=>{
//     console.log(opt.selected)
// 
//   }

  resize = ()=>{
    this.canvas.setDimensions({width : this.width()+'px', height : this.height()+'px'},{cssOnly: true, backstoreOnly : false})
    this.canvas.renderAll()
  }

  zoom = opt=>{
   
    opt.e.preventDefault()
    opt.e.stopPropagation()

    this.changeSizeMarkers()

    let delta = opt.e.deltaY
    let coords = this.canvas.getPointer(opt.e,true)
    delta<0 ? this.canvas.setCursor('zoom-out') : this.canvas.setCursor('zoom-in')
    let zoom = this.canvas.getZoom()


    zoom = zoom + delta/1000
    if (zoom > 10) {
      zoom = 10
      this.canvas.setCursor('default')
    }
    if (zoom < this.minZoom) {
      zoom = this.minZoom
      this.canvas.setCursor('default')
    }
    this.canvas.zoomToPoint({x: coords.x, y: coords.y}, zoom)

  }

  changeSizeMarkers = ()=>{

    this.canvas.getObjects().map(element=>{
      if (element.hasOwnProperty('radius')) element.radius = 10/this.canvas.getZoom()
      if (element.hasOwnProperty('strokeWidth')) element.strokeWidth = 4/this.canvas.getZoom()
      // if (element.hasOwnProperty('fontSize')) element.fontSize = 60/this.canvas.getZoom()
      // if (element.hasOwnProperty('padding')) element.padding = 50/this.canvas.getZoom()
      return true
    })


  }



  scrollStart = opt=>{
    
    let event = opt.e

    if (event.button === 2) {

      this.panning = true
      this.canvas.setCursor('move')
      this.lastPointer = opt.pointer

      this.canvas.on('mouse:up',this.scrollStop)
      this.canvas.on('mouse:move', this.scrollMove)
  }

}

  scrollMove = opt=>{

    if (this.panning) {
       this.canvas.setCursor('move')
        
        let movementX = opt.pointer.x - this.lastPointer.x
        
        let movementY = opt.pointer.y - this.lastPointer.y
        
        let delta = new fabric.Point(movementX, movementY)

          this.canvas.relativePan(delta)
         // if (this.canvas.vptCoords.tl.x<0) this.canvas.absolutePan(point)

         // console.log(this.canvas.width)

         // console.log(this.canvas.calcViewportBoundaries())
         // if (this.canvas.calcViewportBoundaries().tl.x<0)this.canvas.absolutePan(new fabric.Point(0,0))

          this.lastPointer = opt.pointer
          this.canvas.renderAll()
        }

  }

//   scrollTouch = opt=>{
// 
//     if(opt.e.type === 'touchstart') {
//       this.lastPointer = new fabric.Point(opt.e.touches[0].clientX,opt.e.touches[0].clientY)
//       this.lastPointer2 = new fabric.Point(opt.e.touches[1].clientX,opt.e.touches[1].clientY)
//     }
// 
//     else {
// 
//         let movementX = (opt.e.touches[0].clientX - this.lastPointer.x) * 2
//         let movementY = ( opt.e.touches[0].clientY - this.lastPointer.y ) * 2
//         let delta = new fabric.Point(movementX, movementY)
// 
//         this.canvas.relativePan(delta)
// 
//         let deltaZoom = -Math.sqrt((this.lastPointer.x-this.lastPointer2.x)**2 + (this.lastPointer.y-this.lastPointer2.y)**2) + Math.sqrt((opt.e.touches[0].clientX - opt.e.touches[1].clientX)**2+(opt.e.touches[0].clientY - opt.e.touches[1].clientY)**2)
//         let center = new fabric.Point(Math.abs(opt.e.touches[0].clientX-opt.e.touches[1].clientX)/2, Math.abs(opt.e.touches[0].clientY-opt.e.touches[1].clientY)/2)   
// 
//         let zoom = this.canvas.getZoom()
//         zoom = zoom + deltaZoom/500
//     
//         if (zoom > 20) zoom = 20
//         if (zoom < 0.5) zoom = 0.5
//       
//         this.canvas.zoomToPoint(center, zoom)
// 
//     }
// 
//     this.lastPointer = new fabric.Point(opt.e.touches[0].clientX,opt.e.touches[0].clientY)
//     this.lastPointer2 = new fabric.Point(opt.e.touches[1].clientX,opt.e.touches[1].clientY)
//     this.canvas.renderAll()
// 
//   }



  scrollStop = opt=>{
    this.panning = false
    this.canvas.setCursor('default')
    this.canvas.off('mouse:up',this.scrollStop)
    this.canvas.off('mouse:move', this.scrollMove)
    
  }


  resetCanvasPosition = () => {
    
    this.canvas.setViewportTransform([1,0,0,1,0,0])
    this.changeSizeMarkers()
   
  }

  

}








export {navigateCanvasFabric}