import React from 'react'
import MainPage from '../components/mainPage'
import WrapComponent from '../contexts/wrap'
import Loader from '../components/loader'
import socket from '../modules/socket'
import mobile from '../functions/mobile'
import PWA from '../components/PWA'




class Main extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      page : '',
      loader : false
    }

  }

  componentDidMount = () => {

    this.logIn()
   // console.log(this.props.context.mobile)

  }

  componentWillUnmount = () => {

  }

  

   logIn =()=>{
    this.setState({loader : true})
    socket.emit('logIn',()=>this.setState({loader : false}))
    

  }

  render = () => {

    return (
      <>

        {this.state.loader && <Loader/>}

        {this.props.context.jwtData && mobile() && <PWA/>}

        {this.props.context.jwtData && !mobile() && <MainPage/> }

      </>
      
    )

  }

}

export default WrapComponent(Main)