import WrapComponent from '../contexts/wrap'
import React from 'react'
import socket from '../modules/socket'
import anonym from '../images/smileImage.svg'







class PictureId extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)
     this.reader = new FileReader()

    this.state = {
       idProfil : anonym
    }

  }

  componentDidMount = () => {

     this.fetchIdPicture()

    this.reader.addEventListener('load', this.readPictureId)

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }


  fetchIdPicture = ()=>{
    //this.props.patientId
    socket.emit('fetchIdPicture',this.props.patientId,data=>{

      if (data.success) {
        let blob = new Blob([data.data], { type: 'image/jpeg'})
        this.reader.readAsDataURL(blob)
      }

    })
  }

  readPictureId = ()=>{
    let picture = this.reader.result
    this.setState({idProfil : picture})
  }


  render = () => {

       const pictureId = {
        position : 'relative',
     // border : 'solid',
      height : '40vh',
      maxWidth : 400, maxHeight : 400,
      margin : 20,
      ...this.props.style
    }

   

    return (
     
      <div>
          <img style={pictureId} src={this.state.idProfil} alt='identity'/>
        </div>

    )

  }

}


export default WrapComponent(PictureId)