
import {placePictureDataFace} from '../functions/placePictureData'
import {fabricViewer} from '../functions/fabricFunctions/viewerPictureFabric'
import {Rule, Ruler, Protractor} from '../functions/fabricFunctions/fabricLengthTools'
import resizeImage from '../functions/resizeImage'
import socket from '../modules/socket'
import placePicture from '../functions/placePicture'
import LandMark from '../functions/fabricFunctions/fabricLandmarkTools'
import {LandMarksFaceObject} from '../objects/landmarksDataObjects'
import Glasses from '../functions/fabricFunctions/fabricGlasses.js'
import {cephaloMarks, cephaloLin} from '../objects/cephaloObject'
import {PointCephalo, LineCephalo, LinesCephalo, ToothCephalo} from '../functions/fabricFunctions/fabricPoint'
import {pointsAngle} from '../functions/fabricFunctions/fabricFunctions'




class PictureViewer {


  constructor(fileId,pictureType='facePicture', placePictureData = placePictureDataFace, landmarkOption = LandMarksFaceObject) {
    this.pictureType = pictureType
    this.placePictureData = placePictureData
    this.landmarkOption = landmarkOption
    this.canvas = document.createElement('canvas')
    this.canvas.height = 1800
    this.canvas.width = 1800
    this.fileId = fileId
    this.reader = new FileReader()
    this.reader.addEventListener('load', this.loadImageFromArray)
    this.lineList=[]
  }


  loadDatas = async () => {

    return new Promise (async (resolve, reject)=>{

      this.fileDatas = await this.fetchFileData()

      if (this.fileDatas.success) {

        this.corrections = this.fileDatas.data.corrections
        this.presentationCanvas = new fabricViewer(this.canvas, this.corrections)
        this.imagePlacement = new placePicture(this.presentationCanvas, this.corrections.markers, this.placePictureData, this.fileId)
        this.fabricCanvas = this.presentationCanvas.canvas

        resolve(this.fabricCanvas)

      }
       else reject(this.fileDatas.msg)

    })


  }


   fetchFileData = ()=>{

    return new Promise ((resolve, reject)=>{
        socket.emit('fetchFileData', this.fileId, this.pictureType, data=>resolve(data))
    })

  }




   loadImage = ()=>{

    return new Promise ((resolve, reject)=>{

      socket.emit('fetchImage', this.fileId, this.pictureType, data=>{
        
        if (data.success) {
          let blob = new Blob([data.data.picture], { type: 'image/jpeg'})
          this.reader.readAsDataURL(blob)
        }
        else reject('error no image')

        this.resolveLoadImage = resolve   
      })

    })

  }


  loadImageFromArray = data=>{
    this.presentationCanvas.loadImage(this.reader.result)
      .then(image=>{
        this.imageFabric=image
       // this.imagePlacement.place()
        this.resolveLoadImage(image) 
      })
  }




  place = ()=>{

    return new Promise ((resolve, reject)=>{
      this.resolvePlace = resolve
      this.imagePlacement.place(()=>this.loadLandmarks())
    })
  }


  placeCephalo = ()=>{

    return new Promise ( (resolve, reject)=>{
      
      this.resolvePlace = resolve

      this.imagePlacement.place(async ()=>{
        await this.loadCephalo()
        this.setCrop(this.fileDatas.data.tools.crop)
        this.loadTools()
      })

    })

  }



   

  loadLandmarks = ()=>{
    
    let landmarksData = this.fileDatas.data.tools.landmarksDefinition


    this.imagePlacement.placeLandmarks(markers=>{


      this.landmarkOption(markers).map((landmarks, index, array)=>{

        let landmark = new LandMark(this.fabricCanvas)
        landmark.createLine(landmarks.lineCoords, landmarks.dash, landmarks.prolongation)
        landmark.name=landmarks.name
        landmark.draw()

        let landmarkOption = landmarksData.filter(item=>item.name===landmarks.name)[0]
        
        if (landmarkOption) {
          landmark.setColor(landmarkOption.color)
          landmark.setVisibility(landmarkOption.visibility)
        }

        if (index===array.length-1) {
          this.loadTools()
          this.setCrop(this.fileDatas.data.tools.crop)
        //  this.drawGlasses()
        }

        return true

      })

    })

  }

  setCrop = value=>this.presentationCanvas.applyCrop(value)
    


   drawGlasses= async ()=>{

    return new Promise((resolve,reject)=>{

      if (this.fileDatas.data.tools.anonym) {

    this.glasses=new Glasses(this.fabricCanvas)
    this.glasses.create(this.pictureType==='leftSidePicture'? 'leftSide' : this.pictureType==='rightSidePicture'? 'rightSide' : 'face')
    .then(()=>{
    this.glasses.loadData(this.fileDatas.data.tools.anonym)
    resolve()
      
  })
   
    }
    else resolve()

    })


  }



  loadTools = ()=>{
    let tools= this.fileDatas.data.tools.toolsDefinition
   
    if (tools.length>0) { 
   
      tools.map((toolData, index, array)=>{
   
        if (toolData.name === 'rule') {
          this.tool = new Rule(this.fabricCanvas)
        }
   
        else if (toolData.name === 'protractor') this.tool = new Protractor(this.fabricCanvas)
       
        else if (toolData.name === 'ruler') this.tool = new Ruler(this.fabricCanvas)
       
        this.tool.load(toolData)

        if (index===array.length-1) this.resolvePlace()

        return true

      })

    }
    else this.resolvePlace()

  

  }


  loadCephalo = ()=>{

    return new Promise (async (resolve, reject)=>{

      let cephalometryData = this.fileDatas.data.tools.cephalometry
      this.markersData = cephalometryData.markers
      this.linesData = cephalometryData.lines

      await this.drawCephaloMarkers()
      await this.drawCephaloLines()

      resolve()


    })

  }

  drawCephaloMarkers = ()=>{

    return new Promise ((resolve, reject)=>{

      cephaloMarks.map(async (marker, index)=>{
         let data = this.markersData.filter(data=>data.name===marker.initials)[0]
        
         if(data) {
        let fabricMarker = new PointCephalo(this.fabricCanvas)
        fabricMarker.name = marker.initials
        fabricMarker.color = data.color
        fabricMarker.load(data.position)
        fabricMarker.setVisibility(data.visibility)
      }

     
      if (index===cephaloMarks.length-1) resolve()

      })

    })

  }

  drawCephaloLines = ()=>{

     return new Promise ((resolve, reject)=>{

      cephaloLin.map((line, index)=>{
         let data = this.linesData.filter(data=>data.name===line.initials)[0]
        

         if(data) {

            if (line.type==='length') {
                this.line = new LineCephalo(this.fabricCanvas, [line.origin.prolongation, line.end? line.end.prolongation : true], line.dash)
                this.line.name = line.name
                this.line.initials = line.initials
                this.line.create()
                this.lineList[index]=this.line
                this.line.setVisibility(line.visibility)
                this.updateLine(line)
              
    if (data && data.hasOwnProperty('color')) this.line.setColor(data.color)
      if (data && data.hasOwnProperty('text')) this.line.setTextData(data.text)
    if (data && data.hasOwnProperty('visibility'))this.line.setVisibility(data.visibility)
    
            }

            if (line.type==='lines') {

              this.lines = new LinesCephalo(this.fabricCanvas, line)
              this.lines.name = line.name
              this.lines.initials = line.initials
              this.lines.create()

              this.lines.setVisibility(line.visibility)

            this.updateLines(line.lines)


             
             if (data && data.hasOwnProperty('visibility'))this.lines.setVisibility(data.visibility)
            if (data && data.hasOwnProperty('text')) this.lines.setTextData(data.text)
              if (data && data.hasOwnProperty('color')) this.lines.setColor(data.color)

            }


          if (line.type==='tooth') {

            this.tooth = new ToothCephalo(this.fabricCanvas, line)
            this.tooth.mandibule = line.mandibule
            this.tooth.name = line.name
            this.tooth.dataMarkers = this.markersData
            this.tooth.initials = line.initials
            // this.tooth.ratio = this.props.ratio

    this.tooth.create(()=>{
      //this.loadSavedLine(line.initials)

    if (data && data.hasOwnProperty('color')) this.tooth.setColor(data.color)
    if (data && data.hasOwnProperty('visibility'))this.tooth.setVisibility(data.visibility)
    if (data && data.hasOwnProperty('toothVisibility'))this.tooth.setToothVisibility(data.toothVisibility)
    if (data && data.hasOwnProperty('ghostData') && data.ghostData!==false){

      // setTimeout(()=>{
         this.tooth.createGhost()
        // console.log(data.ghostData)
        this.tooth.loadGhostData(data.ghostData)
   //   }, 1)


     }
     

    })
    
    this.updatetooth(line)
   

          }


      }

      if (index===cephaloLin.length-1) resolve()

        return true

      })

    })


  }

  updateLine = (data)=>{

    let point1 = this.findCoords(data.origin.marker)
    
   // let point2 
    if (data.end) {
      let point2 = this.findCoords(data.end.marker)
      this.line.update(point1,point2)
     // this.setState({drawed : point1 && point2 ? true : false})
    }

    else if (typeof data.angle === 'number') {
      let angle = data.angle
      this.line.updateWithAngle(point1, angle)
    //  this.setState({drawed : point1 && angle ? true : false})
    }

    else if (typeof data.angle === 'object') {
    
      let angle = data.angle.origin && data.angle.end? (pointsAngle(this.findCoords(data.angle.end), this.findCoords(data.angle.origin)) * (180/Math.PI)) - data.angle.add : false
      
      this.line.updateWithAngle(point1, angle)
     // this.setState({drawed : point1 && angle ? true : false})
    }
  
   
  }




  updateLines = (lines)=>{


    let arrayCoords = lines
    arrayCoords.map((coords, index)=>this.updateLineWithIndex(coords,index))
  
  }

  updateLineWithIndex = (coords,index)=>{

    let point1 = this.findCoords(coords.origin.marker)
    
   // let point2 
    if (coords.end) {
      let point2 = this.findCoords(coords.end.marker)
      this.lines.update(point1,point2,index)
    }

    else if (typeof coords.angle === 'number') {
      let angle = coords.angle
      this.lines.updateWithAngle(point1, angle, index)
    }

    else if (typeof coords.angle === 'object') {
      let angleOrigin = this.props.markers.filter(line=>line && line.name===coords.angle.origin)[0]
      let angleEnd = this.props.markers.filter(line=>line && line.name===coords.angle.end)[0]
      let angle = angleEnd && angleOrigin? (pointsAngle(this.findCoords(angleOrigin), this.findCoords(angleEnd)) * (180/Math.PI)) - coords.angle.add : false
      this.lines.updateWithAngle(point1, angle, index)
    }
   
  }


 updatetooth = (data)=>{

    let point1 = this.findCoords(data.origin.marker)
   // console.log(point1)
    
   // let point2 
    if (data.end) {
      let point2 = this.findCoords(data.end.marker)
      this.tooth.update(point1,point2)

    }

    else if (typeof data.angle === 'number') {
      let angle = data.angle
      this.tooth.updateWithAngle(point1, angle)
    
    }

    else if (typeof data.angle === 'object') {
    
      let angle = data.angle.origin && data.angle.end? (pointsAngle(this.findCoords(data.angle.end), this.findCoords(data.angle.origin)) * (180/Math.PI)) - data.angle.add : false
      this.tooth.updateWithAngle(point1, angle)
     
    }

  }


   findCoords=marker=>{



    if (typeof marker==='string') {
      let coords = this.markersData.filter(line=>line && line.name===marker)[0]
      return coords && coords.hasOwnProperty('position') ? coords.position : false
    }

    else if (marker.hasOwnProperty('cross') && this.linesData) {

      let line1 = this.lineList.filter(line=>line.initials===marker.cross[0])[0]
      let line2 = this.lineList.filter(line=>line.initials===marker.cross[1])[0]

      let xCoords = (line2.formula.b-line1.formula.b)/(line1.formula.a-line2.formula.a) 
      let yCoords = (line1.formula.a * xCoords) + line1.formula.b 


      return {x : xCoords, y : yCoords}

    }

    else if (marker.length===2){
      let coords1 = this.markersData.filter(line=>line && line.name===marker[0])[0]
      let coords2 = this.markersData.filter(line=>line && line.name===marker[1])[0]

      return coords1 && coords1.hasOwnProperty('position') && coords2 && coords2.hasOwnProperty('position') ?
        {x : (coords1.position.x+coords2.position.x)/2, y : (coords1.position.y+coords2.position.y)/2} : false
    }

  }


    
 

  markersCoords = ()=>{

    return new Promise ((resolve, reject)=>{
      this.imagePlacement.placeLandmarks(landmarks=>resolve(landmarks))
    })

  }

 



  exportPicture = ()=>{

    return new Promise (async (resolve, reject)=>{

      this.fabricCanvas.renderAll()
      let dataURLBigSize = this.fabricCanvas.toDataURL({format : 'jpeg'})
      let dataURLMinisize = await resizeImage(dataURLBigSize, 600)



   resolve({picture : dataURLBigSize, preview :  dataURLMinisize})


    })


     
  }

}




export default PictureViewer