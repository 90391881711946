
const resizeImage = (dataURL, maxSize=3000, compression=0.75) =>{
	
	return new Promise ((resolve,reject)=>{

		let img = new Image()
		let canvas = document.createElement('canvas')
    	let ctx = canvas.getContext("2d")
    
   // console.log(dataURL.split('/')[0])
    if(dataURL.split('/')[0]!=='data:image') reject('no image file')

    img.onload = () =>{

      if (img.width<=maxSize && img.height<=maxSize){
        	canvas.width = img.width
        	canvas.height = img.height
      }
      else if (img.height>img.width){
        let ratio = img.width/img.height
        	canvas.height = maxSize
        	canvas.width = ratio * maxSize
      }

      else if (img.height<=img.width){
        let ratio = img.height/img.width
        	canvas.height = ratio * maxSize
        	canvas.width =  maxSize
      }
      
      ctx.drawImage(img,0,0,canvas.width,canvas.height)
      let newDataURL = canvas.toDataURL('image/jpeg', compression)
     // console.log(newDataURL)
      resolve(newDataURL)

	}

	img.src = dataURL
})

}


export default resizeImage