import socket from '../modules/socket'
import exportCephaloPdf from '../functions/exportCephaloPdf'



class pdfCephaloFunction {

	constructor(id, context) {
		this.fileId = id
		this.context = context

		//this.fetch()
	}

	fetch = (callback)=>{

		 socket.emit('fetchFileData', this.fileId, 'sideTeleradio', data=>{
		 	console.log(data)
		 	if (data.success) {
		 		this.ratio = data.data.tools.calibration
			 	this.lines = data.data.tools.cephalometry.lines
			 	this.context = {...this.context, fileData : {checkUpDate : data.data.checkUpDate}}
			 	callback()
			 }
		 })


	}

	generate = ()=>{

		return new Promise((resolve, reject)=>{

			this.fetch(()=>{
				 resolve (new exportCephaloPdf(this.lines, this.ratio, this.context).createPdf())
			})
		// console.log (this.lines, this.ratio, this.context)
		

		})

	}

	createTableObject = ()=>{

		return new Promise((resolve, reject)=>{

			this.fetch(()=>{
				 resolve (new exportCephaloPdf(this.lines, this.ratio, this.context).createTableObject())
			})
		// console.log (this.lines, this.ratio, this.context)
		

		})


	}
}

export default pdfCephaloFunction