import React from 'react'
import {MailInput} from './formatedInput'
import {PassInput} from './passInput'
import CustomButton from './customButton'
import Frame from './frame'
import socket from '../modules/socket'
import cryptHybride from '../functions/crypt'
import cryptoJS from 'crypto-js'
import Loader from './loader'
import Popup from './popup'
import logo from '../images/bigSigle.svg'



class Authentification extends React.Component {

  static defaultProps = {
    handleClick : click=>click
  }

  constructor(props) {

    super(props)

    this.state = {
      validMail : false,
      mail : '',
      validPass : false,
      password : '',
      rememberMe : window.localStorage.rememberMe?JSON.parse(window.localStorage.rememberMe) : false,
      loader : false,
      popup : false,
      noActivePopup : false,
      newMail : ''
    }



  }

  componentDidMount = () => {

    socket.on('logOut',this.logOut)


    //this.logIn()

  }

  componentWillUnmount = () => {

    socket.off('logOut',this.logOut)

  }

  validMail = mail=>this.setState({validMail : mail.validation, mail : mail.unmaskedValue})

  validPass = pass=>this.setState({validPass : pass.validation, password : pass.unmaskedValue})

  handleClick = ()=>{
   // this.props.connect({mail : this.state.mail, pass : this.state.password})
    this.storeLogs()
    this.logIn()
      
  }



  checkRememberMe = e=>{
    this.setState({rememberMe : !this.state.rememberMe},()=>window.localStorage.rememberMe=this.state.rememberMe)
  }

  storeLogs = async ()=>{
    let key = await this.fetchPublicKey()
    let cryptData = cryptHybride.encrypt(JSON.stringify({mail : this.state.mail, password : cryptoJS.SHA512(this.state.password).toString(cryptoJS.enc.Base64)}),key)
    window.sessionStorage.authentification = cryptData
    if (this.state.rememberMe) window.localStorage.authentification = cryptData
  }

  fetchPublicKey = ()=>{
    return new Promise((resolve, reject)=>{
      socket.emit('fetchPublicKey',key=>resolve(key))
    })

  }

  logOut = msg=>{
      console.log('kill again connexion',msg)
      //window.location.hash='connexion'
      // window.sessionStorage.authentification = ''
      // window.localStorage.authentification = ''
      

      this.setState({loader : false, popup : {error : true, msg : msg}})
  }

  logIn = msg=>{
    this.setState({loader : true})

    socket.emit('logIn',data=>{

     if (data.noActive) {
      this.setState({noActivePopup : data, loader : false})
      // window.sessionStorage.authentification = ''
      // window.localStorage.authentification = ''
    }

     if(data.success) window.location.hash='' 
      
    })
  }

  pushNewMail = ()=>{
    this.setState({loader : true})
    //console.log(this.state.newMail.unmaskedValue)
    socket.emit('resendActivationMail',this.state.newMail.unmaskedValue,response=>{
      this.setState({popup : response, noActivePopup : false, loader : false})
    })
  }


  closePopup = ()=>{
    this.setState({popup : false, noActivePopup : false})
    // window.sessionStorage.authentification = ''
    // window.localStorage.authentification = ''
  }

  forgetPassword = ()=>{
    this.setState({loader : true})
    //console.log('forgetpw')
    socket.emit('restorePassword',this.state.mail,response=>{
      this.setState({popup : response, noActivePopup : false, loader : false})
    })
  }



  render = () => {

    const frameStyle = {
      height : 250, maxWidth : 400, width : '80vw',
      
    }

    const buttonStyle = {
      position : 'relative',
      top : 30
    }

    const formStyle = {
      position : 'relative',
      //border : 'solid',
       display : 'flex',
       flexDirection : 'column', justifyContent : 'space-around', alignItems : 'center',
       width : '100%',
     }

    const subscribeStyle = {
      position : 'relative', top : 20, color : 'purple',
      //border : 'solid', 
      width : '100%',
      display : 'flex', justifyContent : 'center',
      cursor : 'pointer'
    }

    const forgetStyle = {
      color : 'purple', position : 'relative', 
      left : 10, top : -5, cursor : 'pointer'
    }

      const noActiveMsg = 
      <div >
        Appuyez sur envoyer pour recevoir un nouveau mail d'activation
        <div style = {{position : 'relative', left : 20}}>
          <MailInput value={mailObject => this.setState({newMail : mailObject})} localStorage='userMail'/>
        </div>     
      </div>


      const styleLogo = {
        position : 'absolute',
        top : -60, left : 10,
        width : 140
      }
    

    return (
      
     

        

          <Frame label='Connexion' style={frameStyle}>

            <img src={logo} style={styleLogo} alt='logo'/>

            {this.state.loader && <Loader/>}

            {this.state.popup && <Popup success ={this.state.popup.success} error={this.state.popup.error} msg={this.state.popup.msg} handleClick={this.closePopup}/>}

            {this.state.noActivePopup.noActive && <Popup 
              close = {true}
              error={this.state.noActivePopup.error} 
              msg={this.state.noActivePopup.msg} 
              handleClick={this.pushNewMail}
              buttonText = 'Envoyer'
              active = {this.state.newMail.validation}
              onClose = {this.closePopup}
            >

              {noActiveMsg}

            </Popup>

            }

            <form style={formStyle}>
        
            <MailInput  value={this.validMail} localStorage='userMail'/>


            <div>
              <PassInput value={this.validPass}/>
              <div style={forgetStyle} onClick={this.forgetPassword}>
                Mot de passe oublié
              </div>
             
            </div>

          
            <CustomButton 
              style = {buttonStyle}
              label='Se connecter' 
              active={this.state.validMail && this.state.validPass} 
              handleClick={this.handleClick}
              activeEnter = {!this.state.popup }
            />  

             <Check label='Se rappeler de moi' handleClick={this.checkRememberMe} checked = {this.state.rememberMe}/>

             <div style={subscribeStyle} onClick = {e=>window.location.hash = 'subscribe'}>Inscrivez-vous</div>

          

        </form>

        </Frame>

    


    )

  }

}



const Check = props=>{

  const style={
    position : 'relative',
    top : 3,
    width : 15, height : 15,
    cursor : 'pointer' 
  }

  const divStyle = {
    position : 'absolute',
    right : 10, top : 155
  }

  return (
    <div style={divStyle}>
      <input style = {style} type='checkbox' checked = {props.checked} onChange={()=>props.handleClick && props.handleClick('fff')}/>
      <span style={{cursor : 'default'}} >{props.label || 'label'}</span>
    </div>
    )

      

}



export default Authentification
