
const markersClassificationPictureFace = (coords)=>{


	   // let coords = this.coords

	    coords.sort((a,b)=>b.y-a.y)
	    let eyeArray = coords.slice(1)
	    eyeArray.sort((a,b)=>a.x-b.x)

	    let markers = {
	      nose : {x : coords[0].x, y : coords[0].y},
	      leftEye : {x :eyeArray[1].x, y :eyeArray[1].y},
	      rightEye : {x: eyeArray[0].x, y : eyeArray[0].y}
	    }

	    //this.markers = markers

	    return markers
    
 }


 const markersClassificationPictureFaceSpreader = (coords)=>{


	 //   let coords = this.coords



	    coords.sort((a,b)=>a.y-b.y)
	    let eyeArray = coords.slice(0,2)
	    eyeArray.sort((a,b)=>a.x-b.x)
	    let teethArray = coords.slice(3,5)
	    teethArray.sort((a,b)=>a.x-b.x)

	    let markers = {
	      nose : {x : coords[2].x, y : coords[2].y},
	      leftEye : {x :eyeArray[1].x, y :eyeArray[1].y},
	      rightEye : {x: eyeArray[0].x, y : eyeArray[0].y},
	      leftTooth : {x : teethArray[1].x, y : teethArray[1].y},
	      rightTooth : {x : teethArray[0].x, y : teethArray[0].y}
	    }

	   // this.markers = markers

	    return markers
    
  	}



  	const markersClassificationPictureLeftSide = (coords)=>{

	   // let coords = this.coords
	    coords.sort((a,b)=>b.x-a.x)
	    let frontPoint = coords.slice(1)
	    frontPoint.sort((a,b)=>a.y-b.y)

	    let markers = {
	    	ear : {x : coords[0].x, y : coords[0].y},
	    	eye : {x : frontPoint[0].x, y : frontPoint[0].y},
	    	orbit : {x : frontPoint[1].x, y : frontPoint[1].y},
	    	nose : {x : frontPoint[2].x, y : frontPoint[2].y}
	    }

	    //this.markers = markers
	    return markers
  	}




  	const markersClassificationPictureRightSide = (coords)=>{

	   // let coords = this.coords
	    coords.sort((a,b)=>a.x-b.x)
	    let frontPoint = coords.slice(1)
	    frontPoint.sort((a,b)=>a.y-b.y)

	    let markers = {
	    	ear : {x : coords[0].x, y : coords[0].y},
	    	eye : {x : frontPoint[0].x, y : frontPoint[0].y},
	    	orbit : {x : frontPoint[1].x, y : frontPoint[1].y},
	    	nose : {x : frontPoint[2].x, y : frontPoint[2].y}
	    }

	  //  this.markers = markers
	    return markers
  	}



  	const markersClassificationTeethFaceShut = (coords)=>{

	   // let coords = this.coords
	    coords.sort((a,b)=>a.x-b.x)


	    let markers = {
	    	rightCanin : {x : coords[0].x, y : coords[0].y},
	    	middle : {x : coords[1].x, y : coords[1].y},
	    	leftCanin : {x : coords[2].x, y : coords[2].y},
	    	
	    }

	   // this.markers = markers
	    return markers
  	}


  	const markersClassificationTeethFaceOpen = (coords)=>{

	   // let coords = this.coords
	    coords.sort((a,b)=>a.x-b.x)
	    let right = coords.slice(0,2)
	    let middles = coords.slice(2,4)
	    let left = coords.slice(4,6)

	    right.sort((a,b)=>a.y-b.y)
	    middles.sort((a,b)=>a.y-b.y)
	    left.sort((a,b)=>a.y-b.y)


	    let markers = {
	    	rightCanin : {x : right[0].x, y : right[0].y},
	    	rightCaninMandibular :{x : right[1].x, y : right[1].y},
	    	middle : {x : middles[0].x, y : middles[0].y},
	    	middleMandibular : {x : middles[1].x , y : middles[1].y},
	    	leftCanin : {x : left[0].x, y : left[0].y},
	    	leftCaninMandibular : {x : left[1].x, y : left[1].y}	
	    }


	   // this.markers = markers
	    return markers
  	}



  	const markersClassificationTeethOccMaxillar = (coords)=>{

	   // let coords = this.coords
	    coords.sort((a,b)=>a.x-b.x)
	    let right = coords.slice(0,2)
	    let middle = coords[2]
	    let left = coords.slice(3,5)

	    right.sort((a,b)=>b.y-a.y)
	    left.sort((a,b)=>b.y-a.y)


	    let markers = {
	    	rightMolar : {x : right[0].x, y : right[0].y},
	    	rightCanin : {x : right[1].x, y : right[1].y},
	    	middle : {x : middle.x, y : middle.y},
	    	leftMolar : {x : left[0].x, y : left[0].y},
	    	leftCanin : {x : left[1].x, y : left[1].y}
	    		
	    }


	  //  this.markers = markers
	    return markers
  	}



  	const markersClassificationTeethOccMandibular = (coords)=>{

	    //let coords = this.coords
	    coords.sort((a,b)=>a.x-b.x)
	    let right = coords.slice(0,2)
	    let middle = coords[2]
	    let left = coords.slice(3,5)

	    right.sort((a,b)=>a.y-b.y)
	    left.sort((a,b)=>a.y-b.y)


	    let markers = {
	    	rightMolar : {x : right[0].x, y : right[0].y},
	    	rightCanin : {x : right[1].x, y : right[1].y},
	    	middle : {x : middle.x, y : middle.y},
	    	leftMolar : {x : left[0].x, y : left[0].y},
	    	leftCanin : {x : left[1].x, y : left[1].y}
	    		
	    }


	    //this.markers = markers
	    return markers
  	}


  	const markersClassificationTeethLeftSide = (coords)=>{

	    //let coords = this.coords
	    coords.sort((a,b)=>a.x-b.x)
	    


	    let markers = {
	    	incisor : {x : coords[0].x, y : coords[0].y},
	    	canin : {x : coords[1].x, y : coords[1].y},
	    	molar : {x : coords[2].x , y : coords[2].y}	    	
	    }

	   // this.markers = markers
	    return markers
  	}


  	const markersClassificationTeethRightSide = (coords)=>{

	  //  let coords = this.coords
	    coords.sort((a,b)=>b.x-a.x)
	    


	    let markers = {
	    	incisor : {x : coords[0].x, y : coords[0].y},
	    	canin : {x : coords[1].x, y : coords[1].y},
	    	molar : {x : coords[2].x , y : coords[2].y}	    	
	    }

	    //this.markers = markers
	    return markers
  	}



  	const markersClassificationOrthopanto = (coords)=>{

	   // let coords = this.coords
	    coords.sort((a,b)=>a.x-b.x)
	    let middles = coords.slice(2,4)
	    middles.sort((a,b)=>a.y-b.y)
	    
	    


	    let markers = {
	    	rightMolar : {x : coords[0].x, y : coords[0].y},
	    	rightCanin : {x : coords[1].x, y : coords[1].y},
	    	middle : {x : middles[0].x, y : middles[0].y},
	    	middleMandibular : {x : middles[1].x , y : middles[1].y},
	    	leftCanin : {x : coords[4].x, y : coords[4].y},
	    	leftMolar : {x : coords[5].x, y : coords[5].y},
	    }


	    //this.markers = markers
	    return markers
  	}



  	const markersClassificationCephalo = (coords)=>{

	   // let coords = this.coords
	    coords.sort((a,b)=>a.x-b.x)
	   // let middles = coords.slice(2,4)
	   // middles.sort((a,b)=>a.y-b.y)
	    
	    


	    let markers = {
	    	ear : {x : coords[0].x, y : coords[0].y},
	    	orbit : {x : coords[1].x, y : coords[1].y}
	    }


	    //this.markers = markers
	    return markers
  	}




  














  	export {
  		markersClassificationPictureFace, 
  		markersClassificationPictureFaceSpreader,
  		markersClassificationPictureLeftSide,
  		markersClassificationPictureRightSide,

  		markersClassificationTeethFaceShut,
  		markersClassificationTeethFaceOpen,
  		markersClassificationTeethLeftSide,
  		markersClassificationTeethRightSide,
  		markersClassificationTeethOccMaxillar,
  		markersClassificationTeethOccMandibular,

  		markersClassificationOrthopanto,
  		markersClassificationCephalo
  	}