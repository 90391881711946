import {fabric} from 'fabric'
import {navigateCanvasFabric} from './createCanvas'
//import * as faceapi from 'face-api.js'
//import {fabric} from 'fabric-with-gestures'

// import detectFace from './detectFace.js'



class preparePictureFabric {

  constructor(ref) {
    this.navigateCanvas = new navigateCanvasFabric(ref)
    this.canvas = this.navigateCanvas.canvas
        
    this.brightness = new fabric.Image.filters.Brightness({ brightness: 0 })
    this.contrast = new fabric.Image.filters.Contrast({contrast: 0})
  }

  off = ()=>this.navigateCanvas.off()
  
  loadImage = (dataUrl, exifOrientation)=>{

    return new Promise ((resolve,reject)=>{

      fabric.Image.fromURL(dataUrl, image=> {
        this.placeImage(image, 1)

        image.set ({
          lockMovementX : true, lockMovementY : true, hoverCursor : 'default',
          selectable : false, type : 'image'
        })

          this.canvas.add(image)
          image.center()

          this.image=image
          resolve(image)
          image.filters.push(this.brightness, this.contrast)

      })

    })

  }

  loadCorrection = correction=>{

     this.image.set({
      angle : correction.rotate,
      left : correction.position.x, top : correction.position.y,
      height : correction.height,width : correction.width,
      scaleX : correction.scale.x, scaleY : correction.scale.y,
      flipX : correction.flip
     })

     this.setBrightness(correction.brightness*100) 
     this.setContrast(correction.contrast*100) 



     


  }

  placeImage = (image,exifOrientation)=>{

    if (exifOrientation === 2){
      image.height > image.width ? image.scaleToHeight(this.canvas.height) : image.scaleToWidth(this.canvas.width)
      image.scaleX = -image.scaleX
      this.exifAngle = 0
    }

    else if (exifOrientation ===3){
      image.height > image.width ? image.scaleToHeight(this.canvas.height) : image.scaleToWidth(this.canvas.width)
      image.angle = 180
      this.exifAngle = 180
    }

    else if (exifOrientation ===4){
      image.height > image.width ? image.scaleToHeight(this.canvas.height) : image.scaleToWidth(this.canvas.width)
      image.scaleX = -image.scaleX
      image.angle = 180
      this.exifAngle = 180
    }

    else if (exifOrientation ===5){
      image.height > image.width ? image.scaleToHeight(this.canvas.width) : image.scaleToWidth(this.canvas.height)
      image.scaleX = -image.scaleX
      image.angle = -90
      this.exifAngle = -90
    }

    else if (exifOrientation ===6){
      image.height > image.width ? image.scaleToHeight(this.canvas.width) : image.scaleToWidth(this.canvas.height)
      image.angle = -90
      this.exifAngle = -90
    }

    else if (exifOrientation ===7){
      image.height > image.width ? image.scaleToHeight(this.canvas.width) : image.scaleToWidth(this.canvas.height)
      image.scaleX = -image.scaleX
      image.angle = 90
      this.exifAngle = 90
    }

    else if (exifOrientation ===8){
      image.height > image.width ? image.scaleToHeight(this.canvas.width) : image.scaleToWidth(this.canvas.height)
      image.angle = 90
      this.exifAngle = 90
    }

    else {
      image.height > image.width ? image.scaleToHeight(this.canvas.height) : image.scaleToWidth(this.canvas.width)
      this.exifAngle=0
    }


  }


  setRotation = angle=>{
    this.image.rotate(this.image.angle+angle)
    // /this.image.scaleToHeight(1000)

    this.image.center()
    // let sc = fabric.util.findScaleToFit(this.image,this.canvas)
    // console.log('sc',sc, this.image.scaleX)
    //this.canvas.renderAll()
  }


  setBrightness = (value)=>{
    this.brightness.brightness =  value/100
    this.image.applyFilters()
    this.canvas.renderAll()
  }


  setContrast = (value)=>{
    this.contrast.contrast =  value/100
    this.image.applyFilters()
    this.canvas.renderAll()
  }

  setFlip = value=>{
    this.image.flipX=value
    this.canvas.renderAll()
  }

  reset = ()=>{
    this.image.rotate(this.exifAngle)
    this.image.center()
    this.brightness.brightness = 0
    this.contrast.contrast = 0
    this.image.flipX=false
    this.image.applyFilters()
    this.canvas.renderAll()
    this.navigateCanvas.resetCanvasPosition()

  }

  resetPosition =()=>this.navigateCanvas.resetCanvasPosition()
  

}







export {preparePictureFabric}