

let yearsOld = (date, today=new Date())=>{

    today.setHours(0,0,0)
    
    let diff = today-date
    let yearsOld = diff/(1000*3600*24*365.24)

    let age

    if (yearsOld < 1) {
      
      let month = Math.floor(yearsOld*12)

      if (month>0) age = month+' mois' 
      else {
        let days = Math.floor(diff/(1000*3600*24))
        days > 1 ? age = days+' jours' : age = days+' jour'
      }

    }

    else if (yearsOld < 2 ) age = Math.floor(yearsOld)+' an'    
    else age = Math.floor(yearsOld)+' ans'
       
      
    return age

}


  export default yearsOld
