import {fabric} from 'fabric'
import {createMarkers} from './fabricMarkers'
import {randomColor} from './fabricFunctions'
import generateHexa from '../hexa'


class landMark {

	constructor (canvas) {

		this.canvas = canvas
		this.markerArray = []
		this.completed = true
		this.name = 'landmark'
		this.color = randomColor()
		this.id = generateHexa(10)
		this.markerVisibility = true
		this.visibility=true
	}

	create = (callback=()=>true)=>{
		this.callback = callback
		this.markers = new createMarkers(this.canvas, this.callbackVisor, this.createMarker, this.mouseMove)
		this.markers.maxMarker=2
		this.markers.color=this.color	
	}

	
	createLine = (coords, dashed, prolongation)=>{
		
		let lineCoords = this.prolongationCoords({x1 : coords[0], y1 : coords[1], x2 : coords[2], y2 : coords[3]})
		let arrayLineCoords = [lineCoords.x1, lineCoords.y1, lineCoords.x2, lineCoords.y2]
		
		let line = new fabric.Line(prolongation? arrayLineCoords : coords, {
			fill : 'red', strokeWidth : 4, stroke : this.color, type : 'marker',
			originX : 'center', originY : 'center', hoverCursor : 'default',
			id : generateHexa(10),
      		hasControls : false, lockMovementX : true, lockMovementY : true, 
      		selectable : false, 
		})

		if (dashed) line.strokeDashArray = [10, 5]

		this.markerArray.push(line)
		
		// this.canvas.add(this.line)
		
	}

	createPoint = coords=>{

		let point = new fabric.Circle({
      radius: 15, fill: this.color, left: coords.x, top:coords.y, 
      originX : 'center', originY : 'center', hoverCursor : 'default',
      type : 'marker', id : generateHexa(10),
      hasControls : false, lockMovementX : true, lockMovementY : true, 
      selectable : false, marker : true
    })

		this.markerArray.push(point)

	}



	draw = ()=>{
		this.canvas.add(...this.markerArray)
	}


	created = opt=>{

		let point1 = this.markerArray[0].getCenterPoint()
		let point2 = this.canvas.getPointer(opt, false)
		this.line.set({x1 : point1.x, y1 : point1.y, x2 : point2.x, y2 : point2.y})
		this.completed=true
		
		this.addText()
		this.callback(this)
		


	}

	setColor = color=>{
		this.color = color
		//this.text.set({backgroundColor : color})
		this.markerArray.map(marker=>marker.set({fill : color, stroke : color}))
		//this.line.set({stroke : color})
		this.canvas.renderAll()
	}

	

	setMarkerVisibility=value=>{
		let pointArray = this.markerArray.filter(marker=>marker.marker)
		pointArray.map(marker=>marker.opacity = value? 1 : 0)

		this.markerVisibility = value
		this.canvas.discardActiveObject()
		this.canvas.renderAll()
	}



	setVisibility = value=>{
		//this.line.opacity = value? 1 : 0

		let markerVisibility = value? this.markerVisibility : false
		this.markerArray.map(marker=>{
			if (marker.marker) marker.opacity = value? markerVisibility : 0
			else marker.opacity = value? 1 : 0
			return true			
		})

		this.visibility=value

		

		
		this.canvas.discardActiveObject()
		this.canvas.renderAll()
	}


	prolongationCoords = (lineCoords)=>{
		let point1 = new fabric.Point(lineCoords.x1,lineCoords.y1)
		let point2 = new fabric.Point(lineCoords.x2,lineCoords.y2)
		

		let a = point1.x-point2.x !==0 ? (point1.y-point2.y)/(point1.x-point2.x) : point1.x
		let b = point1.y-a*point1.x
		let left = 0
		let top = 0
		let right = this.canvas.width
		let bottom = this.canvas.height


		return {
			x1 : a*left+b < top ? (top-b)/a : a*left+b > bottom ? (bottom-b)/a : left, 
			y1 : a*left+b < top ? top : a*left+b > bottom ? bottom : a*left+b, 
			x2 : a*right+b < top ? (top-b)/a : a*right+b > bottom ? (bottom-b)/a : right, 
			y2 : a*right+b < top ? top : a*right+b > bottom ? bottom : a*right+b
		}

	}


	

}


export default landMark