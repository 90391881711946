
const mobile = ()=>{
    let agent = navigator.userAgent
    let test = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i).test(agent)
    return test? true : false
    //return false
  }


  export default mobile
 