
const deleteArray = (array,filterFunction)=>{

  let index = array.findIndex(filterFunction)
  return index===-1? array : array.splice(index,1)

}


export default deleteArray
