import React from 'react'
import resizeImage from '../functions/resizeImage'
import hashParse from '../functions/hashParse'
import socket from '../modules/socket'
import WrapComponent from '../contexts/wrap'
import faceImageWithMarkers from '../images/faceImageWithMarkers.svg'
import Popup from './popup'
import Loader from './loader'
import {LandMarksFaceObject} from '../objects/landmarksDataObjects'
import LoaderSpinner from 'react-loader-spinner'
import {fabricViewer} from '../functions/fabricFunctions/viewerPictureFabric'
import Visor from './visor'
import Calibration from './calibration'
import Navigation from './navigationViewer'
import Export from './exportClutter'
import ss from 'socket.io-stream'
import placePicture from '../functions/placePicture'

import {placePictureDataClutter} from '../functions/placePictureData'

import {createMarkers} from '../functions/fabricFunctions/fabricMarkers'
import {Rule} from '../functions/fabricFunctions/fabricLengthTools'

import {fabric} from 'fabric'

import {pointsDistance} from '../functions/fabricFunctions/fabricFunctions'
//import {pointsDistance, pointsAngle, randomColor} from './fabricFunctions'
import guideNotice from '../images/guideMarkers.svg'
import toothGuideNotice from '../images/toothGuideMarkers.svg'

import guideImage from '../images/guideClutter.svg'
import trash from '../icons/trash.svg'
import camera from '../icons/camera.svg'
import ClutterViewer from '../functions/pictureClutterFunction'

import download from 'downloadjs'




const style = {
width : 150, height : 150, position : 'relative',
borderRadius : 20,
border : 'solid 2px',
borderRight : 'solid 1px', borderBottom : 'solid 1px',
borderColor : 'purple', 
}


class Clutter extends React.Component {

   static defaultProps = {
    pictureType : 'teethPictureOcclusalMandibular',
    markersNumber : 3,
    style : style,
    landmarks : LandMarksFaceObject,
    imageNavigation : faceImageWithMarkers,
   // neutralPicture : face
  }

  constructor(props) {

    super(props)
    this.inputRef = React.createRef()
   
    this.state = {
      loadedImagePicture : '',
      loadedImagePlaster : '',
      exifOrientation : 1,
      delete : false,
      error : false,
      drag : false,
      imageLoader : false
      


    }

  }

  componentDidMount = () => {

    this.readerPicture = new FileReader()
    this.readerPlaster = new FileReader()
    this.readerPreview = new FileReader()
    this.readerPicture.addEventListener('load', this.loadImagePicture)
    this.readerPlaster.addEventListener('load', this.loadedImagePlaster)
    this.readerPreview.addEventListener('load', this.loadImagePreview)
    window.addEventListener('hashchange', this.hashPage)

    socket.on('clutterPreview'+this.props.context.fileData._id, this.fetchImagePreview)
    this.fetchImagePreview()
   
  }

  componentWillUnmount = () => {

    window.removeEventListener('hashchange', this.hashPage)
     socket.off('clutterPreview'+this.props.context.fileData._id, this.fetchImagePreview)
   // this.readerPicture.removeEventListener('load', this.loadImageFromFile)
   // socket.off(this.props.pictureType+'Preview'+this.props.context.fileData._id, this.fetchImagePreview)

  }

  hashPage = ()=>{
    let hashList = hashParse()
    this.setState({
      view : hashList[1] && hashList[1].hashVar? hashList[1].hashVar.view : undefined,
    })

   if (this.state.view==='clutter') this.fetchImageCorrections()
  }


  fetchImageCorrections = (pictureType=this.props.pictureType)=>{

    socket.emit('fetchImage', this.props.context.fileData._id,'teethPictureOcclusalMandibular',(data)=>{
    
      if (data.success) {
        let blob = new Blob([data.data.picture], { type: 'image/jpeg'})
        let corrections = JSON.parse(data.data.corrections)

        if (blob) {
          this.readerPicture.readAsDataURL(blob)
          this.updateCorrectionsPicture(corrections)
        }
        
      }
      else this.setState({loadCorrectionsPicture : true, loadImagePicture :true})


    })


     socket.emit('fetchImage', this.props.context.fileData._id,'plasterPictureOcclusalMandibular',(data)=>{
     // console.log(data)
      if (data.success) {
        let blob = new Blob([data.data.picture], { type: 'image/jpeg'})
        let corrections = JSON.parse(data.data.corrections)

        if (blob) {
          this.readerPlaster.readAsDataURL(blob)
          this.updateCorrectionsPlaster(corrections)
        }

      }
      else this.setState({loadCorrectionsPlaster : true, loadImagePlaster :true})



    })



  }

  // cancelOpen = ()=>{
  //   if (!(this.state.correctionsPicture||this.state.correctionsPlaster)) this.exit()
  // }



  fetchImagePreview = ()=>{


    this.setState({imageLoader : true})

    socket.emit('fetchClutterPreview', this.props.context.fileData._id,this.props.context.patientData._id,(data)=>{

      this.setState({imageLoader : false})

      if (data.success && data.data) {
        let blob = new Blob([data.data], { type: 'image/jpeg'})
        
        //let corrections = JSON.parse(data.data.corrections)

        if (blob) {
          this.readerPreview.readAsDataURL(blob)
         // console.log(blob)
          //this.updateCorrections(corrections)
        }

      }

    })

  }
 
   loadImagePreview = ()=>{
//    // alert('prev')
//    // console.log('test')
    let loadedImage = this.readerPreview.result
   
    this.setState({preview :  loadedImage})
  }


  loadImagePicture = async ()=>{
   let loadedImage = this.readerPicture.result
   this.setState({loadedImagePicture :  await resizeImage(loadedImage), loadImagePicture : true})
  }

  loadedImagePlaster = async ()=>{
   let loadedImage = this.readerPlaster.result
   this.setState({loadedImagePlaster :  await resizeImage(loadedImage), loadImagePlaster : true})
  }


  updateCorrectionsPicture = corrections=>{
    this.setState({correctionsPicture : corrections, loadCorrectionsPicture : true})
  }

  updateCorrectionsPlaster = corrections=>{
    this.setState({correctionsPlaster : corrections, loadCorrectionsPlaster : true})
  }


  exit = ()=>{
    this.setState({loadedImage : false, loadCorrectionsPlaster : false, loadCorrectionsPicture : false, loadImagePlaster : false, loadImagePicture : false})
    window.location.hash = window.location.hash.split('?')[0]+'?page='+hashParse()[1].hashVar.page
  }

  open = ()=>{
    //this.setState({loadedImage : false})
    window.location.hash = window.location.hash.split('?')[0]+'?page='+hashParse()[1].hashVar.page+'&view=clutter'
  }

  deletePicture = () => {
  this.setState({delete : true})

  //this.setState({loadedImage : false, preview : false})
}

cancelDelete = ()=>this.setState({delete : false})

processDelete = ()=>{
  this.setState({loader : true})
  
  socket.emit('deleteClutter', this.props.context.fileData._id,response=>{
    this.setState({
      loadedImagePicture : false,
      correctionsPicture : false,
      loadedImagePlaster : false,
      correctionsPlaster : false,
      preview : false, 
      delete : false, 
      loader : false,
    })

  })

}


  
closeErrorPopup = ()=>this.setState({error : false})

exportPicture = async ()=>{

  let url = new ClutterViewer(this.props.context.fileData._id).start()
  let dataUrl = await url
  let twoNumbers = number=> Number(number)>9 ? number : '0'+number 
   let creationDate = new Date(this.props.context.fileData.creationDate)

    let date = `${twoNumbers(creationDate.getDate())}${twoNumbers(creationDate.getMonth()+1)}${creationDate.getFullYear()}`
   

  let fileTitle = (
      this.props.context.patientData.name.lastname.slice(0,3)+
      this.props.context.patientData.name.firstname.slice(0,3)+'_'+
      date+'_encombrement.jpg'
    )


     download(dataUrl.picture, fileTitle)


}





  render = () => {

     const trashStyle = {
      position : 'absolute',
      bottom : -10, right : 10,
      width : 30,
      cursor : 'pointer'
    }

    const imageStyle = {
      top : 0, left : 0,
      width : '100%', height : '100%',
      position : 'absolute',
      borderRadius : this.props.style.borderRadius,
      filter : this.state.drag || this.state.preview? 'saturate(1)' : 'saturate(0)',
      cursor : 'pointer'
    }

   // console.log('not-allowed', (this.state.correctionsPicture),(this.state.correctionsPlaster))

    const styleLoader = {
      position : 'absolute', 
      top : `${this.props.style.height/2 - 20}px`,
      left : `${this.props.style.width/2 - 20}px`
    }

     const exportStyle = {
      position : 'absolute',
      bottom : -10, right : -20,
      width : 30,
      cursor : 'pointer'
    }

   
    return (

      <>

        {this.state.loader && <Loader/>}


        {this.state.view==='clutter' && 
        this.state.loadImagePicture && this.state.loadCorrectionsPicture &&
        this.state.loadImagePlaster && this.state.loadCorrectionsPlaster && 
        !(this.state.correctionsPicture||this.state.correctionsPlaster) && <Popup 
          msg="Vous devez charger une photographie de la denture mandibulaire ou d'une empreinte mandibulaire pour pouvoir mesurer l'emcombrement."
          handleClick={this.exit}
        />}
        

        {this.state.error && <Popup msg = {this.state.error} handleClick={this.closeErrorPopup}/>}

        {this.state.delete && <Popup 
          choice={true} 
          msg="Etes-vous sûr de vouloir effacer ces mesures d'encombrement?"
          handleClickNo={this.cancelDelete}
          handleClickYes={this.processDelete}
        />}

        

        <div style={this.props.style}>

          <img 
            src={this.state.preview? this.state.preview : guideImage} 
            style={imageStyle} 
            onClick={this.open}
            alt = 'preview'
          />

          {this.state.imageLoader && <LoaderSpinner type="Oval" color="purple" style={styleLoader} height={40} width={40} radius = {15} />}

          {this.props.context.creator && this.state.preview && <img title='Effacer' style={trashStyle} src={trash} onClick={this.deletePicture} alt='trash'/>}

          {this.state.preview && <img title='Enregistrer image' style={exportStyle} src={camera} onClick={this.exportPicture} alt='export'/>}

      </div>

      
        {this.state.view==='clutter' && 
        this.state.loadImagePicture && this.state.loadCorrectionsPicture &&
        this.state.loadImagePlaster && this.state.loadCorrectionsPlaster && 
        (this.state.correctionsPicture||this.state.correctionsPlaster)
         
          && <ClutterTrace 
            imagePicture={this.state.loadedImagePicture} 
            correctionsPicture={this.state.correctionsPicture} 
            imagePlaster={this.state.loadedImagePlaster} 
            correctionsPlaster={this.state.correctionsPlaster} 
            pictureType={this.props.pictureType}
            placePictureData={placePictureDataClutter}
            landmarks = {this.props.landmarks}
          />
        }

      </>

    )

  }

}






class ClutterTrace extends React.Component {

  static defaultProps = {
    pictureType : 'facePicture'
  }

  constructor(props) {

    super(props)
    this.canvasRef = React.createRef()
    this.measureCanvasRef = React.createRef()
    this.calibrationRef = React.createRef()
    this.navigationRef = React.createRef()
    this.pathref = React.createRef()
    //this.clutterData = false

  
    this.state = {
      landmarks : [],
      widthMarkers : [],
      ratio : false,
      loader : true,
      imagePlaced : false,
      crop : true,
      lines : [],
      incisorsRetraction : false,
      typePicture : 'plaster' 
    }

  }

  componentDidMount = () => {

    const sizeFunction = ()=>{
     
      let height = 0.8*window.innerHeight
      let width = 0.8*window.innerWidth

      let size = height>width? width : height

      return size>0.4*window.innerWidth? 0.4*window.innerWidth : size

    }

    this.presentationCanvas = new fabricViewer(this.canvasRef.current, this.props.correctionsPlaster, sizeFunction, sizeFunction)
    this.measureCanvas = new fabricViewer(this.measureCanvasRef.current,this.props.correctionsPicture, sizeFunction, sizeFunction, false)
    this.measureCanvas.canvas.backgroundColor = 'white'
    //this.finalCanvas = new fabricViewer(this.finalCanvasRef.current)

    this.fabricCanvas = this.presentationCanvas.canvas
    this.setState({canvas : this.fabricCanvas})
   

    this.changePicture(this.props.imagePlaster, this.props.correctionsPlaster,()=>{
      
      socket.emit('fetchClutter',this.props.context.fileData._id, this.props.context.patientData._id, data=>{
        this.loadData(data)
        this.setState({loader : false})
      })

    })

    this.drawWidthMarkers()


    socket.emit('fetchTools', this.props.context.fileData._id, this.props.pictureType, this.props.context.patientData._id, data=>{
      this.setState({loadingData : data})
    })

    socket.emit('fetchFileData', this.props.context.fileData._id, 'sideTeleradio',  data=>{
      let fetched = data.success && data.data.tools.cephalometry.lines.filter(line=>line.name==='iaib')[0]?  data.data.tools.cephalometry.lines.filter(line=>line.name==='iaib')[0].measure.translate : 0
      this.updateIncisorRetraction(fetched)
    })

    

    this.drawNotice()

  }

  loadData=data=>{

    if (data.success && data.data && data.data.drawedLinesData){

      let guideCoords = data.data.drawedLinesData.guidePoints
      guideCoords.map(coords=>this.pointsMarker.loadMarker(coords))

      let lines = data.data.drawedLinesData.lines
      lines.map(coords=>this.loadLine(coords))

     // console.log('dataloaded', data.data.clutterMeasure)

      //let ratio = data.data.ratio
      this.setState({loadingData : data, clutter : data.data.clutterMeasure})


  //     this.setState({clutter : {
  //   total : (Number(2*this.state.incisorsRetraction)+Number(clutterSum)).toFixed(1),
  //   left : leftClutter,
  //   right : rightClutter,
  //   incisorsRetraction : this.state.incisorsRetraction,
  //   toothClutter : clutterSum
  // }})

      let view = data.data.view
      this.changePix(view)

    }
  }



  drawNotice = ()=>{

    new fabric.Image.fromURL(guideNotice, img=>{
      
      img.scaleToWidth(this.measureCanvas.canvas.width)
      img.center()
      img.type='marks'
      
      this.measureCanvas.canvas.add(img)
      this.imageNotice1 = img
    })


    new fabric.Image.fromURL(toothGuideNotice, img=>{
      
      img.scaleToWidth(this.measureCanvas.canvas.width)
      img.center()
      img.type='marks'
      
      this.imageNotice2 = img
    })




  }

  updateIncisorRetraction = value=>{

    let retraction = Number(value).toFixed(1)

   this.setState({incisorsRetraction : retraction<0? -retraction : 0})
    let retractionText = this.state.incisorsRetraction>0? `Recul de l'incisif : ${this.state.incisorsRetraction}mm` : 'Pas de recul des incisives'

    this.clutterIncisorRetractionText = new fabric.Text(retractionText,{
      left : 900, top : 1500,
      originX : 'center', fontSize : 80, fill : 'purple',
      type : 'marks',
      hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
    })

    let clutterText = (2*this.state.incisorsRetraction>0)? `Encombrement : ${2*this.state.incisorsRetraction}mm` : ''

    this.totalClutterText = new fabric.Text(clutterText,{
      left : 900, top : 1700,
      originX : 'center', fontSize : 80, fill : 'red',
      type : 'marks',
      hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
    })

  }


  changePix = type=>{
    //let type = e.target.value
   // this.clutterData.view = type
    this.setState({typePicture : type})
    this.fabricCanvas.remove(this.imageFabric)
   
    if (type==='picture') {
     // this.presentationCanvas.imageCorrections=this.props.correctionsPicture
      this.changePicture(this.props.imagePicture, this.props.correctionsPicture)
       
    }

    if (type==='plaster') {
   //   this.presentationCanvas.imageCorrections=this.props.correctionsPlaster
      this.changePicture(this.props.imagePlaster, this.props.correctionsPlaster) 
    }

  }


  changePicture = (image, corrections, callback=()=>true)=>{

   // console.log('props correction', this.props.correctionsPicture)
    
    let markerss = this.fabricCanvas.getObjects('marker')
    markerss.map(object=>this.fabricCanvas.remove(object) )


    let correct = corrections ? corrections : this.props.correctionsPlaster ? this.props.correctionsPlaster : this.props.correctionsPicture
    let img =image ? image : this.props.imagePlaster ? this.props.imagePlaster : this.props.imagePicture
    this.presentationCanvas.imageCorrections = correct

    this.presentationCanvas.loadImage(img)
      .then(image=>{

        this.imageFabric=image 
        
        
        setTimeout(()=>markerss.map(object=>this.fabricCanvas.add(object)), 400)
       
        let imagePlacement = new placePicture(this.presentationCanvas,correct.markers, placePictureDataClutter, this.props.context.fileData._id)
        
        imagePlacement.place(()=>{
          imagePlacement.placeLandmarks(this.updateLandmarks)
          this.setState({imagePlaced : true})
          this.setCrop(this.state.crop? this.state.crop : false)
          callback()
        })
        
      })

  }


  drawWidthMarkers=()=>{
   
      this.pointsMarker = new createMarkers(this.fabricCanvas,this.visorCanvas,this.updateMarker,this.moveMarker)
      this.pointsMarker.maxMarker = 2
    
  }


  visorCanvas = canvas=>{

    if (canvas === '') this.timer = setTimeout(()=>this.setState({visor : canvas}),1000)
    
    else {
      clearTimeout(this.timer)
      this.setState({visor : canvas})
    }
    
  }

  updateMarker=(marker, type)=>{
    
    if (this.pointsMarker.markersArray.length===2) {
      let size = pointsDistance(this.pointsMarker.markersArray[0].getCenterPoint(), this.pointsMarker.markersArray[1].getCenterPoint())
      this.drawGuide(size)
    }

    else if (this.guide) {

      this.measureCanvas.canvas.remove(this.guide, this.clutterLeftText, this.clutterRightText, this.clutterAddText, this.clutterIncisorRetractionText)

      let lineUncompleted = this.state.lines.filter(line=>!line.completed)
      
      lineUncompleted.map(line=>{
        line.cancel()
        this.measureCanvas.canvas.remove(line.intermediateLine)
        return true
      })

      this.measureCanvas.canvas.getObjects('marks').map(object=>this.measureCanvas.canvas.remove(object))

      this.measureCanvas.canvas.add(this.imageNotice1)
     
    }

  }


  moveMarker = ()=>{

    if (this.pointsMarker.markersArray.length===2) {
      let size = pointsDistance(this.pointsMarker.markersArray[0].getCenterPoint(), this.pointsMarker.markersArray[1].getCenterPoint())
      this.redrawGuide(size)
    }

  }


  redrawGuide = size=>{
   
    let scale = size/100

    this.guide.set({
      scaleX : scale, scaleY : scale,
      strokeWidth : 10/scale, strokeDashArray : [20/scale, 20/scale]
    })

    this.clutterLeftText.set({
      left : this.guide.calcCoords().bl.x, top : this.guide.calcCoords().bl.y+50, 
    })

    this.clutterRightText.set({
      left : this.guide.calcCoords().br.x, top : this.guide.calcCoords().br.y+50, 
    })
    
    this.clutterAddText.set({
      left : this.guide.calcCoords().mb.x, top : this.guide.calcCoords().mb.y+50,
    })


    this.measureCanvas.canvas.renderAll()

    
    let lineUncompleted = this.state.lines.filter(line=>!line.completed)
    lineUncompleted.map(line=>{
      line.cancel()
      this.measureCanvas.canvas.remove(line.intermediateLine)
      return true
    })

    this.createLine()
    this.placeToothOnPath(this.state.lines.filter(line=>line.completed))


  }

drawGuide = (size)=>{

  this.measureCanvas.canvas.remove(this.guide, this.clutterLeftText, this.clutterRightText, this.clutterAddText, this.clutterIncisorRetractionText)

  let pathCoords = 'M 2 101.4 C 2 74 8.2 44.5 24.3 22 C 43.6-5.2 85-5.5 104.6 21.5  c15.5 23.6 22.4 51.7 22.4 80'
  
  let scale = size/100
  this.guide = new fabric.Path(pathCoords)

  this.guide.set({
    fill : 'rgba(0,0,0,0)', stroke : 'purple', scaleX : scale, scaleY : scale,
    originX : 'center', originY : 'top',
    strokeWidth : 10/scale, strokeDashArray : [20/scale, 20/scale],
    top : 300, left : 900,
    hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
    type : 'marks'
  })

  
  this.clutterLeftText = new fabric.Text('',{
    left : this.guide.calcCoords().bl.x, top : this.guide.calcCoords().bl.y+50, 
    originX : 'center', fontSize : 80, fill : 'purple',
    type : 'marks',
    hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
  })

  this.clutterRightText = new fabric.Text('',{
    left : this.guide.calcCoords().br.x, top : this.guide.calcCoords().br.y+50, 
    originX : 'center', fontSize : 80, fill : 'purple',
    type : 'marks',
    hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
  })
  
  this.clutterAddText = new fabric.Text('',{
    left : this.guide.calcCoords().mb.x, top : this.guide.calcCoords().mb.y+50,
    originX : 'center', fontSize : 80, fill : 'red',
    type : 'marks',
    hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
  })

 //console.log(this.measureCanvas)
  this.measureCanvas.canvas.add(this.guide, this.clutterLeftText, this.clutterRightText, this.clutterAddText, this.clutterIncisorRetractionText)
  this.measureCanvas.canvas.remove(this.imageNotice1)
  this.fabricCanvas.on('selection:updated', this.changeSelect)

  this.createLine()
  this.placeToothOnPath(this.state.lines.filter(line=>line.completed))
}


createLine = ()=>{

  let lineUncompleted = this.state.lines.filter(line=>!line.completed)
  lineUncompleted.map(line=>{
    line.cancel()
    this.measureCanvas.canvas.remove(line.intermediateLine)
    return true
  })

  this.fabricCanvas.off('selection:cleared', this.createLine)
  this.fabricCanvas.off('selection:created', this.createLine)

  let line = new Rule(this.fabricCanvas,this.visorCanvas, this.updateLine)
  line.textVisibility = false
  line.color = 'red'

  this.state.lines.push(line)

  line.create(this.lineCreated)

}

loadLine = coords=>{
  let line = new Rule(this.fabricCanvas,this.visorCanvas, this.updateLine)
  line.textVisibility = false
  line.color = 'red'

  this.state.lines.push(line)

  let data={
    coords:[[coords.x1,coords.y1],[coords.x2, coords.y2]],
    color : 'red',
    //this.id = data.id
    textVisibility : false,
    visibility : true,
    markerVisibility : true
  }

  line.load(data,this.lineCreated)

}


lineCreated = (line)=>{

  if (line.intermediateLine) this.measureCanvas.canvas.remove(line.intermediateLine)
  this.createLine()
  this.placeToothOnPath(this.state.lines.filter(line=>line.completed))

}



placeToothOnPath = (lines)=>{

  // console.log(lines.map(line=>line.line))
  // console.log(this.pointsMarker.markersArray)

  this.measureCanvas.canvas.getObjects('marks').map(object=>this.measureCanvas.canvas.remove(object))

  let changeReferenciel = (object,x,y)=>{
    let left = (object.left-(object.scaleX*(object.width/2)))+(x*object.scaleX)-20
    let top = (object.top)+(y*object.scaleY)-20
    return ({x : left, y : top})
  }

  let pathCoords = 'M 2, 101.4 C 2, 74, 8.2, 44.5, 24.3, 22 C 43.6-5.2, 85-5.5, 104.6, 21.5  c 15.5, 23.6, 22.4, 51.7, 22.4, 80'
   
  let path = this.pathref.current
  path.setAttribute('d', pathCoords)
 
  let length = path.getTotalLength()
  let middle = path.getPointAtLength(length*0.5)
  let middlePoint = new fabric.Point(middle.x, middle.y)

  let center = changeReferenciel(this.guide, middlePoint.x, middlePoint.y)


  let point = new fabric.Circle({
      radius: 15, fill: 'red', left: center.x, top:center.y, 
      originX : 'center', originY : 'center', 
      hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
      type : 'marks'
  })
  
  let teeth = this.sortTeeth(lines)

  this.rightTeeth = [...teeth.incisorsRight, teeth.caninRight, ...teeth.premolarRight]
  this.leftTeeth = [...teeth.premolarLeft.reverse(), teeth.caninLeft, ...teeth.incisorsLeft].reverse()

  this.rightTeethMeasure = this.rightTeeth.filter(line=>line).map(line=>line.distance)
  this.leftTeethMeasure = this.leftTeeth.filter(line=>line).map(line=>line.distance)

  let positionedLines = []

  this.leftTeethMeasure.reduce((total,distance, index, array)=>{
      
      let length = path.getTotalLength()

      //let middle = path.getPointAtLength(length*0.5)
      let distanceFromMiddle = (length/2) - ((distance/this.guide.scaleX)+total)
      let oldDistanceFromMiddle = (length/2) - total
      let position = path.getPointAtLength(distanceFromMiddle)
      let last = path.getPointAtLength(oldDistanceFromMiddle)
      let newPosition = changeReferenciel(this.guide, position.x, position.y)
      let lastPosition = changeReferenciel(this.guide, last.x, last.y)

      let point = new fabric.Circle({
        radius: 15, fill: 'red', left : newPosition.x, top : newPosition.y, 
        originX : 'center', originY : 'center', type : 'marks',
        hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
      })

      let lastpoint = new fabric.Line([lastPosition.x, lastPosition.y, newPosition.x, newPosition.y],{
        stroke : 'red', strokeWidth : 8,
        originX : 'center', originY : 'center', type : 'marks',
        hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
      })

      positionedLines.push({x1: lastPosition.x, y1 : lastPosition.y, x2 : newPosition.x, y2 : newPosition.y})

  
      this.measureCanvas.canvas.add(point, lastpoint)

      if (index===array.length-1) this.limitPointLeft = point

      return ((distance/this.guide.scaleX)+total)

  },0)

  

  this.rightTeethMeasure.reduce((total,distance, index, array)=>{
      
      let length = path.getTotalLength()

      // let middle = path.getPointAtLength(length*0.5)
      let distanceFromMiddle = (length/2) + ((distance/this.guide.scaleX)+total)
      let oldDistanceFromMiddle = (length/2) + total
      let position = path.getPointAtLength(distanceFromMiddle)
      let last = path.getPointAtLength(oldDistanceFromMiddle)
      let newPosition = changeReferenciel(this.guide, position.x, position.y)
      let lastPosition = changeReferenciel(this.guide, last.x, last.y)

      let point = new fabric.Circle({
        radius: 15, fill: 'red', left : newPosition.x, top : newPosition.y, 
        originX : 'center', originY : 'center', type : 'marks',
        hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
      })

      let lastpoint = new fabric.Line([lastPosition.x, lastPosition.y, newPosition.x, newPosition.y],{
        stroke : 'red', strokeWidth : 8,
        originX : 'center', originY : 'center', type : 'marks',
        hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
      })

      positionedLines.push({x1: lastPosition.x, y1 : lastPosition.y, x2 : newPosition.x, y2 : newPosition.y})
  
      this.measureCanvas.canvas.add(point, lastpoint)

      if (index===array.length-1) this.limitPointRight = point

      return ((distance/this.guide.scaleX)+total)
    
  },0)



    let uper = line=>line.y1>line.y2? {x: line.x2, y:line.y2} : {x: line.x1, y:line.y1}

    let molarLimitLeft = teeth.molarLeft? uper(teeth.molarLeft.line) : undefined
    let molarLimitRight = teeth.molarRight? uper(teeth.molarRight.line) : undefined

    if (molarLimitLeft) {

      this.molarLeftLimit = new fabric.Line([0,molarLimitLeft.y,1800,molarLimitLeft.y],{
        stroke : 'purple', type : 'marks',
        strokeWidth : 3, strokeDashArray : [20, 20],
        opacity : 0.5,
        hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
      })

      this.measureCanvas.canvas.add(this.molarLeftLimit)

    }
  
    if (molarLimitRight) {

      this.molarRightLimit = new fabric.Line([0,molarLimitRight.y,1800,molarLimitRight.y],{
        stroke : 'purple', type : 'marks',
        strokeWidth : 3, strokeDashArray : [20, 20],
        opacity : 0.5,
        hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
      })

      this.measureCanvas.canvas.add(this.molarRightLimit)

    }  


    if (molarLimitRight && molarLimitLeft) {
      this.molarLimit = new fabric.Line([0,(molarLimitRight.y+molarLimitLeft.y)/2,1800,(molarLimitRight.y+molarLimitLeft.y)/2],{
        stroke : 'red', type : 'marks',
        strokeWidth : 3,
        hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
      })

      this.measureCanvas.canvas.add(this.molarLimit)

    }  

   
    let limit = this.molarLimit? this.molarLimit.y2 : undefined
    let limitToothRight = this.limitPointRight? this.limitPointRight.top : undefined
    let limitToothLeft = this.limitPointLeft? this.limitPointLeft.top : undefined
   
    this.leftClutter = limitToothLeft-limit
    this.rightClutter = limitToothRight-limit

    let clutterMeasure = this.measureClutter()

    if (this.state.lines.filter(line=>line.completed).length>0){
      this.measureCanvas.canvas.add(point,this.guide, this.clutterIncisorRetractionText, this.clutterLeftText, this.clutterRightText, this.clutterAddText, this.totalClutterText)
    }

    else this.measureCanvas.canvas.add(this.imageNotice2)

    // save clutter data
      let toothLines =  lines.map(line=>({
        x1 : line.line.x1, y1 : line.line.y1,
        x2 : line.line.x2, y2 : line.line.y2 
      }))

      let guidePoints = this.pointsMarker.markersArray.map(point=>({x : point.left, y : point.top}))
      
      
      this.clutterData = {
        calibration : this.state.ratio,

        drawedLinesData : {
          guidePoints : guidePoints,
          lines : toothLines
        },

        clutterData : {
          molarLimitLeft : molarLimitLeft,
          molarLimitRight : molarLimitRight,
          lines : positionedLines
        },

        clutterMeasure : clutterMeasure,
        view : this.state.typePicture,
       // crop : this.state.crop

      }
     
}


measureClutter=()=>{
 
  let leftClutter = (this.leftClutter && this.leftClutter>0 && this.state.ratio)? (this.state.ratio.ratio * this.leftClutter).toFixed(1) : 0
  let rightClutter = (this.rightClutter && this.rightClutter>0 && this.state.ratio)? (this.state.ratio.ratio * this.rightClutter).toFixed(1) : 0

  this.clutterLeftText.set({
    text : leftClutter>0? leftClutter+'mm' : ''
  })

  this.clutterRightText.set({
    text : rightClutter>0? rightClutter+'mm' : ''
  })

  let clutterSum = (Number(leftClutter)+Number(rightClutter)).toFixed(1)
  let totalClutter = this.state.ratio ? clutterSum+'mm' : ''

  this.clutterAddText.set({
    text : totalClutter
  })

  this.setState({clutter : {
    total : (Number(2*this.state.incisorsRetraction)+Number(clutterSum)).toFixed(1),
    left : leftClutter,
    right : rightClutter,
    incisorsRetraction : this.state.incisorsRetraction,
    toothClutter : clutterSum
  }})

  let total = this.state.ratio? `Encombrement : ${this.state.clutter.total}mm` : ''

  this.totalClutterText.set({
    text : total
  })

  return this.state.clutter
  

}



sortTeeth = lines=>{


    let molarRight = lines.filter(line=>{
      let point1 = new fabric.Point(line.line.x1, line.line.y1)
      let point2 = new fabric.Point(line.line.x2, line.line.y2)

      let selectLine = (
        ((this.state.LandMarks.rightMolar.y>point1.y && this.state.LandMarks.rightMolar.y<point2.y)
        ||(this.state.LandMarks.rightMolar.y<point1.y && this.state.LandMarks.rightMolar.y>point2.y)) 
        && point1.x > 900
      )

      return selectLine

    })

    let molarLeft = lines.filter(line=>{
      let point1 = new fabric.Point(line.line.x1, line.line.y1)
      let point2 = new fabric.Point(line.line.x2, line.line.y2)

      let selectLine = (
        ((this.state.LandMarks.leftMolar.y>point1.y && this.state.LandMarks.leftMolar.y<point2.y)
        ||(this.state.LandMarks.leftMolar.y<point1.y && this.state.LandMarks.leftMolar.y>point2.y)) 
        && point1.x < 900
      )

      return selectLine

    })


    let caninRight = lines.filter(line=>{
      let point1 = new fabric.Point(line.line.x1, line.line.y1)
      let point2 = new fabric.Point(line.line.x2, line.line.y2)

      let selectLine = (
        ((this.state.LandMarks.rightCanin.y>point1.y && this.state.LandMarks.rightCanin.y<point2.y)
        ||(this.state.LandMarks.rightCanin.y<point1.y && this.state.LandMarks.rightCanin.y>point2.y)) 
        && point1.x > 900
      )

      return selectLine

    })


     let caninLeft = lines.filter(line=>{
      let point1 = new fabric.Point(line.line.x1, line.line.y1)
      let point2 = new fabric.Point(line.line.x2, line.line.y2)

      let selectLine = (
        ((this.state.LandMarks.leftCanin.y>point1.y && this.state.LandMarks.leftCanin.y<point2.y)
        ||(this.state.LandMarks.leftCanin.y<point1.y && this.state.LandMarks.leftCanin.y>point2.y)) 
        && point1.x < 900
      )

      return selectLine

    })

    let incisors = lines.filter(line=>{
      let point1 = new fabric.Point(line.line.x1, line.line.y1)
      let point2 = new fabric.Point(line.line.x2, line.line.y2)

      let selectLines = (
       point1.x>this.state.LandMarks.leftCanin.x &&  point2.x>this.state.LandMarks.leftCanin.x 
        && point1.x<this.state.LandMarks.rightCanin.x &&  point2.x<this.state.LandMarks.rightCanin.x
      )

      return selectLines

    })


    incisors.sort((line1, line2)=>{
      let point11 = new fabric.Point(line1.line.x1, line1.line.y1)
      let point12 = new fabric.Point(line1.line.x2, line1.line.y2)

      let point21 = new fabric.Point(line2.line.x1, line2.line.y1)
      let point22 = new fabric.Point(line2.line.x2, line2.line.y2)

      let sortPoints1 = [point11,point12]
      sortPoints1.sort((a,b)=>a.x-b.x)

      let sortPoints2 = [point21,point22]
      sortPoints2.sort((a,b)=>a.x-b.x)

      return sortPoints1[0].x-sortPoints2[0].x

    })


    let incisorsLeft = incisors.filter(line=>{
      let point1 = new fabric.Point(line.line.x1, line.line.y1)
      let point2 = new fabric.Point(line.line.x2, line.line.y2)
      let sortPoint = [point1,point2]

      sortPoint.sort((a,b)=>a.x-b.x)
      let distanceCenter = sortPoint.map(coords=>Math.abs(coords.x - this.state.LandMarks.middle.x))

      let selectLines = (
        (point1.x < this.state.LandMarks.middle.x && point2.x < this.state.LandMarks.middle.x)
        || (distanceCenter[0]>distanceCenter[1])
      )

      return selectLines

    })


    let incisorsRight = incisors.filter(line=>{
      let point1 = new fabric.Point(line.line.x1, line.line.y1)
      let point2 = new fabric.Point(line.line.x2, line.line.y2)
      let sortPoint = [point1,point2]

      sortPoint.sort((a,b)=>a.x-b.x)
      let distanceCenter = sortPoint.map(coords=>Math.abs(coords.x - this.state.LandMarks.middle.x))

      let selectLines = (
        (point1.x > this.state.LandMarks.middle.x && point2.x > this.state.LandMarks.middle.x)
        || (distanceCenter[0]<distanceCenter[1])
      )

      return selectLines

    })



    let premolarLeft = lines.filter(line=>{
      let point1 = new fabric.Point(line.line.x1, line.line.y1)
      let point2 = new fabric.Point(line.line.x2, line.line.y2)

      let selectLines = (
       point1.y<this.state.LandMarks.leftMolar.y &&  point2.y<this.state.LandMarks.leftMolar.y 
        && point1.y>this.state.LandMarks.leftCanin.y &&  point2.y>this.state.LandMarks.leftCanin.y
        && point1.x < this.state.LandMarks.middle.x
      )

      return selectLines

    })


    let premolarRight = lines.filter(line=>{
      let point1 = new fabric.Point(line.line.x1, line.line.y1)
      let point2 = new fabric.Point(line.line.x2, line.line.y2)

      let selectLines = (
       point1.y<this.state.LandMarks.rightMolar.y &&  point2.y<this.state.LandMarks.rightMolar.y 
        && point1.y>this.state.LandMarks.rightCanin.y &&  point2.y>this.state.LandMarks.rightCanin.y
        && point1.x > this.state.LandMarks.middle.x
      )

      return selectLines

    })



    premolarLeft.sort((line1, line2)=>{
      let point11 = new fabric.Point(line1.line.x1, line1.line.y1)
      let point12 = new fabric.Point(line1.line.x2, line1.line.y2)

      let point21 = new fabric.Point(line2.line.x1, line2.line.y1)
      let point22 = new fabric.Point(line2.line.x2, line2.line.y2)

      let sortPoints1 = [point11,point12]
      sortPoints1.sort((a,b)=>a.y-b.y)

      let sortPoints2 = [point21,point22]
      sortPoints2.sort((a,b)=>a.y-b.y)

      return sortPoints1[0].y-sortPoints2[0].y

    })


     premolarRight.sort((line1, line2)=>{
      let point11 = new fabric.Point(line1.line.x1, line1.line.y1)
      let point12 = new fabric.Point(line1.line.x2, line1.line.y2)

      let point21 = new fabric.Point(line2.line.x1, line2.line.y1)
      let point22 = new fabric.Point(line2.line.x2, line2.line.y2)

      let sortPoints1 = [point11,point12]
      sortPoints1.sort((a,b)=>a.y-b.y)

      let sortPoints2 = [point21,point22]
      sortPoints2.sort((a,b)=>a.y-b.y)

      return sortPoints1[0].y-sortPoints2[0].y

    })




    let teeth = {
      molarRight : molarRight[0],
      molarLeft : molarLeft[0],
      caninLeft : caninLeft[0],
      caninRight : caninRight[0],
      incisorsLeft : incisorsLeft,
      incisorsRight : incisorsRight,
      premolarLeft : premolarLeft,
      premolarRight : premolarRight

    }

    return teeth

    

}

updateLine = (line)=>{
  this.placeToothOnPath(this.state.lines.filter(line=>line.completed))
}


changeSelect = e=>{

  let lineUncompleted = this.state.lines.filter(line=>!line.completed)
  lineUncompleted.map(line=>{
    line.cancel()
    this.measureCanvas.canvas.remove(line.intermediateLine)
    return true
  })

  
  this.fabricCanvas.on('selection:cleared', this.createLine)
  this.fabricCanvas.on('selection:created', this.createLine)

  this.placeToothOnPath(this.state.lines.filter(line=>line.completed))

}


calibrationFocus = ()=>{
  
  this.pointsMarker.pause()
  
  let lineUncompleted = this.state.lines.filter(line=>!line.completed)
  lineUncompleted.map(line=>{
    line.cancel()
    this.measureCanvas.canvas.remove(line.intermediateLine)
    return true
  })

}

calibrationBlur = ()=>{
  this.pointsMarker.unpause()
  if (this.pointsMarker.markersArray.length===2) this.createLine()
}


updateLandmarks = landmarks=>{
  this.setState({LandMarks : landmarks})
}


drawMarkers = coords=>{
   
  let point = new fabric.Circle({
      radius: 10, fill: 'red', left: coords.x, top:coords.y, 
  })
  
  this.measureCanvas.canvas.add(point)

}

  
calibrate = ratio=>{
  this.setState({ratio : ratio},()=>{
    if (this.pointsMarker.markersArray.length===2) this.updateLine()
  })   
}

  setCrop = value=>{
    this.presentationCanvas.applyCrop(value)
    this.setState({crop : value})
  }



  saveClutter = ()=>{
    console.log("save",this.state.crop,this.clutterData )

    this.clutterData = {
      ...this.clutterData, 
      crop : this.state.crop,
      preview : this.props.context.fileData._id+'/clutterPreview'+this.props.context.fileData._id
    }

    socket.emit('pushClutter', this.props.context.fileData._id, this.clutterData, response=>{
      console.log('ok')
    })

    this.savePreview()

  }



  savePreview = async ()=>{

    let dataURLBigSize = this.measureCanvas.canvas.toDataURL()
    let dataURLMinisize = await resizeImage(dataURLBigSize, 600)

    fetch(dataURLMinisize).then(res => res.blob()).then(blob => {
      this.preview = blob
      this.pushViewerData()
    })
     
  }


  pushViewerData = ()=>{

    let  file = this.preview
    let stream = ss.createStream()
    ss(socket).emit('file', stream, {name : 'clutterPreview', size: file.size, fileId : this.props.context.fileData._id, type : 'image'})
    let blobStream = ss.createBlobReadStream(file)
    blobStream.pipe(stream)

  }




  render = () => {


     const divStyle = {
      position : 'fixed',
      border : 'solid',
      width : '100vw', height : '100vh',
      top : 0, left : 0,
      display : 'flex', alignItems : 'center', justifyContent : 'space-around',
      backgroundColor : 'rgba(255,255,255,0.8)',
      zIndex : 2
    }

    const canvasStyle = {
     
      position : 'absolute',
      border : 'solid 2px',
      borderRight : 'solid 1px', borderBottom : 'solid 1px',
      borderColor : 'purple', borderRadius : 10,
      zIndex : 7,
      margin : 20
    
    }

    // const measureCanvasStyle = {
    //  
    //   position : 'absolute',
    //   border : 'solid 2px',
    //   borderRight : 'solid 1px', borderBottom : 'solid 1px',
    //   borderColor : 'purple', borderRadius : 10,
    //   zIndex : 4,
    //   margin : 20,
    //   //height : 400, width : 400
    // 
    // }

   

     const calibrationStyle = {
      position : 'static',
      top : 10, left : 'auto'
     
    }

  

const chooseStyle = {
  position : 'static',
  top : 30, left : 'auto',
  height : 30, fontSize : '1.3em', color : 'purple',
  border : 'solid 2px',
  borderRight : 'solid 1px', borderBottom : 'solid 1px',
  borderColor : 'purple', borderRadius : 10,
  outline : 'none'
}

const navigationStyle = {
  top  : 5, left : 'auto', bottom : 'auto', height : 50,
  position : 'static'
}

const exportStyle = {
  position : 'static'
}

const optionStyle={
  //border : 'solid',
  position : 'fixed',
  width : '100vw', top : 0, left : 0,
  display : 'flex', alignItems : 'center', justifyContent : 'space-around',
  zIndex : 6


}

    return (

      <div style={divStyle}>


        {this.state.loader && <Loader/>}
      
        <canvas style={canvasStyle} ref={this.canvasRef} height={1800} width={1800}/>

        <canvas style={canvasStyle} ref={this.measureCanvasRef} height={1800} width={1800}/>


        {this.state.visor && <Visor canvas={this.state.visor}/>}

       <div style={optionStyle}>

        {this.state.imagePlaced && <Calibration ref={this.calibrationRef} style={calibrationStyle} canvas = {this.state.canvas} visorCanvas={this.visorCanvas} updateCalibration={this.calibrate} loadingData = {this.state.loadingData} blur={this.calibrationBlur} focus={this.calibrationFocus}/>}

         <select style={chooseStyle} onChange={e=>this.changePix(e.target.value)} value={this.state.typePicture}>
          
          {this.props.correctionsPlaster && <option value='plaster'>Empreinte</option>}
          {this.props.correctionsPicture && <option value='picture'>Photographie</option>}

        </select>

        {this.state.imagePlaced && this.state.loadingData && <Navigation style={navigationStyle} ref={this.navigationRef} focus={()=>this.presentationCanvas.navigateCanvas.resetCanvasPosition()} canvas={this.state.canvas} setCrop={this.setCrop} loadingData = {this.state.loadingData} pictureType='teethPictureOcclusalMandibular'/>}

        {this.state.canvas && <Export style={exportStyle} save={this.saveClutter} type={this.props.pictureType} presentationCanvas={this.measureCanvas}/>}

       

        </div>


        <svg style={{display : 'none'}}><path ref={this.pathref}/></svg>


      </div>
    )

  }

}










ClutterTrace = WrapComponent(ClutterTrace)

export default WrapComponent(Clutter)


