import React from 'react'
import socket from '../modules/socket'
import parseSearch from '../functions/parseSearch'
import Popup from '../components/popup'
import Loader from '../components/loader'



class MailActivation extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      popup : false,
      loader : true
    }

  }

  componentDidMount = () => {

    //console.log(window.location)

    this.pushData(parseSearch(window.location.search))

   
  }

  componentWillUnmount = () => {

  }

  pushData = data=> socket.emit('accountActivation',data,this.resultActivation)

  resultActivation = popup=>{
    console.log(popup)
    //window.location.search = ''
    this.setState({popup : popup, loader : false})
  }

  closePopup = ()=> {
    window.close()
    //console.log(window.location)
    //socket.close()
    //window.location.pathname = '/'
  }


  render = () => {

    return (
      
      <>

        {this.state.loader && <Loader/>}

        {this.state.popup && 
          <Popup 
            error={this.state.popup.error}
            success={this.state.popup.success} 
            msg={this.state.popup.msg} 
            handleClick={this.closePopup}/>
          }

      



      </>



    )

  }

}








export default MailActivation