import React from 'react'
import trash from '../icons/trash.svg'
import rotation from '../icons/arrows.svg'
import brightnessIcon from '../icons/illumination.svg'
import contrastIcon from '../icons/tool.svg'
import mirror from '../icons/flip.svg'
import {preparePictureFabric} from '../functions/fabricFunctions/preparePictureFabric'
import {createMarkers} from '../functions/fabricFunctions/fabricMarkers'
import Frame from './frame'
import socket from '../modules/socket'
import ss from 'socket.io-stream'
//import FaceImage from '../images/faceImage'
import Visor from './visor'
import WrapComponent from '../contexts/wrap'
import Navigation from './navigation'


class PreparePicture extends React.Component {

  static defaultProps = {
    pictureType : 'facePicture',
    markersNumber : 3,
   // imageNavigation : FaceImage
  }

  constructor(props) {
    super(props)
    this.canvasRef = React.createRef()

    this.state = {
      visor : '',
      uploadProgress : 0      
    }

  }

  componentDidMount = async () => {

    this.navigateCanvas = new preparePictureFabric(this.canvasRef.current, this.props.image)
    this.image = await this.navigateCanvas.loadImage(this.props.image, this.props.exifOrientation)
    this.fabricCanvas = this.navigateCanvas.canvas
    this.pointsMaker = new createMarkers(this.fabricCanvas,this.visorDataURL,this.updateMarker,this.updateMarker)
    this.pointsMaker.maxMarker = this.props.markersNumber

    if (this.props.savedCorrection) this.loadCorrection(this.props.savedCorrection)
    else this.saveNewImage(this.props.image)

  }

  componentWillUnmount = () => {
    this.navigateCanvas.off()
    this.pointsMaker.off()
  }

  
  loadCorrection = corrections=>{
    this.navigateCanvas.loadCorrection(corrections)
    corrections.markers.map(marker=>this.pointsMaker.loadMarker(marker))
    this.setState({uploadProgress : 1})
  }


  saveNewImage = dataUrl=>{

    fetch(dataUrl).then(res => res.blob())
      .then(blob => {

        let  file = blob
        this.fileSize = file.size
        this.uploaded = 0
        let stream = ss.createStream()
 
        ss(socket).emit('file', stream, {name : this.props.pictureType, size: file.size, fileId : this.props.context.fileData._id})
        let blobStream = ss.createBlobReadStream(file)

        blobStream.on('data', this.uploadProgress)
        blobStream.pipe(stream)

      })
  }

  uploadProgress = chunk=>{
    this.uploaded += chunk.length
    this.setState({uploadProgress : this.uploaded/this.fileSize})
  }



  updateMarker = marker=>{
    
    this.arrayMarkers = this.pointsMaker.markersArray
    let numberMarker = this.arrayMarkers.length
    this.coords = Array.from(this.arrayMarkers,item=>({x : item.left, y : item.top}))    
    this.setState({ numberMarker : numberMarker})
    
  }


  validPreparation = e=>{

    let imageCorrectionData = {
      markers : this.coords,
      brightness : this.navigateCanvas.brightness.brightness,
      contrast : this.navigateCanvas.contrast.contrast,
      height : this.navigateCanvas.image.height,
      width : this.navigateCanvas.image.width,
      rotate : this.navigateCanvas.image.angle,
      position : {x : this.navigateCanvas.image.left, y : this.navigateCanvas.image.top},
      scale : {x : this.navigateCanvas.image.scaleX, y : this.navigateCanvas.image.scaleY},
      flip : this.navigateCanvas.image.flipX 
    }

    socket.emit('savePicture', 
      imageCorrectionData, this.props.context.fileData._id, this.props.pictureType ,
      response=>console.log('saved'))

    this.props.updateCorrections(imageCorrectionData)

  }


  visorDataURL = canvas=>{

    if (canvas === '') this.timer = setTimeout(()=>this.setState({visor : canvas}),1000)
    else {
      clearTimeout(this.timer)
      this.setState({visor : canvas})
    }
    
  }

  handleChangeBrightness = value=>this.navigateCanvas.setBrightness(value)
  
  handleChangeContrast = value=>this.navigateCanvas.setContrast(value)
  
  rotate = angle=>this.navigateCanvas.setRotation(angle)

  flip = e=>{
    this.navigateCanvas.setFlip(e.target.checked)
  }
  
  reset = ()=>{
    this.navigateCanvas.reset()
    this.pointsMaker.eraseAllMarkers()
    this.setState({listMarker : false, numberMarker : 0})
  }

  resetNavigation = ()=>this.navigateCanvas.resetPosition()

  render = () => {

    const divStyle = {
      position : 'fixed',
      border : 'solid',
      width : '100vw', height : '100vh',
      top : 0, left : 0,
      display : 'flex', justifyContent : 'center', alignItems : 'center', flexDirection : 'column',
      backgroundColor : 'rgba(255,255,255,0.8)',
      zIndex : 2
    }

    const canvasStyle = {
      border : 'solid 2px',
      borderRight : 'solid 1px', borderBottom : 'solid 1px',
      borderColor : 'purple', borderRadius : 10,
    }


    return (

      <div style={divStyle}>
      
        <div style={{position : 'relative'}}>
          <ProgressBar progress = {this.state.uploadProgress}/>
          <canvas style={canvasStyle} ref={this.canvasRef} height={1500} width={1500}/>
        </div>
        
        {this.state.visor && <Visor canvas={this.state.visor}/>}
        
        <Setting changeBrightness={this.handleChangeBrightness} changeContrast={this.handleChangeContrast} rotate={this.rotate} flip={this.flip} reset={this.reset} savedCorrection={this.props.savedCorrection}/>
        <Navigation focus={this.resetNavigation}/>
        <Markers numberMarker={this.state.numberMarker} image={this.props.imageNavigation} totalMarkers={this.props.markersNumber}/>

        <Validate 
          validate={this.state.uploadProgress===1 && this.state.numberMarker===this.props.markersNumber} 
          exit={this.props.exit}
          validation = {this.validPreparation}
        />

      </div>
     
    )

  }

}



const ProgressBar = props=>{

  const svgStyle={
    width : '100%', 
    position : 'absolute', top : -3, left : 0,
    opacity : props.progress === 1? 0 : 1,
    transition : 'all 0.5s'
  }

  return (
    <svg  style = {svgStyle}>
      <path stroke="purple" strokeWidth={6} d={`M0 0 H${(props.progress)*(props.width || 0.8*window.innerHeight)}`}/>
    </svg>

    )
}
  



const Validate=props=>{

  const validateStyle = {
    display : 'flex', justifyContent : 'space-between',
    width : 100,
    position : 'absolute',
    bottom : 20, right : 50,
    fontSize : 50,
  }

  const checkStyle = {
    color : 'green',
    opacity : props.validate? 1 : 0,
    pointerEvents : props.validate? 'auto' : 'none',
    transition : 'all 0.3s',
    cursor : 'pointer'
  }

  const uncheckStyle = {
    color : 'red',
    cursor :'pointer'
  }

  return (

    <div style={validateStyle}>

      <div style={checkStyle} onClick = {props.validation}>✓</div>

      <div style={uncheckStyle} onClick = {props.exit}>✗</div>

    </div>


    )
}




const Markers = props=> {


    const frameStyle = {
      position : 'absolute',
      bottom : 120, right : 40,
      width : 150
    }

    const divStyle = {
      position : 'relative',
      display : 'flex', justifyContent : 'center', 
      flexDirection : 'column',alignItems : 'center',
      padding : 10, paddingLeft : 0,
      color : 'purple', fontSize : '0.8em', textAlign : 'center',
      cursor : 'default'
    }

    const markerRest = (props.totalMarkers||3) - (props.numberMarker||0)
    const message = `${markerRest} ${markerRest===1? 'point est' : 'points sont'} encore à placer`

    //let Image = props.image

    return (
      
      <Frame style={frameStyle} label="Marqueurs">

        <div style={divStyle}>

          <img width = {150} src={props.image} alt='points'/>
          {markerRest===0? 'Tous les points de repère sont placés' : message} 

        </div>
       

      </Frame>
    )

  }




class Setting extends React.Component {

  
  constructor(props) {
    super(props)

    this.state = {
      brightness : 0,
      contrast : 0,
      rotation : 0,
      flip : false
    }

  }

  componentDidMount = ()=>{
    
    if (this.props.savedCorrection){
      let contrast = this.props.savedCorrection.contrast*100
      let brightness = this.props.savedCorrection.brightness*100
      let flip = this.props.savedCorrection.flip? true : false
     // console.log('flip',flip)
      this.setState({brightness : brightness, contrast : contrast, flip : flip})
    }

  }


  changeBrightness = e=>{
    this.setState({brightness : e.target.value})
    this.props.changeBrightness(e.target.value)
  }

   changeContrast = e=>{
    this.setState({contrast : e.target.value})
    this.props.changeContrast(e.target.value)
  }

  rotate = angle=>this.props.rotate(angle)

  flip = e=>this.setState({flip : e.target.checked},this.props.flip(e))

  reset = ()=>{
    this.setState({brightness : 0, contrast :0, flip : false})
    this.props.reset()
  }

  render = () => {

    const frameStyle = {
      position : 'absolute',
      top : 20, left : 40,
    }

    const divStyle = {
      position : 'relative',
      display : 'flex', justifyContent : 'space-around', flexDirection : 'column',
      padding : 20,
    }

    const rotateStyle = {
      width : '100%',
      display : 'flex', justifyContent : 'space-between'
    }

    const divRangeStyle = {
      paddingBottom : 10
    }

    return (

      <Frame style={frameStyle} label="Améliorer l'image">

      <div style={divStyle}>
        
        <div style={divRangeStyle}>
          <img src={brightnessIcon} alt='brightness' width={30}/>
          <input type = 'range' title='Changer la luminosité' style={{cursor : "pointer"}} value={this.state.brightness} min={-100} max={100} onChange={this.changeBrightness}/>
        </div>

        <div style={divRangeStyle}>
          <img src={contrastIcon} alt='contrast' width={20} style={{padding : 5}}/>
          <input type = 'range' title='Changer le contraste' style={{cursor : "pointer"}} value={this.state.contrast} min={-100} max={100} onChange={this.changeContrast}/>
        </div>
        
        <div style={rotateStyle}>
          <img title = "Rotation de l'image" alt='rotate' style={{transform : 'scale(-1, -1)', cursor : 'pointer'}} src={rotation} width={30} onClick={e=>this.rotate(-45)}/>
          <img  title = "Effacer toutes les modifications" alt='erase' style={{cursor : "pointer"}} src={trash} width={30} onClick={this.reset}/>
          
          <div title='Utilisation du mirroir'>
            <img alt='flip'  src={mirror} width={35} />
            <input type='checkbox' onChange={this.flip} checked={this.state.flip} style={{cursor : 'pointer', position : 'relative', top : -10}}/>
          </div>
          
          <img title = "Rotation de l'image" alt='rotate' style={{transform : 'scaleY(-1)', cursor : 'pointer'}} src={rotation} width={30} onClick={e=>this.rotate(45)}/>
        </div>


      </div>

      </Frame>
    )

  }

}





export default WrapComponent(PreparePicture)


