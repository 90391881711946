import React from 'react'
import Frame from './frame'
import calibration from '../icons/calibration.svg'
import {Rule} from '../functions/fabricFunctions/fabricLengthTools'
import trash from '../icons/trash.svg'



class Calibration extends React.Component {

  static defaultProps = {
    focus : ()=>true,
    blur : ()=>true
  }

  constructor(props) {

    super(props)
    this.rule = false
    this.state = {
      select : false,
      value : false,
    }

  }

  componentDidMount = ()=>{
    this.load()
  }


  componentDidUpdate = (prevProps)=> {
    if (this.props.loadingData !== prevProps.loadingData) this.load()
  }

  load = ()=>{

    if (this.props.loadingData.success) {
      let data = this.props.loadingData.data.calibration

      if (data) {

        let dataRule = {
          coords : [[data.coords[0].x, data.coords[0].y], [data.coords[1].x, data.coords[1].y]],
          color : '#800080',
          id : 'calibration',
          markerVisibility : true,
          visibility : false,

          text : {
            visibility : false,
            text : '',
            coords : [0,0],
            angle : 0, height : 0, width : 0,
            scaleX : 0, scaleY : 0,
          }
        }

        this.setState({value : data.millimeters},()=>{
          this.rule  = new Rule(this.props.canvas, this.props.visorCanvas, this.updateCalibration)
          this.rule.load(dataRule, this.updateCalibration)
        }) 

      }

    }

  }

  blur = e=>{

    this.setState({select : false})
    this.props.blur()

    if (this.rule && !this.rule.completed) {
      this.rule.cancel()
      this.rule=false
      this.updateCalibration()
    }

    else if (this.rule) this.rule.setVisibility(false)
  }


  focus = e=>{

    this.setState({select : true},this.props.focus)
    

    if (!this.rule) {
      this.rule  = new Rule(this.props.canvas, this.props.visorCanvas, this.updateCalibration)
      this.rule.textVisibility = false
      this.rule.color='#800080'
      this.rule.create(this.updateCalibration)
    }

    else if (this.rule && this.rule.completed) {
      this.rule.setVisibility(true)
    }
   
  }


  handleChange = e=>this.setState({value : e.target.value},this.updateCalibration)

  

  updateCalibration = ()=>{

    if (this.rule) {
      let millimeters = this.state.value
      let pixel = this.rule.distance
      let ratio = this.rule.completed? millimeters / pixel : false
      let coords = this.rule.markerArray.map(marker=>({x : marker.getCenterPoint().x, y : marker.getCenterPoint().y}))
      this.props.updateCalibration(ratio ? {ratio : ratio, millimeters : millimeters, coords : coords} : false)
    }

  }

  cancelCalibration = ()=>{
    this.rule.cancel()
    delete this.rule
    this.setState({value : ''}, this.updateCalibration)
  }


  render = () => {

    const frameStyle = {
      position : 'absolute',
      top : 20, left : 40,
      width : 230, 
      display : 'flex', justifyContent : 'center', flexDirection : 'column',
      zIndex : 6,
      ...this.props.style
    }

    const divStyle = {
      position : 'relative', left : -10,
      display : 'flex', justifyContent : 'space-between', alignItems : 'center',
      zIndex : this.state.select? 4 : 1,
      padding : 5
    }

     const iconStyle = {
      width : 40
    }

    const inputStyle = {
      width : 60,
      border : 'solid 2px',
      borderRight : 'solid 1px', borderBottom : 'solid 1px',
      borderColor : 'purple', borderRadius : 5,
      textAlign : 'center'
    }

    const backgroundStyle = {
      position : 'fixed',
      top : 0, left : 0, width : '100vw', height : '100vh',
      zIndex : 3,
     // backgroundColor : 'rgba(255,255,255,0.7)'
    }

    const colorStyle = {
      width : 30, height : 30,
      pointerEvents : 'none'
    }

    const trashStyle ={
      width : 20,
      cursor : 'pointer'

    }

   

    return (

      <>

      {this.state.select && <div style={backgroundStyle} onClick={this.blur}/>}
      
      <Frame style={frameStyle} label="Calibration">

        <div style={divStyle} onClick={this.focus}>

          <img style={iconStyle} src={calibration} alt='caliper'/>

          <input type='number' min={0} value={this.state.value} placeholder='en mm' style={inputStyle} onChange={this.handleChange}/>

          <input type='color'  style={colorStyle} defaultValue='#800080' />

          <img src={trash} style={trashStyle} title='Effacer la calibration' alt='cancel' onClick={this.cancelCalibration}/>
          
        </div>

      
        </Frame>

        </>

    )

  }

}



export default Calibration