const parseSearch = search=>{

  let request = Buffer.from(search.slice(1), 'base64').toString('utf-8')
  
  let pairArray = request.split('&')
  
  let searchObject = pairArray.reduce((accumul, item)=>{
    let object = {}
    let pair = item.split('=')
    object[pair[0]]=pair[1]
    return Object.assign({},accumul,object)
  },{})

  return searchObject
}




export default parseSearch