import React from 'react'
import shareIcon from '../icons/group.svg'
import socket from '../modules/socket'
import Input from './customInput'
import WrapComponent from '../contexts/wrap'
import Frame from './frame'
import trash from '../icons/trash.svg'
import close from '../icons/cancel.svg'
import Popup from './popup'





class ShareFile extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
     open : false
    }

  }


  open = e=>this.setState({open : true})

  close = e=>this.setState({open : false})

  render = () => {

    const styleDiv = {
     // border : 'solid',
      position : 'fixed',
      bottom : 35, right : 180,
      zIndex : 2
    }

    return (

      <>
      

        <div style={styleDiv}>
          
          <img alt='open' src={shareIcon} width={45} onClick={this.open}/>

          {this.state.open && <Share close={this.close}/>}

        </div>

        


      </>

    )

  }

}


class Share extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)
 
    this.state = {
      listSharedUsers : []
    }

  }

  componentDidMount = () => {
    this.fetchShareList()
    socket.on('updateSharedList',this.fetchShareList)
  }

  componentWillUnmount = () => {
    socket.off('updateSharedList',this.fetchShareList)
  }

 
  fetchShareList = ()=>{

    socket.emit('shareList',this.props.context.patientData._id,list=>{
      this.setState({listSharedUsers : list})
    })

  }

 
  render = () => {

    const backgroungStyle = {
      position : 'fixed',
      top : 0, left : 0,
      width : '100vw', height : '100vh',
      backgroundColor : 'rgba(255,255,255,0.8)',
      display : 'flex', justifyContent : 'center', alignItems : 'center',
    }

    const shareStyle = {
      position : 'relative',
      width : '70vw', height : '80vh',
      display : 'flex', justifyContent : 'space-around', 
    }

    const styleClose = {
      width : 30,
      position : 'absolute',
      right : -20,
      cursor : 'pointer'
    }

    return (
      

      <>

        <div style={backgroungStyle}/>


          
        <div style={shareStyle}>
          
          <ShareInput listSharedUsers={this.state.listSharedUsers}/>

          <ShareActiveList listSharedUsers={this.state.listSharedUsers}/>

          <img alt='close' src={close} style={styleClose} onClick={this.props.close}/>

        </div> 

        

      </>


    )

  }

}


class ShareInput extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)
    this.refInput = React.createRef()

    this.state = {
      value : '',
      listUsers : [],
      popup : false
    }

  }

 

  onChange = value=>{
    console.log(value.unmaskedValue)
    this.setState({value : value.unmaskedValue}, this.fetchUsers)
    //this.fetchUsers(value.unmaskedValue)

  }


  fetchUsers = ()=>{
    let sharedListId  = Array.from(this.props.listSharedUsers, elem=>elem._id)
    socket.emit('fetchUsers', this.state.value, sharedListId, list=>{
      console.log(list)
      this.setState({listUsers : this.state.value==='' ? [] : list})
    })
  }

  selectUser = user=>{
    
    console.log(user._id, this.props.context.patientData._id)
    socket.emit('addShareUser', user._id, this.props.context.patientData._id)
    this.refInput.current.erase()
    this.setState({popup : false})
   // console.log(this.refInput.current)
  }


  selectPopupUser = user => this.setState({popup : user})
  

  closePopup = ()=> this.setState({popup : false})

 

  render = () => {

    const styleDiv = {
     // border : 'solid',
      flex : 1
    }

    const styleList = {
      position : 'relative',
      border : 'solid purple', borderRadius : 10,
      backgroundColor : 'white',
      padding : 5,
      left : 10, 
      width : 200

    }

    return (

      <>

      {this.state.popup && <Popup 
        choice={true} 
        msg={`Etes-vous sur de vouloir paratger ce dossier avec ${this.state.popup.name.firstname} ${this.state.popup.name.lastname} ?`}
        handleClickYes={()=>this.selectUser(this.state.popup)}
        handleClickNo={this.closePopup}
      />}

      <div style={styleDiv}>
      
      <Input ref={this.refInput} check={false} label='Recherche' value={this.onChange} />

      { this.state.listUsers.length>0 && <div style={styleList}>
        
       { this.state.listUsers.map(user=>
                 <AutoCompleteList data={user} selectUser={this.selectPopupUser}/>
                 )}

      </div>}

      </div>

      </>
    )

  }

}




class AutoCompleteList extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      over : false
    }

  }


  over = e=>this.setState({over : true})

  leave = e=>this.setState({over : false})

  render = () => {

    const styleDiv = {
   //   border : 'solid', 
      borderRadius : 5,
      //margin : 5,
      color : this.state.over? 'white' : 'purple',
      backgroundColor : this.state.over? 'purple' : '',
      textAlign : 'center',
      cursor : 'pointer'
    }

    return (
      
      <div  
        style = {styleDiv} 
        onMouseEnter = {this.over}
        onMouseLeave = {this.leave}
        onClick={()=>this.props.selectUser(this.props.data)}
      >
        {this.props.data.name.lastname} {this.props.data.name.firstname}
      </div>

    )

  }

}



class ShareActiveList extends React.Component {

   static defaultProps = {

  }

//   constructor(props) {
// 
//     super(props)
// 
//    
// 
//   }

 
  render = () => {

    const styleDiv = {
      //border : 'solid',
      flex : 1,
      width : '60%', height : '70%',
      padding : 40
    }

    return (
      
      <Frame label='Partagé avec' style = {styleDiv}>

        <div style={{height : '100%', width : '100%', overflowY : 'auto'}}>

        {
          this.props.listSharedUsers.map(list=>

            <SharedDoctor data={list}/>
            )
          }

        </div>

        </Frame>


    )

  }

}

class SharedDoctor extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      popup : false
    }

  }

 

  erasePopup=()=>this.setState({popup : true})

  closePopup=()=>this.setState({popup : false})

  erase = ()=>{
    socket.emit('eraseList',this.props.data._id, this.props.context.patientData._id, this.props.erase)
    this.setState({popup : false})
  }

  render = () => {

    const divStyle = {
   //   position : 'relative',
    //  border : 'solid',
      margin : 20, 
      //marginBottom : 0,
      padding : 5,
      border : 'solid 2px',
      borderRight : 'solid 1px', borderBottom : 'solid 1px',
      borderColor : 'purple', borderRadius : 10,
      cursor : 'default'
    }

    const deleteStyle = {
      position : 'absolute',
      right : 40,
     // top : 22, left : 215,
      width : 20,
     // pointerEvents: this.props.lock? 'none' : 'auto',
      cursor : 'pointer'
    }

   

    return (

      <>

      {this.state.popup && <Popup 
        choice={true} 
        msg={`Etes-vous sur de vouloir arréter le paratge ce dossier avec ${this.props.data.name.firstname} ${this.props.data.name.lastname} ?`}
        handleClickYes={this.erase}
        handleClickNo={this.closePopup}
      />}

      <div style ={divStyle} >
        
       {this.props.data.name.lastname} {this.props.data.name.firstname}

       <img style = {deleteStyle} src = {trash} onClick = {this.erasePopup} alt='delete'/>


      </div>

      </>
      
    )

  }

}



Share = WrapComponent(Share)
ShareInput = WrapComponent(ShareInput)
ShareActiveList = WrapComponent(ShareActiveList)
SharedDoctor = WrapComponent(SharedDoctor)
export default ShareFile