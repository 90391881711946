import {fabric} from 'fabric'
import {navigateCanvasFabric} from './createCanvas'




class fabricViewer {

  constructor (ref, imageCorrections, width, height, scroll) {

    this.navigateCanvas = new navigateCanvasFabric(ref,width,height,scroll)
    this.navigateCanvas.minZoom = 0.5
    this.canvas = this.navigateCanvas.canvas
        
    this.imageCorrections = imageCorrections
    this.brightness = new fabric.Image.filters.Brightness({ brightness: 0 })
    this.contrast = new fabric.Image.filters.Contrast({contrast: 0})

    this.image = new fabric.Image()
  
  }


  loadImage = dataUrl=>{

    return new Promise ((resolve, reject)=>{

      fabric.Image.fromURL(dataUrl, image=> {
          
     
        image.set ({
          lockMovementX : true, lockMovementY : true, 
          hoverCursor : 'default',
         selectable : false, 
         //opacity : 0.2,
          type : 'image'
        })


      this.image=image


      resolve(image)
      image.filters.push(this.brightness, this.contrast)

     
      

  })

  })

  }


  cropping = ()=>{
    this.crop = new fabric.Rect({ 
      lockMovementX : true, lockMovementY : true, 
          hoverCursor : 'default',
         selectable : false, type : 'marker',
      width: this.canvas.width, height: this.canvas.height, 
      fill: 'rgba(0,0,0,0)', 
      //strokeWidth : 10, 
      stroke : 'purple', 
      // opacity: 0.7,
      top : 0, left : 0,
    })

    //this.crop.rx = 100

     this.canvas.add(this.crop)


  }

  applyContrast = ()=>{
    this.brightness.brightness = this.imageCorrections.brightness
    this.contrast.contrast = this.imageCorrections.contrast
    this.image.applyFilters()
    this.canvas.renderAll()
  }

  applyFlip = ()=>{
  this.image.set({flipX : this.imageCorrections.flip})
}


applyScale = (oldDist, newDist)=>{
  this.newScale = (newDist*this.imageCorrections.scale.x)/oldDist
  this.image.set({scaleX : this.newScale, scaleY : this.newScale})
  //return this.newScale

}

applyPlacement = (oldPoint, newPoint) => {

    this.ratio = this.newScale/this.imageCorrections.scale.x

    this.newCoords =  {
      x : newPoint.x - this.ratio*(oldPoint.x -this.imageCorrections.position.x),
      y : newPoint.y - this.ratio*(oldPoint.y-this.imageCorrections.position.y) 
    } 

       
    this.image.set({
      left : this.newCoords.x,
      top : this.newCoords.y,
      angle : this.imageCorrections.rotate
    })

//return this.newScale
  

}




applyRotate = (angle, pivot)=>{


  this.pivot = new fabric.Point(pivot.x, pivot.y)
  this.angle = angle

  let origin = new fabric.Point(this.image.left, this.image.top)
  let newOrigin=fabric.util.rotatePoint(new fabric.Point(pivot.x, pivot.y), origin, -angle)
  let angleDegree = fabric.util.radiansToDegrees(angle)

  this.image.set({
    angle : this.image.angle - angleDegree,
    left : this.image.left + (pivot.x-newOrigin.x),
    top : this.image.top + (pivot.y-newOrigin.y)
  
  })

  this.canvas.add(this.image)

 // console.log('PLAAACE§§§)', this.canvas)

  this.cropping()

}

applyCrop = (cropValue=true)=>{

  let image = this.image
  let width=image.width 
  let height = image.height 
  let radAngle = fabric.util.degreesToRadians(image.angle%180)
 //console.log(image.angle)
 // radAngle = radAngle%(Math.PI/4)

  let h = (Math.abs(height*Math.cos(radAngle)) - Math.abs(width*Math.sin(radAngle))) / (Math.cos(radAngle)**2 - Math.sin(radAngle)**2)
  let w = (Math.abs(width*Math.cos(radAngle)) - Math.abs(height*Math.sin(radAngle)))/ (Math.cos(radAngle)**2 - Math.sin(radAngle)**2)

  let crop = new fabric.Rect({
        left : 0,
        top : 0,
        height : h,
        width : w,
        angle : image.flipX? image.angle : -image.angle,
        originX : 'center', originY : 'center'
  })

  image.clipPath = cropValue? crop : false
  this.canvas.renderAll()


}


newCoordsWithRotate = (oldCoords, pivot=this.pivot, angle=this.angle)=>{

  //let ratio = 1/this.imageCorrections.scale.x

  let oldPoint =  new fabric.Point (
    this.ratio*(oldCoords.x-this.imageCorrections.position.x)+this.newCoords.x ,
    this.ratio*(oldCoords.y-this.imageCorrections.position.y)+this.newCoords.y
  )

  let newCoordsObject = fabric.util.rotatePoint(oldPoint, pivot, -angle)
  
  return newCoordsObject

}


findOriginCoords = (newCoords, pivot=this.pivot, angle=this.angle)=>{

  let point = new fabric.Point(newCoords.x, newCoords.y)

  let DeleteRotate = fabric.util.rotatePoint(point, pivot, angle)

  let originCoords = new fabric.Point (
    ((DeleteRotate.x - this.newCoords.x)/this.ratio)+this.imageCorrections.position.x,
    ((DeleteRotate.y - this.newCoords.y)/this.ratio)+this.imageCorrections.position.y
  )

  return originCoords


}



  }



 



export {fabricViewer}