import React from 'react'
import Frame from './frame'
import mouseRight from '../icons/mouseRight.svg'
import mouseLeft from '../icons/mouseLeft.svg'
import mouseMiddle from '../icons/mouseMiddle.svg'
import arrows from '../icons/4ways.svg'
import magnify from '../icons/search.svg'
import pencil from '../icons/pencil.svg'
import focus from '../icons/focus.svg'


const Navigation = props=> {

    const frameStyle = {
      position : 'absolute',
      bottom : 40, left : 40, ...props.style
    }

    const divStyle = {
      position : 'relative',
      display : 'flex', justifyContent : 'space-around', flexDirection : 'column',
      padding : 20,
    }

    const lineStyle = {
      display : 'flex', justifyContent : 'space-between', 
      alignItems : 'center',
      paddingBottom : 10,
      pointerEvents : 'none'
    }

    const svgStyle = {height : 45}

    const svgStyle2 = {height : 30}

    const textStyle = {color : 'purple',  }

    const panStyle = {
      width : '100%',
      paddingTop : 10,
      display : 'flex', justifyContent : 'center',
      cursor : 'pointer'
    }



    return (
      
      <Frame style={frameStyle} label="Navigation">

        <div style = {divStyle}>
          
          <div style={lineStyle}>
            <img src={mouseRight} style={svgStyle} alt='mouse right'/>
            <span style={textStyle}>Se déplacer</span>
            <img src={arrows} style={svgStyle2} alt='arrow'/>
          </div>

          <div style={lineStyle}>
            <img src={mouseMiddle} style={svgStyle} alt='mouse middle'/>
            <span style={textStyle}>Zoom</span>
            <img src={magnify} style={svgStyle2} alt='magnify'/>
          </div>

        <div style={lineStyle}>
          <img src={mouseLeft} style={svgStyle} alt='mouse left'/>
          <span style={textStyle}>Déssiner un point</span>
          <img src={pencil} style={svgStyle2} alt='pencil'/> 
        </div>

        <div style={panStyle}>
          <img title = "Centrer l'image" alt='pan' src={focus} width ={40} onClick={props.focus}/> 
        </div>

      </div>

    </Frame>




    )

  

}


export default Navigation