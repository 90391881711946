import {fabric} from 'fabric'
import generateHexa from '../hexa'
import glassesFace from '../../images/glassesFace.svg'
import glassesSide from '../../images/glassesSide.svg'


class Glasses {

	constructor (canvas) {

		this.canvas = canvas
		//this.markerArray = []
		//this.completed = true
		this.name = 'glasses'
		//this.color = randomColor()
		this.id = generateHexa(10)
		//this.markerVisibility = true
		this.visibility=false
	}


	create = (position='face')=>{

		this.position=position
		
		return new Promise((resolve, reject)=>{


		
			this.imageGlasses = new fabric.Image.fromURL(position==='face'? glassesFace : glassesSide, image=>{
				
				this.image=image

				this.initialPosition(position)

				this.setVisibility(false)

				this.canvas.add(image)
				image.moveTo(2)
				resolve(image)
				
			})

		})
		
	}

	initialPosition = (position = 'face')=>{
		this.image.set({
					width : 900, height : 300,
					borderColor : 'white', borderScaleFactor : 3, cornerSize : 30,
					transparentCorners : false, cornerColor : 'white',
					originX : 'center', originY : 'center',
					left : 900, top : 900,
					angle : 0
				})

				if (position==='leftSide'){
					this.image.width=300
					this.image.left=400
					this.image.scaleX=-1	
				}

				if (position==='rightSide'){
					this.image.width=300
					this.image.left=1400	
				}

	}

	loadData = data=>{

		this.image.set({
			height : data.height,
			width : data.width,
			angle : data.rotate,
			top : data.position.y, left : data.position.x,
			scaleX : data.scale.x, scaleY : data.scale.y,
			type : 'marker'
		})

		this.setVisibility(data.visibility)

	}

	setVisibility = value=>{
		this.visibility = value
		this.image.opacity = value? this.visibility : 0
		this.image.selectable = value
		this.image.hoverCursor = value? 'pointer' : 'default'
		this.canvas.discardActiveObject()
		this.canvas.renderAll()
	}

	getValues = ()=>{
		return({
			height : this.image.height,
      		width : this.image.width,
      		rotate : this.image.angle,
      		position : {x : this.image.left, y : this.image.top},
      		scale : {x : this.image.scaleX, y : this.image.scaleY},
      		visibility : this.visibility
		})
	}

	resetPosition=()=>{
		console.log('reset')
		this.initialPosition(this.position)
		this.canvas.renderAll()
	}
	
}


export default Glasses