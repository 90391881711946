import React from 'react'
import phone from '../icons/phone.svg'
import Peer from 'peerjs'
import socket from '../modules/socket'
import Progress from 'react-circle-progress-bar'
import close from '../icons/cancel.svg'
import Loader from './loader'




class PhoneImport extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)
    this.importRef = React.createRef()

    this.state = {
      active : false
    }

  }

  componentDidMount = () => {
   
  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

  phoneImport = ()=>{

    this.setState({active : true})

  }

  cancelImport = ()=> this.setState({active : false})

  import = dataURL=> {
   // this.setState({active : false})
    this.props.import(dataURL)
    //this.importRef.current.peer.destroy()
    console.log(this.importRef)
    this.setState({active : false})
  }




  render = () => {

   

    return (

      <>

      {this.state.active && <ImportComponent ref = {this.importRef} stop={this.cancelImport} import={this.import}/>}
      
      <img title='Charger une image depuis votre smartphone' style={this.props.style} src={phone} onClick={this.phoneImport} alt='phone'/>

      </>

    )

  }

}


class ImportComponent extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      progress : 100,
      loader : true,
      receivedData : false
    }

  }

  componentDidMount = () => {
  //  console.log('ImportComponent')
    this.startTimer()
    this.phoneImport()
    this.importedDataArray = []

  }

  componentWillUnmount = () => {
    clearInterval(this.timer)
   // console.log(this.peer, 'peer')
   // this.peer.destroy()

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

  startTimer = ()=>{
    this.timer = setInterval(this.updateTime, 500)
  }

  updateTime = ()=> {
    //console.log('update time')
    this.setState({progress : this.state.progress-1})
    if (this.state.progress<0) this.cancel()
  }

  cancelTimer = ()=>{
    this.setState({loader : false})
   // this.cancel()
  }

  phoneImport = ()=>{

    this.peer = new Peer()

    this.peer.on('open', id=>{

      socket.emit('answerPicture', id)
     // console.log('id', id)
      this.peer.on('connection', this.startConnexion)

    })


  }

  cancel = ()=>{
    this.peer.destroy()
     socket.emit('answerPicture', '')

     this.props.stop()

  }

  startConnexion = conn=>{

    this.setState({receivedData : true})

  //  console.log('peerconnexion')
    socket.emit('answerPicture', '')

    this.cancelTimer()
    conn.on('data', this.receiveData)
     
  }

  receiveData = data=>{
    let datas = JSON.parse(data)
    this.importedDataArray[datas.index]=datas.chunck

    let arrayLength = this.importedDataArray.filter(elem=>elem).length
    //console.log(arrayLength)
    //this.setState({receivedData : arrayLength, totalData : datas.total})
    if (arrayLength===datas.total) {
      // this.peer.destroy()
      this.parseData(this.importedDataArray)
    }

  }

  parseData = data=>{
    this.setState({receivedData : false})
    //console.log(data)
    let dataURL = data.reduce((accumulator, value)=>accumulator+value,'')

    //console.log(dataURL)
    this.props.import(dataURL)
   // this.cancel()
  }

  render = () => {

     const backgroundStyle = {
      backgroundColor : 'rgba(255, 255, 255, 0.7)',
      position : 'fixed', 
      width : '100vw', height : '100vh',
      top : 0, left : 0,
      zIndex : 10,
      display : 'flex', justifyContent : 'center', alignItems : 'center'
    }

    const closeStyle = {

        width : 60,
        cursor : 'pointer'
    }


     const otherBackStyle = {
    //  backgroundColor : 'rgba(255, 255, 255, 0.7)',
      position : 'fixed', 
      width : '100vw', height : '100vh',
      top : 0, left : 0,
      zIndex : 10,
      display : 'flex', justifyContent : 'center', alignItems : 'center'
    }

  

    return (
      <div style = {backgroundStyle}>

              {this.state.receivedData && <Loader/>}

              {this.state.loader && !this.state.receivedData && <div style = {otherBackStyle}>
                
                 <img src={close} style={closeStyle} onClick={this.cancel} title='Annuler le transfert' alt='close'/>

              </div>}


      
            {this.state.loader &&  <Progress 
                            progress={this.state.progress} 
                            hideValue 
                            hideBall 
                            strokeWidth={10} 
                            reduction = {0}
                            gradient = {[{stop: 0, color: '#800080'}]}
                          />}


             
            </div>
    )

  }

}


export default PhoneImport