import React from 'react'
import hashParse from '../functions/hashParse'
import CheckUpList from './checkUpListPWA'
import File from './filePWA'



//import WrapComponent from '../contexts/wrap'



class PatientFile extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      patientId : false
    }

  }

   componentDidMount = () => {


    






    

    window.addEventListener('hashchange', this.hashPage)

  }

  componentWillUnmount = () => {

    window.removeEventListener('hashchange', this.hashPage)

  }

  hashPage = ()=>{
    let hashList = hashParse()
    
    hashList[0].hashPage.slice(0,7)==='patient'? this.setState({patientId : hashList[0].hashPage.slice(7)}) : this.setState({patientId : false})
    
    if (hashList[1]) hashList[1].hashPage.slice(0,4)==='file'? this.setState({fileId : hashList[1].hashPage.slice(4)}) : this.setState({fileId : false})
    else this.setState({fileId : false})
   // this.setState({page : hashList[0].hashPage})

   // if (hashList[1]) console.log(hashList[1].hashPage.slice(4))
  }


  liftUpFileData = data=>{
   //console.log('lift',data)
    this.setState({fileData : data})
  }
  

  render = () => {

    const divStyle = {
      position : 'fixed',
     // border : 'solid',
      top : 0, left : this.props.hide? 0 : '100%', 
      //right : 10, bottom : 20,
      // border : 'solid 2px',
      // borderLeft : 'solid 1px', borderTop : 'solid 1px',
      // borderColor : 'purple', borderRadius : 10,
      transition : 'all 0.3s',
      //zIndex : 1

    }

  

    return (

   
      
      <div style={divStyle}>
        
        {this.state.patientId && !this.state.fileId && <CheckUpList patientId={this.state.patientId} liftUpFileData={this.liftUpFileData}/>}

        {this.state.fileData && this.state.fileId && <File fileData = {this.state.fileData}/>}

        

      </div>

    

    )

  }

}



export default PatientFile