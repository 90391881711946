import {fabric} from 'fabric'





class placePicture {

	constructor (presentationCanvas, coords, placePictureData, fileId) {
		this.coords = coords
		this.presentationCanvas = presentationCanvas
		this.placePictureData = placePictureData
		this.id=fileId
	}


  	place = async (callback = ()=>true)=>{
  		this.placePictureData =  await this.placePictureData(this.coords, this.id)
  		this.callback=callback
  		this.updateContrast()
  		
  	}
	
	updateContrast = ()=>{
		this.presentationCanvas.applyContrast()
		this.updateMirror()
	}

	updateMirror = ()=>{
		this.presentationCanvas.applyFlip()
		this.updateSize()
	}


	updateSize =()=>{
	    this.markers = this.placePictureData.markersClassification
	    this.presentationCanvas.applyScale(this.placePictureData.updateSize.origin,this.placePictureData.updateSize.target)
	    this.updatePlacement()
	}

	updatePlacement = ()=>{
	    this.presentationCanvas.applyPlacement(this.placePictureData.updatePlacement.origin,this.placePictureData.updatePlacement.target)
	    this.rotate()
	}

	rotate = ()=>{
	    this.presentationCanvas.applyRotate(this.placePictureData.updateRotate.angle, this.placePictureData.updateRotate.pivot)

	    this.callback()
	}

	

    placeLandmarks=(callback)=>{

    	let markersCoords = {}

    	for (const property in this.markers) {
    		let markerPoint = new fabric.Point(this.markers[property].x, this.markers[property].y)
    		let newPosition = this.presentationCanvas.newCoordsWithRotate(markerPoint)
    		markersCoords[property] = newPosition
    	}



    	
	    callback(markersCoords)

  	}

}






export default placePicture