import React from 'react'
import trash from '../icons/trash.svg'



export default class SelectInput extends React.Component {

  static defaultProps = {
      label : 'Label',
      items : [{value : 'yes', label : 'YES'}, {value : 'no', label : 'NO'}],
      defaultValue : '',
      focusColor : 'purple',
      value : input=>input,
      onFocus : focus=>focus,
      onBlur : blur => blur,
      localStorage : false, sessionStorage : false
  }


  constructor(props) {

    super(props)

    this.selectRef = React.createRef()

    this.state = {
      value : this.props.defaultValue,
      validation : false,
      focus : false
    }

  }

  componentDidMount = () => {

    let session = JSON.parse(window.sessionStorage.getItem(this.props.sessionStorage))
    let local = JSON.parse(window.localStorage.getItem(this.props.localStorage))
   
    if (!this.props.defaultValue && this.props.localStorage && local) this.setState(local)
    else if (!this.props.defaultValue && this.props.sessionStorage && session) this.setState(session)
   // else if (!this.props.defaultValue && this.props.sessionStorage && window.sessionStorage.getItem(this.props.sessionStorage)) this.setState(window.sessionStorage.getItem(this.props.sessionStorage))

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate(prevProps) {
  
    if (this.props.updateValue !== prevProps.updateValue) this.handleUpdateValue(this.props.updateValue)

  }

  handleChange = e=>this.setState({value : e.target.value, validation : e.target.value? true : false},this.liftState)
    

  erase = e=>{
    this.setState({value : '', validation : false},this.liftState)
    this.selectRef.current.focus()
  }

  handleFocus = e=>this.setState({focus : true},this.props.onFocus)

  handleBlur = e=>this.setState({focus : false}, this.props.onBlur)

  handleUpdateValue = value=>{

    let items = Array.from(this.props.items,item=>item.value)

    if (value && items.includes(value)) {
      this.setState({value : value, validation : true},this.liftState)
      //this.selectRef.current.focus()
    }

  }

  liftState = ()=>{
    let objectStringify = JSON.stringify({value : this.state.value, validation : this.state.validation})
    this.props.localStorage && window.localStorage.setItem(this.props.localStorage, objectStringify)
    this.props.sessionStorage && window.sessionStorage.setItem(this.props.sessionStorage, objectStringify)
    this.props.value({
      value : this.state.value,
      validation : this.state.validation,
    })
  }



  render = () => {

    const divStyle = {
     // border : 'solid',
      width : 330,
      position : 'relative',
      cursor : this.props.lock? 'not-allowed' : 'default',
      opacity : this.props.lock? 0.4 : 1
    }

    const selectStyle = {
      width : 243,
      fontSize : '1.1em',
      padding : 10, margin : 10, paddingRight : 30,
      border : 'solid 2px',
      borderLeft : 'solid 1px', borderTop : 'solid 1px',
      borderColor : this.state.focus ? `${this.props.focusColor}` : 'grey',  
      borderRadius : 5,
      outline: 'none',
       pointerEvents: this.props.lock? 'none' : 'auto',
      transition : 'all 0.2s',
      cursor : 'text',
      backgroundColor : 'white'
    }

    const labelStyle = {
      pointerEvents : 'none',
      backgroundColor : 'white',
      position : 'absolute',
      fontSize : this.state.focus || this.state.value!=='' ? '0.9em' : '1.1em',
      color : this.state.focus ? this.props.focusColor : 'grey',
      top : this.state.focus || this.state.value!=='' ? 2 : 20, paddingRight : 5,
      left : 20,
      transition : 'all 0.2s'
    }

    const deleteStyle = {
      position : 'absolute',
      top : 22, left : 215,
      width : 20,
      pointerEvents: this.props.lock? 'none' : 'auto',
      cursor : 'pointer'
    }

    const validStyle = {
      pointerEvents : 'none',
      position : 'absolute',
      top : 15, left : 260,
      fontSize : '1.4em',
      color : this.state.validation ? 'green' : 'red'
    }

    const options = this.props.items.map((item,index)=><option key={index+1} value={item.value}>{item.label}</option>)

    return (

      <div style = {divStyle}>

      
      <select 
        style={selectStyle} 
        onFocus = {this.handleFocus} 
          onBlur = {this.handleBlur}
          onChange = {this.handleChange}
          value = {this.state.value}
          ref = {this.selectRef}
      >

        <option value="" key={0}></option>
        {options}

      </select>

      <span style={labelStyle}>{this.props.label}</span>

      {this.state.value!=='' && <img style = {deleteStyle} src = {trash} onClick = {this.erase} alt='delete'/>}
         
      {this.state.value!=='' && <span style = {validStyle}>{this.state.validation ? '✓' : '✗'}</span>}

      </div>

    )

  }

}