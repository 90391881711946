import React from 'react'
import ListPatients from './listPatientsPWA'
import PatientFile from './patientFilePWA'
import hashParse from '../functions/hashParse'
import Header from './headerPWA'
import Peer from 'peerjs'








class PWA extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      page : ''
    }

  }

  componentDidMount = () => {

    window.addEventListener('hashchange', this.hashPage)

    const peer = new Peer()
    console.log(peer)

  }

  componentWillUnmount = () => {

    window.removeEventListener('hashchange', this.hashPage)

  }

  hashPage = ()=>{
    let hashList = hashParse()
    this.setState({page : hashList[0].hashPage})
  }
  

  render = () => {

    const styleDiv = {
    top : 0, left : 0, 
    //cursor : 'wait',
    position : 'fixed',
    display : 'flex', justifyContent : 'space-around', alignItems : 'center', flexDirection : 'column',
    width : '90vw', height : '60vh',
    color : 'purple',
    margin : '5vw'
  }

     return (

    
    <div style={styleDiv}>

      <Header/>

      <ListPatients hide={this.state.page && this.state.page!=='subscribePatient'}/>

      <PatientFile hide={this.state.page && this.state.page!=='subscribePatient'}/>

    </div>


    )


  }

}


export default PWA