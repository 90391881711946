import {cephaloLin} from '../objects/cephaloObject'
import { jsPDF } from "jspdf"
import 'jspdf-autotable'
import sigle from '../images/bigSigle.png'


const twoNumbers = number=> Number(number)>9 ? number : '0'+number 

class exportCephaloPdf {

  constructor(cephalometryLines, ratio, context) {
    this.cephalometryLines = cephalometryLines
    this.ratio = ratio
    this.context = context
   // this.doc = new jsPDF()
  }
  



createTableObject = ()=>{

let lines = cephaloLin.filter(item=>item.display!=='none' && item.measure)

let headersTitle = [
  'Nom',
  'Définition',
  'Valeur',
  'Valeur normale'
]

let headers = Array.from(headersTitle, item=>({
  content : item,
  styles : {fillColor : 'purple'}
}))

let body = Array.from(lines, item=>{

  let line = this.cephalometryLines.filter(line=>line.name===item.initials)[0]
  let measure = (line && line.completed && line.measure && Number(line.measure)!==0 && isNaN(line.measure)===false)? line.measure : 'indéfini'
  let unit = (line && line.completed && line.measure && Number(line.measure)!==0 && isNaN(line.measure)===false && item.type==='length')? 'mm' : (line && line.completed && item.measure.hasOwnProperty('unit'))? item.measure.unit : ''
  //console.log(item.name, line.measure)
  let old = yearsOld(new Date(this.context.patientData.birthday.year,this.context.patientData.birthday.month, this.context.patientData.birthday.day),new Date(this.context.fileData.checkUpDate))
  let min = item.min(old, this.ratio.ratio, this.cephalometryLines)
  let max = item.max(old, this.ratio.ratio, this.cephalometryLines)
  let norms = Number(measure) < Number(min) || Number(measure) > Number(max)
  //console.log(norms)
  

  return [
    item.name, 
    item.initials, 
    
    {
      content : `${measure} ${unit}`,
      styles : {textColor : norms? 'red' : ''}
    },

    isNaN(min) || isNaN(max)? 'indéfini' : `${min} ${unit} - ${max} ${unit}`
    ]
})


return (
  {
  head: [headers],
  body: body,
  startY: 70
}
  )

}


createPdf = ()=>{  

let doc = new jsPDF()

doc.addImage(sigle, "PNG", 140, 5, 60, 20)

doc.text(this.context.patientData.name.lastname+' '+this.context.patientData.name.firstname, 10, 30)
doc.text(`${twoNumbers(this.context.patientData.birthday.day)}/${twoNumbers(this.context.patientData.birthday.month)}/${this.context.patientData.birthday.year}`,10,40)
let date = `${twoNumbers(new Date(this.context.fileData.checkUpDate).getDate())}/${twoNumbers(new Date(this.context.fileData.checkUpDate).getMonth()+1)}/${new Date(this.context.fileData.checkUpDate).getFullYear()}`
doc.text ('Rapport céphalométrique du '+date, 105, 60, {align : 'center'})

//console.log(context.fileData.checkUpDate)
doc.autoTable(this.createTableObject())


return doc.output('dataurlstring')

}
       
  }




const yearsOld = (date, today=new Date())=>{

    today.setHours(0,0,0)
    
    let diff = today-date
    let yearsOld = diff/(1000*3600*24*365.24)

    let age = Math.floor(yearsOld)
        
    return age

}






export default exportCephaloPdf