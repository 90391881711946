import React from 'react'
import {CreatePassword, CheckPassword} from './passInput'
import Frame from './frame'
import CustomButton from './customButton'
import Loader from './loader'
import cryptoJS from 'crypto-js'
import socket from '../modules/socket'
import Popup from './popup'
import WrapComponent from '../contexts/wrap'
import cryptHybride from '../functions/crypt'





class UpdatePassword extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      loader : false,
      popupError : false, popupSuccess : false,
      popup : false,
      newPass : {value : '', validation : false},
      oldPass : {value : '', validation : false}

      

    }

  }

   componentDidMount = () => {
    
    }

  

  returnValue = (stateObject)=>this.setState(stateObject)

  updatePopup = ()=>{
    this.setState({popup : {msg : 'Confirmez-vous la mise à jour de votre mot de passe ?'}})
  }
 

  pushUpdate = e=>{

    this.setState({loader : true, popup : false})
     
    let objectUpdate = {
      oldPass : cryptoJS.SHA512(this.state.oldPass.value).toString(cryptoJS.enc.Base64),
      newPass : cryptoJS.SHA512(this.state.newPass.value).toString(cryptoJS.enc.Base64)
    }

    

    socket.emit('fetchUpdatePassword',objectUpdate,(data)=>{
     
      if (data.success) {
        data.msg? this.setState({popupSuccess : data.msg, loader : false}) : this.setState({loader : false}, this.return)
        this.storeLogs(objectUpdate.newPass)        
      }

      else this.setState({popupError : data.msg, loader : false})

    })

  }

  storeLogs = async (newPass)=>{
    console.log(newPass, this.props.context.jwtData.mail)
    let key = await this.fetchPublicKey()
    let cryptData = cryptHybride.encrypt(JSON.stringify({mail : this.props.context.jwtData.mail, password : newPass}),key)
    window.sessionStorage.authentification = cryptData
  }

   fetchPublicKey = ()=>{

    return new Promise((resolve, reject)=>{
      socket.emit('fetchPublicKey',key=>resolve(key))
    })

  }

  return = ()=>window.location.hash = ''

  closePopupError = ()=> this.setState({popupError : false})

  closePopupSuccess = ()=> {
    this.setState({popupSuccess : false},this.return)
  }
  
  cancelPopup = ()=> this.setState({popup : false})

  render = () => {
    
   

    const frameStyle = {
      marginTop: 'auto', 
      marginBottom: 'auto',
      maxWidth : 400, width : '80vw',
      paddingLeft : 20,
    }

    const formStyle = {
      position : 'relative',
      //border : 'solid',
       display : 'flex', flexWrap : 'wrap',
       flexDirection : 'row', justifyContent : 'space-evenly',
       width : '100%',
       paddingTop : 30
    }

    const divButtonStyle = {
      position : 'relative',
     // border : 'solid',
       display : 'flex',
       justifyContent : 'center',
       width : '100%'
    }

    const styleBackground = {
      position : 'fixed',
      height : '100vh', width : '100vw',
      top : 0, left : 0,
      display : 'flex', justifyContent : 'center', itemsAlign : 'center',
      backgroundColor : 'rgba(255,255,255)',
      zIndex : 8

    }
   

    const buttonValidation = (
        this.state.oldPass.validation &&
        this.state.newPass.validation &&
        this.state.oldPass.value !== this.state.newPass.value
   )

   
    return (

      <div style={styleBackground}>

      {this.state.loader && <Loader/>}

      {this.state.popupError && <Popup error={true} msg={this.state.popupError} handleClick={this.closePopupError}/>}
      {this.state.popupSuccess && <Popup success={true} msg={this.state.popupSuccess} handleClick={this.closePopupSuccess}/>}
      {this.state.popup && <Popup choice msg={this.state.popup.msg}  handleClickYes={this.pushUpdate} handleClickNo ={this.cancelPopup}/>}

      <Frame label='Mise à jour du mot de passe' style = {frameStyle} close={true} onClick={this.return}>

       <form>

        <div style={formStyle}>



           <CheckPassword
            value={value=>this.returnValue({oldPass : {value : value.unmaskedValue, validation : value.validation}})}
          />

           
          <CreatePassword 
            value={value=>this.returnValue({newPass : {value : value.pw, validation : value.validation}})}
            label='Nouveau mot de passe'
          />



         
        </div>



        <div style = {divButtonStyle}>

          <CustomButton 
            label="Mettre à jour"
            active={buttonValidation}
            handleClick = {this.updatePopup}
            activeEnter = {!this.state.popup}

          />  

        </div>

         
      </form>


      </Frame>

      </div>
    )

  }

}



export default WrapComponent(UpdatePassword)