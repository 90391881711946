import React from 'react'
import InputSearch from './customInputSearch'
import hashParse from '../functions/hashParse'
import socket from '../modules/socket'
import yearsOld from '../functions/yearsOld'
import WrapComponent from '../contexts/wrap'
import PictureId from './pictureId'
import logo from '../images/bigSigle.svg'
import shareIcon from '../icons/group.svg'
import nonShareIcon from '../icons/user.svg'




class ListPatients extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      page : '',
      popup : false,
      subscribePatient : false,
      listPatients : [],
      input : '',
      select : false
    }

  }

  componentDidMount = () => {

    window.addEventListener('hashchange', this.hashPage)
    this.updateList()
    socket.on('updateListPatient',this.updateList)

  }

  componentWillUnmount = () => {

  }

  hashPage = ()=>{

    console.log('rrrrr')

    let hashList = hashParse()
    this.setState({page : hashList[0].hashPage})
    if (hashList[0].hashPage.slice(0,7)!=='patient') {
      this.props.context.updatePatientData({})
       this.updateList()
    }


  }

    fetchListPatient = ()=>{
    socket.emit('fetchListPatient', this.state.input, response=>{
      response.success? this.setState({listPatients : response.list}) : this.setState({popup : response})
     console.log(response)
    })

  }

  fetchListSharedPatient = ()=>{
    socket.emit('fetchListSharedPatient', this.state.input, response=>{
      response.success? this.setState({listPatients : response.list}) : this.setState({popup : response})
      console.log(response)
    })

  }

  updateList = ()=>{

    this.state.shared? this.fetchListSharedPatient() :  this.fetchListPatient()
   
  
     

  }

 

  changeInput = input=>{
    clearTimeout(this.timerFetchList)
    this.setState({input : input},()=>{
      this.timerFetchList = setTimeout(this.updateList, 500)
    })
    
  }

  select = index=>{
    // console.log(index)
    this.setState({select : index})
  }

   changeSaredStatus = e=>{
    this.setState({shared : !this.state.shared}, this.updateList)
  }

  render = () => {

    const divStyle = {
    //  border : 'solid',
      position : 'fixed',
      left : this.props.hide? '-100%' : 0, 
      top : 0, 
      width : '100vw', height : '100vh',
    //  border : 'solid 2px',
    //  borderLeft : 'solid 1px', borderTop : 'solid 1px',
    //  borderColor : 'purple', borderRadius : 10,
      transition : 'all 0.3s',
      zIndex : 1
    }

    const searchStyle = {
      position : 'absolute', top : 40, left : 0,
      //border : 'solid', 
      width : '100%',
      height : 40, 
      //top : 20,
      display : 'flex', justifyContent : 'center', alignItems : 'center'
    }

    // const addStyle = {
    //   width : 20,
    //   position : 'absolute', top : 13, left : 220,
    //   cursor : 'pointer'
    // }

    const logoStyle = {
      position : 'absolute',
      top : 5, left : 5,
      width : 100
    }

    const listStyle = {
      top : 90, position : 'absolute', bottom : 3,
      left : 5, right : 3,
      overflow : 'overlay',
     
    }

     const addStyle = {
      width : 20,
      opacity : this.state.shared? 0.5 : 1,
      position : 'absolute', top : 13, right : 50,
      cursor : 'pointer',
      transition : 'opacity 0.2s'
    }

    const shareStyle = {
      width : 25,
      opacity : this.state.shared? 1 : 0.5,
      position : 'absolute', top : 10, right : 20,
      cursor : 'pointer',
      transition : 'opacity 0.2s'

    }


    let inputRegex = new RegExp(this.state.input.replace(/[eéèêë]/,'[eéèêë]').replace(/[aàäâ]/,'[aàäâ]').replace(/[uûü]/,'[uûü]'),'i')
    const regexValidDate = /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/

    return (

      
      <div style={divStyle}>

      <img src={logo} style={logoStyle} alt='logo'/>

        <div style={searchStyle}>

          

           {/* {this.state.page === 'subscribePatient' && <SubscribePatient updateList={this.fetchListPatient}/>} */}

          {/* {this.state.popup && <Popup error={this.state.popup.error} msg={this.state.popup.msg} handleClick={this.fetchListPatient}/>} */}

          <InputSearch label = 'Rechercher un patient...' handleChange={this.changeInput}/> 

           <img src={nonShareIcon} alt='share' style={addStyle} onClick={this.changeSaredStatus}/>
          <img src={shareIcon} alt='share' style={shareStyle} onClick={this.changeSaredStatus}/>
        
        </div>
     
        <div style={listStyle}>
          
          {this.state.listPatients.filter(item=>inputRegex.test(item.name.firstname)||inputRegex.test(item.name.lastname)||regexValidDate.test(this.state.input)).map((item, index)=><List index={index} key = {index} data={item} input={this.state.input} over={this.state.select===index} select={this.select}/>)}

        </div>
        

      </div>

      
      
    )

  }

}




class List extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
    //  over : false
    }

  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

//   over = e=>this.props.select(this.props.index)
// 
//   leave = e=>this.props.select(false)

  handleClick = e=>{
    window.location.hash=`patient${this.props.data._id}`
    this.props.context.updatePatientData(this.props.data)
    // this.leave()

  }

  render = () => {

    const lineStyle = {
      //border : 'solid',
     // position : 'absolute',
      //left : 30, right : 20,
      borderRadius : 5,
      margin : 5, padding : 5, marginRight : 20,
      // backgroundColor : this.props.over ? 'purple' : '',
      color : 'purple',
      // cursor : 'pointer',
    }

    let birthDate = new Date(this.props.data.birthday.year, Number(this.props.data.birthday.month)-1,this.props.data.birthday.day)

    let FormatFunction = (props)=>{
      let inputRegex = props.input.replace(/[eéèêë]/,'[eéèêë]').replace(/[aàäâ]/,'[aàäâ]').replace(/[uûü]/,'[uûü]')
      let split = props.word.replace(new RegExp(inputRegex,'ig'),input=>'<u>'+input+'</u>')
     
      return(
        <span dangerouslySetInnerHTML={{ __html: split }}/>
      )
    }

     const divStyle = {
      position : 'fixed',
     // border : 'solid green',
      top : 90, left : 280, 
      right : 10, bottom : 20,
      display : 'flex', justifyContent : 'center', alignItems : 'center'
     

    }

    const idStyle = {
     
      height : '50%'
    }

   

    return (

      <>
     
      
      <div 
        style={lineStyle} 
        // onMouseEnter = {this.over} 
        // onMouseLeave = {this.leave}
        onClick = {this.handleClick}
      >

        <b><FormatFunction word = {this.props.data.name.lastname} input={this.props.input}/> <FormatFunction word = {this.props.data.name.firstname} input={this.props.input}/></b><br/> 
        {`${this.props.data.sex==='man'? '♂' : this.props.data.sex==='woman'? '♀' : '⚥' }
        ${yearsOld(birthDate)}`}

      </div>


      {this.props.over && <div style={divStyle}>
          <PictureId patientId={this.props.data._id} style={idStyle}/>
      </div>}

      </>
       
  

    )

  }

}


List = WrapComponent(List)



export default WrapComponent(ListPatients)