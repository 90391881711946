import React from 'react'
import {fabricViewer} from '../functions/fabricFunctions/viewerPictureFabric'
import Visor from './visor'
import Calibration from './calibration'
import Navigation from './navigationViewer'
import Export from './export'
import resizeImage from '../functions/resizeImage'
import socket from '../modules/socket'
import ss from 'socket.io-stream'
import WrapComponent from '../contexts/wrap'
import Loader from './loader'
import LandMarksTools from './landmarks'
import placePicture from '../functions/placePicture'
import {Tools} from './viewerTools'
import hashParse from '../functions/hashParse'



class PictureViewer extends React.Component {

  static defaultProps = {
    pictureType : 'facePicture'
  }

  constructor(props) {

    super(props)
    this.canvasRef = React.createRef()
    this.navigationRef = React.createRef()

    this.state = {
      tools : [],
      landmarks : [],
      ratio : false,
      loadingData : false,
      loader : true,
      imagePlaced : false,
      crop : true,
     // anonym : false
    }

  }

  componentDidMount = () => {



    this.presentationCanvas = new fabricViewer(this.canvasRef.current, this.props.corrections)
    //console.log(this.props.context)

    this.fabricCanvas = this.presentationCanvas.canvas
  //  this.fabricCanvas.backgroundColor = 'red'
    this.setState({canvas : this.fabricCanvas})

    this.presentationCanvas.loadImage(this.props.image)
      .then(image=>{
        this.imageFabric=image
        this.imagePlacement = new placePicture(this.presentationCanvas,this.props.corrections.markers, this.props.placePictureData, this.props.context.fileData._id)
        this.imagePlacement.place(()=>{
          this.imagePlacement.placeLandmarks(this.updateLandmarks)
          this.setState({imagePlaced : true})
          //image.sendToBack()
          })
        
      })

    socket.emit('fetchTools', this.props.context.fileData._id, this.props.pictureType, this.props.context.patientData._id, data=>{
      this.setState({loadingData : data, loader : false})
    })

  }


  visorCanvas = canvas=>{

    if (canvas === '') this.timer = setTimeout(()=>this.setState({visor : canvas}),1000)
    
    else {
      clearTimeout(this.timer)
      this.setState({visor : canvas})
    }
    
  }


  updateLandmarks = landmarks=>this.setState({LandMarks : landmarks})

  liftUpTools = tools=>this.setState({tools : tools})
  
  liftUpLandmarks = landmarks=>{
    this.setState({landmarks : landmarks})
  }

  calibrate = ratio=>{
    this.setState({ratio : ratio},()=>{
      this.fabricCanvas.renderAll()
    })
    
  }


  saveLength = ()=>{

    // console.log(this.fabricCanvas.getObjects())

    let activeTools = this.state.tools.filter(tool=>tool.completed)

    this.toolsDef = activeTools.map(tool=>{

      let obj = {
        name : tool.name,
        coords : tool.markerArray.map(marker=>[marker.left, marker.top]),
        color : tool.color,
        markerVisibility : tool.markerVisibility,
        visibility : tool.visibility,
        id : tool.id
      }


      if (tool.hasOwnProperty('text')){

        let text = {
          text : tool.text.text,
          coords : [tool.text.left, tool.text.top],
          height : tool.text.height, width : tool.text.width,
          angle : tool.text.angle,
          scaleX : tool.text.scaleX, scaleY : tool.text.scaleY,
          visibility : tool.textVisibility
        }

        obj.text = text

      }

      if (tool.hasOwnProperty('prolongationLine')) obj.prolongation = tool.prolongationLine

      if (tool.hasOwnProperty('direction')) obj.direction = tool.direction
      
      return obj

    })


    this.landmarksDef = this.state.landmarks.map(landmarks=>{
      
      return ({
        color : landmarks.color,
        visibility : landmarks.visibility,
        name : landmarks.name
      })

    })
      
    let dataTools = {
      toolsDefinition : this.toolsDef,
      calibration : this.state.ratio,
      landmarksDefinition : this.landmarksDef,
      crop : this.state.crop,
      anonym : this.navigationRef.current.getGlassesOption()
    }


    //console.log('anonym', this.navigationRef.current.getGlassesOption())


    socket.emit('savePictureTools', dataTools, this.props.context.fileData._id, this.props.pictureType, response=>{

    })

    
    this.savePreview()
    if (this.props.pictureType==='facePictureWithSmile') this.saveIdPicture()
  

  }

  setCrop = value=>{
    this.presentationCanvas.applyCrop(value)
    this.setState({crop : value})
  }

  setGlasses = value=>{
    this.setState({anonym : value})
   // console.log(value)
  }


  savePreview = async ()=>{

    let vptcoords = this.fabricCanvas.vptCoords.tl
    let zoom = this.fabricCanvas.getZoom()

    this.presentationCanvas.navigateCanvas.resetCanvasPosition()

    let dataURLBigSize = this.fabricCanvas.toDataURL()
    let dataURLMinisize = await resizeImage(dataURLBigSize, 600)

    this.fabricCanvas.absolutePan(vptcoords)
    this.fabricCanvas.setZoom(zoom)

    fetch(dataURLMinisize).then(res => res.blob()).then(blob => {
      this.preview = blob
      this.pushViewerData()
    })
     
  }


  saveIdPicture = async ()=>{

    // let vptcoords = this.fabricCanvas.vptCoords.tl
    // let zoom = this.fabricCanvas.getZoom()

    this.presentationCanvas.navigateCanvas.resetCanvasPosition()
    
    this.fabricCanvas.remove(...this.fabricCanvas.getObjects('marker'))

    let dataURLBigSize = this.fabricCanvas.toDataURL()
    let dataURLMinisize = await resizeImage(dataURLBigSize, 500)

    // this.fabricCanvas.absolutePan(vptcoords)
    // this.fabricCanvas.setZoom(zoom)

    //console.log(dataURLMinisize)

    fetch(dataURLMinisize).then(res => res.blob()).then(blob => {
      
      this.pushIdPicture(blob)

    })
     
  }


  pushViewerData = (file=this.preview, name=this.props.pictureType+'Preview')=>{

  //  let  file = this.preview
    let stream = ss.createStream()
    ss(socket).emit('file', stream, {name : name, size: file.size, fileId : this.props.context.fileData._id, type : 'image'})
    let blobStream = ss.createBlobReadStream(file)
    blobStream.pipe(stream)

  }


  pushIdPicture = file=>{
    let patientId = hashParse()[0].hashPage.split('patient')[1]
    let stream = ss.createStream()
    ss(socket).emit('idPicture', stream, {size: file.size, patientId : patientId})
    let blobStream = ss.createBlobReadStream(file)
    blobStream.pipe(stream)
  }




  render = () => {

     const divStyle = {
      position : 'fixed',
      border : 'solid',
      width : '100vw', height : '100vh',
      top : 0, left : 0,
      display : 'flex', justifyContent : 'center', alignItems : 'center', flexDirection : 'column',
      backgroundColor : 'rgba(255,255,255,0.8)',
      zIndex : 8
    }

    const canvasStyle = {
      position : 'relative',
      border : 'solid 2px',
      borderRight : 'solid 1px', borderBottom : 'solid 1px',
      borderColor : 'purple', borderRadius : 10,
      zIndex : 4
    }

   // let LandMarks = this.props.landmarks



    return (

      <div style={divStyle}>


        {this.state.loader && <Loader/>}
      
        <canvas style={canvasStyle} ref={this.canvasRef} height={1800} width={1800}/>

        {this.state.visor && <Visor canvas={this.state.visor}/>}

        {this.state.imagePlaced && <Tools 
                  canvas = {this.state.canvas} 
                  visorCanvas={this.visorCanvas} 
                  canvasRef={this.canvasRef} 
                  ratio={this.state.ratio} 
                  liftUpTools={this.liftUpTools}
                  loadingData = {this.state.loadingData}
                />}

        {this.state.LandMarks && <LandMarksTools canvas = {this.state.canvas} LandMarks = {this.state.LandMarks} liftUpLandmarks={this.liftUpLandmarks} loadingData = {this.state.loadingData} data={this.props.landmarks}/>}

        {this.state.imagePlaced && <Calibration canvas = {this.state.canvas} visorCanvas={this.visorCanvas} updateCalibration={this.calibrate} loadingData = {this.state.loadingData}/>}

        {this.state.imagePlaced && this.state.loadingData && <Navigation ref={this.navigationRef} focus={()=>this.presentationCanvas.navigateCanvas.resetCanvasPosition()} canvas={this.state.canvas} setCrop={this.setCrop} setGlasses={this.setGlasses} loadingData = {this.state.loadingData} pictureType={this.props.pictureType}/>}

        {this.state.canvas && <Export save={this.saveLength} type={this.props.pictureType} presentationCanvas={this.presentationCanvas}/>}



      </div>
    )

  }

}




export default WrapComponent(PictureViewer)