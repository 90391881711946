import React from 'react'
import Frame from './frame'
import rule from '../icons/rule.svg'
import trash from '../icons/trash.svg'
import eye from '../icons/eye.svg'
import prolongation from '../icons/prolongation.svg'
import lineIcon from '../icons/line.svg'
import LandMark from '../functions/fabricFunctions/fabricLandmarkTools'






class LandMarksTools extends React.Component {


  constructor(props) {
    super(props)
    this.state = {
      landmarks : []
    }
  }

  componentDidMount = () => this.drawLandmarks()

  componentDidUpdate = (prevProps)=> {
    if (this.props.loadingData !== prevProps.loadingData) this.loadLandmarks() 
  }

  drawLandmarks = ()=>{

    let landmarkTool = this.props.data(this.props.LandMarks)

    let landmarksArray = landmarkTool.map(landmarks=>{
      let landmark = new LandMark(this.props.canvas)
      landmark.createLine(landmarks.lineCoords, landmarks.dash, landmarks.prolongation)
      landmark.name=landmarks.name
      landmark.draw()
      return landmark

    })



    this.setState({landmarks : landmarksArray},()=>{
      this.props.liftUpLandmarks(landmarksArray)
      this.loadLandmarks()
    })

  }

  loadLandmarks = ()=>{

 
    if (this.props.loadingData.success && this.props.loadingData.data.landmarksDefinition.length>0) {

      let loadLandmarks = this.props.loadingData.data.landmarksDefinition



      loadLandmarks.map(landmarks=>{

        let landmark = loadLandmarks.filter(item=>item.name===landmarks.name)[0]
        this.state.landmarks.filter(item=>item.name===landmarks.name)[0].setColor(landmark.color)
        this.state.landmarks.filter(item=>item.name===landmarks.name)[0].setVisibility(landmark.visibility)
        return true

      })
    }
 
       this.forceUpdate()
 
     }

  

  render = () => {

    const frameStyle = {
      position : 'absolute',
      top : 100, left : 40,
      width : 230, zIndex : 6,
      display : 'flex', justifyContent : 'center', flexDirection : 'column'
    }

    const divStyle = {
      position : 'relative',
      left : -20,
      margin : 10
    }

    const name = this.props.data(this.props.LandMarks).reduce((accumulator, landmark)=>{
      accumulator[landmark.name]=landmark.frenchName
      return accumulator
    },{})


    return (
      
      <Frame style = {frameStyle} label = 'Repères'>

        <div style={divStyle}>

           { this.state.landmarks.map((landmark, index)=>
           <ControlLite
              name = {name[landmark.name]}
              icon = {rule}
              tool = {landmark}
              trashcan = {false}  
              marker = {false}   
              key = {index} 
            />


            )}

        </div>

      </Frame>

    )

  }

}





class ControlLite extends React.Component {

  static defaultProps = {
    completed : true,
    trashcan : true,
    marker : true
  }

  componentDidUpdate = (prevProps)=> {
    if (this.props.load !== prevProps.load) this.update() 
  }

  colorChange = e=>{
    this.props.tool.setColor(e.target.value)
    this.forceUpdate()
  }

  toggleVisibility = e=>{
    this.props.tool.setVisibility(e.target.checked)
    this.forceUpdate()
  }

  update = ()=> this.setState({visibility : this.props.tool.visibility})

  render = () => {

    const divStyle = {
      position : 'relative',
      border : 'solid 2px',
      borderRight : 'solid 1px', borderBottom : 'solid 1px',
      borderColor : 'purple', borderRadius : 10,
      width : 210, 
      height : 35, overflow : 'hidden',
      padding : 5, 
      backgroundColor : 'white', margin : 5,
      transition : 'all 0.4s',
      zIndex : 1 
    }

    const line1Style = {
      display : 'flex', justifyContent : 'space-between', alignItems : 'center'
    }

    const iconStyle = {
      width : 30
    }

    const colorStyle = {
      width : 30, height : 30,
      cursor : 'pointer',
      flex :1
    }

    const eraseStyle = {
      width : 20
    }

    return (

      <div>    

        {this.props.completed && 
          
          <div style={divStyle}>

            <div style = {line1Style}>

              <div style={{color : 'purple', cursor : 'default', flex : 4}}>{this.props.name}</div>

              <input title='Changer la couleur' type='color'  style={colorStyle} value={this.props.tool.color} onChange={this.colorChange}/>
              {this.props.trashcan && <img src={trash} style={eraseStyle} onClick={this.props.erase} alt='erase'/>}

              {this.props.prolongation && <div>
                <input type='checkbox' onChange={this.props.toggleProlongation}/>
                <img src={prolongation} style={iconStyle} alt='prolongation'/>
              </div>}

              {this.props.label && <div>
                <input type='checkbox' onChange={this.props.toggleTextLabel} defaultChecked={true}/>
                <span style={{fontSize : '0.8em', backgroundColor : 'purple', borderRadius : 4, color : 'white', padding:3}}>
                  {this.props.tool.name=== 'rule'? this.props.tool.distance : this.props.tool.angle}
                </span>
              </div>}

              {this.props.marker && <div>
                <input type='checkbox' onChange={this.toggleMarker} defaultChecked={this.props.tool.markerVisibility}/>
                <img src={lineIcon} style={iconStyle} alt='marker'/>
              </div>}

              <div style={{flex : 2}}>
                <input title='Masquer' type='checkbox' onChange={this.toggleVisibility} checked={this.props.tool.visibility} style={{cursor : 'pointer'}}/>
                <img src={eye} alt='visibility' style={{position : 'relative', top : 5, width : 20}}/>
              </div>

          </div>

        </div>}

      </div>

    )

  }

}






export default LandMarksTools