

import pictureViewerFunction from '../functions/pictureViewerFunction.js'


const loadImageIndependant = (fileId, pictureType, placePictureData, landmarks)=>{

  return new Promise (async (resolve, reject)=>{

    let pictureExport = new pictureViewerFunction(
      fileId,
      pictureType,
      placePictureData,
      landmarks
    )

    await pictureExport.loadDatas().catch(error=>resolve(false))
    await pictureExport.loadImage()


   
    pictureType==='sideTeleradio'? await pictureExport.placeCephalo() : await pictureExport.place()
    
    await pictureExport.drawGlasses()
   

     pictureExport.exportPicture()
     		.then(data=>resolve(data))
     		//.catch(error=>resolve(false))


  })


}



export {loadImageIndependant}