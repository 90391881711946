import React from 'react'
import resizeImage from '../functions/resizeImage'
import PreparePicture from './preparePicture'
import exifer from 'exifer'
import PictureViewerClassical from './pictureViewer'
import hashParse from '../functions/hashParse'
import socket from '../modules/socket'
import WrapComponent from '../contexts/wrap'
//import {LandMarksFace} from './landmarks'
import faceImageWithMarkers from '../images/faceImageWithMarkers.svg'
import face from '../images/faceImage.svg'
import trash from '../icons/trash.svg'
import pdf from '../icons/pdf.svg'
import camera from '../icons/camera.svg'
import switchPicture from '../icons/switch.svg'
import Popup from './popup'
import Loader from './loader'
import {LandMarksFaceObject} from '../objects/landmarksDataObjects'
import CephaloViewer from './cephaloViewer'
import LoaderSpinner from 'react-loader-spinner'
import {loadImageIndependant} from '../functions/loadImage'
import download from 'downloadjs'
import pdfCephaloFunction from '../functions/pdfCephaloFunction'
import PhoneImport from './phoneImport'


const photoTitle= {

    facePicture : 'visageFace',
    facePictureWithSmile : 'visageFaceSourire',
    leftSidePicture : 'visageProfilGauche',
    rightSidePicture : 'visageProfilDroit',
    spreaderFacePicture : 'VisageLevreEcarte',

    teethPictureFaceClose : 'dentsSerreFace',
    teethPictureFaceOpen : 'dentsOuvertFace',
    teethPictureLeftSide : 'dentsProfilGauche',
    teethPictureRightSide : 'dentsProfilDroit',
    teethPictureOcclusalMaxillar : 'dentsMaxillaireOcclusal',
    teethPictureOcclusalMandibular : 'dentsMandibulaireOcclusal',

    plasterPictureOcclusalMaxillar : 'empreinteMaxillaireOcclusale',
    plasterPictureLeftSide : 'empreinteProfilGauche',
    plasterPictureFace : 'empreinteFace',
    plasterPictureRightSide :  'empreinteProfilDroit',
    plasterPictureOcclusalMandibular : 'empreinteMandibulaireOcclusale',

    orthopanto : 'panoramique',
    sideTeleradio : 'teleradiographie'
}


const style = {
width : 150, height : 150, 
position : 'relative',
borderRadius : 20,
border : 'solid 2px',
borderRight : 'solid 1px', borderBottom : 'solid 1px',
borderColor : 'purple', 
}


class Picture extends React.Component {

   static defaultProps = {
    pictureType : 'facePicture',
    markersNumber : 3,
    style : style,
    landmarks : LandMarksFaceObject,
    imageNavigation : faceImageWithMarkers,
    neutralPicture : face
  }

  constructor(props) {

    super(props)
    this.inputRef = React.createRef()
   
    this.state = {
      loadedImage : '',
      exifOrientation : 1,
      delete : false,
      error : false,
      drag : false,
      imageLoader : false
      


    }

  }

  componentDidMount = () => {

    //this.fetchImageCorrections()
    this.reader = new FileReader()
    this.readerPreview = new FileReader()
    this.reader.addEventListener('load', this.loadImageFromFile)
    this.readerPreview.addEventListener('load', this.loadImagePreview)
    window.addEventListener('hashchange', this.hashPage)
    this.fetchImagePreview()

    socket.on(this.props.pictureType+'Preview'+this.props.context.fileData._id, this.fetchImagePreview)

  }

  componentWillUnmount = () => {

    window.removeEventListener('hashchange', this.hashPage)
    this.reader.removeEventListener('load', this.loadImageFromFile)
    this.readerPreview.removeEventListener('load', this.loadImagePreview)
    window.removeEventListener('hashchange', this.hashPage)
    socket.off(this.props.pictureType+'Preview'+this.props.context.fileData._id, this.fetchImagePreview)

  }

  componentDidUpdate = (prevProps)=> {
  
    if (this.props.pictureType !== prevProps.pictureType) {
      socket.off(prevProps.pictureType+'Preview'+this.props.context.fileData._id, this.fetchImagePreview)
      socket.on(this.props.pictureType+'Preview'+this.props.context.fileData._id, this.fetchImagePreview)
      this.setState({loadedImage : '', preview : false},this.fetchImagePreview)
    }
      
    
  //    if (this.props.placePictureData !== prevProps.placePictureData) this.componentDidMount()
   //     if (this.props.landmarks !== prevProps.landmarks) this.componentDidMount()
      
    
  }


  hashPage = ()=>{
    let hashList = hashParse()
    this.setState({
      view : hashList[1] && hashList[1].hashVar? hashList[1].hashVar.view : undefined,
      type : hashList[1] && hashList[1].hashVar? hashList[1].hashVar.type : undefined,
    })
  }


  fetchImageCorrections = ()=>{

    socket.emit('fetchImage', this.props.context.fileData._id,this.props.pictureType,(data)=>{
    
      if (data.success) {
        let blob = new Blob([data.data.picture], { type: 'image/jpeg'})
        let corrections = JSON.parse(data.data.corrections)

        if (blob) {
          this.reader.readAsDataURL(blob)
          this.updateCorrections(corrections)
        }

      }

    })

  }

  fetchImagePreview = ()=>{

    this.setState({imageLoader : true})

    socket.emit('fetchImagePreview', this.props.context.fileData._id, this.props.pictureType, this.props.context.patientData._id, (data)=>{
     
      this.setState({imageLoader : false})

      if (data.success) {
        let blob = new Blob([data.data], { type: 'image/jpeg'})
        
        //let corrections = JSON.parse(data.data.corrections)

        if (blob) {
          try {this.readerPreview.readAsDataURL(blob)}
          catch(e){console.log(e)}
         // console.log(blob)
          //this.updateCorrections(corrections)
        }

      }

    })

  }

  loadImagePreview = ()=>{
   // alert('prev')
   // console.log('test')
   let loadedImage = this.readerPreview.result
   //console.log(loadedImage)
   this.setState({preview :  loadedImage})
  }


  handleChange = e=>{
    let file = e.target.files
    this.validFile(file)
  }


  validFile = async file=>{

    let check = this.checkValidationFile(file)

    if (check.success) {
      this.reader.readAsDataURL(file[0])

      let exif ={}
      try { exif = await exifer(file[0])}
      catch(e){exif.Orientation = false}

      this.state.exifOrientation = exif.Orientation? exif.Orientation : 1
      window.location.hash  += '&view=preparePicture&type='+this.props.pictureType
    }
    
    else if (check.error) this.setState({error : check.msg})
  }


  checkValidationFile = files=>{
    
   
    let length = files.length
    let type = files[0].type.split('/')[0]

    if (length>1) return {error : true, msg : "Vous ne pouvez mettre qu'un seul fichier à la fois"} 
    else if (type!=='image') return {error : true, msg : "Le fichier doit être une image"}
    else return {success : true}
  }


  loadImageFromFile = async ()=>{
   let loadedImage = this.reader.result
   this.setState({loadedImage :  await resizeImage(loadedImage)})
  }

  importFromPhone = dataUrl=>{
    //console.log(dataUrl)
    this.setState({loadedImage :  dataUrl})
     window.location.hash  += '&view=preparePicture&type='+this.props.pictureType
  }


  updateCorrections = corrections=>{
    this.setState({corrections : corrections})
    // console.log(hashParse())
    // let hash = hashParse()
    window.location.hash  = window.location.hash.split('?')[0]+'?page='+hashParse()[1].hashVar.page+'&view=pictureViewer&type='+this.props.pictureType
  }


  exit = ()=>{
    this.setState({loadedImage : false})
    window.location.hash = window.location.hash.split('?')[0]+'?page='+hashParse()[1].hashVar.page
  }

  loadImage = ()=>{
    if (this.props.context.creator) this.inputRef.current.click()
  }

  handleDrop = e=>{
    e.preventDefault()
    this.setState({drag : false})
   // console.log('drop',e.dataTransfer.files)
    let file = e.dataTransfer.files
    if (!this.state.preview) this.validFile(file)
  }

  handleDragOver = e => e.preventDefault()

handleDragEnter = e=> this.setState({drag : true})

handleDragLeave = e=> this.setState({drag : false})

deletePicture = () => {
  this.setState({delete : true})

  //this.setState({loadedImage : false, preview : false})
}

cancelDelete = ()=>this.setState({delete : false})

processDelete = ()=>{
  this.setState({loader : true})
  
  socket.emit('deletePicture', this.props.context.fileData._id, this.props.pictureType,response=>{
    this.setState({
      corrections : false, 
      loadedImage : false, 
      preview : false, 
      delete : false, 
      loader : false,
      exifOrientation : false
    })

  })

}

closeErrorPopup = ()=>this.setState({error : false})


exportPicture = async ()=>{

  console.log('export')

  let url = loadImageIndependant (
    this.props.context.fileData._id,
    this.props.pictureType,
    this.props.placePictureData,
    this.props.landmarks
  )

 
  let dataUrl = await url
  let twoNumbers = number=> Number(number)>9 ? number : '0'+number 
   let creationDate = new Date(this.props.context.fileData.creationDate)

    let date = `${twoNumbers(creationDate.getDate())}${twoNumbers(creationDate.getMonth()+1)}${creationDate.getFullYear()}`
   

  let fileTitle = (
      this.props.context.patientData.name.lastname.slice(0,3)+
      this.props.context.patientData.name.firstname.slice(0,3)+'_'+
      date+'_'+photoTitle[this.props.pictureType]+'.jpg'
    )


     download(dataUrl.picture, fileTitle)

}

exportPdf = async ()=>{
   let pdfExport = new pdfCephaloFunction(this.props.context.fileData._id, this.props.context)
 

    let pdfURL = await pdfExport.generate()
   // console.log(pdfURL)

    let twoNumbers = number=> Number(number)>9 ? number : '0'+number 
   

   
    let creationDate = new Date(this.props.context.fileData.creationDate)

    let date = `${twoNumbers(creationDate.getDate())}${twoNumbers(creationDate.getMonth()+1)}${creationDate.getFullYear()}`
    let fileTitle = (
      this.props.context.patientData.name.lastname.slice(0,3)+
      this.props.context.patientData.name.firstname.slice(0,3)+'_'+
      date+'_rapportCeph.pdf'
    )


     download(pdfURL, fileTitle)


  
  }


 


  render = () => {

   

    const type = this.props.pictureType

    const trashStyle = {
      position : 'absolute',
      bottom : -10, right : 10,
      width : 30,
      cursor : 'pointer'
    }

     const exportStyle = {
      position : 'absolute',
      bottom : -10, right : -20,
      width : 30,
      cursor : 'pointer'
    }

     const pdfStyle = {
      position : 'absolute',
      bottom : -10, right : -50,
      width : 30,
      cursor : 'pointer'
    }

    const phoneStyle = {
      position : 'absolute',
      bottom : -15, right : -20,
      width : 40,
      cursor : 'pointer'
    }

    const imageStyle = {
      top : 0, left : 0,
      width : '100%', height : '100%',
      position : 'absolute',
      borderRadius : this.props.style.borderRadius,
      filter : this.state.drag || this.state.preview? 'saturate(1)' : 'saturate(0)',
      cursor : 'pointer'
      // backgroundColor : 'white',
      // opacity : 0.5
    }

    const styleLoader = {
      position : 'absolute', 
      top : `${this.props.style.height/2 - 20}px`,
      left : `${this.props.style.width/2 - 20}px`
    }

    const PictureViewer = this.props.cephalo? CephaloViewer : PictureViewerClassical


    return (

      <>

        {this.state.loader && <Loader/>}

        {this.state.error && <Popup msg = {this.state.error} handleClick={this.closeErrorPopup}/>}

        {this.state.delete && <Popup 
          choice={true} 
          msg='Etes-vous sûr de vouloir effacer cette image?'
          handleClickNo={this.cancelDelete}
          handleClickYes={this.processDelete}
        />}

        <input 
          ref = {this.inputRef}
          style={{display : 'none'}} 
          type='file' accept='image/*' 
          value='' 
          onChange={this.handleChange}
        />

       
        

        <div 
          style={this.props.style} 
          onDrop = {this.handleDrop}
          onDragEnter = {this.handleDragEnter}
          onDragLeave = {this.handleDragLeave}
          onDragOver ={this.handleDragOver}
        >

          <img 
            src={this.state.preview? this.state.preview : this.props.neutralPicture} 
            style={imageStyle} 
            onClick={this.state.preview? e=>this.fetchImageCorrections() : this.loadImage}
            alt = 'preview'
            title = {photoTitle[this.props.pictureType]}
          />

          {this.state.imageLoader && <LoaderSpinner type="Oval" color="purple" style={styleLoader} height={40} width={40} radius = {15} />}

          {this.props.context.creator && this.state.preview && <img title='Effacer' style={trashStyle} src={trash} onClick={this.deletePicture} alt='trash'/>}

          {this.state.preview && <img title='Enregistrer image' style={exportStyle} src={camera} onClick={this.exportPicture} alt='export'/>}

          {this.state.preview && this.props.cephalo && <img title='Enregistrer rapport' style={pdfStyle} src={pdf} onClick={this.exportPdf} alt='export'/>}

          {this.props.switch && <SwitchView switchPictures={this.props.switchPictures} selectView={this.props.selectView}/>}

          {!this.state.preview && this.props.context.creator && <PhoneImport style={phoneStyle} import={this.importFromPhone}/>}

      </div>

      


        {this.state.loadedImage 
          && this.state.view === 'preparePicture' 
          && this.state.type===type 
          && <PreparePicture 
            image={this.state.loadedImage} 
            exifOrientation={this.state.exifOrientation} 
            updateCorrections={this.updateCorrections} 
            exit={this.exit} 
            savedCorrection={this.state.corrections} 
            pictureType={this.props.pictureType} 
            markersNumber = {this.props.markersNumber}
            imageNavigation = {this.props.imageNavigation}
          />
        }

        {this.state.loadedImage 
          && this.state.corrections 
          && this.state.view==='pictureViewer' 
          && this.state.type===type 
          && <PictureViewer 
            image={this.state.loadedImage} 
            corrections={this.state.corrections} 
            pictureType={this.props.pictureType}
            placePictureData={this.props.placePictureData}
            landmarks = {this.props.landmarks}
          />
        }

      </>

    )

  }

}


class SwitchView extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      open : false
    }

  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

  switchView = e=>{
    console.log('switch',e.screenX, e.screenY)
    this.setState({open : {x : e.screenX, y : e.screenY}})
  }

  close = ()=>this.setState({open : false})

  selectView = view=>{
    this.setState({open : false})
    this.props.selectView(view)
  }


  render = () => {

     const switchStyle = {
      position : 'absolute',
      bottom : -10, left : -15,
      width : 30,
      cursor : 'pointer'
    }

    const styleBackground = {
      position : 'fixed',
      top : 0, left : 0,
      width : '100vw', height : '100vh',
      //backgroundColor : 'red',
      zIndex : 2
    }


    return (
      <>

        

     <img title='Modifier la vue' style={switchStyle} src={switchPicture} onClick={this.switchView} alt='switch'/>
  
     {this.state.open && <ViewList x={this.state.open.x} y={this.state.open.y} switchPictures={this.props.switchPictures} handleClick={this.selectView}/>}

      {this.state.open && <div style={styleBackground} onClick={this.close}/>}

      </>

    )

  }

}


class ViewList extends React.Component {

   static defaultProps = {
    switchPictures : []
  }

  constructor(props) {

    super(props)

    this.state = {
      date : new Date()
    }

  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

  render = () => {

     const windowStyle = {
      position : 'fixed',
      border : 'solid 2px',
      borderRight : 'solid 1px', borderBottom : 'solid 1px',
      borderColor : 'purple', borderRadius : 10,
      width : 200, 
      //height : 300,
      backgroundColor : 'white',
      top : this.props.y-200, left : this.props.x-200<10? 10 : this.props.x-200,
      zIndex : 3
    }

    return (
      
    <div style={windowStyle}>
     
      {this.props.switchPictures.map((element, index)=>
        <ViewListItem key={index} title={element} handleClick={this.props.handleClick}/>
        )}

    </div>


    )

  }

}

class ViewListItem extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      over : false
    }

  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

  handleClick = e=>{
    this.props.handleClick(this.props.title)
  }

  over = ()=>this.setState({over : true})

  leave = ()=>this.setState({over : false})

  render = () => {

    const divStyle = {
      //border : 'solid',
      backgroundColor : this.state.over? 'purple' : '',
      color : this.state.over? 'white' : ''
    }

    return (
      <div style={divStyle} onClick={this.handleClick} onMouseEnter = {this.over} onMouseLeave = {this.leave}>
        {photoTitle[this.props.title]}
      </div>
    )

  }

}



export default WrapComponent(Picture)


