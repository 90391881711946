const hashParse = ()=>{

 

	let hashDecode = window.location.hash
  // Buffer.from(window.location.hash, 'base64').toString('utf-8')
  

    let hashList = hashDecode.slice(1).split('#')
      
    hashList = Array.from(hashList,item=>{

      let hashVariables
      let variables = item.split('?')

      if (item.split('?')[1]){

        let variablesArray = variables[1].split('&')

        hashVariables = variablesArray.reduce((accumulator, item)=>{
          let pairs = item.split('=')
          let object = {}
          object[pairs[0]] = pairs[1]
          return Object.assign({},accumulator,object)
        },{})

      }

      else hashVariables = undefined
    

      return {hashPage : item.split('?')[0], hashVar : hashVariables} 

  })

   

  return hashList
	
}

export default hashParse