import React from 'react'



class Visor extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)
    this.canvasRef = React.createRef()

    this.state = {
      
    }

  }

  componentDidMount = () => {
this.ctx = this.canvasRef.current.getContext("2d")
this.updateCanvas()
  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  if (prevProps.canvas !== this.props.canvas) this.updateCanvas()
    
  }

updateCanvas = ()=>{
  let image = this.props.canvas
  this.ctx.drawImage(image, 0, 0,image.width, image.height,0,0,image.width,image.height)
}


  render = () => {

    const divStyle={
    position : 'fixed',
     backgroundColor : 'white',
      border : 'solid 2px',
      borderLeft : 'solid 1px', borderTop : 'solid 1px',
      borderColor : 'purple', borderRadius : 10,
      height : 140, width : 140,
      top : 20, right : 40,
      zIndex : 8
  }

  const visorStyle = {
      position : 'absolute',
      top : 0, left : 0,
      height : '100%',
      width : '100%',
      borderRadius : 10
    }

  const svgStyle = {
    position : 'absolute',
      top : 0, left : 0,
      height : '100%',
      width : '100%',

  }


    return (
       <div style={divStyle}>

      {/* />/><img style={visorStyle} src={props.dataUrl}/> */}
      <canvas  ref = {this.canvasRef} style={visorStyle} height={this.props.canvas.height} width={this.props.canvas.width} />


    <svg style={svgStyle} viewBox="0 0 100 100">
        <path d="M50,0 V100" stroke="purple" strokeWidth="1" />
        <path d="M0,50 H100" stroke="purple" strokeWidth="1" />
    </svg>
    

    </div>
  

    )

  }

}





export default Visor