
import React from 'react'
import hashParse from '../functions/hashParse'
import Picture from './picture'
//import { PlacePictureSpreader, PlacePictureLeftSide, PlacePictureRightSide, placePictureDataa} from '../functions/placePicture'
import face from '../images/faceImage.svg'
import smile from '../images/smileImage.svg'
import side from '../images/sideImage.svg'
import sideLeft from '../images/sideImageLeft.svg'
import spreader from '../images/spreaderImage.svg'
import sideImageLeftWithMarkers from '../images/sideImageLeftWithMarkers.svg'
import sideImageWithMarkers from '../images/sideImageWithMarkers.svg'
import smileImageWithMarkers from '../images/smileImageWithMarkers.svg'
import spreaderImageWithMarkers from '../images/spreaderImageWithMarkers.svg'
import teethOcclusalMax from '../images/toothOcclMax.svg'
import teethOcclusalMaxWithMarkers from '../images/toothOcclMaxWithMarkers.svg'
import teethOcclusalMand from '../images/toothOcclMand.svg'
import teethOcclusalMandWithMarkers from '../images/toothOcclMandWithMarkers.svg'
import teethLeftSide from '../images/toothLeftSide.svg'
import teethLeftSideWithMarkers from '../images/toothLeftSideWithMarkers.svg'
import teethRightSide from '../images/toothRightSide.svg'
import teethRightSideWithMarkers from '../images/toothRightSideWithMarkers.svg'
import teethFaceOpen from '../images/toothFaceOpen.svg'
import teethFaceOpenWithMarkers from '../images/toothFaceOpenWithMarkers.svg'
import teethFaceShut from '../images/toothFaceShut.svg'
import teethFaceShutWithMarkers from '../images/toothFaceShutWithMarkers.svg'
import plasterOcclusalMax from '../images/plasterOcclMax.svg'
import plasterOcclusalMaxWithMarkers from '../images/plasterOcclMaxWithMarkers.svg'
import plasterOcclusalMand from '../images/plasterOcclMand.svg'
import plasterOcclusalMandWithMarkers from '../images/plasterOcclMandWithMarkers.svg'
import plasterLeftSide from '../images/plasterLeftSide.svg'
import plasterLeftSideWithMarkers from '../images/plasterLeftSideWithMarkers.svg'
import plasterRightSide from '../images/plasterRightSide.svg'
import plasterRightSideWithMarkers from '../images/plasterRightSideWithMarkers.svg'
import plasterFace from '../images/plasterFace.svg'
import plasterFaceWithMarkers from '../images/plasterFaceWithMarkers.svg'

import sideTeleradio from '../images/cephalo.svg'
import orthopanto from '../images/orthopanto.svg'
import sideTeleradioWithMarkers from '../images/cephaloWithMarkers.svg'
import orthopantoWithMarkers from '../images/orthopantoWithMarkers.svg'


import WrapComponent from '../contexts/wrap'
import {LandMarksFaceObject, LandMarksSpreaderFaceObject, LandMarksSideObject, LandMarksTeethFaceObject, LandMarksTeethOpenFaceObject, LandMarksTeethSideObject, LandMarksTeethOcclObject, LandMarksOrthopantoObject, LandMarksCephaloObject} from '../objects/landmarksDataObjects'
import {placePictureDataFace, placePictureDataSpreader, placePictureDataLeftSide, placePictureDataRightSide, placePictureDataTeethFaceShut, placePictureDataTeethFaceOpen, placePictureDataTeethOcclMaxillar, placePictureDataTeethOcclMandibular, placePictureDataTeethLeftSide, placePictureDataTeethRightSide, placePictureDataOrthpanto, placePictureDataCephalo} from '../functions/placePictureData'

import Clutter from './clutter'
import close from '../icons/cancel.svg'
import disk from '../icons/disk.svg'

import socket from '../modules/socket'
import Loader from './loader'





const picturesOptions = {
  
  facePictureWithSmile : {
    pictureType : 'facePictureWithSmile', 
    markersNumber : 3,
    placePictureData : placePictureDataFace,
    landmarks : LandMarksFaceObject,
    neutralPicture : smile,
    imageNavigation : smileImageWithMarkers
  },

  leftSidePicture : {
    pictureType : 'leftSidePicture',
    markersNumber : 4,
    placePictureData : placePictureDataLeftSide,
    landmarks : LandMarksSideObject,
    neutralPicture : sideLeft,
    imageNavigation : sideImageLeftWithMarkers
  },

  facePicture : {
    pictureType : 'facePicture',
    markersNumber : 3,
    placePictureData : placePictureDataFace,
    landmarks : LandMarksFaceObject,
    neutralPicture : face
  },

  rightSidePicture : {
    pictureType : 'rightSidePicture',
    markersNumber : 4,
    placePictureData : placePictureDataRightSide,
    landmarks : LandMarksSideObject,
    neutralPicture : side,
    imageNavigation : sideImageWithMarkers
  },

  spreaderFacePicture : {
    pictureType : 'spreaderFacePicture',
    markersNumber : 5,
    placePictureData : placePictureDataSpreader,
    landmarks : LandMarksSpreaderFaceObject,
    neutralPicture : spreader,
    imageNavigation : spreaderImageWithMarkers
  },

  teethPictureOcclusalMaxillar : {
    pictureType : 'teethPictureOcclusalMaxillar',
    markersNumber : 5,
    placePictureData : placePictureDataTeethOcclMaxillar,
    landmarks : LandMarksTeethOcclObject,
    neutralPicture : teethOcclusalMax,
    imageNavigation : teethOcclusalMaxWithMarkers    
  },

  teethPictureLeftSide : {
    pictureType : 'teethPictureLeftSide',
    markersNumber : 3,
    placePictureData : placePictureDataTeethLeftSide,
    landmarks : LandMarksTeethSideObject,
    neutralPicture : teethLeftSide,
    imageNavigation : teethLeftSideWithMarkers
  },

  teethPictureFaceClose : {
    pictureType : 'teethPictureFaceClose',
    markersNumber : 3,
    placePictureData : placePictureDataTeethFaceShut,
    landmarks : LandMarksTeethFaceObject,
    neutralPicture : teethFaceShut,
    imageNavigation : teethFaceShutWithMarkers
  },

  teethPictureFaceOpen : {
      pictureType : 'teethPictureFaceOpen',
      markersNumber : 6,
      placePictureData : placePictureDataTeethFaceOpen,
      landmarks : LandMarksTeethOpenFaceObject,
      neutralPicture : teethFaceOpen,
      imageNavigation : teethFaceOpenWithMarkers
  },

  teethPictureRightSide : {
      pictureType : 'teethPictureRightSide',
      markersNumber : 3,
      placePictureData : placePictureDataTeethRightSide,
      landmarks : LandMarksTeethSideObject,
      neutralPicture : teethRightSide,
      imageNavigation : teethRightSideWithMarkers
  },

  teethPictureOcclusalMandibular : {
      pictureType : 'teethPictureOcclusalMandibular',
      markersNumber : 5,
      placePictureData : placePictureDataTeethOcclMandibular,
      landmarks : LandMarksTeethOcclObject,
      neutralPicture : teethOcclusalMand,
      imageNavigation : teethOcclusalMandWithMarkers
  },

  plasterPictureOcclusalMaxillar : {
      pictureType : 'plasterPictureOcclusalMaxillar',
      markersNumber : 5,
      placePictureData : placePictureDataTeethOcclMaxillar,
      landmarks : LandMarksTeethOcclObject,
      neutralPicture : plasterOcclusalMax,
      imageNavigation : plasterOcclusalMaxWithMarkers
  },

  plasterPictureLeftSide : {
      pictureType : 'plasterPictureLeftSide',
      markersNumber : 3,
      placePictureData : placePictureDataTeethLeftSide,
      landmarks : LandMarksTeethSideObject,
      neutralPicture : plasterLeftSide,
      imageNavigation : plasterLeftSideWithMarkers
  },

  plasterPictureFace : {
      pictureType : 'plasterPictureFace',
      markersNumber : 3,
      placePictureData : placePictureDataTeethFaceShut,
      landmarks : LandMarksTeethFaceObject,
      neutralPicture : plasterFace,
      imageNavigation : plasterFaceWithMarkers
  },

  plasterPictureRightSide : {
      pictureType : 'plasterPictureRightSide',
      markersNumber : 3,
      placePictureData : placePictureDataTeethRightSide,
      landmarks : LandMarksTeethSideObject,
      neutralPicture : plasterRightSide,
      imageNavigation : plasterRightSideWithMarkers
  },

plasterPictureOcclusalMandibular : {
      pictureType : 'plasterPictureOcclusalMandibular',
      markersNumber : 5,
      placePictureData : placePictureDataTeethOcclMandibular,
      landmarks : LandMarksTeethOcclObject,
      neutralPicture : plasterOcclusalMand,
      imageNavigation : plasterOcclusalMandWithMarkers
},

orthopanto : {
      pictureType : 'orthopanto',
      markersNumber : 6,
      placePictureData : placePictureDataOrthpanto,
      landmarks : LandMarksOrthopantoObject,
      neutralPicture : orthopanto,
      imageNavigation : orthopantoWithMarkers
},

sideTeleradio : {
      pictureType : 'sideTeleradio',
      markersNumber : 2,
      placePictureData : placePictureDataCephalo,
      landmarks : LandMarksCephaloObject,
      neutralPicture : sideTeleradio,
      imageNavigation : sideTeleradioWithMarkers,
      cephalo : true
},

clutter : {clutter : true}

 
}

      



class File extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {

      page : Number(hashParse()[1].hashVar.page)
      
    }

  }

  componentDidMount = () => {

    window.addEventListener('hashchange', this.hashPage)
    
    this.props.context.updateFileData(this.props.fileData)






  

  }

  componentWillUnmount = () => {

window.removeEventListener('hashchange', this.hashPage)
this.props.context.updateFileData({})
  }

   componentDidUpdate = async (prevProps)=> {


    
  }

  hashPage = e=>{
let page = hashParse()[1].hashVar.page
this.setState({page : Number(page)})

  }

   exitPage = e=>{
    window.location.hash = hashParse()[0].hashPage
  }

  render = () => {

    const pageStyle = {
      //border : 'solid green',
       top : 0, left : 0, width : '100%', height : '100%',  overflowY : 'auto',
       display : 'flex', justifyContent : 'space-around', 
       //alignItems : 'center'
    }

    const closeStyle = {
      position : 'absolute',
      right : 20, top : 20, width : 30,
      cursor : 'pointer'
    }


    return (

      <div style={pageStyle}>

        <img title='Fermez le dossier' src={close} style={closeStyle} onClick={this.exitPage} alt='close'/>

        {this.props.context.fileData._id && this.state.page===1 && <Page 
            number={1}
            switchPictures = {['facePicture','facePictureWithSmile','leftSidePicture','rightSidePicture','spreaderFacePicture']}

            picturesArray ={[
              [
                [picturesOptions.facePictureWithSmile],
                [picturesOptions.leftSidePicture, picturesOptions.facePicture, picturesOptions.rightSidePicture],
                [picturesOptions.spreaderFacePicture]
              ],
              [[picturesOptions.facePicture, picturesOptions.facePictureWithSmile, picturesOptions.rightSidePicture]],
              [[picturesOptions.leftSidePicture, picturesOptions.spreaderFacePicture, picturesOptions.rightSidePicture]],
              [[picturesOptions.facePicture, picturesOptions.rightSidePicture]],
              [[picturesOptions.facePicture]]

            ]}


        />}

        {this.props.context.fileData._id && this.state.page===2 && <Page 
            number={2}
            switchPictures = {['teethPictureOcclusalMaxillar','teethPictureLeftSide', 'teethPictureFaceClose', 'teethPictureFaceOpen', 'teethPictureRightSide', 'teethPictureOcclusalMandibular']}
            
            picturesArray ={[
              [
                [picturesOptions.teethPictureOcclusalMaxillar],
                [picturesOptions.teethPictureLeftSide, picturesOptions.teethPictureFaceClose, picturesOptions.teethPictureFaceOpen, picturesOptions.teethPictureRightSide],
                [picturesOptions.teethPictureOcclusalMandibular]
              ],
              [[picturesOptions.teethPictureLeftSide, picturesOptions.teethPictureFaceClose, picturesOptions.teethPictureRightSide]],
              [[picturesOptions.teethPictureOcclusalMaxillar, picturesOptions.teethPictureFaceOpen, picturesOptions.teethPictureOcclusalMandibular]],
              [[picturesOptions.teethPictureFaceClose, picturesOptions.teethPictureFaceOpen]],
              [[picturesOptions.teethPictureFaceClose]]

            ]}
          
        />}

        {this.props.context.fileData._id && this.state.page===3 && <Page 
            number={3}
            switchPictures = {['plasterPictureOcclusalMaxillar','plasterPictureLeftSide', 'plasterPictureFace', 'plasterPictureRightSide', 'plasterPictureOcclusalMandibular']}

            picturesArray ={[
              [
                [picturesOptions.plasterPictureOcclusalMaxillar],
                [picturesOptions.plasterPictureLeftSide, picturesOptions.plasterPictureFace, picturesOptions.plasterPictureRightSide],
                [picturesOptions.plasterPictureOcclusalMandibular]
              ],
              [[picturesOptions.plasterPictureLeftSide, picturesOptions.plasterPictureFace, picturesOptions.plasterPictureRightSide]],
              [[picturesOptions.plasterPictureOcclusalMaxillar, picturesOptions.plasterPictureFace, picturesOptions.plasterPictureOcclusalMandibular]],
              [[picturesOptions.plasterPictureFace, picturesOptions.plasterPictureRightSide]],
              [[picturesOptions.plasterPictureFace]]
            ]}
        />}

        {this.props.context.fileData._id && this.state.page===4 && <Page 
            number={4}
            switchPictures = {['orthopanto', 'sideTeleradio']}

            picturesArray ={[
              [[picturesOptions.orthopanto, picturesOptions.sideTeleradio]],
              [[picturesOptions.sideTeleradio]]
              
            ]}
        />}

        {this.props.context.fileData._id && this.state.page===5 && <Page 
            number={5}
            picturesArray ={[
              [[picturesOptions.clutter]]
            ]}
        />}

      </div>
      


    )

  }

}



class Page extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.boxRef = React.createRef()
    this.refSlider = React.createRef()

    this.state = {
      size : 1,
      picturesArray : this.props.picturesArray[0],
      loader : true
    }

  }

  componentDidMount = () => {

    //console.log(this.props.context.patientData._id)

     socket.emit('loadView',this.props.context.fileData._id,this.props.number, this.props.context.patientData._id, data=>{
       
      if (data.success) this.updateSize(data.data)
       this.setState({loader : false})

     })

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

  updateSize = data=>{
    
    let newArray = Array.from(data.views,array=>{
      return Array.from(array,type=>picturesOptions[type])
    })
    
    this.setState({size : data.size, picturesArray : newArray})
    this.refSlider.current.setState({zoomValue : data.size})

  }

  changeSize = size=>{
    this.setState({size : size, picturesArray : this.props.picturesArray[size-1]})
  }

  selectView = (view, coords)=>{
    let array = this.state.picturesArray
    array[coords[0]][coords[1]]=picturesOptions[view]
    this.setState({picturesArray : array})
  }

  saveView = ()=>{

    console.log('save process')
    

    let views = Array.from(this.state.picturesArray, array=>{
      let newArray = Array.from(array, element=>element.pictureType)
      return newArray
    })

    socket.emit('saveView', this.props.context.fileData._id, {page : this.props.number, size : this.state.size, views : views},()=>{
      console.log('saved')
      this.refSlider.current.saveInformation()
    })
  }

  render = () => {

    const divStyle = {      
      marginTop : 20,
      width : this.state.picturesArray.length===3? '70%' : '90%', 
      height : '90%',
      display : 'flex', justifyContent : 'space-around', alignItems : 'center', flexDirection : 'column',

    }

    const rowStyle = {
      width : '100%', 
      //height : 600,
      display : 'flex', justifyContent : 'space-around', alignItems : 'center'

    }

    let pictureSize = 170
    if (this.state.picturesArray.length===3) pictureSize = 170
    else if (this.state.picturesArray[0].length>2) pictureSize = 300
    else if (this.state.picturesArray[0].length===2) pictureSize = 400
    else if (this.state.picturesArray[0].length===1) pictureSize = 500

 const stylePicture = {
    width : pictureSize, 
    height : pictureSize, 
    position : 'relative',
    borderRadius : 20,
    border : 'solid 2px',
    borderRight : 'solid 1px', borderBottom : 'solid 1px',
    borderColor : 'purple', 
}

//if (this.boxRef.current) console.log(this.boxRef.current.getBoundingClientRect())



    return (
     
      <div style={divStyle} ref={this.boxRef}>

        {this.state.loader && <Loader/>}

        {this.props.picturesArray.length>1 && <SizeSlider 
            ref = {this.refSlider}
            changeSize={this.changeSize} 
            picturesArray = {this.props.picturesArray} 
            save={this.saveView} 
            idFile={this.props.context.fileData._id}
        />}

        {this.state.picturesArray.map((pictures, index1)=>

          <div style={rowStyle} key={index1}>

            {pictures.map((picture, index2)=>

                picture.clutter? 

                <Clutter key = {index2} style={stylePicture}/>

                :

                <Picture 
                  key={index2}
                  style={stylePicture}
                  pictureType={picture.pictureType} 
                  markersNumber = {picture.markersNumber} 
                  placePictureData={picture.placePictureData} 
                  landmarks={picture.landmarks} 
                  neutralPicture={picture.neutralPicture} 
                  imageNavigation={picture.imageNavigation}
                  cephalo = {picture.cephalo}
                  switchPictures ={this.props.switchPictures}
                  selectView={view=>this.selectView(view, [index1, index2])}
                  switch = {Number(this.state.size)!==1}
                />

            )}

          </div>
        
        )}

      </div>

    )

  }

}






class SizeSlider extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      zoomValue : 1,
      saveLabel : false
     // step : 1
    }

  }

  componentDidMount = () => {

   

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

  handleChange = size=>{
   this.setState({zoomValue :  size})
    this.props.changeSize(size)

  }

  saveInformation =()=>{
    this.setState({saveLabel : true})
    setTimeout(()=>this.setState({saveLabel : false}), 1000)

  }

  render = () => {

    const divStyle = {
      position : 'absolute',
      top : 30, left : 50,
      transform : 'rotate(90deg)',
      transformOrigin: 'top left',
      width : '60vh'
    }

    const sliderStyle = {
      width : '100%',
      cursor : 'pointer'
    }

    const labelList = this.props.picturesArray.map((element,index)=>index*(100/(this.props.picturesArray.length-1)))

   const saveStyle = {
    width : 30, 
     position : 'absolute',
      top : '70vh', left : 25,
      cursor : 'pointer'
   }

   const saveLabelStyle = {
    border : 'solid white',
    color : 'white',
    borderRadius : 5,
    padding : 5,
    backgroundColor : 'purple',
   // width : 30, height : 15,
     position : 'absolute',
      top : '75vh', left : 15,
      opacity : this.state.saveLabel? 1 : 0,
      transition : 'opacity 0.3s',
      pointerEvents : 'none'
   }

    return (

      <>
      
      <div style={divStyle}>
  <input style={sliderStyle} onChange={e=>this.handleChange(e.target.value)} step={1} type="range" min="1" max={this.props.picturesArray.length} value={this.state.zoomValue}/>
  
  {labelList.map((top,index)=><Label key = {index} top = {top} picturesArray={this.props.picturesArray[index]} changeSize={()=>this.handleChange(index+1)}/>)}

      </div>

      {this.props.context.creator && <img title='Sauvegarder la vue actuelle' src={disk} style={saveStyle} onClick={this.props.save} alt='save'/>}

      <div style={saveLabelStyle}>Sauvegardé!</div>

      </>


    )

  }

}

class Label extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      date : new Date()
    }

  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

  render = () => {

     const labelStyle = {
      //border : 'solid',
      height : 20, width : 20,
      position : 'absolute',
      left : this.props.top+'%',
      top : -30,
     transform : 'rotate(-90deg)',
     cursor : 'pointer'
    }

    const markerStyle = {
      //border : 'solid red',
      height : 50, width : 50,
      position : 'relative',
      top : '-50px', 
      display : 'flex', justifyContent : 'space-around', flexDirection : 'column',
      alignItems : 'center'
      //left : 20
     // transform : 'translate(0px,-10px)'
    }

    const rowStyle = {
       position : 'relative',
     //top : '-15px', 
      //border : 'solid',
      height : '33%', width : '100%',
       display : 'flex', justifyContent : 'space-around', 
       //flexDirection : 'column',
      alignItems : 'center'
    }

    return (
      
      <div style={labelStyle} onClick={this.props.changeSize}>
        <div style={markerStyle}>
          
          {this.props.picturesArray.map((array,index)=><div key={index} style={rowStyle}>
            {array.map((element, index)=><div key={index} style={{fontSize : '4em', color : 'purple'}}>.</div>)}
          </div>)}


        </div>
      </div>


    )

  }

}

Page = WrapComponent(Page)
SizeSlider = WrapComponent(SizeSlider)

export default WrapComponent(File)