

import socket from '../modules/socket'
import resizeImage from '../functions/resizeImage'
import {fabric} from 'fabric'
import {pointsDistance} from '../functions/fabricFunctions/fabricFunctions'




class ClutterViewer {


  constructor(fileId) {

    console.log('clutterviewer')
   // this.pictureType = pictureType
    //this.placePictureData = placePictureData
   // this.landmarkOption = landmarkOption
    let canvas = document.createElement('canvas')
    canvas.height = 1800
    canvas.width = 1800
    this.fileId = fileId
    this.canvas = new fabric.Canvas(canvas)
    this.canvas.backgroundColor = 'white'
   // this.reader = new FileReader()
   // this.reader.addEventListener('load', this.loadImageFromArray)
   // this.lineList=[]

   // this.start()
  }


  start = ()=>{

    return new Promise(async (resolve,reject)=>{

      this.datas = await this.fetchFileData()

     if (this.datas.data && this.datas.data.drawedLinesData) {
      let guidePoints=this.datas.data.drawedLinesData.guidePoints
      let size = pointsDistance(guidePoints[0], guidePoints[1])
      console.log(size)
      this.drawGuide(size)

     }
     else resolve(false)

      this.resolve=resolve

    })
  }


  fetchFileData = ()=>{

    return new Promise ((resolve, reject)=>{
        socket.emit('fetchClutter', this.fileId, data=>{
            resolve(data)
            //console.log(data)
        })
    })

  }



  drawGuide = (size)=>{


  let pathCoords = 'M 2 101.4 C 2 74 8.2 44.5 24.3 22 C 43.6-5.2 85-5.5 104.6 21.5  c15.5 23.6 22.4 51.7 22.4 80'

     let scale = size/100
  this.guide = new fabric.Path(pathCoords)

  this.guide.set({
    fill : 'rgba(0,0,0,0)', stroke : 'purple', scaleX : scale, scaleY : scale,
    originX : 'center', originY : 'top',
    strokeWidth : 10/scale, strokeDashArray : [20/scale, 20/scale],
    top : 300, left : 900,
    hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
    type : 'marks'
  })

  this.canvas.add(this.guide)

  this.drawLines()

  }


drawLines = ()=>{
    console.log(this.datas)
    let lines=this.datas.data.clutterData.lines

    lines.map(line=>{
        
        let point1 = new fabric.Circle({
      radius: 15, fill: 'red', left: line.x1, top:line.y1, 
      originX : 'center', originY : 'center', 
        })

         let point2 = new fabric.Circle({
      radius: 15, fill: 'red', left: line.x2, top:line.y2, 
      originX : 'center', originY : 'center', 
        })


         let segment = new fabric.Line([line.x1, line.y1, line.x2, line.y2],{
        stroke : 'red', strokeWidth : 8,
        originX : 'center', originY : 'center', 
        })

         this.canvas.add(point1, point2, segment)

         return true


    })




    this.drawLimits()
}


drawLimits = ()=>{

    
    let molarLimitLeft = this.datas.data.clutterData.molarLimitLeft
    let molarLimitRight = this.datas.data.clutterData.molarLimitRight


    if(molarLimitLeft) {
       let molarLeftLimitLine = new fabric.Line([0,molarLimitLeft.y,1800,molarLimitLeft.y],{
        stroke : 'purple', 
        strokeWidth : 3, strokeDashArray : [20, 20],
        opacity : 0.5,
    })

        this.canvas.add(molarLeftLimitLine)

    }

    if(molarLimitRight) {
       let molarRightLimitLine = new fabric.Line([0,molarLimitRight.y,1800,molarLimitRight.y],{
        stroke : 'purple', 
        strokeWidth : 3, strokeDashArray : [20, 20],
        opacity : 0.5,
    })

      this.canvas.add(molarRightLimitLine)

  }

   if (molarLimitRight && molarLimitLeft) {
      let molarLimitLine = new fabric.Line([0,(molarLimitRight.y+molarLimitLeft.y)/2,1800,(molarLimitRight.y+molarLimitLeft.y)/2],{
        stroke : 'red', strokeWidth : 3,
      })

      this.canvas.add(molarLimitLine)

    }  

      this.drawText()


}


drawText = ()=>{

    console.log(this.datas.data.clutterMeasure)

    let retractionText = this.datas.data.clutterMeasure.incisorsRetraction>0? `Recul de l'incisif : ${this.datas.data.clutterMeasure.incisorsRetraction}mm` : 'Pas de recul des incisives' 

     let clutterIncisorRetractionText = new fabric.Text(retractionText,{
      left : 900, top : 1500,
      originX : 'center', fontSize : 80, fill : 'purple',
      type : 'marks',
      hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
    })

   let clutterText = `Encombrement : ${this.datas.data.clutterMeasure.total}mm`

    let totalClutterText = new fabric.Text(clutterText,{
      left : 900, top : 1700,
      originX : 'center', fontSize : 80, fill : 'red',
      type : 'marks',
      hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
    })

    let leftText = this.datas.data.clutterMeasure.left? this.datas.data.clutterMeasure.left+'mm' : ''

    let clutterLeftText = new fabric.Text(leftText,{
    left : this.guide.calcCoords().bl.x, top : this.guide.calcCoords().bl.y+50, 
    originX : 'center', fontSize : 80, fill : 'purple',
    type : 'marks',
    hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
  })

    let rightText = this.datas.data.clutterMeasure.right? this.datas.data.clutterMeasure.right+'mm' : ''


  let clutterRightText = new fabric.Text(rightText,{
    left : this.guide.calcCoords().br.x, top : this.guide.calcCoords().br.y+50, 
    originX : 'center', fontSize : 80, fill : 'purple',
    type : 'marks',
    hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
  })

  let addText = this.datas.data.clutterMeasure.toothClutter+'mm'

  
   let clutterAddText = new fabric.Text(addText,{
    left : this.guide.calcCoords().mb.x, top : this.guide.calcCoords().mb.y+50,
    originX : 'center', fontSize : 80, fill : 'red',
    type : 'marks',
    hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, selectable : false,
  })


    this.canvas.add(
        clutterIncisorRetractionText, 
    )

    if (this.datas.data.calibration) {
      this.canvas.add(
     //   clutterIncisorRetractionText, 
        totalClutterText,
        clutterLeftText,
        clutterRightText,
        clutterAddText
      )
    }




    this.exportPicture()
}



exportPicture = ()=>{

    return new Promise (async (resolve, reject)=>{

      this.canvas.renderAll()
      let dataURLBigSize = this.canvas.toDataURL({format : 'jpeg'})
      let dataURLMinisize = await resizeImage(dataURLBigSize, 600)



   // if (this.datas.data)
     this.resolve({picture : dataURLBigSize, preview :  dataURLMinisize})
    //console.log ({picture : dataURLBigSize, preview :  dataURLMinisize})


    })


     
  }






}



export default ClutterViewer
