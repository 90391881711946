import React from 'react'
import socket from '../modules/socket'
import WrapComponent from '../contexts/wrap'
import yearsOld from '../functions/yearsOld'
import Loader from './loader'



const months= ['janvier','février','mars', 'avril', 'mai','juin','juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']



class CheckUpList extends React.Component {

  constructor(props) {
    super(props)
   
    this.state = {
      filesList : [],
      popup : false,
      validPopup : ()=>true
    
     
    }
  }

  componentDidMount = () => {
    this.fetchCheckUpData()

   
   

  }

  fetchCheckUpData = ()=> socket.emit('fetchCheckUpData',this.props.patientId, data=>{
    this.setState({filesList : data.data})
   if (data.data.length===1) this.goToFile(data.data[0])
  })
  
 


  goToFile = data=>{
      window.location.hash += `#file${data._id}?page=1`
      this.props.liftUpFileData(data)
      // this.props.context.updateFileId(data._id)
      // this.props.context.updateFileData(data)
  }


  // exit = e=> window.location.hash  = ''

 

  render = () => {

    const divStyle = {
      position : 'absolute',
      top : 0, left : 0,
      width : '100vw', height : '100vh',
      display : 'flex', justifyContent : 'center', alignItems : 'center', flexDirection : 'column'
    }

   
   
   this.state.filesList.sort((a,b)=>new Date(a.checkUpDate).getTime()-new Date(b.checkUpDate).getTime())

    return (

    
   


      <div style={divStyle} >
{/*  */}
{/*          <img src={cancel} style={styleClose} onClick={this.exit} alt='cancel'/> */}
      

        {this.state.filesList.map((element, index)=>
            <CheckUpElement element={element} key = {index}  onClick = {e=>this.goToFile(element)} delete={this.deleteCheckUp}/>
        )}

        {this.state.filesList.length===0 && <div>Ce patient n'a pas de dossier crée</div>}

      </div>

   
     
    )

  }

}




class CheckUpElement extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
     popup : false, 
     validPopup : ()=>true,
     loader: false,
     over : false
    }

  }

  componentDidMount = () => {
  

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

  // deleteCheckUp = ()=>{
  //  let id = this.props.element._id
  //   this.props.delete(id)
  // }

  // cancelPopup = ()=>this.setState({popup : false, validPopup : ()=>true})

  // createPopup = ()=>this.setState({popup : {msg : "Voulez-vous vraiment effacer ce bilan ?"}, validPopup : this.deleteCheckUp})

  // zipPopup = ()=>this.setState({popup : {msg : "La génération du dossier peut prendre plusieurs minutes. Voulez-vous vraiment générer le dossier rassemblant les photographie en haute définition ?"}, validPopup : this.zipPictures})

  // pdfPopup = ()=>this.setState({popup : {msg : "La génération du rapport peut prendre plusieurs minutes. Voulez-vous vraiment générer le rapport PDF ?"}, validPopup : this.pdfReport})

//   zipPictures = async ()=>{
// 
// 
//     this.setState({loader : true, popup : false, validPopup : ()=>true})
//   
//     let zip = new generatePictures(this.props.element._id, this.props.context, this.props.element.checkUpDate)
//     //zip.onProgress(()=>console.log('progress'))
//     zip.onFinish(()=>this.setState({loader : false}))
//     zip.zipGenerate()
// 
//   }
// 
//   pdfReport = ()=>{
//     this.setState({loader : true, popup : false, validPopup : ()=>true})
//     let zip = new generatePictures(this.props.element._id, this.props.context, this.props.element.checkUpDate)
//     
//     zip.pdfReport((pdfURL)=>{
// 
//       this.setState({loader : false})
// 
//      
//       let twoNumbers = number=> Number(number)>9 ? number : '0'+number 
//       let creationDate = new Date(this.props.element.checkUpDate)
//       let date = `${twoNumbers(creationDate.getDate())}${twoNumbers(creationDate.getMonth()+1)}${creationDate.getFullYear()}`
//       
//       let fileTitle = (
//         this.props.context.patientData.name.lastname.slice(0,3)+
//         this.props.context.patientData.name.firstname.slice(0,3)+'_'+
//         date+'_rapportBilan.pdf'
//       )
// 
// 
//      download(pdfURL, fileTitle)
// 
// 
// 
//     })
// 
//   }

//   over = ()=>this.setState({over : true})
// 
//   leave = ()=>this.setState({over : false})
// 
// 
//   testZip=()=>{
//     socket.emit('testfabric')
//   }
// 


  render = () => {

    let date = new Date(this.props.element.checkUpDate)
    let birthday = this.props.context.patientData.birthday
    let birthDate
   if (birthday) birthDate = new Date(birthday.year, birthday.month-1, birthday.day)
    let checkUpAge=yearsOld(birthDate, date)

    const styleDiv = {
      //border : 'solid',
      margin : 10,
      padding : 10,
      border : 'solid 2px',
      borderRight : 'solid 1px', borderBottom : 'solid 1px',
      borderColor : 'purple', borderRadius : 10,
      color : this.state.over ? 'white' : 'purple',
      width : 300,
      cursor : 'pointer',
      backgroundColor : this.state.over ? 'purple' : 'white'
    }

 

    return (

      <div style={{position : 'relative'}}>

        {this.state.loader && <Loader/>}

        {/* {this.state.popup && <Popup choice msg={this.state.popup.msg}  handleClickYes={this.state.validPopup} handleClickNo ={this.cancelPopup}/>} */}

        <div style={styleDiv} onClick={this.props.onClick} onMouseEnter = {this.over} onMouseLeave = {this.leave}>

          Bilan du {date.getDate()} {months[date.getMonth()]} {date.getFullYear()} ({checkUpAge}) 

        </div>

        {/* <img title='Effacer le bilan' src={trash} width={20} style={styleTrash} onClick={this.deleteCheckUp} alt='trash'/> */}

        {/* <img title="Exporter l'ensemble des images" src={folder} width={20} style={styleFolder} onClick={this.testZip} alt='folder'/> */}

        {/* <img title="Exporter l'ensemble des images" src={folder} width={20} style={styleFolder} onClick={this.zipPopup} alt='folder'/> */}

        {/* <img title="Créer un rapport au format pdf " src={pdf} width={20} style={stylePdf} onClick={this.pdfPopup} alt='pdf'/> */}

      </div>
    )

  }

}






CheckUpElement = WrapComponent(CheckUpElement)

export default WrapComponent(CheckUpList)