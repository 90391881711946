import React from 'react'
import {Rule, Ruler, Protractor} from '../functions/fabricFunctions/fabricLengthTools'
import Frame from './frame'
import rule from '../icons/rule.svg'
import protractor from '../icons/protractor.svg'
import vRuler from '../icons/vRuler.svg'
import hRuler from '../icons/hRuler.svg'
import trash from '../icons/trash.svg'
import eye from '../icons/eye.svg'
import prolongation from '../icons/prolongation.svg'
import lineIcon from '../icons/line.svg'


class Tools extends React.Component {

   static defaultProps = {

  }

  constructor(props) {



    super(props)
    this.test=[]

    this.state = {
      tools : [],
      choose : false,
      selected : false
    }

  }

  componentDidMount = ()=>{
  
    if (this.props.ratio) this.updateRatio(this.props.ratio.ratio)
    if (this.props.loadingData) this.loadTool(this.props.loadingData) 

  }


  componentDidUpdate = (prevProps, prevState)=> {



  
    if (this.state.type !== prevState.type) {
      this.createTool(this.state.type)
      this.props.canvas.on('selection:updated',()=>this.changeTool(''))  
    }

    if (this.props.ratio !== prevProps.ratio) this.updateRatio(this.props.ratio.ratio)
  
    if (this.props.loadingData!==prevProps.loadingData) this.loadTool(this.props.loadingData) 

  }


  updateRatio = ratio=>{
    this.state.tools.map(tool=>{
      if (tool.name==='rule') tool.updateRatio(ratio)
      return true
    })
  }

  changeTool=type=>{
    
    let notCompletedTools = this.state.tools.filter(tool=>!tool.completed)

    notCompletedTools.map(tool=>tool.cancel())

    this.setState({type : type, tools : this.state.tools.filter(tool=>tool.completed)})
    this.cancel()

  }


  loadTool = data=>{
   
    if (data.data.toolsDefinition.length>0) { 

      let tools = data.data.toolsDefinition
   
      tools.map((toolData, index)=>{
   
        if (toolData.name === 'rule') {
          this.tool = new Rule(this.props.canvas, this.props.visorCanvas)
          this.tool.ratio=this.props.ratio
        }
   
        else if (toolData.name === 'protractor') this.tool = new Protractor(this.props.canvas, this.props.visorCanvas)
       
        else if (toolData.name === 'ruler') this.tool = new Ruler(this.props.canvas, this.props.visorCanvas)
       
        this.tool.load(toolData, tool=>this.saveTool(tool, index))

        return true

      })

    }

  }

  createTool = type=>{

    if (type === 'rule') {
      this.tool = new Rule(this.props.canvas, this.props.visorCanvas)
      this.tool.ratio=this.props.ratio.ratio
    }

    else if (type === 'angle') this.tool = new Protractor(this.props.canvas, this.props.visorCanvas)
    else if (type === 'hRuler') this.tool = new Ruler(this.props.canvas, this.props.visorCanvas)
    else if (type === 'vRuler') {
      this.tool = new Ruler(this.props.canvas, this.props.visorCanvas)
      this.tool.direction = 'V'
    }
    
    if (type!=='') this.tool.create(tool=>this.saveTool(tool))

  }



  saveTool = (tool, index)=>{
   
    this.state.tools.push(tool)

    this.setState({

      type : '',

      tools : this.state.tools.filter((item, index)=>{
        let toolsId = Array.from(this.state.tools, item=>item.id)
        return toolsId.indexOf(item.id)===index
      }) 

    },()=>this.props.liftUpTools(this.state.tools))
    
    delete this.tool

  }

  cancel = tool=>{
    if (this.tool) this.tool.cancel()
    delete this.tool
  }

  selected = index=>this.setState({selected : index})


  render = () => {

    const frameStyle = {
      position : 'absolute',
      top : 20, right : 40,
      width : 230, 
      zIndex : 6,
      display : 'flex', justifyContent : 'center', flexDirection : 'column', ...this.props.style
    }

    const divStyle = {
      width : 300,
      maxHeight : '70vh',
      overflow : 'auto'
    }

    return (
      
      <Frame style={frameStyle} label="Outils de mesure">

      <ChooseTool createTool={this.changeTool} cancel={this.cancel} active = {this.state.type}/>

      <div style={divStyle}>


        {this.state.tools.map((tool, index)=>
          <ToolController 
            tool={tool} 
            key={index} 
            selected={select=>select? this.selected(index) : this.selected(false)} 
            select={this.state.selected===index}
          />
        )}

      </div>

        </Frame>

    )

  }

}



const ChooseTool = props=>{

  let handleClickTool = type=>{
    let tool = type===props.active? '' : type
    props.createTool(tool)
  }

  const divStyle ={
    width : '100%',
    height : 50,
    display : 'flex', alignItems : 'center', justifyContent : 'space-around',
    zIndex : 2
  }

  const iconStyle = active=>{
    return {
      width : active? 40 : 30,
      opacity : active? 1 : 0.6,
      transformOrigin : 'center',
      transition : 'all 0.3s',
      cursor : 'pointer'
    }
  }

  return (

    <div style={divStyle} >
        
      <img  title='Mesure distance' style={iconStyle(props.active==='rule')} src={rule} alt='rule' onClick={e=>handleClickTool('rule')}/>

      <img  title='Mesure angle' style={iconStyle(props.active==='angle')} src={protractor} alt='protractor' onClick={e=>handleClickTool('angle')}/>

      <img  title='Horizontale' style={iconStyle(props.active==='hRuler')} src={hRuler} alt='ruler' onClick={e=>handleClickTool('hRuler')}/>

      <img  title='Verticale' style={iconStyle(props.active==='vRuler')} src={vRuler} alt='ruler' onClick={e=>handleClickTool('vRuler')}/>


    </div>

    )

  }



class ToolController extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      tool : this.props.tool,
      option : false
    }

  }


  componentDidUpdate = (prevProps)=> {
    if (this.props.tool !== prevProps.tool) this.setState({tool : this.props.tool}) 
  }

  colorChange = e=>{
    this.state.tool.setColor(e.target.value)
    this.forceUpdate()
  }

  toggleProlongation =e=>this.state.tool.setProlongation(e.target.checked)

  toggleMarker = e=>this.state.tool.setMarkerVisibility(e.target.checked)
  
  toggleTextLabel = e=>this.state.tool.setTextVisibility(e.target.checked)

  toggleVisibility = e=>this.state.tool.setVisibility(e.target.checked)

  erase = e=>{
    this.state.tool.cancel()
    this.setState({options : false},()=>this.setState({options : false}))  
  }


  render = () => {

    const name = name=>{
      if (name==='rule') return 'Distance'
      else if (name==='protractor') return 'Angle'
      else if (name==='ruler' && this.props.tool.direction==='H') return 'Horizontale'
      else if (name==='ruler' && this.props.tool.direction==='V') return 'Verticale'
    }


    const icon = name=>{
      if (name==='rule') return rule
      else if (name==='protractor') return protractor
      else if (name==='ruler' && this.props.tool.direction==='H') return hRuler
      else if (name==='ruler' && this.props.tool.direction==='V') return vRuler
    }


    return (

      <Control
        name ={name(this.state.tool.name)}
        icon = {icon(this.state.tool.name)}
        tool = {this.state.tool}
        prolongation = {this.state.tool.name === 'rule'}
        toggleProlongation = {this.toggleProlongation}
        label = {this.state.tool.name === 'rule' || this.state.tool.name === 'protractor'}
        toggleTextLabel = {this.toggleTextLabel}
        toggleMarker = {this.toggleMarker}
        toggleVisibility = {this.toggleVisibility}
        colorChange = {this.colorChange}
        erase = {this.erase}
        completed = {this.state.tool.completed}
        selected={this.props.selected}
        select={this.props.select}

      />

   

    )

  }

}




class Control extends React.Component {

   static defaultProps = {
    completed : true,
    trashcan : true
  }

  constructor(props) {

    super(props)

    this.state = {
     options : false     
    }

  }

  componentDidUpdate = (prevProps)=> {
    if (this.props.select !== prevProps.select) this.setState({options : this.props.select})
  }

  showOptions = e=>this.props.selected(true)
  
  hideOptions = e=>{
    this.setState({options : false})
    this.props.selected(false)
  }

  toggleMarker = e=>this.setState({toggleMarkerStatus : !this.state.toggleMarkerStatus},this.props.toggleMarker(e)) 
  

  render = () => {

    const divStyle = {
      position : 'relative',
      border : 'solid 2px',
      borderRight : 'solid 1px', borderBottom : 'solid 1px',
      borderColor : 'purple', borderRadius : 10,
      width : 210, 
      height : this.state.options? 80 : 35, overflow : 'hidden',
      padding : 5, 
      backgroundColor : 'white', margin : 5,
      transition : 'all 0.4s',
      zIndex : 3
      
    }

    const line1Style = {
      display : 'flex', justifyContent : 'space-between', alignItems : 'center'
    }

    const line2Style = {
      display : 'flex', justifyContent : 'space-around', alignItems : 'flex-end'
    }

    const iconStyle = {
      width : 30, flex : 2
    }

    const colorStyle = {
      width : 30, height : 30,
      cursor : 'pointer', flex : 2
    }

    const eraseStyle = {
      width : 20,
      cursor : 'pointer', 
      flex : 1, padding : 5
    }

    const iconStyle2 = {
      position : 'relative', top : 10,
      width : 40
    }

    const backgroundStyle = {
      position : 'fixed',
      top : 0, left : 0, width : '100vw', height : '100vh',
     // backgroundColor : 'red',
      zIndex : 2
    }



    return (
      

      <div>

        {this.state.options && this.props.completed && <div style={backgroundStyle} onClick={this.hideOptions}/>}

        {this.props.completed && 

          <div style={divStyle} onClick={this.showOptions}>


            <div style = {line1Style}>
              <img src={this.props.icon} style={iconStyle} alt='icon'/>
              <div style={{color : 'purple', cursor : 'default', flex : 8, textAlign: 'center'}}>{this.props.name}</div>
              <input title='Changer la couleur' type='color'  style={colorStyle} value={this.props.tool.color} onChange={this.props.colorChange}/>
              {this.props.trashcan && <img title='Effacer' src={trash} style={eraseStyle} onClick={this.props.erase} alt='erase'/>}
            </div>


            <div style={line2Style}>

              {this.props.prolongation && <div>
                <input type='checkbox' onChange={this.props.toggleProlongation} defaultChecked={this.props.tool.prolongationLine} style={{cursor : 'pointer'}}/>
                <img title='Prolonger la droite en segment' src={prolongation} style={iconStyle2} alt='prolongation'/>
              </div>}

              {this.props.label && <div>
                <input type='checkbox' onChange={this.props.toggleTextLabel} defaultChecked={this.props.tool.textVisibility} style={{cursor : 'pointer'}}/>
                <span title='Afficher la valeur de la mesure' style={{fontSize : '0.8em', backgroundColor : 'purple', borderRadius : 4, color : 'white', padding:3, cursor : 'default'}}>
                  {this.props.tool.name=== 'rule'? this.props.tool.label : this.props.tool.angle}
                </span>
              </div>}

              <div>
                <input type='checkbox' onChange={this.toggleMarker} defaultChecked={this.props.tool.markerVisibility} style={{cursor : 'pointer'}}/>
                <img title='Afficher les points de contrôle' src={lineIcon} style={iconStyle2} alt='marker'/>
              </div>

              <div>
                <input type='checkbox' onChange={this.props.toggleVisibility} defaultChecked={this.props.tool.visibility} style={{cursor : 'pointer'}}/>
                <img title='Masquer' src={eye} style={{position : 'relative', top : 5, width : 20}} alt='visibility'/>
              </div>

            </div>

          </div>}
      
        </div>

    )

  }

}


export {Tools}