import React from 'react'
import camera from '../icons/camera.svg'
import Peer from 'peerjs'
import socket from '../modules/socket'
import resizeImage from '../functions/resizeImage'
import Loader from './loader'





class PhoneExport extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)
     this.inputRef = React.createRef()

    this.state = {
      //  active : false
        idPeer : '',
        loader : false
    }

  }

  componentDidMount = () => {

    socket.on('fetchPeerId', this.activeCamera)
     this.reader = new FileReader()
     this.reader.addEventListener('load', this.loadImageFromFile)
  }

  componentWillUnmount = () => {
    socket.off('fetchPeerId', this.activeCamera)
    this.peer.disconnect(this.state.idPeer)
  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

  activeCamera=id=>{
    this.setState({idPeer : id})
  }


  loadFile = async e=>{

    let file = e.target.files
    this.reader.readAsDataURL(file[0])
  
  }


  p2pConnexion = ()=>{

    return new Promise((resolve, reject)=>{

      this.peer = new Peer()

      this.peer.on('open',id=>{
        console.log(id)

        this.conn = this.peer.connect(this.state.idPeer)
        this.conn.on('open', ()=>{
          console.log('opened connexion')
          resolve(id)
        })
      })


    })

  }

  sendData = dataArray=>{

    //console.log(data)
    // this.setState({loader : true})

    dataArray.map((chunck, index)=>{
     // console.log(JSON.stringify({chunck : chunck, index : index, total : dataArray.length}))
      this.conn.send(JSON.stringify({chunck : chunck, index : index, total : dataArray.length}))
      if (index===dataArray.length-1) this.setState({loader : false}) 
      //: this.setState({progress : false})
      return true
      
    })

  

  }

  loadImageFromFile = async ()=>{
      this.setState({loader : true})
     let loadedImage = this.reader.result
     this.resizePicture = await resizeImage(loadedImage)
     //console.log(this.resizePicture.length, loadedImage.length)


     this.p2pConnexion().then(()=>this.sendData(this.sliceDataURL(this.resizePicture)))


  }

  takePicture = ()=>{
    this.inputRef.current.click()
  }

  sliceDataURL = (dataURL, chunckSize=1000) =>{

    let dataURLArray = []

    for (let i = 0; i <= dataURL.length; i=i+chunckSize) {
      let chunck = dataURL.slice(i,i+chunckSize)
      dataURLArray.push(chunck)
    }

    //console.log(dataURLArray.map(string=>string.length))
    return dataURLArray

  }

  render = () => {

    const divStyle = {

    }

    const cameraStyle = {
      position : 'fixed',
     // bottom : 50,
      width : 70
    }

     const backgroundStyle = {
      backgroundColor : 'rgba(255, 255, 255, 0.8)',
      position : 'fixed', 
      width : '100vw', height : '100vh',
      top : 0, left : 0,
      zIndex : 10,
      display : 'flex', justifyContent : 'center', alignItems : 'center',

    }

   
    return (
      
      <div style={divStyle}>

      {this.state.loader && <Loader/>}


     {this.state.idPeer && !this.state.loader && <div style = {backgroundStyle}>
              <input accept="image/*" style={{display : 'none'}} ref = {this.inputRef} type='file' onChange={this.loadFile}/>
              <img src={camera} style={cameraStyle} onClick={this.takePicture} alt='camera'/>
           </div>}

   
        

       

      </div>

    )

  }

}


export default PhoneExport