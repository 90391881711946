
import React from 'react'
import hashParse from '../functions/hashParse'
import face from '../images/faceImage.svg'
import smile from '../images/smileImage.svg'
import side from '../images/sideImage.svg'
import sideLeft from '../images/sideImageLeft.svg'
import spreader from '../images/spreaderImage.svg'
import sideImageLeftWithMarkers from '../images/sideImageLeftWithMarkers.svg'
import sideImageWithMarkers from '../images/sideImageWithMarkers.svg'
import smileImageWithMarkers from '../images/smileImageWithMarkers.svg'
import spreaderImageWithMarkers from '../images/spreaderImageWithMarkers.svg'
import teethOcclusalMax from '../images/toothOcclMax.svg'
import teethOcclusalMaxWithMarkers from '../images/toothOcclMaxWithMarkers.svg'
import teethOcclusalMand from '../images/toothOcclMand.svg'
import teethOcclusalMandWithMarkers from '../images/toothOcclMandWithMarkers.svg'
import teethLeftSide from '../images/toothLeftSide.svg'
import teethLeftSideWithMarkers from '../images/toothLeftSideWithMarkers.svg'
import teethRightSide from '../images/toothRightSide.svg'
import teethRightSideWithMarkers from '../images/toothRightSideWithMarkers.svg'
import teethFaceOpen from '../images/toothFaceOpen.svg'
import teethFaceOpenWithMarkers from '../images/toothFaceOpenWithMarkers.svg'
import teethFaceShut from '../images/toothFaceShut.svg'
import teethFaceShutWithMarkers from '../images/toothFaceShutWithMarkers.svg'
import plasterOcclusalMax from '../images/plasterOcclMax.svg'
import plasterOcclusalMaxWithMarkers from '../images/plasterOcclMaxWithMarkers.svg'
import plasterOcclusalMand from '../images/plasterOcclMand.svg'
import plasterOcclusalMandWithMarkers from '../images/plasterOcclMandWithMarkers.svg'
import plasterLeftSide from '../images/plasterLeftSide.svg'
import plasterLeftSideWithMarkers from '../images/plasterLeftSideWithMarkers.svg'
import plasterRightSide from '../images/plasterRightSide.svg'
import plasterRightSideWithMarkers from '../images/plasterRightSideWithMarkers.svg'
import plasterFace from '../images/plasterFace.svg'
import plasterFaceWithMarkers from '../images/plasterFaceWithMarkers.svg'

import sideTeleradio from '../images/cephalo.svg'
import orthopanto from '../images/orthopanto.svg'
import sideTeleradioWithMarkers from '../images/cephaloWithMarkers.svg'
import orthopantoWithMarkers from '../images/orthopantoWithMarkers.svg'


import WrapComponent from '../contexts/wrap'
import {LandMarksFaceObject, LandMarksSpreaderFaceObject, LandMarksSideObject, LandMarksTeethFaceObject, LandMarksTeethOpenFaceObject, LandMarksTeethSideObject, LandMarksTeethOcclObject, LandMarksOrthopantoObject, LandMarksCephaloObject} from '../objects/landmarksDataObjects'
import {placePictureDataFace, placePictureDataSpreader, placePictureDataLeftSide, placePictureDataRightSide, placePictureDataTeethFaceShut, placePictureDataTeethFaceOpen, placePictureDataTeethOcclMaxillar, placePictureDataTeethOcclMandibular, placePictureDataTeethLeftSide, placePictureDataTeethRightSide, placePictureDataOrthpanto, placePictureDataCephalo} from '../functions/placePictureData'

import socket from '../modules/socket'
import Loader from './loader'

import guideImage from '../images/guideClutter.svg'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader2 from 'react-loader-spinner'







const picturesOptions = {
  
  facePictureWithSmile : {
    pictureType : 'facePictureWithSmile', 
    markersNumber : 3,
    placePictureData : placePictureDataFace,
    landmarks : LandMarksFaceObject,
    neutralPicture : smile,
    imageNavigation : smileImageWithMarkers
  },

  leftSidePicture : {
    pictureType : 'leftSidePicture',
    markersNumber : 4,
    placePictureData : placePictureDataLeftSide,
    landmarks : LandMarksSideObject,
    neutralPicture : sideLeft,
    imageNavigation : sideImageLeftWithMarkers
  },

  facePicture : {
    pictureType : 'facePicture',
    markersNumber : 3,
    placePictureData : placePictureDataFace,
    landmarks : LandMarksFaceObject,
    neutralPicture : face
  },

  rightSidePicture : {
    pictureType : 'rightSidePicture',
    markersNumber : 4,
    placePictureData : placePictureDataRightSide,
    landmarks : LandMarksSideObject,
    neutralPicture : side,
    imageNavigation : sideImageWithMarkers
  },

  spreaderFacePicture : {
    pictureType : 'spreaderFacePicture',
    markersNumber : 5,
    placePictureData : placePictureDataSpreader,
    landmarks : LandMarksSpreaderFaceObject,
    neutralPicture : spreader,
    imageNavigation : spreaderImageWithMarkers
  },

  teethPictureOcclusalMaxillar : {
    pictureType : 'teethPictureOcclusalMaxillar',
    markersNumber : 5,
    placePictureData : placePictureDataTeethOcclMaxillar,
    landmarks : LandMarksTeethOcclObject,
    neutralPicture : teethOcclusalMax,
    imageNavigation : teethOcclusalMaxWithMarkers    
  },

  teethPictureLeftSide : {
    pictureType : 'teethPictureLeftSide',
    markersNumber : 3,
    placePictureData : placePictureDataTeethLeftSide,
    landmarks : LandMarksTeethSideObject,
    neutralPicture : teethLeftSide,
    imageNavigation : teethLeftSideWithMarkers
  },

  teethPictureFaceClose : {
    pictureType : 'teethPictureFaceClose',
    markersNumber : 3,
    placePictureData : placePictureDataTeethFaceShut,
    landmarks : LandMarksTeethFaceObject,
    neutralPicture : teethFaceShut,
    imageNavigation : teethFaceShutWithMarkers
  },

  teethPictureFaceOpen : {
      pictureType : 'teethPictureFaceOpen',
      markersNumber : 6,
      placePictureData : placePictureDataTeethFaceOpen,
      landmarks : LandMarksTeethOpenFaceObject,
      neutralPicture : teethFaceOpen,
      imageNavigation : teethFaceOpenWithMarkers
  },

  teethPictureRightSide : {
      pictureType : 'teethPictureRightSide',
      markersNumber : 3,
      placePictureData : placePictureDataTeethRightSide,
      landmarks : LandMarksTeethSideObject,
      neutralPicture : teethRightSide,
      imageNavigation : teethRightSideWithMarkers
  },

  teethPictureOcclusalMandibular : {
      pictureType : 'teethPictureOcclusalMandibular',
      markersNumber : 5,
      placePictureData : placePictureDataTeethOcclMandibular,
      landmarks : LandMarksTeethOcclObject,
      neutralPicture : teethOcclusalMand,
      imageNavigation : teethOcclusalMandWithMarkers
  },

  plasterPictureOcclusalMaxillar : {
      pictureType : 'plasterPictureOcclusalMaxillar',
      markersNumber : 5,
      placePictureData : placePictureDataTeethOcclMaxillar,
      landmarks : LandMarksTeethOcclObject,
      neutralPicture : plasterOcclusalMax,
      imageNavigation : plasterOcclusalMaxWithMarkers
  },

  plasterPictureLeftSide : {
      pictureType : 'plasterPictureLeftSide',
      markersNumber : 3,
      placePictureData : placePictureDataTeethLeftSide,
      landmarks : LandMarksTeethSideObject,
      neutralPicture : plasterLeftSide,
      imageNavigation : plasterLeftSideWithMarkers
  },

  plasterPictureFace : {
      pictureType : 'plasterPictureFace',
      markersNumber : 3,
      placePictureData : placePictureDataTeethFaceShut,
      landmarks : LandMarksTeethFaceObject,
      neutralPicture : plasterFace,
      imageNavigation : plasterFaceWithMarkers
  },

  plasterPictureRightSide : {
      pictureType : 'plasterPictureRightSide',
      markersNumber : 3,
      placePictureData : placePictureDataTeethRightSide,
      landmarks : LandMarksTeethSideObject,
      neutralPicture : plasterRightSide,
      imageNavigation : plasterRightSideWithMarkers
  },

plasterPictureOcclusalMandibular : {
      pictureType : 'plasterPictureOcclusalMandibular',
      markersNumber : 5,
      placePictureData : placePictureDataTeethOcclMandibular,
      landmarks : LandMarksTeethOcclObject,
      neutralPicture : plasterOcclusalMand,
      imageNavigation : plasterOcclusalMandWithMarkers
},

orthopanto : {
      pictureType : 'orthopanto',
      markersNumber : 6,
      placePictureData : placePictureDataOrthpanto,
      landmarks : LandMarksOrthopantoObject,
      neutralPicture : orthopanto,
      imageNavigation : orthopantoWithMarkers
},

sideTeleradio : {
      pictureType : 'sideTeleradio',
      markersNumber : 2,
      placePictureData : placePictureDataCephalo,
      landmarks : LandMarksCephaloObject,
      neutralPicture : sideTeleradio,
      imageNavigation : sideTeleradioWithMarkers,
      cephalo : true
},

clutter : {
  clutter : true,
  neutralPicture : guideImage
}

 
}

      



class File extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {

      page : Number(hashParse()[1].hashVar.page)
      
    }

  }

  componentDidMount = () => {

    window.addEventListener('hashchange', this.hashPage)
    
    this.props.context.updateFileData(this.props.fileData)






  

  }

  componentWillUnmount = () => {

window.removeEventListener('hashchange', this.hashPage)
this.props.context.updateFileData({})
  }

   componentDidUpdate = async (prevProps)=> {


    
  }

  hashPage = e=>{
let page = hashParse()[1].hashVar.page
this.setState({page : Number(page)})

  }

   exitPage = e=>{
    window.location.hash = hashParse()[0].hashPage
  }

  render = () => {

    const pageStyle = {
    //  border : 'solid green',
       top : 0, left : 0, width : '100%', height : '100vh',  overflowY : 'auto',
       display : 'flex', justifyContent : 'space-around', 
       //alignItems : 'center'
    }

    
    return (

      <div style={pageStyle}>

      

        {this.props.context.fileData._id && this.state.page===1 && <Page 
            number={1}
            // switchPictures = {['facePicture','facePictureWithSmile','leftSidePicture','rightSidePicture','spreaderFacePicture']}

            picturesArray ={[
                picturesOptions.facePicture, 
                picturesOptions.facePictureWithSmile, 
                picturesOptions.spreaderFacePicture,
                picturesOptions.leftSidePicture, 
                picturesOptions.rightSidePicture
            ]}


        />}

        {this.props.context.fileData._id && this.state.page===2 && <Page 
            number={2}
           //> switchPictures = {['teethPictureOcclusalMaxillar','teethPictureLeftSide', 'teethPictureFaceClose', 'teethPictureFaceOpen', 'teethPictureRightSide', 'teethPictureOcclusalMandibular']}
            
            picturesArray ={[
                picturesOptions.teethPictureOcclusalMaxillar,
                picturesOptions.teethPictureLeftSide,
                picturesOptions.teethPictureFaceClose, 
                picturesOptions.teethPictureFaceOpen, 
                picturesOptions.teethPictureRightSide,
                picturesOptions.teethPictureOcclusalMandibular
            ]}
          
        />}

        {this.props.context.fileData._id && this.state.page===3 && <Page 
            number={3}
           //> switchPictures = {['plasterPictureOcclusalMaxillar','plasterPictureLeftSide', 'plasterPictureFace', 'plasterPictureRightSide', 'plasterPictureOcclusalMandibular']}

            picturesArray ={[
                picturesOptions.plasterPictureOcclusalMaxillar,
                picturesOptions.plasterPictureLeftSide, 
                picturesOptions.plasterPictureFace, 
                picturesOptions.plasterPictureRightSide,
                picturesOptions.plasterPictureOcclusalMandibular
            ]}
        />}

        {this.props.context.fileData._id && this.state.page===4 && <Page 
            number={4}
           //> switchPictures = {['orthopanto', 'sideTeleradio']}

            picturesArray ={[
                picturesOptions.orthopanto, 
                picturesOptions.sideTeleradio
            ]}
        />}

        {this.props.context.fileData._id && this.state.page===5 && <Page 
            number={5}
            picturesArray ={[
              picturesOptions.clutter
            ]}
        />}

      </div>
      


    )

  }

}



class Page extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.boxRef = React.createRef()
    this.refSlider = React.createRef()

    this.state = {
      size : 1,
      picturesArray : this.props.picturesArray,
      loader : false
    }

  }

  componentDidMount = () => {

    

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

 
  

  render = () => {

    const divStyle = {    
      position : 'absolute',  
      top : 60, bottom : 0,
      left : 0, width : '100vw',
      //border : 'solid red', 
      textAlign : 'center',
     // width : this.state.picturesArray.length===3? '70%' : '90%', 
    //  height : '90%',
     // display : 'flex', justifyContent : 'space-around', alignItems : 'center', flexDirection : 'column',
      overflowY : 'auto'

    }

//     const rowStyle = {
//       width : '100%', 
//       //height : 600,
//       display : 'flex', justifyContent : 'space-around', alignItems : 'center'
// 
//     }

//     let pictureSize = 170
//     if (this.state.picturesArray.length===3) pictureSize = 170
//     else if (this.state.picturesArray[0].length>2) pictureSize = 300
//     else if (this.state.picturesArray[0].length===2) pictureSize = 400
//     else if (this.state.picturesArray[0].length===1) pictureSize = 500
// 
//  const stylePicture = {
//     width : pictureSize, 
//     height : pictureSize, 
//     position : 'relative',
//     borderRadius : 20,
//     border : 'solid 2px',
//     borderRight : 'solid 1px', borderBottom : 'solid 1px',
//     borderColor : 'purple', 
// }

//if (this.boxRef.current) console.log(this.boxRef.current.getBoundingClientRect())



    return (
     
      <div style={divStyle} ref={this.boxRef}>

        {this.state.loader && <Loader/>}

        {/* {this.props.picturesArray.length>1 && <SizeSlider  */}
        {/*     ref = {this.refSlider} */}
        {/*     changeSize={this.changeSize}  */}
        {/*     picturesArray = {this.props.picturesArray}  */}
        {/*     save={this.saveView}  */}
        {/*     idFile={this.props.context.fileData._id} */}
        {/* />} */}

        {this.state.picturesArray.map((pictures, index)=>

          <Preview
            key={index}
            pictureType={pictures.pictureType}
            neutralPicture = {pictures.neutralPicture}
            clutter = {pictures.clutter}

            />
        
        )}

      </div>

    )

  }

}



class Preview extends React.Component {

   static defaultProps = {
     // neutralPicture : guideImage
  }

  constructor(props) {

    super(props)

    this.state = {
      preview : false,
      loader : true
    }

  }

  componentDidMount = () => {
     
    this.readerPreview = new FileReader()
   
    this.readerPreview.addEventListener('load', this.loadImagePreview)
   
    if (!this.props.clutter) {
      this.fetchImagePreview()
      socket.on(this.props.pictureType+'Preview'+this.props.context.fileData._id, this.fetchImagePreview)
    }
    else {
      this.fetchImageClutterPreview()
      socket.on('clutterPreview'+this.props.context.fileData._id, this.fetchImageClutterPreview)
    }

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }


   fetchImagePreview = ()=>{

    console.log('test')

    this.setState({imageLoader : true})

    socket.emit('fetchImagePreview', this.props.context.fileData._id,this.props.pictureType, this.props.context.patientData._id, (data)=>{
      this.setState({loader : false})

      if (data.success) {
        let blob = new Blob([data.data], { type: 'image/jpeg'})
        
        //let corrections = JSON.parse(data.data.corrections)

        if (blob) {
          try {this.readerPreview.readAsDataURL(blob)}
          catch(e){console.log(e)}
         // console.log(blob)
          //this.updateCorrections(corrections)
        }

      }

    })

  }


   fetchImageClutterPreview = ()=>{

    this.setState({imageLoader : true})

    socket.emit('fetchClutterPreview', this.props.context.fileData._id, this.props.context.patientData._id, (data)=>{
      this.setState({loader : false})

      if (data.success && data.data) {
        let blob = new Blob([data.data], { type: 'image/jpeg'})
        if (blob)  this.readerPreview.readAsDataURL(blob)  
      }


    })

  }
 

  loadImagePreview = ()=>{
   // alert('prev')
   // console.log('test')
   let loadedImage = this.readerPreview.result
  // console.log(loadedImage)
   this.setState({preview :  loadedImage})
  }


  render = () => {

    let maxSize = window.innerHeight>=window.innerWidth ? window.innerWidth : window.innerHeight

   const divStyle = {
    //border : 'solid',
    position : 'relative'
   }

    const pictureStyle = {
      width : 0.8*maxSize, height : 0.8*maxSize,
     // border : 'solid', borderRadius : 10,
        border : 'solid 2px',
      borderRight : 'solid 1px', borderBottom : 'solid 1px',
      borderColor : 'purple', borderRadius : 10,
      marginTop : 15, marginBottom : 15
    }

    const loaderStyle = {
      position : 'absolute',
      top : (0.8*maxSize)/2 + 7.5,
      left : (0.8*maxSize)/2 + 7.5
    }

    return (

      <div style={divStyle}>

        {this.state.loader && <Loader2 style={loaderStyle} type="Oval" color="purple" height={50} width={50} radius = {15} />}

        <img src={this.state.preview? this.state.preview : this.props.neutralPicture} style={pictureStyle} alt='preview'/>

      </div>
      

    )

  }

}




Page = WrapComponent(Page)
Preview = WrapComponent(Preview)

export default WrapComponent(File)