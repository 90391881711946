import React from 'react'
import IMask from 'imask'
import trash from '../icons/trash.svg'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

//import {VelocityTransitionGroup} from 'velocity-react'
  //require('velocity-animate/velocity.ui');
//import {CSSTransitionGroup} from 'react-transition-group'


class Input extends React.Component {

   static defaultProps = {
      label : 'Label',
      defaultValue : '',
      hide : false,
      focusColor : 'purple',
      maskOptions : {mask : /[\w \W]/},
      formatFunction : input=>input,
      validFunction : input=>input!=='',
      value : input=>input,
      onFocus : focus=>focus,
      onBlur : blur => blur,
      localStorage : false, sessionStorage : false,
      style : {div : {}, input : {}, valid : {}, loader : {}, delete : {}, label : {}},
      check : true
    }

  constructor(props) {

    super(props)

    this.inputRef = React.createRef()

    this.state = {
      focus : false,
      value : '',
      unmaskedValue : '',
      validation : false,
     // loading : this.props.loading
    }

  }

  componentDidMount = ()=> {

   // console.log('refcomponent',this.inputRef.current)

    let element = this.inputRef.current

    let maskOptions = this.props.maskOptions
    
    this.mask = IMask(element, maskOptions)

    this.mask.on('accept',this.handleChange)

    this.mask.value = this.props.defaultValue
    //this.inputRef.current.blur()

    if (!this.props.defaultValue && this.props.localStorage && window.localStorage.getItem(this.props.localStorage)) this.mask.value = window.localStorage.getItem(this.props.localStorage)
    else if (!this.props.defaultValue && this.props.sessionStorage && window.sessionStorage.getItem(this.props.sessionStorage)) this.mask.value = window.sessionStorage.getItem(this.props.sessionStorage)


  }

  componentWillUnmount = ()=> {

    this.mask.off('accept',this.handleChange)

  }

  componentDidUpdate = (prevProps) => {
  
    if (this.props.updateValue !== prevProps.updateValue) this.handleUpdateValue(this.props.updateValue)
    if (this.props.update !== prevProps.update) this.handleChange() 
   // if (this.props.validFunction !== prevProps.validFunction) this.handleChange() 
    //if (this.props.update !== prevProps.update) this.handleChange() 

  }


  handleChange = async e=>{
    
    this.mask.value = this.props.formatFunction(this.mask.value)

    this.setState({
      value : this.mask.value, 
      unmaskedValue : this.mask.unmaskedValue,
      validation : await this.props.validFunction(this.mask.value)
    }, this.liftState)

    this.props.localStorage && window.localStorage.setItem(this.props.localStorage,this.mask.value)
    this.props.sessionStorage && window.sessionStorage.setItem(this.props.sessionStorage,this.mask.value)

    
    
  }

  handleFocus = e=>this.setState({focus : true},this.props.onFocus)

  handleBlur = e=>this.setState({focus : false}, this.props.onBlur)

  erase = e=>{
    this.mask.value=''
    this.inputRef.current.focus()
    this.setState({value : '', unmaskedValue : '', validation : false},this.liftState)
    
  }

  select = ()=>this.inputRef.current.focus()

  handleUpdateValue = value=>{

    if (value) {
      this.mask.value = value     
    }

  }

  liftState = ()=>{
    this.props.value({
      maskedValue : this.mask.value,
      unmaskedValue : this.mask.unmaskedValue,
      validation : this.state.validation,
    })
  }


  render = ()=> {

    const divStyle = Object.assign({},{
      //border : 'solid',
      width : 330,
      position : 'relative',
      cursor : this.props.lock? 'not-allowed' : 'default',
      opacity : this.props.lock? 0.4 : 1,
      transition : 'opacity 0.2s',
      textAlign : 'left'
    },this.props.style.div)

    const inputStyle = Object.assign({},{
      width : 200,
      fontSize : '1.1em',
      padding : 10, margin : 10, paddingRight : 30,
      border : 'solid 2px',
      borderLeft : 'solid 1px', borderTop : 'solid 1px',
      borderColor : this.state.focus ? `${this.props.focusColor}` : 'grey', 
      borderRadius : 5,
      outline: 'none',
      transition : 'all 0.2s',
      // cursor : this.state.lock? 'not-allowed' : 'text',
      pointerEvents: this.props.lock? 'none' : 'auto',
      // color: 'transparent',
      // textShadow: '0 0 0 gray'
    },this.props.style.input)


    const labelStyle = Object.assign({},{
      pointerEvents : 'none',
      backgroundColor : 'white',
      position : 'absolute',
      fontSize : this.state.focus || this.state.value!=='' ? '0.9em' : '1.1em',
      color : this.state.focus ? this.props.focusColor : 'grey',
      top : this.state.focus || this.state.value!=='' ? 2 : 20, paddingRight : 5,
      left : 20,
      transition : 'all 0.2s'
    },this.props.style.label)

    const deleteStyle = Object.assign({},{
      position : 'absolute',
      top : 22, left : 215,
      width : 20,
      pointerEvents: this.props.lock? 'none' : 'auto',
      cursor : 'pointer'
    },this.props.style.delete)

    const validStyle = Object.assign({},{
      pointerEvents : 'none',
      position : 'absolute',
      top : 15, left : 260,
      fontSize : '1.4em',
      color : this.state.validation ? 'green' : 'red'
    },this.props.style.valid)

    const loaderStyle = Object.assign({},{
      pointerEvents : 'none',
      position : 'absolute',
      top : 15, left : -25,
    },this.props.style.loader)

    return (

      <div style = {divStyle}>

        <input 
          type = {this.props.hide ? 'password' : 'texte'}
          style = {inputStyle} 
          onFocus = {this.handleFocus} 
          onBlur = {this.handleBlur}
          onChange = {this.handleChange}
          ref = {this.inputRef}
          autoComplete = "new-password"
        />

        <span style={labelStyle}>{this.props.label}</span>

        {this.state.value!=='' && <img style = {deleteStyle} src = {trash} onClick = {this.erase} alt='delete'/>}
         
        {this.state.value!=='' && this.props.check && <span style = {validStyle}>{this.state.validation ? '✓' : '✗'}</span>}

        {this.props.loading && <div style={loaderStyle} >
          <Loader type="Oval" color="purple" height={30} width={30} radius = {15} />
        </div>}
       

      </div>

    )

  }

}



export default Input