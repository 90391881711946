import React from 'react'
import trash from '../icons/trash.svg'


class InputSearch extends React.Component {

  static defaultProps = {
    label : 'label',
    handleChange : value=>value
  }

  constructor(props) {

    super(props)
    this.inputRef = React.createRef()

    this.state = {
      focus : false,
      value : ''
    }

  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

  handleFocus = ()=>this.setState({focus : true})

  handleBlur = ()=>this.setState({focus : false})

  handleChange = e=>{
    this.setState({value : e.target.value})
    this.props.handleChange(e.target.value)
  }

  erase = ()=>{
    this.setState({value : ''})
    this.inputRef.current.focus()
    this.props.handleChange('')
  }

  render = () => {

    const divStyle={
      position : 'relative'
     // border : 'solid'

    }

    const inputStyle = {
     // width : 200,
     // fontSize : '1.1em',
      padding : 5, paddingRight : 30,
      margin : 10, 
      border : 'solid 2px',
      borderLeft : 'solid 1px', borderTop : 'solid 1px',
      borderColor : this.state.focus ? `purple` : 'grey', 
      borderRadius : 5,
      outline: 'none',
      transition : 'all 0.2s',
      // cursor : this.state.lock? 'not-allowed' : 'text',
     // pointerEvents: this.props.lock? 'none' : 'auto'
      // color: 'transparent',
      // textShadow: '0 0 0 gray'
   }

    const labelStyle = {
      color : 'grey',
      position : 'absolute', top : 13, left : 15,
      pointerEvents : 'none',
      transformOrigin: 'left center',
      transform : this.state.focus || this.state.value ? 'scaleX(0)' : '',
      transition : 'all 0.2s'
    }

    const deleteStyle = {
      position : 'absolute',
      width : 15,
      top : 16, left : 180,
      cursor : 'pointer'
    }

    return (

      <div style = {divStyle}>

      <div style={labelStyle}>
        {this.props.label}
      </div>

      {this.state.value && <img alt='delete' src = {trash} style = {deleteStyle} onClick={this.erase}/>}



      <input 
        ref = {this.inputRef}
        style={inputStyle} 
        onFocus = {this.handleFocus}
        onBlur = {this.handleBlur}
        value={this.state.value}
        onChange = {this.handleChange}
      />

      </div>

    )

  }

}


export default InputSearch