import React from 'react'
import Input from './customInput'
import IMask from 'imask'
import yearsOld from '../functions/yearsOld'



 var options = {
    mask: Date,
    pattern: 'd/m/Y',
    parse: str=>{
      let dmy = str.split('/')
      let date = new Date(dmy[2], dmy[1]-1, dmy[0])
      //this.setAge(date)
      return date
    },
    format: date => {
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()

      if (day < 10) day = '0' + day
      if (month < 10) month = '0' + month

      return [day, month, year].join('/')
    },

    lazy: true,
    overwrite: true,
    autofix: true,
    blocks: {
      d: {mask: IMask.MaskedRange, placeholderChar: 'j', from: 1, to: 31, maxLength: 2},
      m: {mask: IMask.MaskedRange, placeholderChar: 'm', from: 1, to: 12, maxLength: 2},
      Y: {mask: IMask.MaskedRange, placeholderChar: 'a', from: 1900, to: 9999, maxLength: 4}
    },
    max : new Date()

  }



class BirthInput extends React.Component {

  static defaultProps = {
    value : value=>value,
    defaultValue : ''
  }

  constructor(props) {

    super(props)

    this.state = {
      yearsOld : false
    }

  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

  returnValue = value=>{
   // console.log(value)
    let returnValue = value

    if (value.validation) {
      let birthDate = options.parse(value.maskedValue)
      Object.assign(returnValue,{date : birthDate, age : yearsOld(birthDate)})
      this.setState({yearsOld : yearsOld(birthDate)})
    }
    else this.setState({yearsOld : false})

    this.props.value(returnValue)
  }



  

  render = () => {

    return (
      
      <div>

      <Input
        label = {`Date de naissance${this.state.yearsOld ? ` (${this.state.yearsOld})` : ''}`}
        maskOptions = {options}
        value = {this.returnValue}
        validFunction = {input=>/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i.test(input)}
        lock = {this.props.lock}
        updateValue = {this.props.updateValue}
        localStorage = {this.props.localStorage}
        sessionStorage = {this.props.sessionStorage}
       
      />

      </div>

    )

  }

}


export default BirthInput