import React from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'


const LoaderComponent = props=>{

  const divStyle = {
    //border : 'solid',
    top : 0, left : 0, cursor : 'wait',
    position : 'fixed',
    display : 'flex', justifyContent : 'center', alignItems : 'center',
    width : '100vw', height : '100vh',
    backgroundColor : 'rgba(255,255,255,0.6)',
    zIndex : 10
  }

  // const loaderStyle = {
  //     pointerEvents : 'none',
  //   }

  return (

    <div style = {divStyle}>

      <Loader type="Oval" color="purple" height={50} width={50} radius = {15} />

    </div>

  )

}

export default LoaderComponent


// class Loader extends React.Component {
// 
//   constructor(props) {
// 
//     super(props)
// 
//     this.state = {
//       date : new Date()
//     }
// 
//   }
// 
//   componentDidMount = () => {
// 
//   }
// 
//   componentWillUnmount = () => {
// 
//   }
// 
//   render = () => {
// 
//     return (
//       'caca'
//     )
// 
//   }
// 
// }