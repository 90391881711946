import  {markersClassificationPictureFace, 
  		markersClassificationPictureFaceSpreader,
  		markersClassificationPictureLeftSide,
  		markersClassificationPictureRightSide,
  		markersClassificationTeethFaceShut,
  		markersClassificationTeethFaceOpen,
  		markersClassificationTeethLeftSide,
  		markersClassificationTeethRightSide,
  		markersClassificationTeethOccMaxillar,
  		markersClassificationTeethOccMandibular,
  		markersClassificationOrthopanto,
      markersClassificationCephalo} from './markersClassification'

import {pointsDistance, pointsAngle} from '../functions/fabricFunctions/fabricFunctions'
import socket from '../modules/socket'
import viewer from '../functions/pictureViewerFunction'
import {LandMarksSideObject} from '../objects/landmarksDataObjects'




   
const placePictureDataFace= coords=>{

  let markersClassification = markersClassificationPictureFace(coords)

  return (

    {
      markersClassification : markersClassification,
      
      updateSize : {
        origin : pointsDistance(markersClassification.leftEye,markersClassification.rightEye),
        target : 400
      },
      
      updatePlacement : {
        origin : { x : markersClassification.rightEye.x, y : markersClassification.rightEye.y}, 
        target : {x : 700, y : 900}
      },
      updateRotate : {
        pivot : {x : 700, y : 900}, 
        angle : pointsAngle(markersClassification.rightEye,markersClassification.leftEye)
      }

    }

  )

}




const placePictureDataSpreader= coords=>{

  let markersClassification = markersClassificationPictureFaceSpreader(coords)

  return (

    {
      markersClassification : markersClassification,
      
      updateSize : {
        origin : pointsDistance(markersClassification.leftEye,markersClassification.rightEye),
        target : 400
      },
      
      updatePlacement : {
        origin : { x : markersClassification.rightEye.x, y : markersClassification.rightEye.y}, 
        target : {x : 700, y : 900}
      },
      updateRotate : {
        pivot : {x : 700, y : 900}, 
        angle : pointsAngle(markersClassification.rightEye,markersClassification.leftEye)
      }

    }

  )

}



const placePictureDataLeftSide = async (coords, id)=>{

  let markersClassification = markersClassificationPictureLeftSide(coords)
  let noseHeight = await fetchFaceNoseDistance(id)
 
  return (

    {
      markersClassification : markersClassification,
      
      updateSize : {
        origin : markersClassification.nose.y - markersClassification.eye.y,
        target : noseHeight || 200
      },
      
      updatePlacement : {
        origin : { x : markersClassification.eye.x, y : markersClassification.eye.y}, 
        target : {x : 400, y : 900}
      },
      updateRotate : {
        pivot : {x : 400, y : 900}, 
        angle : pointsAngle(markersClassification.orbit,markersClassification.ear)
      }

    }

  )

}



const placePictureDataRightSide = async (coords, id)=>{

  let markersClassification = markersClassificationPictureRightSide(coords)
  let noseHeight = await fetchFaceNoseDistance(id)
 
  return (

    {
      markersClassification : markersClassification,
      
      updateSize : {
        origin : markersClassification.nose.y - markersClassification.eye.y,
        target : noseHeight || 200
      },
      
      updatePlacement : {
        origin : { x : markersClassification.eye.x, y : markersClassification.eye.y}, 
        target : {x : 1400, y : 900}
      },
      updateRotate : {
        pivot : {x : 1400, y : 900}, 
        angle : pointsAngle(markersClassification.ear, markersClassification.orbit)
      }

    }

  )

}





const placePictureDataTeethFaceShut = async (coords, id)=>{

  let markersClassification = markersClassificationTeethFaceShut(coords)
  let occlusalAngle = await fetchOcclusalAngle(id)
 
  return (

    {
      markersClassification : markersClassification,
      
      updateSize : {
        origin : pointsDistance(markersClassification.rightCanin, markersClassification.leftCanin),
        target : 800
      },
      
      updatePlacement : {
        origin : {x : markersClassification.middle.x, y : markersClassification.middle.y}, 
        target : {x : 900, y : 900}
      },
      updateRotate : {
        pivot : {x : 900, y : 900}, 
        angle : pointsAngle(markersClassification.rightCanin, markersClassification.leftCanin)-occlusalAngle
      }

    }

  )

}



const placePictureDataTeethFaceOpen = async (coords, id)=>{

  let markersClassification = markersClassificationTeethFaceOpen(coords)
  let occlusalAngle = await fetchOcclusalAngle(id)
 
  return (

    {
      markersClassification : markersClassification,
      
      updateSize : {
        origin : pointsDistance(markersClassification.rightCanin, markersClassification.leftCanin),
        target : 800
      },
      
      updatePlacement : {
        origin : {x : markersClassification.middle.x, y : markersClassification.middle.y}, 
        target : {x : 900, y : 900}
      },
      updateRotate : {
        pivot : {x : 900, y : 900}, 
        angle : pointsAngle(markersClassification.rightCanin, markersClassification.leftCanin)-occlusalAngle
      }

    }

  )

}




const placePictureDataTeethOcclMaxillar = async coords=>{

  let markersClassification = markersClassificationTeethOccMaxillar(coords)

 
  return (

    {
      markersClassification : markersClassification,
      
      updateSize : {
        origin : pointsDistance(markersClassification.rightCanin, markersClassification.leftCanin),
        target : 800
      },
      
      updatePlacement : {
        origin : {x : markersClassification.middle.x, y : markersClassification.middle.y}, 
        target : {x : 900, y : 300}
      },
      updateRotate : {
        pivot : {x : 900, y : 300}, 
        angle : pointsAngle(markersClassification.rightMolar, markersClassification.leftMolar)
      }

    }

  )

}



const placePictureDataTeethOcclMandibular = async (coords, id)=>{

  let markersClassification = markersClassificationTeethOccMandibular(coords)
  let caninDistance = await fetchCaninDistance(id)

 
  return (

    {
      markersClassification : markersClassification,
      
      updateSize : {
        origin : pointsDistance(markersClassification.rightCanin, markersClassification.leftCanin),
        target : caninDistance || 600
      },
      
      updatePlacement : {
        origin : {x : markersClassification.middle.x, y : markersClassification.middle.y}, 
        target : {x : 900, y : 1500}
      },
      updateRotate : {
        pivot : {x : 900, y : 1500}, 
        angle : pointsAngle(markersClassification.rightMolar, markersClassification.leftMolar)
      }

    }

  )

}


const placePictureDataTeethLeftSide = async (coords, id)=>{

  let markersClassification = markersClassificationTeethLeftSide(coords)
  let caninMolarDistance = await fetchCaninMolarDistance(id)

 
  return (

    {
      markersClassification : markersClassification,
      
      updateSize : {
        origin : pointsDistance(markersClassification.canin, markersClassification.molar),
        target : caninMolarDistance.left || 500
      },
      
      updatePlacement : {
        origin : {x : markersClassification.incisor.x, y : markersClassification.incisor.y}, 
        target : {x : 200, y : 900}
      },
      updateRotate : {
        pivot : {x : 200, y : 900}, 
        angle : pointsAngle(markersClassification.canin, markersClassification.molar)
      }

    }

  )

}





const placePictureDataTeethRightSide = async (coords, id)=>{

  let markersClassification = markersClassificationTeethRightSide(coords)
  let caninMolarDistance = await fetchCaninMolarDistance(id)

 
  return (

    {
      markersClassification : markersClassification,
      
      updateSize : {
        origin : pointsDistance(markersClassification.canin, markersClassification.molar),
        target : caninMolarDistance.right || 500
      },
      
      updatePlacement : {
        origin : {x : markersClassification.incisor.x, y : markersClassification.incisor.y}, 
        target : {x : 1600, y : 900}
      },
      updateRotate : {
        pivot : {x : 1600, y : 900}, 
        angle : pointsAngle(markersClassification.molar, markersClassification.canin)
      }

    }

  )

}



const placePictureDataOrthpanto = async (coords, id)=>{

  let markersClassification = markersClassificationOrthopanto(coords)
  let occlusalAngle = await fetchOcclusalAngle(id)

 
  return (

    {
      markersClassification : markersClassification,
      
      updateSize : {
        origin : 1500,
        target : 1800
      },
      
      updatePlacement : {
        origin : {x : markersClassification.middle.x, y : markersClassification.middle.y}, 
        target : {x : 900, y : 900}
      },
      updateRotate : {
        pivot : {x : 900, y : 900}, 
        angle : pointsAngle(markersClassification.rightMolar, markersClassification.leftMolar)-occlusalAngle
      }

    }

  )

}




const placePictureDataCephalo = async (coords,id)=>{

  let markersClassification = markersClassificationCephalo(coords)
  let francfortData = await fetchFranfortData(id)
  

 
  return (

    {
      markersClassification : markersClassification,
      
      updateSize : {
        origin : pointsDistance(markersClassification.ear, markersClassification.orbit),
        target : (francfortData && pointsDistance(francfortData.ear, francfortData.orbit)) || 550
        //target : 550
      },
      
      updatePlacement : {
        origin : {x : markersClassification.orbit.x, y : markersClassification.orbit.y}, 
        target : {x : (francfortData && francfortData.orbit.x) || 1300, y : (francfortData && francfortData.orbit.y) || 1000},
        //target : {x : 1400, y : 950}
      },
      updateRotate : {
        pivot : {x : markersClassification.orbit.x, y : markersClassification.orbit.y}, 
        angle : pointsAngle(markersClassification.ear, markersClassification.orbit)
      }

    }

  )

}




//const hashList = hashParse()

const fetchFaceNoseDistance = (fileId)=>{

    return new Promise ((resolve,reject)=>{

        
      //  let fileId = hashList[1].hashPage.slice(4)
        
        socket.emit('fetchImageMarkers', fileId, 'facePicture', async data=>{
            
            let coords = data.data

            if (coords.length===0) resolve(false)
            
            else {
              let coordsObject = markersClassificationPictureFace(coords)
            let eyesDistance = pointsDistance(coordsObject.leftEye,coordsObject.rightEye)
              let leftEyeNoseDistance = pointsDistance(coordsObject.leftEye,coordsObject.nose)
              let rightEyeNoseDistance = pointsDistance(coordsObject.rightEye,coordsObject.nose)

              let p = (eyesDistance+leftEyeNoseDistance+rightEyeNoseDistance)/2
              let sqrt = Math.sqrt(p*(p-eyesDistance)*(p-leftEyeNoseDistance)*(p-rightEyeNoseDistance))
              let h=(2/eyesDistance)*sqrt

          let scale = eyesDistance/400
              let hCorrected = (h/scale) * 0.8

          resolve(hCorrected)
        

          }

        })

    })

  }




  const fetchOcclusalAngle = (fileId)=>{

    return new Promise ((resolve,reject)=>{

     
        
        socket.emit('fetchImageMarkers', fileId, 'spreaderFacePicture', data=>{
            
            let coords = data.data

            if (coords.length===0) resolve(false)
            
            else {

              let coordsObject = markersClassificationPictureFaceSpreader(coords)
              let angle = pointsAngle(coordsObject.rightTooth, coordsObject.leftTooth)
              resolve(angle)

          }

        })

    })

  }



  const fetchCaninDistance = (fileId)=>{

    return new Promise ((resolve,reject)=>{

      
        
        socket.emit('fetchImageMarkers', fileId, 'teethPictureFaceOpen', data=>{
            
            let coords = data.data

            if (coords.length===0) resolve(false)
            
            else {

              let coordsObject = markersClassificationTeethFaceOpen(coords)
              let caninDistanceMax = pointsDistance(coordsObject.rightCanin, coordsObject.leftCanin)
              let caninDistanceMand = pointsDistance(coordsObject.rightCaninMandibular, coordsObject.leftCaninMandibular)
              let newCaninDistance = (caninDistanceMand*800)/caninDistanceMax
          
              resolve(Math.abs(newCaninDistance))

          }

        })

    })

  }



  const fetchCaninMolarDistance = (fileId)=>{

    return new Promise ((resolve,reject)=>{

     
        
        socket.emit('fetchImageMarkers', fileId, 'plasterPictureOcclusalMaxillar', data=>{
            
            let coords = data.data

            if (coords.length===0) resolve(false)
            
            else {

              let coordsObject = markersClassificationTeethOccMaxillar(coords)
              let caninDistance = pointsDistance(coordsObject.rightCanin, coordsObject.leftCanin)
              let leftCaninMolarDistance = pointsDistance(coordsObject.leftCanin, coordsObject.leftMolar)
              let newLeftCaninMolarDistance = (leftCaninMolarDistance*800)/caninDistance
              let rightCaninMolarDistance = pointsDistance(coordsObject.rightCanin, coordsObject.rightMolar)
              let newRightCaninMolarDistance = (rightCaninMolarDistance*800)/caninDistance
            
              resolve({left : newLeftCaninMolarDistance, right : newRightCaninMolarDistance})

          }

        })

    })

  }



  const fetchFranfortData = (fileId)=>{

    return new Promise ( (resolve,reject)=>{

      
        let pictureReader = new viewer(fileId, 'rightSidePicture', placePictureDataRightSide, LandMarksSideObject)
        
        pictureReader.loadDatas()
          .then(async ()=>{
            await pictureReader.place()
            let markersCoords = await pictureReader.markersCoords()
            resolve(markersCoords)
          })
          .catch(()=>resolve(false))
  
    })

  }





  const placePictureDataClutter = async (coords, id)=>{

  let markersClassification = markersClassificationTeethOccMandibular(coords)
  let caninDistance = await fetchCaninDistance(id)

 
  return (

    {
      markersClassification : markersClassification,
      
      updateSize : {
        origin : pointsDistance(markersClassification.rightCanin, markersClassification.leftCanin),
        target : caninDistance || 600
      },
      
      updatePlacement : {
        origin : {x : markersClassification.middle.x, y : markersClassification.middle.y}, 
        target : {x : 900, y : 300}
      },
      updateRotate : {
        pivot : {x : 900, y : 300}, 
        angle : pointsAngle(markersClassification.rightMolar, markersClassification.leftMolar)+(Math.PI)
      }

    }

  )

}





export {
  placePictureDataFace, placePictureDataSpreader, placePictureDataLeftSide, placePictureDataRightSide, 
  placePictureDataTeethFaceShut, placePictureDataTeethFaceOpen, placePictureDataTeethOcclMaxillar, placePictureDataTeethOcclMandibular,
  placePictureDataTeethLeftSide, placePictureDataTeethRightSide,
  placePictureDataOrthpanto, placePictureDataCephalo,
  placePictureDataClutter
}


