import React from 'react'
import close from '../icons/cancel.svg'

const Frame = props => {

    const divStyle = {
      position : 'relative',
      ...props.style
      // backgroundColor : 'white',
      // border : 'solid 2px',
      // borderRight : 'solid 1px', borderBottom : 'solid 1px',
      // borderColor : 'purple', borderRadius : 10,
      //display : 'flex', justifyContent : 'space-around', alignItems : 'center', flexDirection : 'column',
      //maxWidth : 400, 
      //width : '100vw',
      //height : 200,
     // padding : 20, 
    }

    const frameStyle = {
      position : 'relative',
      backgroundColor : 'white',
      border : 'solid 2px',
      borderRight : 'solid 1px', borderBottom : 'solid 1px',
      borderColor : props.color || 'purple', borderRadius : 10,
      width : '105%', height : '110%',
      top : 10, 
      left : -30,
      paddingLeft : 20,
      boxShadow : '5px 5px 8px 3px rgba(0, 0, 0, .2)'
      

    }

    const labelStyle = {
      position : 'absolute', top : -15, right : 20,
      //border : 'solid', 
      fontSize : '1.2em',
      paddingRight : 5, paddingLeft : 5,
      color : props.color ||'purple',
      //backgroundColor : 'white',
      cursor : 'default',
    }

    const cropLabelStyle = {
      position : 'absolute', top : -2, right : 20,
      //border : 'solid', 
      fontSize : '1.2em',
      paddingRight : 5, paddingLeft : 5,
      backgroundColor : 'white',
      cursor : 'default',
      color : 'rgba(0,0,0,0)',
      height : 2

    }

    const returnStyle={
      position : 'absolute',
      top : 10, right : 10,
      width : 20,
      cursor : 'pointer',
      zIndex : 4
      
    }


    return (

      <div style={divStyle}>

        <div style={frameStyle}>

          <div style={cropLabelStyle}>{props.label}</div>

          <div style={labelStyle}>{props.label}</div>

          {props.children}

          {props.close && <img src={close} style = {returnStyle} alt='close' onClick={props.onClick || false}/>}
          
        </div>

      </div>
      
    )

  }

export default Frame