import {fabric} from 'fabric'
import {createMarkers} from './fabricMarkers'
import {pointsDistance, pointsAngle, randomColor} from './fabricFunctions'
import generateHexa from '../hexa'


class PointCephalo {

	constructor(canvas, callbackVisor,callbackUpdate=()=>true, markerMoveCallback=()=>true) {

		this.canvas = canvas
		this.callbackVisor = callbackVisor
		this.completed = false
		this.color = randomColor()
		this.id = generateHexa(10)
		this.visibility=true
		this.callbackUpdate=callbackUpdate
		this.markerMoveCallback = markerMoveCallback
		this.name = 'ceph'


	}

	create = (callback=()=>true)=>{
		this.callback = callback
		this.markers = new createMarkers(this.canvas, this.callbackVisor, this.createMarker, this.mouseMove)
		this.markers.maxMarker=1
		this.markers.color=this.color	

	}

	cancel = ()=>{
		this.markers.off()
		this.canvas.off('mouse:move',this.moving)
		this.completed = false
		this.canvas.remove(this.marker,this.initials)
		this.canvas.renderAll()
	}

	load = (coords, callback=()=>true)=>{
		this.callback = callback
		this.markers = new createMarkers(this.canvas, this.callbackVisor, (marker,type)=>this.createMarker(marker, type), this.mouseMove)
		this.markers.maxMarker=1
		this.markers.color=this.color
		this.markers.loadMarker({x : coords.x, y : coords.y})
	}


	createMarker = (marker,type)=>{
		
		if (type==='add' || type==='load') {
			this.completed = true
			this.marker=marker
			this.createText()
			marker.on('moving',this.moving)
			marker.on('selected',this.focus)
			marker.on('deselected',this.blur)
		} 
			
		this.callback(marker, type)
	}

	mouseMove=()=>{
		 this.updateText()
		 this.callbackUpdate()
	}

	moving = ()=>{
		this.updateText()
		this.markerMoveCallback()
	}

	focus = ()=>this.initials.opacity = 1
	
	blur = ()=>this.initials.opacity = 0
	

	setColor = color=>{
		this.color = color
		this.marker.set({fill : color})
		this.initials.set({fill : color})
		this.canvas.renderAll()
	}

	setVisibility=value=>{
		this.visibility = value
		this.marker.selectable = value
		this.marker.hoverCursor = value? 'pointer' : 'default'
		this.marker.opacity = value? this.visibility : 0
		//this.initials.opacity =  this.textVisibility?value :0
		this.canvas.renderAll()
	}

	createText = ()=>{
		let coords = this.marker.getCenterPoint()
		
		this.initials = new fabric.Text(this.name,{
			left : coords.x+50, top : coords.y, 
			originX : 'center', originY : 'center',
			fontSize : 50, 
			fill : this.color, 
			type : 'marker',
			opacity : 0,
			selectable : false,
			hoverCursor : 'default'
		})

		
 			
		this.canvas.add(this.initials)
	}


	updateText = ()=>{
		let coords = this.marker.getCenterPoint()
		this.initials.set({left : coords.x+50, top : coords.y})
	}


}




class LineCephalo {

	constructor (canvas,prolongation=[false,false], dashed=false) {
		this.canvas = canvas
		this.color = randomColor()
		this.id = generateHexa(10)
		this.completed=false
		this.visibility=true
		this.textVisibility=false
		this.prolongation1=prolongation[0]
		this.prolongation2=prolongation[1]
		this.dashed = dashed
		this.savedTool = false
		this.name = 'ceph'
		this.formula = false
		this.length = false
		this.ratio = false
	}

	create = (callback=()=>true)=>{

		this.callback = callback

		let lineCoords=[0,0,0,0]
		
		this.line = new fabric.Line(lineCoords, {
			fill : 'red', strokeWidth : 4/this.canvas.getZoom(), stroke : this.color, type : 'marker',
			originX : 'center', originY : 'center', hoverCursor : 'default',
      		hasControls : false, lockMovementX : true, lockMovementY : true, 
      		selectable : false
		})

		if (this.dashed) this.line.strokeDashArray = [10, 5]

		this.text = new fabric.Text('',{
			left : 0, top : 0, 
			originX : 'center', originY : 'center',
			fontSize : 60, 
			backgroundColor : this.color, 
			stroke : 'black', fill : 'white', strokeSize : 25, 
			type : 'marker',
			cornerSize : 20, transparentCorners : false,
			opacity : this.textVisibility?1 :0,
			selectable : this.textVisibility,
			hoverCursor : this.textVisibility? 'move' : 'default'
		})


	}


	update = (point1, point2)=>{

		if (point1 && point2) {

			//console.log(point1, point2)
		
			this.line.set(this.prolongationCoords({
				x1 : point1.x,
				y1 : point1.y,
				x2 : point2.x,
				y2 : point2.y,
			}))




			this.formula = this.updateFormula()
			this.length = pointsDistance(point1, point2)
			this.angleBase = pointsAngle(point1, point2) * (180/Math.PI)


			if (!this.line.canvas) {
				let coordsText = {x : (point1.x+point2.x)/2, y : (point1.y+point2.y)/2}
				if (!this.savedTool) this.text.set({left : coordsText.x, top : coordsText.y})
			 	this.canvas.add(this.line, this.text)
			 	this.completed=true
			 	this.line.sendToBack()
			 	this.line.bringForward()
			}





		}

		else if (this.line.canvas) {
			this.completed=false
			this.formula = false
			this.length = false
			this.canvas.remove(this.line, this.text)
			this.canvas.renderAll()
		}

	}


	updateWithAngle = (point, angle)=>{

		let radian = angle * (Math.PI/180)

		if (point && angle) {
		
			this.line.set(this.prolongationCoords({
				x1 : point.x,
				y1 : point.y,
				x2 : point.x + 100*Math.cos(radian),
				y2 : point.y + 100*Math.sin(radian),
			}))

			this.formula = this.updateFormula()
			this.angleBase = angle


			if (!this.line.canvas) {
				let coordsText = {x : point.x, y : point.y}
				if (!this.savedTool) this.text.set({left : coordsText.x, top : coordsText.y})
			 	this.canvas.add(this.line, this.text)
			 	this.completed=true
			 	this.line.sendToBack()
			 	this.line.bringForward()
			}


		}

		else if (this.line.canvas) {
			this.completed=false
			this.formula = false
			this.length = false
			this.canvas.remove(this.line, this.text)
			this.canvas.renderAll()
		}



	}

	updateFormula = ()=>{
		let a = (this.line.y2-this.line.y1)/(this.line.x2-this.line.x1)
		let b = this.line.y1 - (a*this.line.x1)

		return {a : a, b : b}

	}

	setColor = color=>{
		this.color = color
		this.line.set({stroke : color})
		this.text.set({backgroundColor : color})
		this.canvas.renderAll()
	}

	setVisibility=value=>{
		this.visibility = value
		this.line.selectable = value
		this.line.hoverCursor = value? 'pointer' : 'default'
		this.line.opacity = value? this.visibility : 0
		this.setTextVisibility(this.textVisibility)
		this.canvas.renderAll()
	}

	setText = text=>{
		this.text.set({text : text})
	}

	setTextVisibility = value=>{
	
		this.text.opacity = value? this.visibility : 0
		this.text.selectable = value
		this.text.hoverCursor = value? 'move' : 'default'
		this.textVisibility = value
		this.canvas.discardActiveObject()
		this.canvas.renderAll()

	}

	setTextData = data=>{

		this.savedTool = true
		
		this.text.set({
			angle : data.angle,
			top : data.position.y, left : data.position.x,
			width : data.width, height : data.height,
			text : data.text,
			scaleX : data.scaleX, scaleY : data.scaleY
		})

		this.setTextVisibility(data.visibility)

		 // text : {
			//  	position : {x:this.text.left, y : this.text.top},
			//  	visibility : this.textVisibility,
			//  	angle : this.text.angle,
			//  	width : this.text.width, height : this.text.height,
			//  	scaleX : this.text.scaleX, scaleY : this.text.scaleY,
			//  	text : this.text.text
			//  }



	}


	cancel = ()=>this.canvas.remove(this.line)


	prolongationCoords = (lineCoords)=>{
		let point1 = new fabric.Point(lineCoords.x1,lineCoords.y1)
		let point2 = new fabric.Point(lineCoords.x2,lineCoords.y2)
		

		let a = point1.x-point2.x !==0 ? (point1.y-point2.y)/(point1.x-point2.x) : point1.x
		let b = point1.y-a*point1.x
		let left = 0
		let top = 0
		let right = this.canvas.width
		let bottom = this.canvas.height

		let xOrigin = a*left+b < top ? (top-b)/a : a*left+b > bottom ? (bottom-b)/a : left 
		let yOrigin = a*left+b < top ? top : a*left+b > bottom ? bottom : a*left+b 
		let xEnd = a*right+b < top ? (top-b)/a : a*right+b > bottom ? (bottom-b)/a : right 
		let yEnd = a*right+b < top ? top : a*right+b > bottom ? bottom : a*right+b

		let x1 = point1.x-point2.x<0? xOrigin : xEnd
		let y1 = point1.x-point2.x<0? yOrigin : yEnd
		let x2 = point1.x-point2.x<0? xEnd : xOrigin
		let y2 = point1.x-point2.x<0? yEnd : yOrigin

		return {
			x1 : this.prolongation1? x1 : lineCoords.x1, 
			y1 : this.prolongation1? y1 : lineCoords.y1, 
			x2 : this.prolongation2? x2 : lineCoords.x2, 
			y2 : this.prolongation2? y2 : lineCoords.y2
		}

	}


	getDatas = ()=>(
		{
			 completed : this.completed,
			 name : this.initials, 
			 color : this.color, 
			 visibility : this.visibility, 
			 measure : this.calculatedMeasure,
			 text : {
			 	position : {x:this.text.left, y : this.text.top},
			 	visibility : this.textVisibility,
			 	angle : this.text.angle,
			 	width : this.text.width, height : this.text.height,
			 	scaleX : this.text.scaleX, scaleY : this.text.scaleY,
			 	text : this.text.text
			 }
		}
	)
	


}


class LinesCephalo {

	constructor (canvas, datas) {
		this.canvas = canvas
		this.datas = datas
		this.color = randomColor()
		this.id = generateHexa(10)
		this.savedTool = false
		this.completed=false
		this.visibility=true
		this.textVisibility = false
		this.name = 'ceph'
		this.ratio = false
		this.measureFormula = (lines, ratio)=>console.log('measure functions',lines)
	}

	create = ()=>{

		this.lines = this.datas.lines.map(line=>{
			//console.log (this.canvas, [line.origin.prolongation, line.end? line.end.prolongation : true], line.dash) 
			let lineObject = new LineCephalo (this.canvas, [line.origin.prolongation, line.end? line.end.prolongation : true], line.dash) 
			lineObject.name = this.name
			lineObject.color = this.color
			
			lineObject.create()

			this.text = new fabric.Text('',{
				left : 0, top : 0, 
				originX : 'center', originY : 'center',
				fontSize : 60, 
				backgroundColor : this.color, 
				stroke : 'black', fill : 'white', strokeSize : 25, 
				type : 'marker',
				cornerSize : 20, transparentCorners : false,
				opacity : this.textVisibility?1 :0,
				selectable : this.textVisibility,
				hoverCursor : this.textVisibility? 'move' : 'default'
			})

			//this.canvas.renderAll()

			//lineObject.line.set({selectable:false})
			//console.log(lineObject.line)


			return lineObject
		})


		this.measureFormula = this.datas.measure.formula
	}


	update = (point1, point2, index)=>{


		
		if (point1 && point2) {

			//console.log(this.name, point1, point2, this.lines[index].completed)
		
			this.lines[index].line.set(this.prolongationCoords({
				x1 : point1.x,
				y1 : point1.y,
				x2 : point2.x,
				y2 : point2.y,
			}, index))

			this.lines[index].formula = this.updateFormula(this.lines[index].line)
			this.lines[index].length = pointsDistance(point1, point2)
			this.lines[index].angleBase = pointsAngle(point1, point2) * (180/Math.PI)



			if (!this.lines[index].line.canvas) {
			 	this.lines[index].completed=true
			 	// this.canvas.add(this.text)

			}

		}

		else if (this.lines[index].line.canvas) {
		//	console.log(this.lines[index].completed)
			this.lines[index].completed=false
			//this.canvas.remove(this.text)
		}



		this.updateComplete()

	}


	updateWithAngle = (point, angle, index)=>{

		let radian = angle * (Math.PI/180)


		if (point && angle && this.lines[index].line) {
		
			this.lines[index].line.set(this.prolongationCoords({
				x1 : point.x,
				y1 : point.y,
				x2 : point.x + 100*Math.cos(radian),
				y2 : point.y + 100*Math.sin(radian),
			},index))

			this.lines[index].formula = this.updateFormula(this.lines[index].line)
			this.lines[index].angleBase = angle
	
			if (!this.lines[index].line.canvas) {
				this.lines[index].completed=true
			}

		}

		else if (this.lines[index].line && this.lines[index].line.canvas) {
			this.lines[index].completed=false
		}

		this.updateComplete()

	}


	updateComplete = ()=>{

		let completeArray = Array.from(this.lines,line=>line.completed)
		let linesArray = Array.from(this.lines, line=>line.line)

		if (!completeArray.includes(false)) {

			//console.log(this.name, completeArray)

			this.completed = true
			this.measure = this.measureFormula(this.lines, this.ratio)
			

			linesArray.map(line=>{
				
				if (!line.canvas) {
					let coordsText = {x : this.lines[0].line.left, y : this.lines[0].line.top}
					if (!this.savedTool) this.text.set({top : coordsText.y, left : coordsText.x})
					this.canvas.add(line, this.text)
				}

				line.sendToBack()
				line.bringForward()
				return true			 	
			})

			
		}

		else {
			this.completed = false

			linesArray.map(line=>{
		
				if (line.canvas) {
					this.canvas.remove(line, this.text)
					this.canvas.renderAll()
				}

				return true

			})
			
		}

	}

	setColor = color=>{
		let linesArray = Array.from(this.lines, line=>line.line)
		this.color = color
		linesArray.map(line=>line.set({stroke : color}))
		this.text.set({backgroundColor : color})
		this.canvas.renderAll()
	}

	setVisibility=value=>{
		let linesArray = Array.from(this.lines, line=>line.line)
		this.visibility = value
		this.setTextVisibility(this.textVisibility)

		linesArray.map(line=>{
			line.selectable = value
			line.hoverCursor = value? 'pointer' : 'default'
			line.opacity = value? this.visibility : 0
			return true
		})
		
		
		this.canvas.renderAll()
	}

	setText = text=>{
		this.text.set({text : text})
	}


	setTextVisibility = value=>{

		
		//{x : (point1.x+point2.x)/2, y : (point1.y+point2.y)/2}
			//	this.text.set({left : coordsText.x, top : coordsText.y})
	
		this.text.set({
			opacity : value? this.visibility : 0,
			selectable : value,
			hoverCursor : value? 'move' : 'default',
			//top : coordsText.y, left : coordsText.x
		})

		this.textVisibility = value
		this.canvas.discardActiveObject()
		this.canvas.renderAll()

	}

	setTextData = data=>{

		this.savedTool = true
		
		this.text.set({
			angle : data.angle,
			top : data.position.y, left : data.position.x,
			width : data.width, height : data.height,
			text : data.text,
			scaleX : data.scaleX, scaleY : data.scaleY
		})

		this.setTextVisibility(data.visibility)

	}

	// setTextData = data=>{
	// 	console.log(this.name, data)
	// }

	updateFormula = (line)=>{

		let a = (line.y2-line.y1)/(line.x2-line.x1)
		let b = line.y1 - (a*line.x1)

		return {a : a, b : b}

	}

	prolongationCoords = (lineCoords, index)=>{

		let point1 = new fabric.Point(lineCoords.x1,lineCoords.y1)
		let point2 = new fabric.Point(lineCoords.x2,lineCoords.y2)
		

		let a = point1.x-point2.x !==0 ? (point1.y-point2.y)/(point1.x-point2.x) : point1.x
		let b = point1.y-a*point1.x
		let left = 0
		let top = 0
		let right = this.canvas.width
		let bottom = this.canvas.height

		let xOrigin = a*left+b < top ? (top-b)/a : a*left+b > bottom ? (bottom-b)/a : left 
		let yOrigin = a*left+b < top ? top : a*left+b > bottom ? bottom : a*left+b 
		let xEnd = a*right+b < top ? (top-b)/a : a*right+b > bottom ? (bottom-b)/a : right 
		let yEnd = a*right+b < top ? top : a*right+b > bottom ? bottom : a*right+b

		let x1 = point1.x-point2.x<0? xOrigin : xEnd
		let y1 = point1.x-point2.x<0? yOrigin : yEnd
		let x2 = point1.x-point2.x<0? xEnd : xOrigin
		let y2 = point1.x-point2.x<0? yEnd : yOrigin



		return {
			x1 : this.lines[index].prolongation1? x1 : lineCoords.x1, 
			y1 : this.lines[index].prolongation1? y1 : lineCoords.y1, 
			x2 : this.lines[index].prolongation2? x2 : lineCoords.x2, 
			y2 : this.lines[index].prolongation2? y2 : lineCoords.y2
		}

	}

	getDatas = ()=>(
		{
			 name : this.initials, 
			 completed : this.completed,
			 color : this.color, 
			 visibility : this.visibility, 
			 measure : this.calculatedMeasure,
			 text : {
			 	position : {x:this.text.left, y : this.text.top},
			 	visibility : this.textVisibility,
			 	angle : this.text.angle,
			 	width : this.text.width, height : this.text.height,
			 	scaleX : this.text.scaleX, scaleY : this.text.scaleY,
			 	text : this.text.text
			 }
		}
	)


}



class ToothCephalo {

	constructor (canvas, datas) {
		this.canvas = canvas
		this.datas = datas
		this.color = randomColor()
		this.ghostColor = '#FF0000'
		this.id = generateHexa(10)
		this.completed=false
		this.visibility=true
		this.toothVisibility = true
		this.ghostVisibility = true
		this.name = 'ceph'
		this.ratio = false
		this.measureFormula = (lines, ratio)=>console.log('measure functions',lines)
		this.dataMarkers = false
		this.mandibule = false
	}

	create = (callback)=>{

		this.line = new LineCephalo (this.canvas, [this.datas.origin.prolongation, this.datas.end? this.datas.end.prolongation : true], this.datas.dash) 
		this.line.name = this.name
		this.line.color = this.color
			
		this.line.create()

		this.toothPath = new fabric.Path("M 100.60762 110.53893 C 99.256202 95.705816 94.267482 47.172881 93.793242 38.365228 93.318022 29.55756 91.805222 2.4213721 85.304362 1.6425462 74.504522 0.34870908 71.137347 24.467513 70.273361 28.930609 c -0.864647 4.46308 -9.627284 56.096288 -10.304976 63.862898 -0.679836 7.766473 -3.646863 16.020913 -2.107824 24.605723 0.993354 5.53351 9.996573 7.95711 13.431072 20.46956 15.439729 46.4426 29.841047 -2.9213 29.314337 -27.32999 z")
		this.toothPath.set({ 
			left: 1000, top: 1000, 
			strokeWidth : 4/this.canvas.getZoom(), stroke : this.color, fill : 'rgba(0,0,0,0)',
			originX : 'center', type : 'marker',
			hoverCursor : 'default', hasControls : false, lockMovementX : true, lockMovementY : true, 
      		selectable : false,
      		flipX : this.mandibule

		})

		this.canvas.add(this.toothPath)
		callback()

		

	}

	createGhost = (callback=()=>true)=>{

		this.callbackGhostMovement=callback

		this.toothGhost = new fabric.Path("M 100.60762 110.53893 C 99.256202 95.705816 94.267482 47.172881 93.793242 38.365228 93.318022 29.55756 91.805222 2.4213721 85.304362 1.6425462 74.504522 0.34870908 71.137347 24.467513 70.273361 28.930609 c -0.864647 4.46308 -9.627284 56.096288 -10.304976 63.862898 -0.679836 7.766473 -3.646863 16.020913 -2.107824 24.605723 0.993354 5.53351 9.996573 7.95711 13.431072 20.46956 15.439729 46.4426 29.841047 -2.9213 29.314337 -27.32999 z")
		
		let angle
		let point1 = this.dataMarkers.filter(marker=>marker? marker.name==='F' : false)[0]
		let point2 = this.dataMarkers.filter(marker=>marker? marker.name==='Gn' : false)[0]
		if (point1&&point2) angle = pointsAngle(point1.position,point2.position) * 180/(Math.PI)
		

		this.toothGhost.set({ 
			left: this.toothPath.left, top: this.toothPath.top, 
			scaleY : this.toothPath.scaleY, scaleX : this.toothPath.scaleX,
			strokeWidth : 4/this.canvas.getZoom(), stroke : this.ghostColor, fill : 'rgba(0,0,0,0)',
			originX : 'center', type : 'marker',
			cornerSize : 20, transparentCorners : false,
			angle : this.mandibule? this.toothPath.angle : 360+angle-90,
			lockScalingX : true, lockScalingY : true,
			flipX : this.mandibule
		})

		this.canvas.add(this.toothGhost)
		this.ghost = true
		
		this.toothGhost.on('moving',this.calculateBackward)
		this.toothGhost.on('rotating',this.calculateBackward)

		this.calculateBackward()

	}


	cancelGhost = ()=>{
		this.ghost = false
		this.canvas.remove(this.toothGhost)
	}


	update = (point1, point2)=> {
		
		this.line.update(point1, point2)

		this.toothPath.set({
			scaleY:this.line.length/this.toothPath.height,
			scaleX:this.line.length/this.toothPath.height,
			angle : this.line.angleBase+90,
			left : point2.x, top : point2.y
		})


	}


	loadGhostData = data=>{

		if (data) {

				this.toothGhost.set({
					left : data.position.x,
					top : data.position.y,
					angle : data.angle,
					width : data.width,
					height : data.height,
					stroke : data.color
				})

				this.setGhostVisibility(data.visibility)

				this.calculateBackward()
	
		}
	}

	setVisibility=value=>{
		
		this.visibility = value
		this.toothPath.selectable = value
		this.toothPath.opacity = value? (this.toothVisibility?1 : 0) : 0
		this.line.setVisibility(value)
		this.canvas.renderAll()
	}

	setToothVisibility = value=>{
		this.toothVisibility = value
		this.toothPath.opacity = value? this.visibility : 0
		this.canvas.renderAll()

	}

	setGhostVisibility = value=>{
		this.ghostVisibility = value
		this.toothGhost.opacity = value? 1 : 0
		this.toothGhost.selectable = value
		this.toothGhost.hoverCursor = value? 'move' : 'default'
		this.toothGhost.hasBorders = value
		this.toothGhost.hasControls = value
		this.canvas.renderAll()

	}


	setColor = color=>{
	
		this.color = color
		this.toothPath.set({stroke : color})
		this.line.setColor(color)
	}


	setGhostColor = color=>{
	
		this.ghostColor = color
		this.toothGhost.set({stroke : color})
		this.canvas.renderAll()
	}



	calculateBackward = e=>{

		this.toothGhost.setCoords()
		this.toothPath.setCoords()

		this.ghostBackward = this.ratio? (-this.ratio.ratio * ((this.toothPath.aCoords.bl.x+this.toothPath.aCoords.br.x)/2-(this.toothGhost.aCoords.bl.x+this.toothGhost.aCoords.br.x)/2)).toFixed(1) : false
		this.ghostRotate = this.mandibule? (this.toothGhost.angle - this.toothPath.angle).toFixed(0) : (360-(this.toothGhost.angle - this.toothPath.angle)).toFixed(0)

		this.callbackGhostMovement()

	}


	getDatas = ()=>(
		{
			name : this.initials, 
			color : this.color, 
			visibility : this.visibility,
			toothVisibility : this.toothVisibility,
		
			ghostData : this.ghost? {
				position : {x : this.toothGhost.left, y : this.toothGhost.top},
				width : this.toothGhost.width,
				height : this.toothGhost.height,
				angle : this.toothGhost.angle,
				visibility : this.ghostVisibility,
				color : this.ghostColor
			} : false,

			 measure : this.ghost? {
			 	translate : this.ghostBackward,
			 	rotate : this.ghostRotate
			 } : false


		}
	)



		
		


}








export {PointCephalo, LineCephalo, LinesCephalo, ToothCephalo} 