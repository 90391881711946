import React from 'react'
import StoreContext from '../contexts/storeContext'




class StoreProvider extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      jwtData : false,
      updateLogData : this.updateLogData,
      patientData : {},
      updatePatientData : this.updatePatientData,
     // fileId : '',
      fileData : {},
     // updateFileId : this.updateFileId,
      updateFileData : this.updateFileData,
      creator : false
     // mobile : navigator.userAgent
      // listPatients : [],
      // updateListPatient : this.updateListPatient
     
    }

  }

  componentDidMount = () => {

    //  console.log(this.state.mobile)
      //, navigator.userAgent, window.orientation, window.screen)

  }

  componentWillUnmount = () => {

  }

  updateLogData = data=>{
    //console.log('upload jwt',data)
    this.setState({jwtData : data})
  }

  updatePatientData = data=>{
    let creator
    if (data.idUser) creator = data.idUser._id===this.state.jwtData.id? true : false
    this.setState({patientData : data, creator : creator})
    document.title = data.name? data.name.firstname+' '+data.name.lastname : 'orth07up'
  }

 // updateFileId = id=>this.setState({fileId : id})

   updateFileData = data=>{
  //  console.log(data)
    
    this.setState({fileData : data})

  }

//   updateListPatient = ()=>{
//     socket.emit('fetchListPatient',list=>{
//       console.log(list)
//     })
//     this.setState({listPatients : ['EEE','RRR']})
// 
//   }

  render = () => {

   // console.log('store',this.state)
  
  return(

      <StoreContext.Provider value={this.state}>
        
        {this.props.children}

      </StoreContext.Provider>

      )

}
           

}




export default StoreProvider