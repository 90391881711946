import React from 'react';
//import logo from './logo.svg';
import './App.css';

import Application from './pages/application'
import MailActivation from './pages/mailActivation'
import ForgetPassword from './pages/forgetPassword'
import UpdateMail from './pages/updateMail'


//require('velocity-animate')
//require ('velocity-animate/velocity.ui')


function App() {
  
  return (

  	<>

    	{window.location.pathname === '/' && <Application/>}

		{window.location.pathname === '/activation' && <MailActivation/>}  

		{window.location.pathname === '/forget' && <ForgetPassword/>} 

		{window.location.pathname === '/updateMail' && <UpdateMail/>}  	

    </>
  
  )
}

export default App
