import React from 'react'
import Input from './customInput'
import visible from '../icons/eye.svg'
import invisible from '../icons/hidden.svg'
import socket from '../modules/socket'
import WrapComponent from '../contexts/wrap'
import cryptoJS from 'crypto-js'




class PassInput extends React.Component {

  static defaultProps = {
    label : 'Mot de passe',
    validFunction : input=>/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(\S{6,})$/.test(input)  ? true : false,
    value : input=>input,
    onFocus : focus=>focus,
    onBlur : blur => blur
  }

  constructor(props) {

    super(props)

    this.state = {
      hide : true
    }

  }

  secret = e=>this.setState({hide : !this.state.hide})

  
  render = () => {

    const divStyle={
      //border : 'solid',
       position : 'relative',
     // width : 330
     // padding : 30
    }

    const secretStyle = {
      cursor : 'pointer',
      position : 'absolute',
      width : 25,
      top : 20, left : 280
    }

    return (

      <div style={divStyle}>
      
        <Input
          label = {this.props.label}
          hide = {this.state.hide}
          formatFunction = {input=>input.replace(' ','')}
          validFunction = {this.props.validFunction}
          value = {this.props.value}
          updateValue = {this.props.updateValue}
          lock = {this.props.lock}
          validation = {this.props.validation}
          update = {this.props.update}
          onFocus = {this.props.onFocus}
          onBlur = {this.props.onBlur}
        />

        <img src = {this.state.hide? invisible : visible} alt = 'visibility' style={secretStyle} onClick={this.secret}/>

      </div>

    )

  }

}








class CheckPassword extends React.Component {

   static defaultProps = {

  }

  

  fetchPassValidity =  newPassword=>{

      return new Promise ((resolve, reject)=>{

        console.log(newPassword)

        socket.emit('CheckPassword',window.sessionStorage.authentification, newPassword, response=>resolve(response))

      })

  }


  validationFunction =  input=>{

    return new Promise(async (resolve, reject)=>{

      let  newPassword = cryptoJS.SHA512(input).toString(cryptoJS.enc.Base64)
      let check = await this.fetchPassValidity(newPassword)

      resolve(check)

      })

    }


  

  render = () => {

    return (
      
      <PassInput

    label = 'Ancien mot de passe'
    validFunction = {this.validationFunction}
    value = {this.props.value} 

  />
    )

  }

}







class CreatePassword extends React.Component {

  static defaultProps = {
    value : value=>value
  }

  constructor(props) {

    super(props)

    this.state = {
      pw : '',
      pwValidation : false, 
      repeatPwValidation : false,
      repeatPwUpdate : false,
      focus : false
    }

  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

  pwValue = value=>{
    //console.log(value)
    this.setState({pwValidation : value.validation, pw : value.maskedValue, repeatPwUpdate : !this.state.repeatPwUpdate})
    this.liftUpState()

  }

  repeatPwValue = value=>{

    this.setState({repeatPwValidation : value.validation})
    this.liftUpState()
  }

  liftUpState = ()=>this.props.value({
    pw : this.state.pw, 
    validation : (this.state.pwValidation && this.state.repeatPwValidation)? true : false
  })

  handleFocus = ()=>this.setState({focus : true})

  handleBlur = ()=>this.setState({focus : false})

  render = () => {

    const divStyle = {
      position : 'relative'
      //paddingLeft : 30
      //border : 'solid'
    }

    return (

      <div style = {divStyle}>

        <PassInput 
          value = {this.pwValue} 
          lock = {this.props.lock}
          onFocus = {this.handleFocus}
          onBlur = {this.handleBlur}
          label = {this.props.label}

        />

        <PassInput 
          value = {this.repeatPwValue}
          label = 'Répétez le mot de passe' 
          validFunction={input=>this.state.pwValidation && input===this.state.pw}
          lock = {this.props.lock}
          update = {this.state.repeatPwUpdate}
        />

        {!this.state.pwValidation && this.state.pw && this.state.focus && <ValidPW passWord = {this.state.pw}/>}
        

      </div>
      
    )

  }

}


class ValidPW extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      length : false,
      upperCase : false,
      lowerCase : false,
      number : false
    }

  }

  componentDidMount = () => {
    this.checkPass(this.props.passWord)
  }

  componentWillUnmount = () => {

  }

  componentDidUpdate = (prevProps) =>{
    if (this.props.passWord !== prevProps.passWord) this.checkPass(this.props.passWord)
  }

  checkPass = pass=>{
    this.setState({
      upperCase : /(?=.*[A-Z])/.test(pass)? true : false,
      lowerCase : /(?=.*[a-zéèêëàâäûü])/.test(pass)? true : false,
      number : /(?=.*[0-9])/.test(pass)? true : false,
      length : pass.length >= 6
    })
  }
  

  render = () => {

    const divStyle = {
      position : 'absolute',
      top : 60, left : 10, 
      border: 'solid purple 1px', 
      borderRadius: '0px 0px 5px 5px', 
      boxShadow: '5px 5px 5px rgba(0, 0, 0, .4)',
      // left: '50%', transform: 'translateX(-50%)', 
      padding: 5, 
      //margin : 10,
       zIndex: 20, backgroundColor: 'white'
    }

    const validSign = valid=>
        <span style = {{color : valid? 'green' : 'red'}}>
          {valid? '✓ ' : '✗ '}
        </span>
    
  

    return (
      
      <div style = {divStyle}>
        
        <div>
          {validSign(this.state.length)} Au moins 6 caractères
        </div>
        <div>
          {validSign(this.state.upperCase)} Au moins 1 majusule
        </div>
        <div>
          {validSign(this.state.lowerCase)} Au moins 1 minuscule
        </div>
        <div>
          {validSign(this.state.number)} Au moins 1 chiffre
        </div>


      </div>


    )

  }

}




CheckPassword = WrapComponent(CheckPassword)

export {PassInput, CreatePassword, CheckPassword}




