import {loadImageIndependant} from './loadImage'
import {LandMarksFaceObject, LandMarksSpreaderFaceObject, LandMarksSideObject, LandMarksTeethFaceObject, LandMarksTeethOpenFaceObject, LandMarksTeethSideObject, LandMarksTeethOcclObject, LandMarksOrthopantoObject, LandMarksCephaloObject} from '../objects/landmarksDataObjects'
import {placePictureDataFace, placePictureDataSpreader, placePictureDataLeftSide, placePictureDataRightSide, placePictureDataTeethFaceShut, placePictureDataTeethFaceOpen, placePictureDataTeethOcclMaxillar, placePictureDataTeethOcclMandibular, placePictureDataTeethLeftSide, placePictureDataTeethRightSide, placePictureDataOrthpanto, placePictureDataCephalo} from './placePictureData'
import ClutterViewer from './pictureClutterFunction'
import JSZip from 'jszip'
//import { saveAs } from 'file-saver'
import pdfCephaloFunction from '../functions/pdfCephaloFunction'
import download from 'downloadjs'
import socket from '../modules/socket'
import sigle from '../images/bigSigle.png'
import smallSigle from '../images/smallSigle.png'
import { jsPDF } from "jspdf"
import 'jspdf-autotable'




const twoNumbers = number=> Number(number)>9 ? number : '0'+number 

// const photoTitle= {
// 
//     facePicture : 'visageFace',
//     facePictureWithSmile : 'visageFaceSourire',
//     leftSidePicture : 'visageProfilGauche',
//     rightSidePicture : 'visageProfilDroit',
//     spreaderFacePicture : 'VisageLevreEcarte',
// 
//     teethPictureFaceClose : 'dentsSerreFace',
//     teethPictureFaceOpen : 'dentsOuvertFace',
//     teethPictureLeftSide : 'dentsProfilGauche',
//     teethPictureRightSide : 'dentsProfilDroit',
//     teethPictureOcclusalMaxillar : 'dentsMaxillaireOcclusal',
//     teethPictureOcclusalMandibular : 'dentsMandibulaireOcclusal',
// 
//     plasterPictureOcclusalMaxillar : 'empreinteMaxillaireOcclusale',
//     plasterPictureLeftSide : 'empreinteProfilGauche',
//     plasterPictureFace : 'empreinteFace',
//     plasterPictureRightSide :  'empreinteProfilDroit',
//     plasterPictureOcclusalMandibular : 'empreinteMandibulaireOcclusale',
// 
//     orthopanto : 'panoramique',
//     sideTeleradio : 'teleradiographie'
// }


const listPictures = {
	facePicture : {
		placeData : placePictureDataFace,
		landmarksObject : LandMarksFaceObject,
		frenchName : 'visageFace'
	},
 	facePictureWithSmile : {
		placeData : placePictureDataFace,
		landmarksObject : LandMarksFaceObject,
		frenchName : 'visageFaceSourire'
 	},
 	leftSidePicture : {
 		placeData : placePictureDataLeftSide,
		landmarksObject : LandMarksSideObject,
		frenchName : 'visageProfilGauche'
	},
 	rightSidePicture : {
 		placeData : placePictureDataRightSide,
		landmarksObject : LandMarksSideObject,
		frenchName : 'visageProfilDroit'
 	},
 	spreaderFacePicture : {
 		placeData : placePictureDataSpreader,
		landmarksObject : LandMarksSpreaderFaceObject,
		frenchName : 'VisageLevreEcarte'
 	},
 
 	teethPictureFaceClose : {
 		placeData : placePictureDataTeethFaceShut,
		landmarksObject : LandMarksTeethFaceObject,
		frenchName : 'dentsSerreFace'
 	},
 	teethPictureFaceOpen : {
 		placeData : placePictureDataTeethFaceOpen,
		landmarksObject : LandMarksTeethOpenFaceObject,
		frenchName : 'dentsOuvertFace'
 	},
 	teethPictureLeftSide : {
 		placeData : placePictureDataTeethLeftSide,
		landmarksObject : LandMarksTeethSideObject,
		frenchName : 'dentsProfilGauche'
 	},
 	teethPictureRightSide : {
 		placeData : placePictureDataTeethRightSide,
		landmarksObject : LandMarksTeethSideObject,
		frenchName : 'dentsProfilDroit'
 	},
 	teethPictureOcclusalMaxillar : {
 		placeData : placePictureDataTeethOcclMaxillar,
		landmarksObject : LandMarksTeethOcclObject,
		frenchName : 'dentsMaxillaireOcclusal'
 	},
 	teethPictureOcclusalMandibular : {
 		placeData : placePictureDataTeethOcclMandibular,
		landmarksObject : LandMarksTeethOcclObject,
		frenchName : 'dentsMandibulaireOcclusal'
 	},
 
 	plasterPictureOcclusalMaxillar : {
 		placeData : placePictureDataTeethOcclMaxillar,
		landmarksObject : LandMarksTeethOcclObject,
		frenchName : 'empreinteMaxillaireOcclusale'
 	},
 	plasterPictureLeftSide : {
 		placeData : placePictureDataTeethLeftSide,
		landmarksObject : LandMarksTeethSideObject,
		frenchName : 'empreinteProfilGauche'
 	},
 	plasterPictureFace : {
 		placeData : placePictureDataTeethFaceShut,
		landmarksObject : LandMarksTeethFaceObject,
		frenchName : 'empreinteFace'
 	},
 	plasterPictureRightSide : {
 		placeData : placePictureDataTeethRightSide,
		landmarksObject : LandMarksTeethSideObject,
		frenchName : 'empreinteProfilDroit'
 	},
 	plasterPictureOcclusalMandibular : {
 		placeData : placePictureDataTeethOcclMandibular,
		landmarksObject : LandMarksTeethOcclObject,
		frenchName : 'empreinteMandibulaireOcclusale'
 	},
 
 	orthopanto : {
 		placeData : placePictureDataOrthpanto,
		landmarksObject : LandMarksOrthopantoObject,
		frenchName : 'panoramique'
 	},
 
 	sideTeleradio : {
 		placeData : placePictureDataCephalo,
		landmarksObject : LandMarksCephaloObject,
		frenchName : 'teleradiographie'
 	}


}





class generatePictures {

	constructor (id, context, checkUpDate) {
		this.id=id
		this.context=context
		this.checkUpDate = checkUpDate
		//this.zipGenerate()

	}

	//onProgress =  (callback=()=>true) =>this.progressCallback = callback
	onFinish = (callback=()=>true) =>this.finishCallback = callback

	loadDataURL = (id=this.id)=>{
	
	return new Promise((resolve, reject)=>{
		
		let list = Array.from(Object.keys(listPictures),key=>({key : key, name : listPictures[key].frenchName}))
		
		let picturesListUrlPromises = Array.from(Object.keys(listPictures),key=>{
			let url = loadImageIndependant (
				id,
				key,
				listPictures[key].placeData,
				listPictures[key].landmarksObject
			)

			//url.then(()=>this.progressCallback())

			return url
		})

		//console.log(test)

		Promise.all(picturesListUrlPromises).then((urlList) => {
  			let urlListComplete = Array.from(urlList,(elem,index)=>{
  				return (elem? {...elem, ...list[index]} : false)
  			})
  			resolve(urlListComplete)
		})
		




	})
}


cephaloTable = (id=this.id)=>{

	return new Promise (async (resolve, reject)=>{

	 let pdfExport = new pdfCephaloFunction(id, this.context)
 

    let pdfURL = await pdfExport.generate()

    resolve(pdfURL)

    })
}

cephaloTableObject = (id=this.id)=>{

	return new Promise (async (resolve, reject)=>{

	 let pdfExport = new pdfCephaloFunction(id, this.context)
 

    let object = await pdfExport.createTableObject()

    resolve(object)

    })
}


clutterPicture = (id=this.id)=>{
 
 return new Promise(async(resolve, reject)=>{

 	 let clutterPicture = new ClutterViewer(id)
 	 let url = await clutterPicture.start()

 	 resolve(url)



 })

}

fileName = (title, extension='jpg')=>{
	
    let creationDate = new Date(this.checkUpDate)
    let date = `${twoNumbers(creationDate.getDate())}${twoNumbers(creationDate.getMonth()+1)}${creationDate.getFullYear()}`
    let fileTitle = (
      this.context.patientData.name.lastname.slice(0,3)+
      this.context.patientData.name.firstname.slice(0,3)+'_'+
      date+title+'.'+extension
    )


    return fileTitle


}

fetchIdPicture = ()=>{

	let reader = new FileReader()

	return new Promise((resolve,reject)=>{

		reader.addEventListener('load', ()=>resolve(reader.result))

    	socket.emit('fetchIdPicture',this.context.patientData._id,data=>{

      		if (data.success) {
        		let blob = new Blob([data.data], { type: 'image/jpeg'})
        		reader.readAsDataURL(blob)
      		}
      		
      		else resolve(false)

    	})

    })
}

fetchPicture = type=>{

	let reader = new FileReader()

	return new Promise (async(resolve,reject)=>{

		reader.addEventListener('load', ()=>resolve(reader.result))

    	 socket.emit('fetchImagePreview', this.id, type,(data)=>{
    	 	//socket.emit('fetchIdPicture',this.context.patientData._id,data=>{

      		if (data.success) {
        		let blob = new Blob([data.data], { type: 'image/jpeg'})
        		reader.readAsDataURL(blob)
      		}
      		
      		else resolve(false)

    	})


	})
}

// fetchPicture = type=>{
// 
// 	return new Promise(async(resolve, reject)=>{
// 
// 		let url = await loadImageIndependant (
// 				this.id,
// 				type,
// 				listPictures[type].placeData,
// 				listPictures[type].landmarksObject
// 			)
// 
// 		resolve(url.preview)
// 
// 	})
// }

zipGenerate = ()=>{

	new Promise(async (resolve,reject)=>{

		let zip = new JSZip()
		
		let clutter = await this.clutterPicture()
		let ceph = await this.cephaloTable()

		if (clutter) zip.file(this.fileName('_encombrement'), clutter.picture.split(',')[1], {base64: true})
		if (ceph) zip.file(this.fileName('_rapportCeph', 'pdf'), ceph.split(',')[1], {base64: true})

		let picturesUrl = await this.loadDataURL()

		picturesUrl.map(elem=>{
			if (elem) zip.file(this.fileName('_'+elem.name),elem.picture.split(',')[1], {base64 : true})
			return true
		})


 
		zip.generateAsync({type:"blob"}).then(blob=>{
	     	download(blob, this.fileName('','zip'))
	     	this.finishCallback()
	     })

		return true


	})
}


pdfReport = async (callback)=>{

	let doc = new jsPDF()

	//page1
	doc.addImage(sigle, "PNG", 140, 5, 60, 20)

	doc.setFontSize(22)
	doc.setFont('times', '')
	let date = `${new Date(this.checkUpDate).getDate()}/${new Date(this.checkUpDate).getMonth()+1}/${new Date(this.checkUpDate).getFullYear()}`
	doc.text ('RAPPORT du '+date, 105, 70, {align : 'center'})
	
	let idPicture = await this.fetchIdPicture()
	if (idPicture) doc.addImage(idPicture,'JPEG', 55, 100, 100, 100)

	doc.setFontSize(20)
	doc.setFont('times', 'bold')
	doc.text(this.context.patientData.name.lastname+' '+this.context.patientData.name.firstname, 105, 220,{align : 'center'})
	doc.setFont('times', '')
	doc.text(`${twoNumbers(this.context.patientData.birthday.day)}/${twoNumbers(this.context.patientData.birthday.month)}/${this.context.patientData.birthday.year}`,105,230,{align : 'center'})
	
	doc.setFont('times', 'italic')
	doc.setFontSize(14)
	doc.text('Dr '+this.context.jwtData.lastname+' '+this.context.jwtData.firstname, 200, 280, {align : 'right'})
	
	
//	page2
	doc.addPage("a4", "l")

 	let picture

	picture = await this.fetchPicture('leftSidePicture')
	if (picture) doc.addImage(picture,'JPEG', 6.75, 15, 90, 90)
	picture = await this.fetchPicture('facePicture')
	if (picture) doc.addImage(picture,'JPEG', 103.5, 15, 90, 90)
	picture = await this.fetchPicture('rightSidePicture')
	if (picture) doc.addImage(picture,'JPEG', 200.25, 15, 90, 90)

	picture = await this.fetchPicture('facePicture')
	if (picture) doc.addImage(picture,'JPEG', 6.75, 110, 90, 90)
	picture = await this.fetchPicture('facePictureWithSmile')
	if (picture) doc.addImage(picture,'JPEG', 103.5, 110, 90, 90)
	picture = await this.fetchPicture('spreaderFacePicture')
	if (picture) doc.addImage(picture,'JPEG', 200.25, 110, 90, 90)

	doc.addImage(smallSigle, "PNG", 280, 5, 15, 11)
	
	doc.setFont('times', 'italic')
	doc.setFontSize(14)
	doc.text('Dr '+this.context.jwtData.lastname+' '+this.context.jwtData.firstname, 280, 206, {align : 'right'})

	doc.setFontSize(16)
	doc.text(this.context.patientData.name.lastname+' '+this.context.patientData.name.firstname+' - '+date, 10, 10)

//	page3
	doc.addPage("a4", "l")

	picture = await this.fetchPicture('teethPictureLeftSide')
	if (picture) doc.addImage(picture,'JPEG', 6.75, 15, 90, 90)
	picture = await this.fetchPicture('teethPictureFaceClose')
	if (picture) doc.addImage(picture,'JPEG', 103.5, 15, 90, 90)
	picture = await this.fetchPicture('teethPictureRightSide')
	if (picture) doc.addImage(picture,'JPEG', 200.25, 15, 90, 90)

	picture = await this.fetchPicture('teethPictureOcclusalMaxillar')
	if (picture) doc.addImage(picture,'JPEG', 6.75, 110, 90, 90)
	picture = await this.fetchPicture('teethPictureFaceOpen')
	if (picture) doc.addImage(picture,'JPEG', 103.5, 110, 90, 90)
	picture = await this.fetchPicture('teethPictureOcclusalMandibular')
	if (picture) doc.addImage(picture,'JPEG', 200.25, 110, 90, 90)

	doc.addImage(smallSigle, "PNG", 280, 5, 15, 11)
	
	doc.setFont('times', 'italic')
	doc.setFontSize(14)
	doc.text('Dr '+this.context.jwtData.lastname+' '+this.context.jwtData.firstname, 280, 206, {align : 'right'})

	doc.setFontSize(16)
	doc.setFont('times', 'bold')
	doc.text(this.context.patientData.name.lastname+' '+this.context.patientData.name.firstname+' - '+date, 10, 10)


//	page4
	doc.addPage("a4", "l")

	picture = await this.fetchPicture('plasterPictureLeftSide')
	if (picture) doc.addImage(picture,'JPEG', 6.75, 15, 90, 90)
	picture = await this.fetchPicture('plasterPictureFace')
	if (picture) doc.addImage(picture,'JPEG', 103.5, 15, 90, 90)
	picture = await this.fetchPicture('plasterPictureRightSide')
	if (picture) doc.addImage(picture,'JPEG', 200.25, 15, 90, 90)

	picture = await this.fetchPicture('plasterPictureOcclusalMaxillar')
	if (picture) doc.addImage(picture,'JPEG', 6.75, 110, 90, 90)
	picture = await this.fetchPicture('plasterPictureFace')
	if (picture) doc.addImage(picture,'JPEG', 103.5, 110, 90, 90)
	picture = await this.fetchPicture('plasterPictureOcclusalMandibular')
	if (picture) doc.addImage(picture,'JPEG', 200.25, 110, 90, 90)

	doc.addImage(smallSigle, "PNG", 280, 5, 15, 11)
	
	doc.setFont('times', 'italic')
	doc.setFontSize(14)
	doc.text('Dr '+this.context.jwtData.lastname+' '+this.context.jwtData.firstname, 280, 206, {align : 'right'})

	doc.setFontSize(16)
	doc.setFont('times', 'bold')
	doc.text(this.context.patientData.name.lastname+' '+this.context.patientData.name.firstname+' - '+date, 10, 10)


//	page5
	doc.addPage("a4", "l")

	picture = await this.fetchPicture('orthopanto')
	if (picture) doc.addImage(picture,'JPEG', 10, 30, 150, 150)
	picture = await this.clutterPicture()
	if (picture) doc.addImage(picture.preview,'JPEG', 180, 60, 90, 90)
	

	doc.addImage(smallSigle, "PNG", 280, 5, 15, 11)
	
	doc.setFont('times', 'italic')
	doc.setFontSize(14)
	doc.text('Dr '+this.context.jwtData.lastname+' '+this.context.jwtData.firstname, 280, 206, {align : 'right'})
	doc.setFontSize(16)
	doc.text(this.context.patientData.name.lastname+' '+this.context.patientData.name.firstname+' - '+date, 10, 10)



	//	page6

	


	picture = await this.fetchPicture('sideTeleradio')
 	if (picture) {

 		doc.addPage("a4", "p")

 		doc.addImage(picture,'JPEG', 5, 45, 200, 200)

	

	doc.addImage(smallSigle, "PNG", 190, 5, 15, 11)
	
	doc.setFont('times', 'italic')
	doc.setFontSize(14)
	doc.text('Dr '+this.context.jwtData.lastname+' '+this.context.jwtData.firstname, 200, 290, {align : 'right'})
	doc.setFontSize(16)
	doc.text(this.context.patientData.name.lastname+' '+this.context.patientData.name.firstname+' - '+date, 10, 10)



	//page7

	doc.addPage("a4", "p")

	let tables = this.cephaloTableObject()
	let table={...await tables, startY : 40}
	
	doc.autoTable( table)

	doc.addImage(smallSigle, "PNG", 190, 5, 15, 11)
	
	doc.setFont('times', 'italic')
	doc.setFontSize(14)
	doc.text('Dr '+this.context.jwtData.lastname+' '+this.context.jwtData.firstname, 200, 290, {align : 'right'})
	doc.setFontSize(16)
	doc.text(this.context.patientData.name.lastname+' '+this.context.patientData.name.firstname+' - '+date, 10, 10)

	}

	let dataUrl = doc.output('dataurlstring')
	//this.finishCallback()
	callback(dataUrl)

	return true


}

}



export default generatePictures