import React from 'react'
import Header from './header'
import ListPatients from './listPatients'
import hashParse from '../functions/hashParse'
import PatientFile from './patientFile'


class MainPage extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      page : ''
    }

  }

  componentDidMount = () => {

    window.addEventListener('hashchange', this.hashPage)

  }

  componentWillUnmount = () => {

    window.removeEventListener('hashchange', this.hashPage)

  }

  hashPage = ()=>{
    let hashList = hashParse()
    this.setState({page : hashList[0].hashPage})
  }
  

  render = () => {

    return (

      <>

        <Header/>

        <ListPatients hide={this.state.page && this.state.page!=='subscribePatient'}/>

        <PatientFile hide={this.state.page && this.state.page!=='subscribePatient'}/>



      </>


      

    )

  }

}


export default MainPage