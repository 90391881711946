import {fabric} from 'fabric'
import generateHexa from '../hexa'
import deleteArray from '../deleteArray'
//import * as faceapi from 'face-api.js'
//import {fabric} from 'fabric-with-gestures'

// import detectFace from './detectFace.js'




class createMarkers {

  constructor(canvas, callbackURL, callbackMarker, callbackMoveMarker) {
    this.canvas = canvas
    this.callbackURL = callbackURL
    this.callbackMarker = callbackMarker
    this.callbackMoveMarker = callbackMoveMarker
    this.maxMarker=3
    this.markersArray = []
    this.color = 'purple'
    this.constraint = false

    this.on()
  }

  on = canvas=>{

    this.canvas.setCursor('crosshair')

    this.canvas
      .on('mouse:down',this.createPoint)
      .on('selection:created',e=>this.selected = true)
      .on('selection:cleared', e=>this.selected = false)
  }


  off = canvas=>{

    this.canvas.setCursor('default')

    this.canvas
      .off('mouse:down',this.createPoint)
      .off('mouse:move',this.move)
      .off('mouse:up',this.mouseUp)
      .off('selection:created',e=>this.selected = true)
      .off('selection:cleared', e=>this.selected = false)

    this.markersArray.map(marker=>marker.moveMarkers.off())  

  }

  pause = ()=>{
    this.canvas.off('mouse:down',this.createPoint)
  }

  unpause = ()=>{
    this.canvas.on('mouse:down',this.createPoint)
  }



  createPoint = opt => {

    let event = opt.e

    if (!this.selected && event.button===0 && this.markersArray.length < this.maxMarker) {
    
      this.tracePoint = true
      this.canvas.on('mouse:move',this.move)
      this.canvas.on('mouse:up',this.mouseUp)

    }

  }

  move = opt=>{
        let coords = this.canvas.getPointer(opt.e,true)
        this.canvas.setCursor('crosshair')
        this.toVisor(coords)
  }

  toVisor = coords=>{
        let test = this.canvas.getObjects('marker')
        test.map(object=>object.visible=false)
        let canvas = this.canvas.toCanvasElement(1,{left : coords.x-30, top : coords.y-30, height : 60, width : 60})
        test.map(object=>object.visible=true)
        this.callbackURL(canvas)
        this.callbackURL('')
  }

  
  mouseUp = opt=>{

    this.tracePoint = false
  
    let coords = this.canvas.getPointer()

    let marker = this.drawMarker(coords)
    marker.moveMarkers = new moveMarkers(marker, this.canvas, this.toVisor, this.eraseMarker, this.callbackMoveMarker)

    this.canvas.off('mouse:up',this.mouseUp).off('mouse:move',this.move)
    this.canvas.setActiveObject(marker)
    
    this.markersArray=[...this.markersArray,marker]
    this.callbackMarker(marker, 'add')

  }

  loadMarker = (coords)=>{

    let marker = this.drawMarker(coords) 
    marker.moveMarkers = new moveMarkers(marker, this.canvas, this.toVisor, this.eraseMarker, this.callbackMoveMarker)

    this.markersArray=[...this.markersArray,marker]
    
    this.callbackMarker(marker, 'load')

  }

  drawMarker = coords=>{

    let marker = new fabric.Circle({
      radius: 10/this.canvas.getZoom(), fill: this.color, left: coords.x, top:coords.y, 
      originX : 'center', originY : 'center', hoverCursor : 'pointer',
      hasControls : false, 
      type : 'marker', id : generateHexa(10),
      borderScaleFactor : 3, borderColor : 'white',
      front : true
    })

    marker.bringToFront()

    this.canvas.add(marker)
    this.canvas.setCursor('default')

    // this.canvas.on('mouse:wheel', ()=>{
    //   marker.radius = 15/this.canvas.getZoom()
    // })

    return marker

  }


  eraseMarker = marker=>{
    deleteArray(this.markersArray,item=>item.id===marker.id)
    this.callbackMarker(marker,'delete')
  }

  eraseAllMarkers = ()=>{
    this.canvas.remove(...this.markersArray)
    this.markersArray = []
  }

  getMarkerList = ()=>this.markersArray

}







class moveMarkers {

  constructor(marker, canvas, callbackVisor, callbackEraseMarker, callbackMoveMarker=blankFunction) {
    this.marker = marker
    this.callbackVisor = callbackVisor
    this.canvas = canvas
    this.callbackEraseMarker = callbackEraseMarker
    this.callbackMoveMarker = callbackMoveMarker
     
    this.on()
  }


  on = ()=>{
    this.marker.on('moving',this.move)
    this.marker.on('moved',this.moved)
    this.marker.on('selected', this.select)
    this.marker.on('deselected', this.deselect)
    //this.canvas.on('mouse:wheel',()=>console.log('zoom') )
   
  }

   off = ()=>{
    this.marker.off('moving',this.move)
    this.marker.off('moved',this.moved)
    this.marker.off('selected', this.select)
    this.marker.off('deselected', this.deselect)
    window.removeEventListener('keydown',this.keyControl)
  }

  select = e=>{
    this.selected=true
     window.addEventListener('keydown',this.keyControl)
   }
   
  deselect = e=>{
    this.selected=false
    window.removeEventListener('keydown',this.keyControl)
  }

  move = e=>{
    let AbsoluteCoords = new fabric.Point(this.marker.oCoords.tl.x+this.marker.width/2, this.marker.oCoords.tl.y+this.marker.height/2)
    this.marker.setCoords()
    this.callbackVisor(AbsoluteCoords)
  }

  moved =e=>{
    this.callbackMoveMarker(this.marker,'mouse')
  }

  keyControl = e=>{
  //  if (this.selected) {

      if (e.key === 'Delete') {
        this.off()
        this.canvas.remove(this.marker)
        this.callbackEraseMarker(this.marker)
      }

      else if (e.key === 'ArrowLeft') this.marker.left -= 2
      else if (e.key === 'ArrowRight') this.marker.left += 2
      else if (e.key === 'ArrowUp') this.marker.top -= 2
      else if (e.key === 'ArrowDown') this.marker.top += 2

      this.canvas.renderAll()
      this.callbackMoveMarker(this.marker,'keyboard')
      this.move()

      if (e.key === 'ArrowLeft' || e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'ArrowRight') this.move()
      
     
   // }
  }




}


const blankFunction = marker=>marker


export {createMarkers}