import React from 'react'
import Input from './customInput'
import add from '../icons/plus.svg'


const listTest = [
  {label :'Bonjour'},
  {label  : 'chez'},
  {label : 'vous'},
  {label  :'et bonne'},
  {label  :'journée', JSX : <span style={{color : 'red'}}>journée</span>},

]



class AutoCompleteInput extends React.Component {

	static defaultProps = {
		returnSelectedObject : object=>object,
		value : value=>value,
		list : listTest,
		label : 'label',
		limit : 8 
	}

	constructor(props) {
		super(props)
		this.state={
			focus : false,
			list : this.props.list,
			value : '',
			selectedLabel : '',
			validation : false,
			newItem : {},
			updateInput : false
		}
	}

	componentDidUpdate = (prevProps) => {
    	if (this.props.updateValue !== prevProps.updateValue) this.setState({selectedLabel : this.props.updateValue})
    	if (this.props.list !== prevProps.list) this.setState({list : this.props.list, updateInput : !this.state.updateInput})
	}

	handleValue = value => {
		this.setState({value : value.maskedValue, validation : value.validation})
		this.filterList(value.maskedValue)
		this.props.value(value)

		if (value.validation) {
		 	let objectSelected = [...this.props.list,this.state.newItem].filter(item=>item.label===value.maskedValue)[0]
		 	this.props.returnSelectedObject(objectSelected)
		}

	}

	checkValueInLabels = value=>{
		let labelArray = Array.from([...this.props.list,this.state.newItem],element=>element.label)
		let validValue = labelArray.includes(value)
		return validValue
	}

	handleBlur = () => setTimeout(()=> this.setState({focus: false}), 300)

	handleFocus = () => setTimeout(()=> this.setState({focus: true}), 301)
	

	filterList = value => {
		let prepareRegex = value.replace(/e/g,'[eéèëê]').replace(/a/g,'[aàâ]').replace(/u/g,'[uûùü]').replace(/i/g,'[iïî]').replace(/o/g,'[oôö]')
		let regex = new RegExp(prepareRegex,'i')
		let filterList = [...this.props.list,this.state.newItem]
			.sort((a,b)=>a.label<b.label? -1 : a.label>b.label? 1 : 0)
			.filter(item=>regex.test(item.label))
			
		

		this.setState({list : filterList}) 
	}


	select = object => this.setState({selectedLabel : object.label})

	selectNewItem = item=>{
		//console.log(this.state.value)
		
		let newItem = {label : this.state.value, type : 'personal label'}
		this.setState({newItem : newItem, updateInput : !this.state.updateInput})
		//this.forceUpdate()
		
		//this.props.returnSelectedObject(newItem)
	}

	render = ()=> {

		const addStyle = {
			cursor : 'pointer',
	      	position : 'absolute',
		    width : 20,
		    top : 20, left : 280
		}

		return (

			<div style={{position: 'relative'}}>

				<Input
			 	label={this.props.label}
			 	value={this.handleValue}
				onFocus={this.handleFocus}
				onBlur={this.handleBlur}
				updateValue = {this.state.selectedLabel}
				validFunction = {this.checkValueInLabels}
				formatFunction = {input=>input.toLowerCase()}
				localStorage = {this.props.localStorage}
    			sessionStorage = {this.props.sessionStorage}
				lock = {this.props.lock}
				update = {this.state.updateInput}
				/>

				{this.state.value && !this.state.validation && <img 
					src = {add} 
					alt = 'add' 
					title = 'Ajouter une spécialité en dehors de la liste' 
					style={addStyle} 
					onClick={this.selectNewItem}
				/>}

				{this.state.list.length>0 && this.state.focus && !this.state.validation &&
				
					<AutoComplete
						list={this.state.list}
						selected={this.select}
					/>
				}

			</div>

		)

	}

}


class AutoComplete extends React.Component {

	constructor(props) {
		super(props)
		this.state={
			selected: -1
		}
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this.handleKey)
	}

	componentWillUnmount = () => {
		document.removeEventListener('keydown', this.handleKey)
	}

	handleKey = e => {
	//use arrows to navigate
		if (e.key === 'ArrowDown') {	
			e.preventDefault()

			if (this.state.selected===-1)
				this.setState({selected: 0})
			else if (this.state.selected>=this.props.list.length-1)
				this.setState({selected: 0})
			else 
				this.setState({selected: this.state.selected+1})
		}

		if (e.key === 'ArrowUp') {
			e.preventDefault()

			if (this.state.selected===-1)
				this.setState({selected: this.props.list.length-1})
			else if (this.state.selected<=0)
				this.setState({selected: this.props.list.length-1})
			else 
				this.setState({selected: this.state.selected-1})
		}

		if (e.key === 'Enter' && this.state.selected>=0) {
			e.preventDefault()
			this.props.selected(this.props.list[this.state.selected])
		}

	}

	select = index => {
		this.setState({selected: index})
	}

	chosen = index => {
		this.props.selected(this.props.list[index])
		this.setState({selected: ''})
	}

	render = () => {

		const styleDiv = {
			//border : 'solid',
			position: 'absolute', 
			top :52, left : 10,
			width : 250, 
			//width: '90%', 
			border: 'solid purple 1px', 
			borderRadius: '0px 0px 5px 5px', 
			boxShadow: '5px 5px 5px rgba(0, 0, 0, .4)',
			// left: '50%', transform: 'translateX(-50%)', 
			padding: 5, 
			//margin : 10,
			 zIndex: 20, backgroundColor: 'white',
			 maxHeight : 250,
			 overflowY : 'auto'
		}
		

		return(

			<div style={styleDiv}>
				{this.props.list.map((element, index)=>
					<LineAutoComplete 
						key={index} 
						properties={element}
						select={this.select}
						selected={this.state.selected}
						chosen={this.chosen}
						i={index}
					/>)
				}

			</div>

		)
	}
}


let LineAutoComplete = props =>{

	const divStyle = {
		paddingTop : 6, paddingBottom : 6,
		backgroundColor: props.selected===props.i?'purple':'white', 
		color :  props.selected===props.i?'white':'', 
		cursor: 'pointer'
	}

	return <div 
		style={divStyle}
		onMouseEnter={()=>props.select(props.i)}
		onMouseLeave={()=>props.select(-1)}		
		onTouchStart={()=>props.select(props.i)}
		onTouchEnd={()=>props.select(-1)}
		onClick={()=>props.chosen(props.i)}
	>
		{props.properties.label && props.properties.JSX ? props.properties.JSX : props.properties.label}

	</div>

}




export default AutoCompleteInput