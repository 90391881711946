import React from 'react'
import Authentification from './authentification'
import Subscribe from './subscribe'
import hashParse from '../functions/hashParse'
import socket from '../modules/socket'
import WrapComponent from '../contexts/wrap'


class Connexion extends React.Component {

  static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      page : '', 
      newMail : ''
    }

  }

  componentDidMount = async () => {

    window.addEventListener('hashchange', this.hashPage)
    this.hashPage()

    socket.on('fetchDataLog', this.pushDataLog)
    socket.on('logOut',this.logOut)
    socket.on('fetchJwt',this.JWT)

   // console.log('mount log sentinel...',this.props.context.test, this.props.test)  
    
  }


  componentWillUnmount = () => {

    window.removeEventListener('hashchange', this.hashPage)

    socket.off('fetchDataLog', this.pushDataLog)
    socket.off('logOut',this.logOut)
    socket.off('fetchJwt',this.JWT)

  }


  hashPage = ()=>{
    let hashList = hashParse()
    this.setState({page : hashList[0].hashPage})
  }

  pushDataLog = callback=>{
    if (window.sessionStorage.authentification) callback(window.sessionStorage.authentification)
    
    else if (window.localStorage.authentification) {
      callback(window.localStorage.authentification)
      window.sessionStorage.authentification = window.localStorage.authentification
    }

    else callback ('')

  }


  logOut = msg=>{
      console.log('kill connexion',msg)
      window.location.hash==='subscribe'? window.location.hash='subscribe' : window.location.hash='connexion'
      window.sessionStorage.authentification = ''
      window.localStorage.authentification = ''
  }

  JWT = token=>token.activated ? this.props.context.updateLogData(token) : this.logOut()
 


  render = () => {

    const styleDiv = {
      display : 'flex', justifyContent : 'center', 
      alignItems : 'center',
      width : '100vw', height : '100vh',
    }


    return (
      
      <div style = {styleDiv} >
        
        {this.state.page === 'connexion' && <Authentification/>}

        {this.state.page === 'subscribe' && <Subscribe/>}

      </div>

    )

  }

}



 


export default WrapComponent(Connexion)