import React from 'react'
import {LastNameInput, FirstNameInput, SexInput} from './formatedInput'
import BirthInput from './datesInput'
import Frame from './frame'
import CustomButton from './customButton'
import Loader from './loader'
import socket from '../modules/socket'
import Popup from './popup'


class SubscribePatient extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      firstname : {value : '', validation : false},
      lastname : {value : '', validation : false},
      sex : {value : '', validation : false},
      birthday : {value : '', validation : false},

    }

  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

  returnValue = (stateObject)=>this.setState(stateObject)

  pushSubscribe = ()=>{

    this.setState({loader : true})

    let parseBirthday = this.state.birthday.value.split('/')

    let subscribeObject = {
      name : {
        firstname : this.state.firstname.value,
        lastname : this.state.lastname.value
      },
      sex : this.state.sex.value,
      birthday : {day : parseBirthday[0], month : parseBirthday[1], year : parseBirthday[2]}
    }

    

    socket.emit('subscribePatient',subscribeObject,result=>{
      console.log(result)
      if (result.error) this.setState({popup : result, loader : false})
      else  window.location.hash=`patient${result.msg}`
      this.props.updateList()
    })

  }

  closePopup = success=>success? window.location.hash = '' : this.setState({popup : false})

  
  render = () => {

    const backgroundStyle = {
      position : 'fixed',
      top : 0, left : 0,
      display : 'flex', justifyContent : 'center', 
      alignItems : 'center',
      width : '100vw', height : '100vh',
       backgroundColor : 'rgba(255,255,255,0.8)',
       zIndex : 3
    }


    const frameStyle = {
      position : 'relative',
      marginTop: 'auto', 
      marginBottom: 'auto',
      maxWidth : 400, width : '80vw',
      paddingLeft : 20,
       zIndex : 3
      
      //boxShadow : '5px 5px 8px 3px rgba(0, 0, 0, .2)' 
    }

    const formStyle = {
      paddingTop : 10,
      position : 'relative',
      //border : 'solid',
       display : 'flex', flexWrap : 'wrap',
       flexDirection : 'row', justifyContent : 'space-evenly',
       width : '100%',
       zIndex : 4
    }

    const divButtonStyle = {
      position : 'relative',
     // border : 'solid',
       display : 'flex',
       justifyContent : 'center',
       width : '100%'
    }

    const buttonValidation = (
      this.state.firstname.validation &&
      this.state.lastname.validation  &&
      this.state.birthday.validation &&
      this.state.sex.validation 
    )
   


    return (
      
       <div style={backgroundStyle}>

      {this.state.loader && <Loader/>}

      {this.state.popup && <Popup success = {this.state.popup.success} error={this.state.popup.error} msg={this.state.popup.msg} handleClick={()=>this.closePopup(this.state.popup.success)}/>}


      <Frame label='Nouveau patient' style = {frameStyle} close={true} onClick={e=>window.location.hash = ''}>

       <form>

        <div style={formStyle}>

          <LastNameInput 
            value={value=>this.returnValue({lastname : {value : value.unmaskedValue, validation : value.validation}})}
          />

          <FirstNameInput 
            value={value=>this.returnValue({firstname : {value : value.unmaskedValue, validation : value.validation}})}
          />

          <SexInput
            value = {value=>this.returnValue({sex : {value : value.value, validation : value.validation}})}
          />

          <BirthInput
            value = {value=>this.returnValue({birthday : {value : value.maskedValue, validation : value.validation}})}
          />



  
        </div>



        <div style = {divButtonStyle}>

          <CustomButton 
            label="Enregistrer"
            active={buttonValidation}
            handleClick = {this.pushSubscribe}
            activeEnter = {!this.state.popup}

          />  

        </div>

         
      </form>


      </Frame>

      </div>

    )

  }

}

export default SubscribePatient