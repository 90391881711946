

const init = {
	dev : {

		socketUrl : 'http://localhost:8000',

		appliUrl : 'http://localhost:3000',
	},

	prod : {

		socketUrl : 'https://orth07up.com',

		appliUrl : 'https://orth07up.com',
	}
}


export default init.prod