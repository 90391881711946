import React from 'react'
import StoreContext from '../contexts/storeContext'


const WrapComponent = WrappedComponent=> {

  return class extends React.Component {
    
    render = ()=>
      <StoreContext.Consumer>
        {context => <WrappedComponent {...this.props} context = {context} />}
      </StoreContext.Consumer>
    
  }
}


export default WrapComponent