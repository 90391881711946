import {fabric} from 'fabric'
import {createMarkers} from './fabricMarkers'
import {pointsDistance, pointsAngle, randomColor} from './fabricFunctions'
import generateHexa from '../hexa'

class Rule {

	constructor (canvas, callbackVisor,callbackUpdate=()=>true) {

		this.canvas = canvas
		this.callbackVisor = callbackVisor
		this.markerArray = []
		this.completed = false
		this.name = 'rule'
		this.color = randomColor()
		this.id = generateHexa(10)
		this.prolongationLine = false
		this.textVisibility = true
		this.markerVisibility = true
		this.visibility=true
		this.ratio = false
		this.callbackUpdate=callbackUpdate
		this.savedTool = false

	}

	create = (callback=()=>true)=>{
		this.callback = callback
		this.markers = new createMarkers(this.canvas, this.callbackVisor, this.createMarker, this.mouseMove)
		this.markers.maxMarker=2
		this.markers.color=this.color	
	}


	load = (data, callback=()=>true)=>{
		this.savedTool = data
		this.callback = callback
		let coords = data.coords
		this.color = data.color
		if (data.id) this.id = data.id
		if (data.text) this.textVisibility = data.text.visibility
		this.visibility = data.visibility
		this.markerVisibility = data.markerVisibility

		this.markers = new createMarkers(this.canvas, this.callbackVisor, (marker,type)=>this.createMarker(marker, type), this.mouseMove)
		this.markers.maxMarker=2
		this.markers.color=this.color

		coords.map(coords=>this.markers.loadMarker({x : coords[0], y : coords[1]}))

	}

	cancel = ()=>{
		this.markers.off()
		this.canvas.remove(...this.markerArray,this.line, this.text)
		this.canvas.off('mouse:move',this.moving)
		this.completed = false
	}


	createMarker = (marker, type)=>{
		
		this.markerArray = this.markers.getMarkerList()

		if (type==='load') marker.on('moving',this.mouseMove) 		

		if (type==='add') marker.on('moving',this.mouseMove)

		else if (type==='delete') this.deleteMarker(marker)
		

		if (this.markerArray.length===0) this.cancel() 
		if (this.markerArray.length===1) this.createLine() 
		if (this.markerArray.length===2) this.created(type) 

	}

	deleteMarker = marker=>{
		this.canvas.remove(this.line, this.text)
		marker.off('moving', this.mouseMove)
		this.canvas.off('mouse:move',this.moving)
		this.completed=false
		this.savedTool = false
		if (this.markerArray.length>0) this.canvas.setActiveObject(this.markerArray[this.markerArray.length-1])
	}


	createLine = ()=>{
		let point1 = this.markerArray[0].getCenterPoint()
		let point2 = this.markerArray[0].getCenterPoint()
		let lineCoords = [point1.x, point1.y, point2.x, point2.y]
		
		this.line = new fabric.Line(lineCoords, {
			fill : 'red', strokeWidth : 4/this.canvas.getZoom(), stroke : this.color, type : 'marker',
			originX : 'center', originY : 'center', hoverCursor : 'default',
      		hasControls : false, lockMovementX : true, lockMovementY : true, 
      		selectable : false, 
		})
		
		this.canvas.add(this.line)
		this.markerArray.map(marker=>marker.bringForward())
		//this.canvas.on('mouse:wheel', ()=>this.line.strokeWidth = 4/this.canvas.getZoom())

		this.canvas.on('mouse:move',this.moving)
		
	}



	mouseMove = opt=>{
		
		if (this.markerArray.length===2) {
			let point1 = this.markerArray[0].getCenterPoint()
			let point2 = this.markerArray[1].getCenterPoint()
			let lineCoords = this.prolongationLine? this.prolongationCoords({x1 : point1.x, y1 : point1.y, x2 : point2.x, y2 : point2.y}) : {x1 : point1.x, y1 : point1.y, x2 : point2.x, y2 : point2.y}
			this.line.set(lineCoords)
			this.updateText(pointsDistance(point1,point2))
			this.callbackUpdate(this)
			this.canvas.renderAll()
		}
		
	}

	moving = opt=>{

		if (this.markerArray.length===1 && opt.e.buttons===1) {
			let point1 = this.markerArray[0].getCenterPoint()
			let point2 = this.canvas.getPointer(opt.e, false)
			this.line.set({x1 : point1.x, y1 : point1.y, x2 : point2.x, y2 : point2.y})
			this.canvas.renderAll()
		}
	}


	created = (type, opt)=>{

		let point1 = this.markerArray[0].getCenterPoint()
		let point2 = type==='load'? this.markerArray[1].getCenterPoint() : this.canvas.getPointer(opt, false)
		this.line.set({x1 : point1.x, y1 : point1.y, x2 : point2.x, y2 : point2.y})
		this.completed=true
		
		this.addText()
		this.setProlongation(this.prolongationLine)
		this.markerArray.map(marker=>marker.bringToFront())

		if (this.savedTool) this.loadData()

		this.callback(this)

	}


	loadData = ()=>{

		this.setProlongation(this.savedTool.prolongation)
		this.setMarkerVisibility(this.savedTool.markerVisibility)
		this.setVisibility(this.savedTool.visibility)

		let data = this.savedTool

		if (data.text) {
			
			this.text.set({
			 	text : data.text.text,
			 	left : data.text.coords[0], top : data.text.coords[1], 
				angle : data.text.angle, height : data.text.height, width : data.text.width,
				scaleX : data.text.scaleX, scaleY : data.text.scaleY
			})

		}

		this.canvas.renderAll()

	}


	setColor = color=>{
		this.color = color
		this.text.set({backgroundColor : color})
		this.markerArray.map(marker=>marker.set({fill : color}))
		this.markers.color = color
		this.line.set({stroke : color})
		this.canvas.renderAll()
	}

	setProlongation = value=>{
		this.prolongationLine = value
		let point1 = this.markerArray[0].getCenterPoint()
		let point2 = this.markerArray[1].getCenterPoint()
		let lineCoords = this.prolongationLine? this.prolongationCoords({x1 : point1.x, y1 : point1.y, x2 : point2.x, y2 : point2.y}) : {x1 : point1.x, y1 : point1.y, x2 : point2.x, y2 : point2.y}
		this.line.set(lineCoords)
		this.canvas.renderAll()
	}


	setMarkerVisibility=value=>{
		this.markerArray.map(marker=>{
			marker.opacity = value? this.visibility : 0
			marker.selectable = value
			marker.hoverCursor = value? 'pointer' : 'default'
			return true
		})

		this.markerVisibility = value
		this.canvas.discardActiveObject()
		this.canvas.renderAll()
	}


	setTextVisibility=value=>{
		this.text.opacity = value? this.visibility : 0
		this.text.selectable = value
		this.text.hoverCursor = value? 'move' : 'default'
		this.textVisibility = value
		this.canvas.discardActiveObject()
		this.canvas.renderAll()
	}


	setVisibility = value=>{
		this.line.opacity = value? 1 : 0

		let markerVisibility = value? this.markerVisibility : false
		this.markerArray.map(marker=>{
			marker.opacity = markerVisibility? 1 : 0
			marker.selectable = markerVisibility
			marker.hoverCursor = markerVisibility? 'pointer' : 'default'
			return true
		})

		let textVisibility = value? this.textVisibility : false
		this.text.opacity = textVisibility? 1 : 0
		this.text.selectable = textVisibility
		this.text.hoverCursor = textVisibility? 'move' : 'default'
		this.visibility = value

		
		this.canvas.discardActiveObject()
		this.canvas.renderAll()
	}

	updateRatio = ratio=>{
		this.ratio = ratio
		this.updateText()
	}


	addText = ()=>{

		let coords = this.line.getCenterPoint()
		let point1 = new fabric.Point(this.line.x1,this.line.y1)
		let point2 = new fabric.Point(this.line.x2,this.line.y2)
		this.distance = Math.round(pointsDistance(point1,point2))
		this.label = this.ratio ? `${(this.distance*this.ratio).toFixed(1)}` : `${this.distance}`



		this.text = new fabric.Text(this.ratio ? `${(this.distance*this.ratio).toFixed(1)} mm` : `${this.distance} px`,{
			left : coords.x, top : coords.y, 
			originX : 'center', originY : 'center',
			fontSize : 60, 
			backgroundColor : this.color, 
			stroke : 'black', fill : 'white', strokeSize : 25, 
			type : 'marker',
			cornerSize : 20, transparentCorners : false,
			opacity : this.textVisibility?1 :0,
			selectable : this.textVisibility,
			hoverCursor : this.textVisibility? 'move' : 'default'
		})

		
 			
		this.canvas.add(this.text)
		
	}


	updateText = ()=>{

		let point1 = this.markerArray[0].getCenterPoint()
		let point2 = this.markerArray[1].getCenterPoint()
		this.distance = Math.round(pointsDistance(point1,point2))
		this.label = this.ratio ? `${(this.distance*this.ratio).toFixed(1)}` : `${this.distance}`

		this.text.set({
			text : this.ratio ? `${(this.distance*this.ratio).toFixed(1)} mm` : `${this.distance} px`
		})
	}

	prolongationCoords = (lineCoords)=>{
		let point1 = new fabric.Point(lineCoords.x1,lineCoords.y1)
		let point2 = new fabric.Point(lineCoords.x2,lineCoords.y2)
		

		let a = point1.x-point2.x !==0 ? (point1.y-point2.y)/(point1.x-point2.x) : point1.x
		let b = point1.y-a*point1.x
		let left = 0
		let top = 0
		let right = this.canvas.width
		let bottom = this.canvas.height


		return {
			x1 : a*left+b < top ? (top-b)/a : a*left+b > bottom ? (bottom-b)/a : left, 
			y1 : a*left+b < top ? top : a*left+b > bottom ? bottom : a*left+b, 
			x2 : a*right+b < top ? (top-b)/a : a*right+b > bottom ? (bottom-b)/a : right, 
			y2 : a*right+b < top ? top : a*right+b > bottom ? bottom : a*right+b
		}

	}

}




class Protractor {

	constructor (canvas, callbackVisor, callbackUpdate) {
		this.canvas = canvas
		this.callbackVisor = callbackVisor
		this.markerArray = []
		this.completed = false
		this.name = 'protractor'
		this.color = randomColor()
		this.id = generateHexa(10)
		this.textVisibility = true
		this.markerVisibility = true
		this.visibility=true
		this.callbackUpdate=callbackUpdate
		this.savedTool = false

	}

	create = (callback=()=>true)=>{
		this.callback = callback
		this.markers = new createMarkers(this.canvas, this.callbackVisor, this.createMarker, this.mouseMove)
		this.markers.maxMarker=3
		this.markers.color=this.color	
	}


	load = (data, callback=()=>true)=>{
		this.savedTool = data
		this.callback = callback
		let coords = data.coords
		this.color = data.color
		this.id = data.id
		this.textVisibility = data.text.visibility
		this.visibility = data.visibility
		this.markerVisibility = data.markerVisibility

		this.markers = new createMarkers(this.canvas, this.callbackVisor, (marker,type)=>this.createMarker(marker, type), this.mouseMove)
		this.markers.maxMarker=3
		this.markers.color=this.color

		coords.map(coords=>this.markers.loadMarker({x : coords[0], y : coords[1]}))


	}

	loadData = ()=>{

		this.setMarkerVisibility(this.savedTool.markerVisibility)
		this.setVisibility(this.savedTool.visibility)

		let data = this.savedTool
		this.text.set({
		 	text : data.text.text,
		 	left : data.text.coords[0], top : data.text.coords[1], 
			angle : data.text.angle, height : data.text.height, width : data.text.width,
			scaleX : data.text.scaleX, scaleY : data.text.scaleY
		})

		this.canvas.renderAll()

	}

	cancel = ()=>{
		this.markers.off()
		this.canvas.remove(...this.markerArray,this.line1, this.line2, this.text)
		this.completed = false
	}

	createMarker = (marker, type)=>{
		
		this.markerArray = this.markers.getMarkerList()

		if (type==='add' || type==='load') marker.on('moving',this.mouseMove)
		
		else if (type==='delete') {
			this.canvas.remove(this.line1, this.line2, this.text)
			marker.off('moving',this.mouseMove)
			this.canvas.off('mouse:move',this.moving)
			this.canvas.off('mouse:up',this.created)
			this.completed = false
			this.savedTool = false

			if (this.markerArray.length>0) this.canvas.setActiveObject(this.markerArray[this.markerArray.length-1])
			
			if (this.markerArray.length===2) this.createLine()
		}


		if (this.markerArray.length===0) this.cancel()
		if (this.markerArray.length===1) this.createLine1() 
		if (this.markerArray.length===2) this.createLine2() 

		if (this.markerArray.length===3){
			
			this.addText()
			this.completed = true

			if (this.savedTool) {
				this.createLine1()
				this.createLine2()
				this.loadData()
			}

			this.created()

			this.callback(this)
		}

	}

createLine = ()=>{
		
		let point1 = this.markerArray[0].getCenterPoint()
		let point2 = this.markerArray[1].getCenterPoint()
		
		this.line1 = new fabric.Line([point1.x,point1.y, point2.x, point2.y], {
			fill : 'red', strokeWidth : 4/this.canvas.getZoom(), stroke : this.color, type : 'marker',
			originX : 'center', originY : 'center', hoverCursor : 'default',
      		hasControls : false, lockMovementX : true, lockMovementY : true, 
      		selectable : false, 
		})
		
		this.canvas.add(this.line1)
		this.markerArray.map(marker=>marker.bringForward())

	//	this.canvas.on('mouse:wheel', ()=>this.line1.strokeWidth = 4/this.canvas.getZoom())
		this.canvas.on('mouse:move',this.moving)
		this.canvas.on('mouse:up',this.created)
	}


	createLine1 = ()=>{
	
		let point1 = this.markerArray[0].getCenterPoint()
		let point2 = this.markerArray[0].getCenterPoint()
		
		this.line1 = new fabric.Line([point1.x,point1.y, point2.x, point2.y], {
			fill : 'red', strokeWidth : 4/this.canvas.getZoom(), stroke : this.color, type : 'marker',
			originX : 'center', originY : 'center', hoverCursor : 'default',
      		hasControls : false, lockMovementX : true, lockMovementY : true, 
      		selectable : false, 
		})
		
		this.canvas.add(this.line1)
		this.markerArray.map(marker=>marker.bringForward())
		
	//	this.canvas.on('mouse:wheel', ()=>this.line1.strokeWidth = 4/this.canvas.getZoom())
		this.canvas.on('mouse:move',this.moving)
		
	}

	createLine2 = ()=>{

		let point1 = this.markerArray[1].getCenterPoint()
		let point2 = this.markerArray[1].getCenterPoint()
		
		this.line2 = new fabric.Line([point1.x,point1.y, point2.x, point2.y], {
			fill : 'red', strokeWidth : 4/this.canvas.getZoom(), stroke : this.color, type : 'marker',
			originX : 'center', originY : 'center', hoverCursor : 'default',
      		hasControls : false, lockMovementX : true, lockMovementY : true, 
      		selectable : false, 
		})
		
	//	this.canvas.on('mouse:wheel', ()=>this.line2.strokeWidth = 4/this.canvas.getZoom())
		this.canvas.add(this.line2)
		this.markerArray.map(marker=>marker.bringForward())

	}


	mouseMove = opt=>{
		
		if (this.markerArray.length===2) {
			let point1 = this.markerArray[0].getCenterPoint()
			let point2 = this.markerArray[1].getCenterPoint()
			this.line1.set({x1 : point1.x, y1 : point1.y, x2 : point2.x, y2 : point2.y})
		}

		if (this.markerArray.length===3) {
			let point1 = this.markerArray[0].getCenterPoint()
			let point2 = this.markerArray[1].getCenterPoint()
			let point3 = this.markerArray[2].getCenterPoint()
			this.line1.set({x1 : point1.x, y1 : point1.y, x2 : point2.x, y2 : point2.y})
			this.line2.set({x1 : point2.x, y1 : point2.y, x2 : point3.x, y2 : point3.y})
			this.updateText()
		}
		
	}

	moving = opt=>{

		if (this.markerArray.length===1 && opt.e.buttons===1) {
			
			let point1 = this.markerArray[0].getCenterPoint()
			let point2 = this.canvas.getPointer(opt.e, false)
			this.line1.set({x1 : point1.x, y1 : point1.y, x2 : point2.x, y2 : point2.y})
			this.canvas.renderAll()
		}

		if (this.markerArray.length===2 && opt.e.buttons===1) {
			
			let point1 = this.markerArray[1].getCenterPoint()
			let point2 = this.canvas.getPointer(opt.e, false)
			this.line2.set({x1 : point1.x, y1 : point1.y, x2 : point2.x, y2 : point2.y})
			this.canvas.renderAll()
		}
	}


	created = opt=>{
		
		if (this.markerArray.length===1){
			let point1 = this.markerArray[0].getCenterPoint()
			let point2 = this.canvas.getPointer(opt, false)
			this.line1.set({x1 : point1.x, y1 : point1.y, x2 : point2.x, y2 : point2.y})
		}

		if (this.markerArray.length===2){
			let point1 = this.markerArray[0].getCenterPoint()
			let point2 = this.markerArray[1].getCenterPoint()
			let point3 = this.canvas.getPointer(opt, false)
			this.line1.set({x1 : point1.x, y1 : point1.y, x2 : point2.x, y2 : point2.y})
			this.line2.set({x1 : point2.x, y1 : point2.y, x2 : point3.x, y2 : point3.y})
		}

		if (this.markerArray.length===3){
			let point1 = this.markerArray[0].getCenterPoint()
			let point2 = this.markerArray[1].getCenterPoint()
			let point3 = this.markerArray[2].getCenterPoint()
			this.line1.set({x1 : point1.x, y1 : point1.y, x2 : point2.x, y2 : point2.y})
			this.line2.set({x1 : point2.x, y1 : point2.y, x2 : point3.x, y2 : point3.y})
			this.canvas.renderAll()
		}

	}

	setColor = color=>{
		this.color = color
		this.text.set({backgroundColor : color})
		this.markerArray.map(marker=>marker.set({fill : color}))
		this.markers.color = color
		this.line1.set({stroke : color})
		this.line2.set({stroke : color})
		this.canvas.renderAll()
	}



	setMarkerVisibility=value=>{
		this.markerArray.map(marker=>{
			marker.opacity = value? 1 : 0
			marker.selectable = value
			marker.hoverCursor = value? 'pointer' : 'default'
			return true
		})

		this.markerVisibility = value
		this.canvas.discardActiveObject()
		this.canvas.renderAll()
	}


	setTextVisibility=value=>{
		this.text.opacity = value? 1 : 0
		this.text.selectable = value
		this.text.hoverCursor = value? 'move' : 'default'
		this.textVisibility = value
		this.canvas.discardActiveObject()
		this.canvas.renderAll()
	}


	setVisibility = value=>{
		this.line1.opacity = value? 1 : 0
		this.line2.opacity = value? 1 : 0

		let markerVisibility = value? this.markerVisibility : false
		this.markerArray.map(marker=>{
			marker.opacity = markerVisibility? 1 : 0
			marker.selectable = markerVisibility
			marker.hoverCursor = markerVisibility? 'pointer' : 'default'
			return true
		})

		let textVisibility = value? this.textVisibility : false
		this.text.opacity = textVisibility? 1 : 0
		this.text.selectable = textVisibility
		this.text.hoverCursor = textVisibility? 'move' : 'default'
		this.visibility = value

		
		this.canvas.discardActiveObject()
		this.canvas.renderAll()
	}
		
	

	addText = opt=>{

		let coords = this.markerArray[1].getCenterPoint()
		let point1 = this.markerArray[0].getCenterPoint()
		let point2 = this.markerArray[1].getCenterPoint()
		let point3 = this.markerArray[2].getCenterPoint()
		let angle1 = -Math.round(fabric.util.radiansToDegrees(pointsAngle(point2,point1)))
		let angle2 = -Math.round(fabric.util.radiansToDegrees(pointsAngle(point2,point3)))
		let angle = Math.abs(angle1-angle2)
		this.angle = angle>180? 360-angle : angle
		
		this.text = new fabric.Text(`${this.angle}°`,{
			left : coords.x-70, top : coords.y, stroke : this.color,
			originX : 'center', originY : 'center',
			fill : 'white',
			fontSize : 60, 
			backgroundColor : this.color, 
			strokeSize : 25, 
			type : 'marker',
			cornerSize : 20, transparentCorners : false
		})

		this.canvas.add(this.text)

	}


	updateText = ()=>{

		let point1 = this.markerArray[0].getCenterPoint()
		let point2 = this.markerArray[1].getCenterPoint()
		let point3 = this.markerArray[2].getCenterPoint()
		let angle1 = -Math.round(fabric.util.radiansToDegrees(pointsAngle(point2,point1)))
		let angle2 = -Math.round(fabric.util.radiansToDegrees(pointsAngle(point2,point3)))
		let angle = Math.abs(angle1-angle2)
		this.angle = angle>180? 360-angle : angle

		this.text.set({
			text : `${this.angle}°`
		})
	}



}





class Ruler {

	constructor (canvas, callbackVisor, callbackUpdate) {

		this.canvas = canvas
		this.callbackVisor = callbackVisor
		this.markerArray = []
		this.direction = 'H'
		this.completed = false
		this.name = 'ruler'
		this.color = randomColor()
		this.id = generateHexa(10)
		this.markerVisibility = true
		this.visibility=true
		this.callbackUpdate=callbackUpdate
		this.savedTool = false

	}

	create = (callback=()=>true)=>{
		this.callback = callback
		this.markers = new createMarkers(this.canvas, this.callbackVisor, this.createMarker, this.mouseMove)
		this.markers.maxMarker=1
		this.markers.color=this.color

				this.canvas.on('mouse:move',this.moving)
				this.canvas.on('mouse:down',this.createLine)

		
	}

	load = (data, callback=()=>true)=>{
		this.savedTool = data
		this.callback = callback
		let coords = data.coords
		this.color = data.color
		this.id = data.id
		this.visibility = data.visibility
		this.markerVisibility = data.markerVisibility

		this.markers = new createMarkers(this.canvas, this.callbackVisor, (marker,type)=>this.createMarker(marker, type), this.mouseMove)
		this.markers.maxMarker=1
		this.markers.color=this.color
		this.direction = data.direction

		coords.map(coords=>this.markers.loadMarker({x : coords[0], y : coords[1]}))

		this.loadData()



	}


	loadData = ()=>{
		this.setMarkerVisibility(this.savedTool.markerVisibility)
		this.setVisibility(this.savedTool.visibility)
	}


	cancel = ()=>{
		this.markers.off()
		this.canvas.off('mouse:move',this.moving)
		this.canvas.off('mouse:down',this.createLine)
		this.canvas.remove(...this.markerArray,this.line)
		this.completed = false
		
	}


	createMarker = (marker, type)=>{
		
		this.markerArray = this.markers.getMarkerList()
		

		if (type==='add' || type==='load') marker.on('moving',this.mouseMove)

		else if (type==='delete') {
			this.cancel()
			this.completed = false
		}


		if (this.markerArray.length===1) {
			this.created(type) 
		}

	}


	createLine = opt=>{
	
		let point = this.savedTool ? this.markerArray[0].getCenterPoint() : this.canvas.getPointer(opt.e, false)
		let coords = this.lineCoords(point)
		
		this.line = new fabric.Line([coords.x1, coords.y1, coords.x2, coords.y2], {
			fill : 'red', strokeWidth : 4/this.canvas.getZoom(), stroke : this.color, type : 'marker',
			originX : 'center', originY : 'center', hoverCursor : 'default',
      		hasControls : false, lockMovementX : true, lockMovementY : true, 
      		selectable : false, 
		})
		
		this.canvas.add(this.line)
		this.markerArray.map(marker=>marker.bringForward())
		this.canvas.off('mouse:down',this.createLine)
		//this.canvas.on('mouse:wheel', ()=>this.line.strokeWidth = 4/this.canvas.getZoom())

		
	}



	mouseMove = opt=>{

		if (this.markerArray.length>0){		
			let point = this.markerArray[0].getCenterPoint()
			this.line.set(this.lineCoords(point))
		}
	}
		
	

	moving = opt=>{

		if (opt.e.buttons===1) {
			let point = this.canvas.getPointer(opt.e, false)
			this.line.set(this.lineCoords(point))
			this.canvas.renderAll()
		}
	}


	created = (type, opt)=>{
		if (type==='load') this.createLine()
		this.canvas.off('mouse:move',this.moving)
		this.completed = true
		this.callback(this)
	}

	setColor = color=>{
		this.color = color
		this.markerArray.map(marker=>marker.set({fill : color}))
		this.line.set({stroke : color})
		this.canvas.renderAll()
	}


	lineCoords = coords=>{

		return this.direction === 'H' ?
			{x1 : 0, y1 : coords.y, x2 : this.canvas.width, y2 : coords.y} :
			{x1 : coords.x, y1 : 0, x2 : coords.x, y2 : this.canvas.height}
	}


	setMarkerVisibility=value=>{
		this.markerArray.map(marker=>{
			marker.opacity = value? 1 : 0
			marker.selectable = value
			marker.hoverCursor = value? 'pointer' : 'default'
			return true
		})

		this.markerVisibility = value
		this.canvas.discardActiveObject()
		this.canvas.renderAll()
	}


	setVisibility = value=>{
		this.line.opacity = value? 1 : 0

		let markerVisibility = value? this.markerVisibility : false
		
		this.markerArray.map(marker=>{
			marker.opacity = markerVisibility? 1 : 0
			marker.selectable = markerVisibility
			marker.hoverCursor = markerVisibility? 'pointer' : 'default'
			return true
		})

		this.visibility = value
		this.canvas.discardActiveObject()
		this.canvas.renderAll()
	}
		
	

}







export {Rule, Ruler, Protractor}