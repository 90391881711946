

const cephaloMarks = [
  {name : 'Porion', initials : 'Po'},
  {name : 'Orbite', initials : 'Or'},
  {name : 'Selle tucique', initials : 'S'},
  {name : 'Nasion', initials : 'N'},
  {name : 'Basion', initials : 'Ba'},
  {name : 'Point tubérositaire', initials : 'T'},
  {name : 'Point sous-épineux', initials : 'A'},
  {name : 'Centre de la fente ptérygo-maxillaire', initials : 'F'},
  {name : 'Epine nasale antérieure', initials : 'Ena'},
  {name : 'Condylion', initials : 'Co'},
  {name : 'Gonion', initials : 'Go'},
  {name : 'Gnathion', initials : 'Gn'},
  {name : 'Point sus mentonnier', initials : 'B'},
  {name : 'Point super pogonion', initials : 'Pm'},
  {name : 'Centre de la symphyse', initials : 'D'},
  {name : 'Centre mandibulaire', initials : 'Cm'},
  {name : 'Point mentonnier', initials : 'Me'},
  {name : 'Point incisal supèrieur (courone)', initials : 'Ia'},
  {name : 'Point incisal supèrieur (apex)', initials : 'Ib'},
  {name : 'Point incisal inférieur (courone)', initials : 'ia'},
  {name : 'Point incisal inferieur (apex)', initials : 'ib'},
  {name : 'Point intersection des cuspides mesiales des dents de 6 ans', initials : '6i'},
  {name : 'Point de tangence de la face distale de la dent de 6 ans supérieure', initials : '6t'},
  {name : "Point d'engrènement maximum des secteurs latéraux", initials : 'R'},

]






const cephaloLin = [
  
  {
    type : 'length',
    name : 'Plan de Francfort',
    initials : 'Po-Or',
    origin : {marker : 'Po', prolongation : true},
    end : {marker : 'Or', prolongation : true},
    dash : false,
    visibility : true,
    measure : false,
   
  },

  {
    type : 'length',
    name : 'Longueur crânienne antérieure',
    initials : 'S-N',
    origin : {marker : 'S', prolongation : false},
    end : {marker : 'N', prolongation : false},
    //angle : {origin : 'Po', end : 'Or', add : 0},
    dash : false,
    visibility : false,
    measure : true,
    min : (old=5)=>(63+(old>12? 12 : old))-2,
    max : (old=5)=>(63+(old>12? 12 : old))+2
  },

  {
    type : 'length',
    name : 'Longueur crânienne postérieure',
    initials : 'S-Ba',
    origin : {marker : 'S', prolongation : false},
    end : {marker : 'Ba', prolongation : false},
   // angle : 90,
    dash : false,
    visibility : false,
    measure : true,
    min : (old=5)=>(41+(0.5*(old>12? 12 : old)))-2,
    max : (old=5)=>(41+(0.5*(old>12? 12 : old)))+2
  },
  // {
  //   type : 'length',
  //   name : 'test',
  //   initials : 'tr',
  //   origin : {marker : {cross : ['Longueur crânienne antérieure','Plan de Francfort']}, prolongation : false},
  //   end : {marker : 'N', prolongation : false},
  //  // angle : 90,
  //   dash : true,
  //   visibility : true,
  //   display : 'none'
  //  
  // },


   {
    type : 'lines',
    name : 'Compas crânien',
    initials : 'Ba-S-N',
    lines : 
    [

      {
      origin : {marker : 'S', prolongation : false},
      end : {marker : 'Ba', prolongation : false},
      dash : false
      },
      {
      origin : {marker : 'S', prolongation : false},
      end : {marker : 'N', prolongation : false},
      dash : false,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>Math.abs(line[1].angleBase-line[0].angleBase).toFixed(0),
      unit : '°'
    },
    min : ()=>125, max : ()=>135
   
  },


  {
    type : 'lines',
    name : 'Index crânien',
    initials : 'Ba-S/S-N',
    lines : 
    [

      {
      origin : {marker : 'S', prolongation : false},
      end : {marker : 'Ba', prolongation : false},
      dash : false
      },
      {
      origin : {marker : 'S', prolongation : false},
      end : {marker : 'N', prolongation : false},
      dash : false,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>Math.abs(line[1].length/line[0].length).toFixed(2),
      unit : ''
    },
    min : ()=>1.45, max : ()=>1.65,

   
  },


  {
    type : 'length',
    name : 'cocoModel',
    initials : 'cocoModel',
    origin : {marker : 'Ena', prolongation : true},
   // end : {marker : 'Ba', prolongation : true},
    angle : {origin : 'Po', end : 'Or', add : 90},
    dash : false,
    visibility : false,
    display : 'none'
  },

  

  {
    type : 'length',
    name : 'Profondeur maxillaire',
    initials : 'T-A',
    origin : {marker : 'T', prolongation : false},
    end : {marker : 'A', prolongation : false},
    dash : false,
    measure : true,
    min : (old)=>{
      if (old<=8) return 43
      else if (old<=12) return 46
      else if (old<=16) return 48
      else return 49
    },
   max : (old)=>{
      if (old<=8) return 49
      else if (old<=12) return 52
      else if (old<=16) return 54
      else return 55
    }
    
  },

  {
    type : 'lines',
    name : 'Projection maxillaire',
    initials : 'S-N-A',
    lines : 
    [

      {
      origin : {marker : 'N', prolongation : false},
      end : {marker : 'A', prolongation : false},
      dash : false
      },
      {
      origin : {marker : 'S', prolongation : false},
      end : {marker : 'N', prolongation : false},
      dash : false,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>180-Math.abs(line[1].angleBase-line[0].angleBase).toFixed(0),
      unit : '°'
    },
    min : ()=>80, max : ()=>84
   
  },

 {
    type : 'lines',
    name : 'Compas palatin',
    initials : 'T-Ena/S-N',
    lines : 
    [

      {
      origin : {marker : 'T', prolongation : false},
      end : {marker : 'Ena', prolongation : false},
      dash : false
      },
       
      {
      origin : {marker : 'S', prolongation : false},
      end : {marker : 'N', prolongation : false},
      dash : false,
      },

      {
      origin : {marker : 'T', prolongation : true},
      end : {marker : 'Ena', prolongation : false},
      dash : true
      },

       {
      origin : {marker : 'S', prolongation : true},
      end : {marker : 'N', prolongation : false},
      dash : true,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>Math.abs(line[1].angleBase-line[0].angleBase).toFixed(0),
      unit : '°'
    },
    min : ()=>4, max : ()=>8
   
  },

   {
    type : 'length',
    name : 'Longueur du corpus mandibulaire',
    initials : 'Cm-Pm',
    origin : {marker : 'Cm', prolongation : false},
    end : {marker : 'Pm', prolongation : false},
    dash : false,
    measure : true,
    min : (old)=>{
      if (old<=9) return 64
      else if (old<=10) return 66
      else if (old<=11) return 67
      else return 69
    },
   max : (old)=>{
      if (old<=9) return 70
      else if (old<=10) return 72
      else if (old<=11) return 73
      else return 75
    }
  },


 {
    type : 'lines',
    name : 'Compas goniaque',
    initials : 'Co-Go-Gn',
    lines : 
    [

      {
      origin : {marker : 'Co', prolongation : false},
      end : {marker : 'Go', prolongation : false},
      dash : false
      },
      {
      origin : {marker : 'Go', prolongation : false},
      end : {marker : 'Gn', prolongation : false},
      dash : false,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>180-Math.abs(line[1].angleBase-line[0].angleBase).toFixed(0),
      unit : '°'
    },
    min : ()=>122, max : ()=>128
   
  },


  {
    type : 'lines',
    name : 'Projection mandibulaire corticale',
    initials : 'S-N-B',
    lines : 
    [

      {
      origin : {marker : 'N', prolongation : false},
      end : {marker : 'B', prolongation : false},
      dash : false
      },
      {
      origin : {marker : 'S', prolongation : false},
      end : {marker : 'N', prolongation : false},
      dash : false,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>180-Math.abs(line[1].angleBase-line[0].angleBase).toFixed(0),
      unit : '°'
    },
    min : ()=>78, max : ()=>82
   
  },


  {
    type : 'lines',
    name : 'Projection mandibulaire basale',
    initials : 'S-N-D',
    lines : 
    [

      {
      origin : {marker : 'N', prolongation : false},
      end : {marker : 'D', prolongation : false},
      dash : false
      },
      {
      origin : {marker : 'S', prolongation : false},
      end : {marker : 'N', prolongation : false},
      dash : false,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>180-Math.abs(line[1].angleBase-line[0].angleBase).toFixed(0),
      unit : '°'
    },
    min : ()=>74, max : ()=>78
   
  },

  {
    type : 'lines',
    name : 'Compas ramal',
    initials : 'Cm-S-N',
    lines : 
    [

      {
      origin : {marker : 'Cm', prolongation : false},
      end : {marker : 'S', prolongation : false},
      dash : false
      },
      {
      origin : {marker : 'S', prolongation : false},
      end : {marker : 'N', prolongation : false},
      dash : false,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>Math.abs(180-Math.abs(line[1].angleBase-line[0].angleBase).toFixed(0)),
      unit : '°'
    },
    min : ()=>92, max : ()=>98
   
  },


  {
    type : 'length',
    name : "Plan d'occlusion de Steiner",
    initials : 'Steiner',
    origin : {marker : '6i', prolongation : false},
    end : {marker : ['Ia','ia'], prolongation : true},
    dash : false
  },

  {
    type : 'length',
    name : 'Ao',
    initials : 'Ao',
    origin : {marker : 'A', prolongation : false},
   // end : {marker : 'Gn', prolongation : false},
    angle : {origin : '6i', end : ['Ia','ia'], add : 90},
    dash : false,
    display : 'none'
  },

   {
    type : 'length',
    name : 'Bo',
    initials : 'Bo',
    origin : {marker : 'B', prolongation : false},
   // end : {marker : 'Gn', prolongation : false},
    angle : {origin : '6i', end : ['Ia','ia'], add : -90},
    dash : false,
    display : 'none'
  },




   {
    type : 'lines',
    name : 'Witts de Steiner',
    initials : 'Ao-Bo',
    lines : 
    [

      {
      origin : {marker : 'A', prolongation : true},
      end :    {marker : {cross : ['Ao','Steiner']}, prolongation : false},
      dash : false,
      },

       {
      origin : {marker : 'B', prolongation : true},
      end :    {marker : {cross : ['Bo','Steiner']}, prolongation : false},
      dash : false
      },


      {
      origin : {marker : {cross : ['Ao','Steiner']}, prolongation : false},
      end : {marker : {cross : ['Bo','Steiner']}, prolongation : false},
      dash : true,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>{
          let length =  (line[2].length * ratio.ratio).toFixed(1)
          let sign = (line[2].line.x1 - line[2].line.x2)<0? -1 : 1

          return ratio.ratio? (sign * length).toFixed(1) : false
        
      },
      unit : 'mm'
    },
    min : ()=>1, max : ()=>3
   
  },


  {
    type : 'length',
    name : "Plan d'occlusion de Ricketts",
    initials : 'Ricketts',
    origin : {marker : '6i', prolongation : true},
    end : {marker : 'R', prolongation : true},
    dash : false,
    measure : false,
    visibility : true
  },

   {
    type : 'length',
    name : 'AT90',
    initials : 'AT90',
    origin : {marker : 'T', prolongation : false},
    angle : {origin : 'A', end : 'T', add : 90},
    dash : false,
    measure : false,
    display : 'none'
  },

  {
    type : 'length',
    name : 'AT0',
    initials : 'AT0',
    origin : {marker : '6t', prolongation : false},
    angle : {origin : 'A', end : 'T', add : 0},
    dash : false,
    measure : false,
    display : 'none'
  },


   {
    type : 'lines',
    name : 'Recul molaire',
    initials : 'Recul 6t',
    lines : 
    [

      {
      origin : {marker : 'T', prolongation : false},
      end :    {marker : {cross : ['AT90','AT0']}, prolongation : true},
      dash : false,
      },

       {
      origin : {marker : '6t', prolongation : false},
      end :    {marker : {cross : ['AT90','AT0']}, prolongation : false},
      dash : true
      },
 
    ],
    measure : {
      formula : (line, ratio)=>ratio.ratio? (line[1].length*ratio.ratio).toFixed(1) : false,
      unit : 'mm'
    },
    min : age=>age>12? 10 : age-2, max : age=>age>12? 14 : age+2
   
  },


  {
    type : 'length',
    name : 'Axe facial',
    initials : 'F-Gn',
    origin : {marker : 'F', prolongation : false},
    end : {marker : 'Gn', prolongation : false},
    dash : false,
    display : 'none'
  },



  {
   type : 'length',
    name : 'Ba-N',
    initials : 'Ba-N',
    origin : {marker : 'Ba', prolongation : false},
    end : {marker : 'N', prolongation : false},
    dash : false,
    measure : false,
    display : 'none'
  },

  {
   type : 'length',
    name : 'Go-Gn',
    initials : 'Go-Gn',
    origin : {marker : 'Go', prolongation : false},
    end : {marker : 'Gn', prolongation : false},
    dash : false,
    measure : false,
    display : 'none'
  },


  {
    type : 'lines',
    name : 'Compas facial',
    initials : 'F-Gn/Ba-N',
    visibility : true,
    lines : 
    [

      {
      origin : {marker : {cross : ['Ba-N','F-Gn']} , prolongation : false},
      end : {marker : 'Gn', prolongation : false},
      dash : false
      },
      {
      origin : {marker : 'Ba', prolongation : false},
      end : {marker : 'N', prolongation : false},
      dash : false,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>Math.abs(line[1].angleBase-line[0].angleBase).toFixed(0),
      unit : '°'
    },
    min : ()=>77, max : ()=>93
   
  },


  {
    type : 'lines',
    name : 'Compas mandibulaire',
    initials : 'Go-Gn/S-N',
    lines : 
    [

      {
      origin : {marker : {cross : ['Go-Gn','S-N']} , prolongation : false},
      end : {marker : 'Gn', prolongation : false},
      dash : false
      },
      {
      origin : {marker : {cross : ['Go-Gn','S-N']}, prolongation : false},
      end : {marker : 'N', prolongation : false},
      dash : false,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>Math.abs(line[1].angleBase-line[0].angleBase).toFixed(0),
      unit : '°'
    },
    min : ()=>29, max : ()=>35
   
  },


  {
   type : 'length',
    name : 'Hauteur faciale postérieure',
    initials : 'Co-Go',
    origin : {marker : 'Co', prolongation : false},
    end : {marker : 'Go', prolongation : false},
    dash : false,
    measure : true,
    min : age=>45+(age>12? 12 : age)/2,
    max : age=>51+(age>12? 12 : age)/2
  },

  {
   type : 'length',
    name : 'Hauteur faciale antérieure',
    initials : 'Ena-Me',
    origin : {marker : 'Ena', prolongation : false},
    end : {marker : 'Me', prolongation : false},
    dash : false,
    measure : true,
    min : age=>62+(age>12? 12 : age)/2,
    max : age=>68+(age>12? 12 : age)/2
  },


  {
    type : 'lines',
    name : 'Divergence de la face',
    initials : 'Co-Go/Ena-Me',
    lines : 
    [

      {
      origin : {marker : 'Co', prolongation : false},
      end : {marker : 'Go', prolongation : false},
      dash : false
      },
      {
      origin : {marker : 'Ena', prolongation : false},
      end : {marker : 'Me', prolongation : false},
      dash : false,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>Math.abs(line[1].length/line[0].length).toFixed(2),
      unit : ''
    },
    min : ()=>1.21, max : ()=>1.51,

   
  },


  {
    type : 'lines',
    name : 'Compas dento-alvéolaire individualisé',
    initials : 'CDAI',
    lines : 
    [

      {
      origin : {marker : 'Ena' , prolongation : false},
      end : {marker : 'Cm', prolongation : false},
      dash : false
      },
      {
      origin : {marker : 'Cm', prolongation : false},
      end : {marker : 'Pm', prolongation : false},
      dash : false,
      },
  
    ],
    measure : {
      formula : (line, ratio, data)=>180-Math.abs(line[1].angleBase-line[0].angleBase).toFixed(0),
      unit : '°'
    },
    min : (age,ratio,data)=>{
      let CF=data && data.filter(line=>line.name==='F-Gn/Ba-N')[0]? data.filter(line=>line.name==='F-Gn/Ba-N')[0].measure : false
      let CM=data && data.filter(line=>line.name==='Go-Gn/S-N')[0]? data.filter(line=>line.name==='Go-Gn/S-N')[0].measure : false
      return data? (20.40+0.2*(Number(CF)+Number(CM))-2.5).toFixed(0) : false
    }, 
    max : (age,ratio,data)=>{
      let CF=data && data.filter(line=>line.name==='F-Gn/Ba-N')[0]? data.filter(line=>line.name==='F-Gn/Ba-N')[0].measure : false
      let CM=data && data.filter(line=>line.name==='Go-Gn/S-N')[0]? data.filter(line=>line.name==='Go-Gn/S-N')[0].measure : false
      return data? (20.40+0.2*(Number(CF)+Number(CM))+2.5).toFixed(0) : false

    }
   
  },

  {
    type : 'length',
    name : 'T-Ena',
    initials : 'T-Ena',
    origin : {marker : 'T', prolongation : false},
    end : {marker : 'Ena', prolongation : false},
    dash : false,
    visibility : false,
    display : 'none'
  },



   {
    type : 'lines',
    name : 'Compensation maxillaire',
    initials : 'T-Ena/Steiner',
    lines : 
    [

      {
      origin : {marker : {cross : ['T-Ena','Steiner']} , prolongation : false},
      end : {marker : 'Ena', prolongation : false},
      dash : false
      },
      {
      origin : {marker : {cross : ['T-Ena','Steiner']}, prolongation : true},
      end : {marker : ['Ia','ia'], prolongation : true},
      dash : true,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>Math.abs(line[1].angleBase-line[0].angleBase).toFixed(0),
      unit : '°'
    },
    min : ()=>7, max : ()=>15
   
  },



   {
    type : 'lines',
    name : 'Compensation mandibulaire',
    initials : 'Cm-Pm/Steiner',
    lines : 
    [

      {
      origin : {marker : {cross : ['Cm-Pm','Steiner']} , prolongation : false},
      end : {marker : 'Pm', prolongation : false},
      dash : false
      },
      {
      origin : {marker : {cross : ['Cm-Pm','Steiner']}, prolongation : true},
      end : {marker : ['Ia','ia'], prolongation : true},
      dash : true,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>Math.abs(line[1].angleBase-line[0].angleBase).toFixed(0),
      unit : '°'
    },
    min : ()=>17, max : ()=>25
   
  },

  {
    type : 'length',
    name : 'Ia-Ib',
    initials : 'Ia-Ib',
    origin : {marker : 'Ia', prolongation : false},
    end : {marker : 'Ib', prolongation : false},
    dash : false,
    visibility : false,
    display : 'none'
  },

  {
    type : 'length',
    name : 'ia-ib',
    initials : 'ia-ib',
    origin : {marker : 'ia', prolongation : false},
    end : {marker : 'ib', prolongation : false},
    dash : false,
    visibility : false,
    display : 'none'
  },

  {
    type : 'length',
    name : 'Go-Me',
    initials : 'Go-Me',
    origin : {marker : 'Go', prolongation : false},
    end : {marker : 'Me', prolongation : false},
    dash : false,
    visibility : false,
    display : 'none'
  },


  {
    type : 'lines',
    name : 'Angle incisif supèrieure',
    initials : 'Po-Or/Ia-Ib',
    lines : 
    [

      {
      origin : {marker : {cross : ['Po-Or','Ia-Ib']}  , prolongation : false},
      end : {marker : 'Po', prolongation : false},
      dash : false
      },
      {
      origin : {marker : {cross : ['Po-Or','Ia-Ib']}, prolongation : false},
      end : {marker : 'Ia', prolongation : false},
      dash : false,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>Math.abs(line[1].angleBase-line[0].angleBase).toFixed(0),
      unit : '°'
    },
    min : ()=>104, max : ()=>110
   
  },


   {
    type : 'lines',
    name : 'Angle incisif inférieure',
    initials : 'Go-Me/ia-ib',
    lines : 
    [

      {
      origin : {marker : {cross : ['Go-Me','ia-ib']}  , prolongation : false},
      end : {marker : 'Go', prolongation : false},
      dash : false
      },
      {
      origin : {marker : {cross : ['Go-Me','ia-ib']}, prolongation : false},
      end : {marker : 'ia', prolongation : false},
      dash : false,
      },
  
    ],
    measure : {
      formula : (line, ratio)=>Math.abs(line[1].angleBase-line[0].angleBase).toFixed(0),
      unit : '°'
    },
    min : ()=>87, max : ()=>93
   
  },




  {
    type : 'length',
    name : 'Plan antérieure de Cocconi',
    initials : 'Cocconi',
    origin : {marker : {cross : ['Po-Or','cocoModel']}, prolongation : false},
   // end : {marker : 'Ba', prolongation : true},
    angle : {origin : 'Po', end : 'Or', add : 89.99},
    dash : false,
    visibility : true
  },

  {
    type : 'tooth',
    name : 'Position incisive supèrieure',
    initials : 'IaIb',
    origin : {marker : 'Ia', prolongation : false},
    end : {marker : 'Ib', prolongation : false},
   
    dash : false
  },

  {
    type : 'tooth',
    name : 'Position incisive inférieure',
    initials : 'iaib',
    origin : {marker : 'ia', prolongation : false},
    end : {marker : 'ib', prolongation : false},
   
    dash : false,
    mandibule : true
  },




  



]




export {cephaloMarks, cephaloLin}