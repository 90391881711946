

  const pointsDistance = (point1, point2)=>Math.sqrt( (point1.x - point2.x)**2 + (point1.y - point2.y)**2 )

  const pointsAngle = (point1, point2)=> Math.atan2 ((point2.y-point1.y),(point2.x-point1.x))

  const randomColor = ()=>{
  	let letters = '0123456789ABCDEF'
  	let color = '#'

  	for (var i = 0; i < 6; i++) {
    	color += letters[Math.floor(Math.random() * 16)]
  	}

  	return color

  }


   const setVisibility = (value, object, canvas)=>{
    
    object.opacity = value? this.visibility : 0
    object.selectable = value
    object.hoverCursor = value? 'pointer' : 'default'
    canvas.discardActiveObject()
    canvas.renderAll()
  }



 

export {pointsDistance, pointsAngle, randomColor, setVisibility}