import React from 'react'
import WrapComponent from '../contexts/wrap'
import socket from '../modules/socket'
import logOut from '../icons/logout.svg'
import search from '../icons/search.svg'
import hashParse from '../functions/hashParse'
import photography from '../icons/camera.svg'
import teeth from '../icons/toothFace.svg'
import trail from '../icons/trail.svg'
import radio from '../icons/radiation.svg'
import compass from '../icons/compass.svg'
import PhoneExport from './phoneExport'



class Header extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      updateProfil : false,
      updatePassword : false,
      main : true
    }

  }

   componentDidMount = () => {

    window.addEventListener('hashchange', this.hashPage)

  }

  componentWillUnmount = () => {

    window.removeEventListener('hashchange', this.hashPage)

  }

  hashPage = ()=>{
    let hashList = hashParse()
   // console.log(hashList)
    hashList[0].hashPage==='updateProfil' ?  this.setState({updateProfil : true}) : this.setState({updateProfil : false})
    hashList[0].hashPage==='updatePassword' ?  this.setState({updatePassword : true}) : this.setState({updatePassword : false})
    hashList[0].hashPage==='' ?  this.setState({main : true}) : this.setState({main : false})
  }

  logOut = ()=>socket.emit('logOut')

  updateProfil = ()=>window.location.hash  = 'updateProfil'

  updatePassword = ()=>window.location.hash  = 'updatePassword'

  exit = e=> window.location.hash  = ''
  

  render = () => {

    const divStyle = {
      position : 'fixed',
      height : 0, width : 0,
      left : 0, top : 0,
      display : 'flex', justifyContent : 'center',
      zIndex : 2
    }


    const logoutStyle = {
      position : 'fixed',
      bottom : 10, right : 10,
      width : 30,
      //cursor : 'pointer'
    }

     const searchStyle = {
      position : 'fixed',
      bottom : 50, right : 10,
      width : 30,
      //cursor : 'pointer'
    }



    return (

      <>

       
      <div style={divStyle}>

        <Icons/>
        
        <img src={logOut} style={logoutStyle} onClick={this.logOut} alt='logout' title='Déconnexion'/>

        {!this.state.main && <img src={search} style={searchStyle} onClick={this.exit} alt='search' title='rechercher'/>} 

        <PhoneExport/>           
        
      </div>

      </>
      
    )

  }

}


class Icons extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
     // page : 1
    }

  }

  componentDidMount = () => {

     window.addEventListener('hashchange', this.hashPage)


     


  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

  hashPage = ()=>{
    let parse = hashParse()
    this.setState({page : (parse[1] && parse[1].hashVar.page) ? parse[1].hashVar.page : false}) 
  }

  changePage = page=>{
    window.location.hash = window.location.hash.split('?')[0]+'?page='+page
  }

  render = () => {

     const iconsStyle = {
      position : 'fixed',
      top : 0, left : 0,
      width : '100%', height : 60,
    //  border : 'solid',
      display : 'flex', justifyContent : 'space-around', alignItems : 'center'
    }

    const iconStyle = index=> ({
     // width : 70,
      width : Number(index)===Number(this.state.page)? 50 : 30,
      transformOrigin: 'center',
      cursor : 'pointer',
      transition : 'all 0.4s',
      //zIndex : 5

    })

    //console.log(this.state.page)

    const menu = [
      {icon : photography, title : 'Photographie du visage'},
      {icon : teeth, title : 'Photographie endo-buccale'},
      {icon : trail, title : 'Photographie des empreintes'},
      {icon : radio, title : 'Radiographie du patient'},
      {icon : compass, title : 'Mesure des encombrements'}
    ]

    


    return (



      <>

        {this.state.page && <div style={iconsStyle}>
                
            {menu.map((icon,index)=>
                  <div  key={index} title={icon.title} onClick={()=>this.changePage(index+1) }>
                    <img alt={icon.title} src={icon.icon} style={iconStyle(index+1)}/>
                  </div>
            )}
        
        </div>}

      </>
      


    )

  }

}

// 
// 
// class PatientId extends React.Component {
// 
//    static defaultProps = {
// 
//   }
// 
//   constructor(props) {
// 
//     super(props)
// 
//     this.state = {
//      
//     }
// 
//   }
// 
//   componentDidMount = () => {
// 
//   }
// 
//   componentWillUnmount = () => {
// 
//   }
// 
//    componentDidUpdate = (prevProps)=> {
//   // Utilisation classique (pensez bien à comparer les props) :
//   //if (this.props.context !== prevProps.context) console.log(this.props.context, 'patient') 
//     
//   }
// 
//   changeDate = e=>{
//     let newDate = new Date(e.target.value)
//    socket.emit('changeDate',newDate,this.props.context.fileData._id)
//   }
// 
// 
// 
//   render = () => {
// 
// 
//     const idPatientStyle = {
//       position : 'absolute',
//       top : 0, left : 10,
//       padding : 5, color : 'purple',
//       cursor : 'default'
//      // border : 'solid',
//       // borderRight : 'solid 1px', borderBottom : 'solid 1px',
//       // borderColor : 'purple', 
// 
//     }
// 
//     const styleDate = {
//       color : 'purple',
//       borderRadius : 5, borderColor : 'purple',
//       border : 'solid',
//       cursor : 'pointer', 
//     }
// 
//     const closeStyle = {
//       position : 'absolute',
//       width : 15,
//       top : 10, right : -20,
//       cursor : 'pointer'
//     }
// 
// 
//     const formatBirthday = birthday=>{
//       let twoNumbers = number=> Number(number)>9 ? number : '0'+number 
//       return `${twoNumbers(birthday.day)}/${twoNumbers(birthday.month)}/${birthday.year}`
//     }
//      
// 
//    // console.log(this.props.context, new Date(this.props.context.fileData.checkUpDate))
// 
//     return (
// 
//       <>
//       
//        {this.props.context.patientData&&this.props.context.patientData.name && <div style={idPatientStyle}>
// 
//         <img src={cancel} style={closeStyle} alt='close' onClick={this.props.exit}/>
//           
//          <b>{this.props.context.patientData.name.lastname} {this.props.context.patientData.name.firstname} {this.props.context.patientData.sex==='man'? '♂' : this.props.context.patientData.sex==='woman'? '♀' : '⚥' }</b><br/>
// 
//          {formatBirthday(this.props.context.patientData.birthday)} <br/>
// 
//          {this.props.context.fileData.checkUpDate && <div><input title='Date du bilan' style={styleDate} defaultValue={new Date(this.props.context.fileData.checkUpDate).toISOString().substring(0, 10)} type='date' onChange={this.changeDate}/></div> }
// 
//          {this.props.context.fileData.creationDate && !this.props.context.fileData.checkUpDate && <div><input title='Date du bilan' style={styleDate} defaultValue={new Date().toISOString().substring(0, 10)} type='date' onChange={this.changeDate}/></div> }
// 
//         </div>}
// 
//         </>
//     )
// 
//   }
// 
// }
// 
// 
// 
// 





export default WrapComponent(Header)