import React from 'react'
import {LastNameInput, FirstNameInput, MailInput} from './formatedInput'
import Frame from './frame'
import CustomButton from './customButton'
import Loader from './loader'
import socket from '../modules/socket'
import Popup from './popup'
import WrapComponent from '../contexts/wrap'




class UpdateProfil extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      loader : false,
      popupError : false, popupSuccess : false,
      popup : false,

      firstname : {value : '',validation : false},
      lastname : {value : '',validation : false},
      mail : {value : '',validation : false},

      inialLastname : '', inialFirstname : '', inialMail : ''

    }

  }

   componentDidMount = () => {
    this.setState({inialLastname : this.props.context.jwtData.lastname, inialFirstname : this.props.context.jwtData.firstname, inialMail : this.props.context.jwtData.mail})
   }

  

  returnValue = (stateObject)=>this.setState(stateObject)

  updatePopup = ()=>{
    this.setState({popup : {msg : 'Confirmez-vous la mise à jour de vos données?'}})
  }
 

  pushUpdate = e=>{

    this.setState({loader : true, popup : false})
     
    let objectUpdate = {
      name : {firstname : this.state.firstname.value, lastname : this.state.lastname.value},
      mail : this.state.mail.value
    }

   // console.log(objectSubscribe)
    socket.emit('fetchUpdateProfil',objectUpdate,(data)=>{
     
     console.log(data)
     
      if (data.success) {
        
        data.msg? this.setState({popupSuccess : data.msg, loader : false}) : this.setState({loader : false}, this.return)
        
      }

      else {
       this.setState({popupError : data.msg, loader : false})
      }


    })
//     this.setState({loader : true})
  }



  // callbackSubscribe = answer=>{
  //   // let state = Object.assign({}, {loader : false, popup : answer})
  //   // this.setState(state)
  // }



  return = ()=>window.location.hash = ''

  closePopupError = ()=> this.setState({popupError : false})

  closePopupSuccess = ()=> {
    this.setState({popupSuccess : false},this.return)
  }
  
  cancelPopup = ()=> this.setState({popup : false})

  render = () => {
    
   

    const frameStyle = {
      marginTop: 'auto', 
      marginBottom: 'auto',
      maxWidth : 400, width : '80vw',
      paddingLeft : 20,
    }

    const formStyle = {
      position : 'relative',
      //border : 'solid',
       display : 'flex', flexWrap : 'wrap',
       flexDirection : 'row', justifyContent : 'space-evenly',
       width : '100%',
    }

    const divButtonStyle = {
      position : 'relative',
     // border : 'solid',
       display : 'flex',
       justifyContent : 'center',
       width : '100%'
    }

    const styleBackground = {
      position : 'fixed',
      height : '100vh', width : '100vw',
      top : 0, left : 0,
      display : 'flex', justifyContent : 'center', itemsAlign : 'center',
      backgroundColor : 'rgba(255,255,255)',
      zIndex : 8

    }
   

    const buttonValidation = (
      this.state.firstname.validation &&
      this.state.lastname.validation  &&
      this.state.mail.validation &&
      (this.state.firstname.value !== this.props.context.jwtData.firstname ||
       this.state.lastname.value !== this.props.context.jwtData.lastname || 
       this.state.mail.value !== this.props.context.jwtData.mail)  
    )


    return (

      <div style={styleBackground}>

      {this.state.loader && <Loader/>}

      {this.state.popupError && <Popup error={true} msg={this.state.popupError} handleClick={this.closePopupError}/>}
      {this.state.popupSuccess && <Popup success={true} msg={this.state.popupSuccess} handleClick={this.closePopupSuccess}/>}
      {this.state.popup && <Popup choice msg={this.state.popup.msg}  handleClickYes={this.pushUpdate} handleClickNo ={this.cancelPopup}/>}

      <Frame label='Mise à jour du profil' style = {frameStyle} close={true} onClick={this.return}>

       <form>

        <div style={formStyle}>

          <MailInput
            value={value=>this.returnValue({mail : {value : value.unmaskedValue, validation : value.validation}})}
            localStorage = 'userMail'
             updateValue={this.state.inialMail}
          />

          <LastNameInput 
            updateValue={this.state.inialLastname} value={value=>this.returnValue({lastname : {value : value.unmaskedValue, validation : value.validation}})}
          />

          <FirstNameInput 
            updateValue={this.state.inialFirstname} value={value=>this.returnValue({firstname : {value : value.unmaskedValue, validation : value.validation}})}
          />

  
        </div>



        <div style = {divButtonStyle}>

          <CustomButton 
            label="Mettre à jour"
            active={buttonValidation}
            handleClick = {this.updatePopup}
            activeEnter = {!this.state.popup}

          />  

        </div>

         
      </form>


      </Frame>

      </div>
    )

  }

}



export default WrapComponent(UpdateProfil)