import React from 'react'
import WrapComponent from '../contexts/wrap'
import socket from '../modules/socket'
import logOut from '../icons/logout.svg'
import profil from '../icons/social.svg'
import cadenas from '../icons/restricted.svg'
import hashParse from '../functions/hashParse'

import photography from '../icons/camera.svg'
import teeth from '../icons/toothFace.svg'
import trail from '../icons/trail.svg'
import radio from '../icons/radiation.svg'
import compass from '../icons/compass.svg'
import cancel from '../icons/cancel.svg'


import UpdateProfil from './updateProfil'
import UpdatePassword from './updatePassword'

import logo from '../images/bigSigle.svg'



class Header extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      updateProfil : false,
      updatePassword : false,
      main : true
    }

  }

   componentDidMount = () => {

    window.addEventListener('hashchange', this.hashPage)

  }

  componentWillUnmount = () => {

    window.removeEventListener('hashchange', this.hashPage)

  }

  hashPage = ()=>{
    let hashList = hashParse()
   // console.log(hashList)
    hashList[0].hashPage==='updateProfil' ?  this.setState({updateProfil : true}) : this.setState({updateProfil : false})
    hashList[0].hashPage==='updatePassword' ?  this.setState({updatePassword : true}) : this.setState({updatePassword : false})
    hashList[0].hashPage==='' ?  this.setState({main : true}) : this.setState({main : false})
  }

  logOut = ()=>socket.emit('logOut')

  updateProfil = ()=>window.location.hash  = 'updateProfil'

  updatePassword = ()=>window.location.hash  = 'updatePassword'

//   hashPage = e=>{
// 
//     
//     
//     // if (this.props.context.patientData.birthday){
//     //   let birthday = this.props.context.patientData.birthday
//     //   console.log(this.props.context.patientData, `${twoNumbers(birthday.day)}/${twoNumbers(birthday.month)}/${birthday.year}`)
//     // }
// 
//     
//   }

  exit = e=> window.location.hash  = ''
  

  render = () => {

    const divStyle = {
      position : 'fixed',
      border : 'solid 2px',
      borderLeft : 'solid 1px', borderTop : 'solid 1px',
      borderColor : 'purple', borderRadius : '0px 0px 10px 10px',
      height : 80, 
      //width : "100%",
      left : 10,
      right : 10,
      display : 'flex', justifyContent : 'center',
     // zIndex : 2
    }

    
     const idStyle = {
      backgroundColor : 'purple',
      color : 'white', textAlign : 'center',
      borderRadius : 4,
      paddingBottom : 3,
      width : '100%',
      cursor : 'default'
    }

   

    const optionStyle = {
       width : 100,
       display : 'flex', justifyContent : 'space-between',
       padding :5 
    }

    const logStyle = {
      position : 'absolute',
      right : 5, top : 5,
      display : 'flex', justifyContent : 'space-between', flexDirection : 'column', alignItems : 'center'

    }

    const imageStyle = {
      width : 30,
      cursor : 'pointer'
    }

    const logoStyle = {
      position : 'absolute',
      top : 3, left : 3,
      width : 180
    }

   

    return (

      <>

         {this.state.updateProfil && <UpdateProfil/>}

         {this.state.updatePassword && <UpdatePassword/>}

      <div style={divStyle}>

        {this.state.main && <img style={logoStyle} src={logo} alt='logo'/>}
       
        <PatientId context={this.props.context} exit={this.exit}/>
        
        <Icons/>

        

        
        <div style = {logStyle}>

          <span 
            title = {`${this.props.context.jwtData.firstname} ${this.props.context.jwtData.lastname} - ${this.props.context.jwtData.mail}`} 
            style={idStyle}>
              {`${this.props.context.jwtData.firstname[0]}. ${this.props.context.jwtData.lastname}`}
          </span>

          <div style = {optionStyle}>

            <img src={profil} style={imageStyle} alt='profil' onClick={this.updateProfil} title='Mise à jour de vos données'/>
            <img src={cadenas} style={imageStyle} alt='password' onClick={this.updatePassword} title='Modifier le mot de passe'/>
            <img src={logOut} style={imageStyle} onClick={this.logOut} alt='logout' title='Déconnexion'/>
            
          </div>

        </div>
        

      </div>

      </>
      
    )

  }

}


class Icons extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
     // page : 1
    }

  }

  componentDidMount = () => {

     window.addEventListener('hashchange', this.hashPage)


     


  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.userID !== prevProps.userID) 
    
  }

  hashPage = ()=>{
    let parse = hashParse()
    this.setState({page : (parse[1] && parse[1].hashVar.page) ? parse[1].hashVar.page : false}) 
  }

  changePage = page=>{
    window.location.hash = window.location.hash.split('?')[0]+'?page='+page
  }

  render = () => {

     const iconsStyle = {
      width : '60%',
     // border : 'solid',
      display : 'flex', justifyContent : 'space-around', alignItems : 'center'
    }

    const iconStyle = index=> ({
     // width : 70,
      width : Number(index)===Number(this.state.page)? 70 : 40,
      transformOrigin: 'center',
      cursor : 'pointer',
      transition : 'all 0.4s'

    })

    //console.log(this.state.page)

    const menu = [
      {icon : photography, title : 'Photographie du visage'},
      {icon : teeth, title : 'Photographie endo-buccale'},
      {icon : trail, title : 'Photographie des empreintes'},
      {icon : radio, title : 'Radiographie du patient'},
      {icon : compass, title : 'Mesure des encombrements'}
    ]

    


    return (



      <>

        {this.state.page && <div style={iconsStyle}>
                
            {menu.map((icon,index)=>
                  <div  key={index} title={icon.title} onClick={()=>this.changePage(index+1) }>
                    <img alt={icon.title} src={icon.icon} style={iconStyle(index+1)}/>
                  </div>
            )}
        
        </div>}

      </>
      


    )

  }

}



class PatientId extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
     
    }

  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

   componentDidUpdate = (prevProps)=> {
  // Utilisation classique (pensez bien à comparer les props) :
  //if (this.props.context !== prevProps.context) console.log(this.props.context, 'patient') 
    
  }

  changeDate = e=>{
    let newDate = new Date(e.target.value)
   socket.emit('changeDate',newDate,this.props.context.fileData._id)
  }



  render = () => {


    const idPatientStyle = {
      position : 'absolute',
      top : 0, left : 10,
      padding : 5, color : 'purple',
      cursor : 'default'
     // border : 'solid',
      // borderRight : 'solid 1px', borderBottom : 'solid 1px',
      // borderColor : 'purple', 

    }

    const styleDate = {
      color : 'purple',
      borderRadius : 5, borderColor : 'purple',
      border : 'solid',
      cursor : 'pointer', 
    }

    const closeStyle = {
      position : 'absolute',
      width : 15,
      top : 10, right : -20,
      cursor : 'pointer'
    }


    const formatBirthday = birthday=>{
      let twoNumbers = number=> Number(number)>9 ? number : '0'+number 
      return `${twoNumbers(birthday.day)}/${twoNumbers(birthday.month)}/${birthday.year}`
    }
     

   // console.log(this.props.context, new Date(this.props.context.fileData.checkUpDate))

    return (

      <>
      
       {this.props.context.patientData&&this.props.context.patientData.name && <div style={idPatientStyle}>

        <img src={cancel} style={closeStyle} alt='close' onClick={this.props.exit}/>
          
         <b>{this.props.context.patientData.name.lastname} {this.props.context.patientData.name.firstname} {this.props.context.patientData.sex==='man'? '♂' : this.props.context.patientData.sex==='woman'? '♀' : '⚥' }</b><br/>

         {formatBirthday(this.props.context.patientData.birthday)} <br/>

         {this.props.context.fileData.checkUpDate && <div><input title='Date du bilan' style={styleDate} defaultValue={new Date(this.props.context.fileData.checkUpDate).toISOString().substring(0, 10)} type='date' onChange={this.changeDate}/></div> }

         {this.props.context.fileData.creationDate && !this.props.context.fileData.checkUpDate && <div><input title='Date du bilan' style={styleDate} defaultValue={new Date().toISOString().substring(0, 10)} type='date' onChange={this.changeDate}/></div> }

        </div>}

        </>
    )

  }

}









export default WrapComponent(Header)