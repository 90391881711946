import React from 'react'
import InputSearch from './customInputSearch'
import add from '../icons/plus.svg'
import SubscribePatient from './subscribePatient'
import hashParse from '../functions/hashParse'
import socket from '../modules/socket'
import Popup from './popup'
import yearsOld from '../functions/yearsOld'
import WrapComponent from '../contexts/wrap'
import PictureId from './pictureId'
import shareIcon from '../icons/group.svg'
import nonShareIcon from '../icons/user.svg'




class ListPatients extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      page : '',
      popup : false,
      subscribePatient : false,
      listPatients : [],
      input : '',
      select : false,
      shared : false,
      admin : false
    }

  }

  componentDidMount = () => {

    window.addEventListener('hashchange', this.hashPage)
    this.updateList()
    socket.on('updateListPatient',this.updateList)

    window.addEventListener('keydown', this.changeAdminMode)


  }

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', this.hashPage)
    window.removeEventListener('keydown', this.changeAdminMode)

  }

  hashPage = ()=>{
    let hashList = hashParse()
    this.setState({page : hashList[0].hashPage})
    if (hashList[0].hashPage.slice(0,7)!=='patient') {
      this.props.context.updatePatientData({})
       this.updateList()
    }


  }

  fetchListPatient = ()=>{
    socket.emit('fetchListPatient', this.state.input, response=>{
      response.success? this.setState({listPatients : response.list}) : this.setState({popup : response})
    })

  }

  fetchListSharedPatient = ()=>{
    socket.emit('fetchListSharedPatient', this.state.input, response=>{
      response.success? this.setState({listPatients : response.list}) : this.setState({popup : response})
    })

  }

  fetchListAdminPatient = ()=>{
    socket.emit('fetchListAdminPatient', this.state.input, response=>{
      response.success? this.setState({listPatients : response.list}) : this.setState({popup : response})
    })

  }

  updateList = ()=>{

    if (this.state.admin) this.fetchListAdminPatient()
    else this.state.shared? this.fetchListSharedPatient() :  this.fetchListPatient()
   
  
     

  }

  changeInput = input=>{
    clearTimeout(this.timerFetchList)
    this.setState({input : input},()=>{
      this.timerFetchList = setTimeout(this.updateList, 500)
    })
    
  }

  select = index=>{
    // console.log(index)
    this.setState({select : index})
  }

  changeSaredStatus = e=>{
    this.setState({shared : !this.state.shared}, this.updateList)
  }

  changeAdminMode=e=>{
    if (e.key==='F1' && this.props.context.jwtData.admin) {
      e.preventDefault()
      this.setState({admin : !this.state.admin}, this.updateList)
    }
  }

  render = () => {

    const divStyle = {
      //border : 'solid',
      position : 'fixed',
      left : this.props.hide? -260 : 10, 
      top : 90, bottom : 20,
      width : 260,
      border : 'solid 2px',
      borderLeft : 'solid 1px', borderTop : 'solid 1px',
      borderColor : 'purple', borderRadius : 10,
      transition : 'all 0.3s',
      zIndex : 1
    }

    const searchStyle = {
     // border : 'solid',
      height : 50
    }

    const addStyle = {
      width : 20,
      opacity : this.state.shared? 0.5 : 1,
      position : 'absolute', top : 13, left : 210,
      cursor : 'pointer',
      transition : 'opacity 0.2s',
      color : 'purple'
    }

    const shareStyle = {
      width : 25,
      opacity : this.state.shared? 1 : 0.5,
      position : 'absolute', top : 10, left : 232,
      cursor : 'pointer',
      transition : 'opacity 0.2s'

    }

    const listStyle = {
      top : 50, position : 'absolute', bottom : 3,
      left : 5, right : 3,
      //border : 'solid',
     // margin : 5, 
      //paddingTop : 45,
      overflow : 'overlay',
      //scrollbarWidth : 'none',
      //height : '90%', 
      //width : '99%'
    }

      const screenStyle = {
      position : 'fixed',
      //border : 'solid green',
      top : 90, left : 280, 
      right : 10, bottom : 20,
      display : 'flex', justifyContent : 'center', alignItems : 'center', flexDirection : 'column'
    }

    const addScreenStyle = {
      width : 50,
      cursor : 'pointer'
    }

    const textScreenStyle = {
      cursor : 'default',
      color : 'purple',

    }

    let inputRegex = new RegExp(this.state.input.replace(/[eéèêë]/,'[eéèêë]').replace(/[aàäâ]/,'[aàäâ]').replace(/[uûü]/,'[uûü]'),'i')
    const regexValidDate = /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/

    return (

      
      <div style={divStyle}>

        {!this.props.hide && <div style={screenStyle}>
          <div style={textScreenStyle}>Ajout d'un nouveau patient</div>
          <img src={add} alt={add} style={addScreenStyle} onClick={e=>window.location.hash = 'subscribePatient'}/>
        </div>}
        

        <div style={searchStyle}>

           {this.state.page === 'subscribePatient' && <SubscribePatient updateList={this.fetchListPatient}/>}

          {this.state.popup && <Popup error={this.state.popup.error} msg={this.state.popup.msg} handleClick={this.fetchListPatient}/>}

          <InputSearch label = 'Rechercher un patient...' handleChange={this.changeInput}/> 

          {!this.state.admin && <img src={nonShareIcon} alt='share' style={addStyle} onClick={this.changeSaredStatus}/>}
          {!this.state.admin && <img src={shareIcon} alt='share' style={shareStyle} onClick={this.changeSaredStatus}/>}
          {this.state.admin && <div style={addStyle}>ADMIN</div>}
        
        </div>
     
        <div style={listStyle}>
          
          {this.state.listPatients.filter(item=>inputRegex.test(item.name.firstname)||inputRegex.test(item.name.lastname)||regexValidDate.test(this.state.input)).map((item, index)=><List index={index} key = {index} data={item} input={this.state.input} over={this.state.select===index} select={this.select}/>)}

        </div>
        

      </div>

      
      
    )

  }

}




class List extends React.Component {

   static defaultProps = {

  }

  constructor(props) {

    super(props)

    this.state = {
      over : false
    }

  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

  over = e=>this.props.select(this.props.index)

  leave = e=>this.props.select(false)

  handleClick = e=>{
    window.location.hash=`patient${this.props.data._id}`
    //this.props.context.updatePatientData(this.props.data)
    this.leave()

  }

  render = () => {

    const lineStyle = {
      //border : 'solid',
     // position : 'absolute',
      //left : 30, right : 20,
      borderRadius : 5,
      margin : 5, padding : 5, marginRight : 20,
      backgroundColor : this.props.over ? 'purple' : '',
      color : this.props.over ? 'white' : 'purple',
      cursor : 'pointer',
    }

    let birthDate = new Date(this.props.data.birthday.year, Number(this.props.data.birthday.month)-1,this.props.data.birthday.day)

    let FormatFunction = (props)=>{
      let inputRegex = props.input.replace(/[eéèêë]/,'[eéèêë]').replace(/[aàäâ]/,'[aàäâ]').replace(/[uûü]/,'[uûü]')
      let split = props.word.replace(new RegExp(inputRegex,'ig'),input=>'<u>'+input+'</u>')
     
      return(
        <span dangerouslySetInnerHTML={{ __html: split }}/>
      )
    }

     const divStyle = {
      position : 'fixed',
     // border : 'solid green',
      top : 90, left : 280, 
      right : 10, bottom : 20,
      display : 'flex', justifyContent : 'center', alignItems : 'center'
     

    }

    const idStyle = {
     
      height : '50%'
    }

   

    return (

      <>
     
      
      <div 
        style={lineStyle} 
        onMouseEnter = {this.over} 
        onMouseLeave = {this.leave}
        onClick = {this.handleClick}
      >

        <b><FormatFunction word = {this.props.data.name.lastname} input={this.props.input}/> <FormatFunction word = {this.props.data.name.firstname} input={this.props.input}/></b><br/> 
        {`${this.props.data.sex==='man'? '♂' : this.props.data.sex==='woman'? '♀' : '⚥' }
        ${yearsOld(birthDate)}`}
        {this.props.data.idUser && <div><i>Adressé par {this.props.data.idUser.name.lastname} {this.props.data.idUser.name.firstname}</i></div>}

      </div>


      {this.props.over && <div style={divStyle}>
          <PictureId patientId={this.props.data._id} style={idStyle}/>
      </div>}

      </>
       
  

    )

  }

}


List = WrapComponent(List)



export default WrapComponent(ListPatients)