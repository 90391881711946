import React from 'react'
import CustomButton from './customButton'
import close from '../icons/cancel.svg'


class Popup extends React.Component {

  static defaultProps = {
    msg : 'Le message à ecrire dans le pop-up!',
    buttonText : 'Fermer',
    error : false,
    handleClick : click=>click,
    handleClickYes : click=>click,
    handleClickNo : click=>click,
    active : true,
    onClose : click=>click,
    close : false,
    choice : false
  }

  constructor(props) {

    super(props)

    this.state = {
     // date : new Date()
    }

  }

  componentDidMount = () => {

  }

  componentWillUnmount = () => {

  }

  render = () => {

    const divStyle = {
      position : 'fixed',
     // border : 'solid',
      width : '100vw', height : '100vh',
      top : 0, left : 0,
      display : 'flex', justifyContent : 'center', alignItems : 'center',
      backgroundColor : 'rgba(255,255,255,0.8)',
      zIndex : 4
    }

    const popupStyle = {
      position : 'relative',
     // border : 'solid',
      width : 300, 
      boxShadow : '5px 5px 8px 3px rgba(0, 0, 0, .2)' ,
      padding : 15,
      //height : 200,
      backgroundColor : 'white',
      border : 'solid 2px',
      borderRight : 'solid 1px', borderBottom : 'solid 1px',
      borderColor : 'purple', borderRadius : 10,
      display : 'flex', justifyContent : 'space-around', alignItems : 'center', flexDirection : 'column'
    }

    const signStyle = {
      color : this.props.error? 'red' : 'green',
      fontSize : '1.5em'
    }

    const buttonStyle = {
     // margin : 50
    }

    

    const buttonChoiceStyleYes = {
     width : 100,
     color : 'green',
     fontSize : '1.8em', padding : 0,

    }

    const buttonChoiceStyleNo = {
     width : 100,
      color : 'red',
     fontSize : '1.8em', padding : 0
    }

    const messageStyle = {
      position : 'relative',
      textAlign : 'center'
    //  top : 10
    }

    const closeStyle={
      position : 'absolute',
      top : 5, right : 5,
      width : 20,
      cursor : 'pointer'
      
    }

    const choiceStyle = {
     // border : 'solid',
      width : '100%',
      display : 'flex', justifyContent : 'space-around'
    }



    return (

      <div style={divStyle}>

        <div style = {popupStyle} >

          {this.props.close && <img src={close} style = {closeStyle} alt='close' onClick={this.props.onClose}/>}

          <div style={messageStyle}>
            <span style={signStyle}>{this.props.error? '✗ ' : this.props.success? '✓ ' : ''}</span>{this.props.msg}
            <div>{this.props.children}</div>
          </div>

          {!this.props.choice && <CustomButton 
            label={this.props.buttonText} 
            style={buttonStyle} 
            handleClick = {this.props.handleClick} 
            active={this.props.active}
            activeEnter = {true}
          />}

          {this.props.choice && <div style={choiceStyle}>
                      
            <CustomButton 
              label='✓' 
              style={buttonChoiceStyleYes} 
              handleClick = {this.props.handleClickYes} 
              active={this.props.active}
              activeEnter = {true}
            />
          
            <CustomButton 
              label='✗' 
              style={buttonChoiceStyleNo} 
              handleClick = {this.props.handleClickNo} 
              active={this.props.active}
              activeEnter = {true}
            />
          
          </div>}
          
          
        </div>
        

      </div>
      


    )

  }

}


export default Popup