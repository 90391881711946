import React from 'react'
import Input from './customInput'
import IMask from 'imask'
import SelectInput from './selectInput'
import luhn from '../functions/luhn'
import AutoCompleteInput from './autocomplete'
import specialities from '../objects/medicalSpecialities'
import jobs from '../objects/medicalJobs'
//import Pass from './passInput'



const LastNameInput = props=>
  <div><Input
    label = {props.label || 'Nom'}
    formatFunction = {input=>input.toUpperCase()}
    validFunction = {input=>input.length>=2}
    value = {props.value}
    localStorage = {props.localStorage}
    sessionStorage = {props.sessionStorage}
    updateValue = {props.updateValue}
    lock = {props.lock}
  /></div>

const FirstNameInput = props=>
  <div><Input
    label = {props.label || 'Prénom'}
    formatFunction = {value=>value.toLowerCase().replace(/^.|( +|-+)./g, val=>val.toUpperCase())}
    validFunction = {input=>input.length>=2}
    value = {props.value}
    localStorage = {props.localStorage}
    sessionStorage = {props.sessionStorage}
    updateValue = {props.updateValue}
    lock = {props.lock}
  /></div>


const MailInput = (props)=>
  <div><Input
    label = {props.label || 'E-mail'}
    formatFunction = {input=>input.replace(' ','').toLowerCase()}
    validFunction = {input=>/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/.test(input)}
    value = {props.value}
    localStorage = {props.localStorage}
    sessionStorage = {props.sessionStorage}
    updateValue = {props.updateValue}
    lock = {props.lock}
  /></div>



const PhoneInput = props=>{

  let mask = {
    mask : '\\0Id 00 00 00 00',
    blocks: {
      Id: {mask: IMask.MaskedRange,from: 6, to: 7}
    }
  }

  return <div><Input
    label = {props.label || 'Téléphone portable'}
    maskOptions = {mask}
    value = {props.value}
    validFunction = {input=>/^0[67]+(\s[0-9]{2}){4}$/.test(input)}
    localStorage = {props.localStorage}
    sessionStorage = {props.sessionStorage}
    updateValue = {props.updateValue}
    lock = {props.lock}
  /></div>

}




const RPPSInput = props=>
  <div><Input
    label = {props.label || 'RPPS'}
    maskOptions = {{mask : '00000000000'}}
    validFunction = {input=>/[0-9]{11}/.test(input) && luhn(input)}
    value = {props.value}
    localStorage = {props.localStorage}
    sessionStorage = {props.sessionStorage}
    loading = {props.loading}
  /></div>



const SexInput = props=>
  <div><SelectInput 
    label = {props.label || 'Sexe'}
    items = {[{value : 'man', label : '♂ Homme'}, {value : 'woman', label : '♀ Femme'}, {value : 'other', label : '⚥ Autre'}]}
    value ={props.value} 
    localStorage = {props.localStorage}
    sessionStorage = {props.sessionStorage}
    updateValue = {props.updateValue}
    lock = {props.lock}

  /></div>


  const JobInput = props=>
  <div><SelectInput 
    label = {props.label || 'Métier'}
    items = {jobs}
    value ={props.value} 
    localStorage = {props.localStorage}
    sessionStorage = {props.sessionStorage}
    updateValue = {props.updateValue}
    lock = {props.lock}

  /></div>

  const SpecialitiesInput = props=>
    <AutoCompleteInput 
      label = 'Spécialité'
      list = {Number(props.job)===40? specialities.dental : specialities.medical}
      value ={props.value} 
      localStorage = {props.localStorage}
      sessionStorage = {props.sessionStorage}
      returnSelectedObject = {props.selectedObject}
      updateValue = {props.updateValue}
      lock = {props.lock}

    />




//const PassInput = props=> <Pass/>
  


export {LastNameInput, FirstNameInput, MailInput, PhoneInput, RPPSInput, SexInput, SpecialitiesInput, JobInput}